/* eslint-disable react/no-array-index-key */
/* eslint-disable array-callback-return */
/* eslint-disable max-lines */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-sequences */
/* eslint-disable react/jsx-fragments */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';
import { Loader, Select } from 'lec-ui';
import queryString from 'query-string';
import moment from 'moment';
import { Tooltip } from 'react-tippy';

import { Row, Col } from 'react-bootstrap';

import * as act from 'app/actions';
import messages from './myLessonsMessages';

import * as myLessonsActions from './myLessonsActions';
import * as panelActions from '../panel/panelActions';

import CardMonth from '../calendar/CardMonth';

import './MyLessons.scss';

const MyLessonsContainer = (props) => {
  const {
    intl,
    location,
    history,
    isLoading,
    getMyClasses,
    myClassesInfo,
    getStudentFilterDisciplines,
    filterDisciplines,
    getScheduledLessonsDates,
    scheduledLessonsDates,
    managePlanScheduledAnalytics,
  } = props;

  function toDayDate(date) {
    return moment(date).startOf('day').toDate();
  }

  const params = queryString.parse(location.search.replace('?', ''));
  const [currentDate, setCurrentDate] = useState(toDayDate());
  const [loading, setLoading] = useState(false);
  const [idPlanScheduled, setIdPlanScheduled] = useState(params.idPlanScheduled || '');
  const [showMore, setShowMore] = useState(false);
  const [displayCount, setDisplayCount] = useState(10);
  const [newFilterIdPlanScheduled, setNewFilterIdPlanScheduled] = useState([]);

  // Function to increment the display count by 10
  const handleViewMore = () => {
    setDisplayCount((prevCount) => prevCount + 10);
  };

  useEffect(async () => {
    setLoading(false);
    await getStudentFilterDisciplines();
    if (params.idPlanScheduled) {
      await getMyClasses({ idPlanScheduled: params.idPlanScheduled });
    }
  }, []);

  useEffect(() => {
    const currentMonthNumber = moment(currentDate).month() + 1;
    const oneMonthNumberFromNow = moment(currentDate).add(1, 'month').month() + 1;
    const twoMonthsNumberFromNow = moment(currentDate).add(2, 'month').month() + 1;

    getScheduledLessonsDates({
      months: [currentMonthNumber, oneMonthNumberFromNow, twoMonthsNumberFromNow],
      idPlanScheduled,
    });
  }, [currentDate]);

  useEffect(async () => {
    const arr = filterDisciplines.map((discipline) => ({
      value: discipline.idPlanScheduled,
      label: discipline.name,
    }));
    setNewFilterIdPlanScheduled(arr);
  }, [filterDisciplines]);

  async function handleChangeDisciplines(id) {
    setIdPlanScheduled(id);
    await getMyClasses({ idPlanScheduled: id });
  }

  // function changeButtonStatus() {
  //   console.log('change button status');
  // }

  function handleBackToPlan() {
    history.push('/');
  }

  function selectDate(date) {
    setCurrentDate(date);
  }

  function nextDate() {
    const nextCurrentDate = moment(currentDate).add(1, 'month').toDate();
    selectDate(nextCurrentDate);
  }

  function previousDate() {
    const nextCurrentDate = moment(currentDate).subtract(1, 'month').toDate();
    selectDate(nextCurrentDate);
  }

  function handleShowMore() {
    setShowMore(!showMore);
  }

  function accessScheduledLesson(idPlanSchedule, idLesson, lessonIndex) {
    managePlanScheduledAnalytics({ idPlanScheduled: idPlanSchedule, idLesson, lessonIndex });
    history.push(
      `/student-lesson?idPlanScheduled=${idPlanSchedule}&idLesson=${idLesson}&lessonIndex=${lessonIndex}`,
    );
  }

  const oneMonthFromNow = moment(currentDate).add(1, 'month').toDate();
  const twoMonthsFromNow = moment(currentDate).add(2, 'month').toDate();

  return (
    <Fragment>
      <Row>
        <Loader show={isLoading || loading} />
        <Col xs={6} md={6} lg={9} xl={9}>
          <Row className="mb-2">
            <Col>
              <div className="cp-container myLessons-container">
                <div className="d-flex align-items-center">
                  {myClassesInfo && myClassesInfo.componentIcon && (
                    <i className={`icon ${myClassesInfo.componentIcon}`} />
                  )}
                  <h1 className="page-title mr-3 mb-0">{intl.formatMessage(messages.title)}</h1>
                  <Select
                    containerclassname="mt-0"
                    placeholder={intl.formatMessage(messages.disciplines)}
                    onChange={(e) => handleChangeDisciplines(e.target.value)}
                    options={newFilterIdPlanScheduled || []}
                    value={idPlanScheduled || ''}
                    nullable="true"
                  />
                </div>

                <div className="d-flex">
                  <button type="button" onClick={() => handleBackToPlan()} className="btn-back">
                    {intl.formatMessage(messages.back)}
                  </button>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="classes-found mb-4">
                {myClassesInfo.myClassesCount} {intl.formatMessage(messages.classesFound)}
              </div>

              <div className="class-block">
                {Object.keys(myClassesInfo).length > 0 &&
                  myClassesInfo.myClasses &&
                  myClassesInfo.myClasses.slice(0, displayCount).map((el, idx) => (
                    <div className="class-item mb-4" key={`idx-${idx}-${Math.random()}`}>
                      <div className="d-flex justify-content-between mb-3">
                        <div className="lc-title d-flex align-items-center">
                          <button
                            type="button"
                            className="btn-classDate"
                            onClick={() =>
                              accessScheduledLesson(
                                params.idPlanScheduled,
                                el.idLesson,
                                el.lessonInfo.lessonIndex,
                              )
                            }
                          >
                            {intl.formatMessage(messages.class)} {el.lessonInfo.label}
                          </button>
                          <div className="class-title">{el.lessonInfo.title}</div>
                        </div>

                        <div className="icons-block d-flex align-items-center justify-content-end">
                          {el.lessonInfo.chatMessagesCount > 0 && (
                            <Tooltip
                              title={intl.formatMessage(messages.messages)}
                              size="small"
                              theme="light"
                              arrow
                            >
                              <div className="icon-item">
                                <div className="status">{el.lessonInfo.chatMessagesCount}</div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  height="1em"
                                  viewBox="0 0 512 512"
                                >
                                  <path d="M123.6 391.3c12.9-9.4 29.6-11.8 44.6-6.4c26.5 9.6 56.2 15.1 87.8 15.1c124.7 0 208-80.5 208-160s-83.3-160-208-160S48 160.5 48 240c0 32 12.4 62.8 35.7 89.2c8.6 9.7 12.8 22.5 11.8 35.5c-1.4 18.1-5.7 34.7-11.3 49.4c17-7.9 31.1-16.7 39.4-22.7zM21.2 431.9c1.8-2.7 3.5-5.4 5.1-8.1c10-16.6 19.5-38.4 21.4-62.9C17.7 326.8 0 285.1 0 240C0 125.1 114.6 32 256 32s256 93.1 256 208s-114.6 208-256 208c-37.1 0-72.3-6.4-104.1-17.9c-11.9 8.7-31.3 20.6-54.3 30.6c-15.1 6.6-32.3 12.6-50.1 16.1c-.8 .2-1.6 .3-2.4 .5c-4.4 .8-8.7 1.5-13.2 1.9c-.2 0-.5 .1-.7 .1c-5.1 .5-10.2 .8-15.3 .8c-6.5 0-12.3-3.9-14.8-9.9c-2.5-6-1.1-12.8 3.4-17.4c4.1-4.2 7.8-8.7 11.3-13.5c1.7-2.3 3.3-4.6 4.8-6.9c.1-.2 .2-.3 .3-.5z" />
                                </svg>
                              </div>
                            </Tooltip>
                          )}
                          {el.lessonInfo.hasVirtualClasses && (
                            <Tooltip
                              title={intl.formatMessage(messages.onlineClassLink)}
                              size="small"
                              theme="light"
                              arrow
                            >
                              <div className="icon-item">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="43.575"
                                  height="43.564"
                                  viewBox="0 0 43.575 43.564"
                                >
                                  <path
                                    id="Icon_ionic-ios-globe"
                                    data-name="Icon ionic-ios-globe"
                                    d="M25.157,3.375h-.073a21.782,21.782,0,0,0,.01,43.564h.073a21.782,21.782,0,1,0-.01-43.564ZM44.028,23.691H35.9a41.24,41.24,0,0,0-1.026-7.98,31.9,31.9,0,0,0,5.2-2.2A18.762,18.762,0,0,1,44.028,23.691Zm-20.337,0H17.219a37.223,37.223,0,0,1,.922-7.236,31.861,31.861,0,0,0,5.55.744Zm0,2.932v6.482a32,32,0,0,0-5.55.744,37.218,37.218,0,0,1-.922-7.226Zm2.932,0h6.419a37,37,0,0,1-.922,7.215,31.258,31.258,0,0,0-5.5-.733Zm0-2.932V17.2a32,32,0,0,0,5.5-.733,37.114,37.114,0,0,1,.922,7.226ZM38.08,11.344A29.093,29.093,0,0,1,34.1,12.968,21.623,21.623,0,0,0,31.325,7.26,18.95,18.95,0,0,1,38.08,11.344Zm-6.744,2.377a29.472,29.472,0,0,1-4.712.628V6.663C28.4,7.627,30.142,10.213,31.336,13.722Zm-7.645-7.1v7.718a28.964,28.964,0,0,1-4.765-.639C20.141,10.161,21.9,7.574,23.691,6.621Zm-4.775.67a21.793,21.793,0,0,0-2.754,5.665,29.747,29.747,0,0,1-3.927-1.613A18.667,18.667,0,0,1,18.916,7.292Zm-8.681,6.231A31.27,31.27,0,0,0,15.387,15.7a39.578,39.578,0,0,0-1.026,7.98H6.3A18.665,18.665,0,0,1,10.234,13.523Zm-3.948,13.1H14.35a41.134,41.134,0,0,0,1.026,7.98,33.178,33.178,0,0,0-5.152,2.178A18.764,18.764,0,0,1,6.286,26.623ZM12.224,38.97a29.309,29.309,0,0,1,3.938-1.613,21.871,21.871,0,0,0,2.754,5.676A19.131,19.131,0,0,1,12.224,38.97Zm6.7-2.367a29.116,29.116,0,0,1,4.765-.639v7.729C21.89,42.74,20.141,40.143,18.926,36.6Zm7.7,7.048v-7.7a29.473,29.473,0,0,1,4.712.628C30.142,40.1,28.4,42.688,26.623,43.651Zm4.712-.6a21.623,21.623,0,0,0,2.775-5.707,28.614,28.614,0,0,1,3.979,1.634A19.168,19.168,0,0,1,31.336,43.054Zm8.744-6.262a31.9,31.9,0,0,0-5.2-2.2A41.025,41.025,0,0,0,35.9,26.623h8.126A18.68,18.68,0,0,1,40.08,36.792Z"
                                    transform="translate(-3.375 -3.375)"
                                    fill="#9a9a9a"
                                  />
                                </svg>
                              </div>
                            </Tooltip>
                          )}
                          {el.lessonInfo.hasSimplificaEvaluations && (
                            <Tooltip
                              title={intl.formatMessage(messages.simplificaLink)}
                              size="small"
                              theme="light"
                              arrow
                            >
                              <div className="icon-item">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="38.73"
                                  height="48.413"
                                  viewBox="0 0 38.73 48.413"
                                >
                                  <path
                                    id="Caminho_494"
                                    data-name="Caminho 494"
                                    d="M19.365,0,0,12.449V35.964L11.066,42.88,30.431,30.431V17.982L27.664,16.6,13.832,24.9V19.365l13.832-8.3,6.916,4.15V33.2L15.215,45.646l4.15,2.766L38.73,35.964V12.449L27.664,5.533,9.682,17.982V30.431l1.383,1.383,15.215-9.683v5.533L11.066,37.347,4.15,33.2V15.215L23.515,2.766Z"
                                    fill="#9a9a9a"
                                  />
                                </svg>
                              </div>
                            </Tooltip>
                          )}
                          {el.lessonInfo.filesCount > 0 && (
                            <Tooltip
                              title={intl.formatMessage(messages.attachments)}
                              size="small"
                              theme="light"
                              arrow
                            >
                              <div className="icon-item">
                                <div className="status">{el.lessonInfo.filesCount}</div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  height="1em"
                                  viewBox="0 0 448 512"
                                >
                                  <path d="M364.2 83.8c-24.4-24.4-64-24.4-88.4 0l-184 184c-42.1 42.1-42.1 110.3 0 152.4s110.3 42.1 152.4 0l152-152c10.9-10.9 28.7-10.9 39.6 0s10.9 28.7 0 39.6l-152 152c-64 64-167.6 64-231.6 0s-64-167.6 0-231.6l184-184c46.3-46.3 121.3-46.3 167.6 0s46.3 121.3 0 167.6l-176 176c-28.6 28.6-75 28.6-103.6 0s-28.6-75 0-103.6l144-144c10.9-10.9 28.7-10.9 39.6 0s10.9 28.7 0 39.6l-144 144c-6.7 6.7-6.7 17.7 0 24.4s17.7 6.7 24.4 0l176-176c24.4-24.4 24.4-64 0-88.4z" />
                                </svg>
                              </div>
                            </Tooltip>
                          )}
                        </div>
                      </div>

                      <div>
                        {el.lessonInfo.text.length > 355 && !showMore
                          ? `${el.lessonInfo.text.slice(0, 355)}...`
                          : el.lessonInfo.text}
                        <br />
                        {/* {el.lessonInfo.text.length > 355 && ( */}
                        <button
                          type="button"
                          // onClick={() => handleShowMore()}
                          onClick={() =>
                            accessScheduledLesson(
                              params.idPlanScheduled,
                              el.idLesson,
                              el.lessonInfo.lessonIndex,
                            )
                          }
                          className="btn-showMore mt-2"
                        >
                          {showMore
                            ? intl.formatMessage(messages.viewLess)
                            : intl.formatMessage(messages.viewMore)}
                        </button>
                        {/* )} */}
                      </div>
                    </div>
                  ))}
                {displayCount <
                  (Object.keys(myClassesInfo).length > 0 &&
                    myClassesInfo.myClasses &&
                    myClassesInfo.myClasses.length) && (
                  <button onClick={handleViewMore} type="button" className="btn-loadMore mt-5">
                    {intl.formatMessage(messages.loadMore)}
                  </button>
                )}
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={6} md={6} lg={3} xl={3} className="calendar-container">
          <button type="button" className="calendar-navButton" onClick={() => previousDate()}>
            <i className="fa fa-angle-up" />
          </button>
          <CardMonth
            month={currentDate.getMonth() + 1}
            year={currentDate.getFullYear()}
            appointments={scheduledLessonsDates}
            onClickDate={selectDate}
          />
          <CardMonth
            month={oneMonthFromNow.getMonth() + 1}
            year={oneMonthFromNow.getFullYear()}
            appointments={scheduledLessonsDates}
            onClickDate={() => selectDate()}
          />
          <CardMonth
            month={twoMonthsFromNow.getMonth() + 1}
            year={twoMonthsFromNow.getFullYear()}
            appointments={scheduledLessonsDates}
            onClickDate={() => selectDate()}
          />

          <button type="button" className="calendar-navButton" onClick={() => nextDate()}>
            <i className="fa fa-angle-down" />
          </button>
        </Col>
      </Row>
    </Fragment>
  );
};

MyLessonsContainer.propTypes = {
  intl: intlShape,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  getMyClasses: PropTypes.func.isRequired,
  myClassesInfo: PropTypes.objectOf(PropTypes.any),
  getStudentFilterDisciplines: PropTypes.func.isRequired,
  filterDisciplines: PropTypes.arrayOf(PropTypes.any).isRequired,
  getScheduledLessonsDates: PropTypes.func.isRequired,
  managePlanScheduledAnalytics: PropTypes.func.isRequired,
  scheduledLessonsDates: PropTypes.arrayOf(PropTypes.any),
};

MyLessonsContainer.defaultProps = {
  intl: [],
  myClassesInfo: {},
  scheduledLessonsDates: [],
};

const mapStateToProps = ({ myLessons, panel }) => ({
  ...myLessons,
  ...panel,
});

export default compose(
  withRouter,
  injectIntl,
  connect(
    mapStateToProps,
    act.promisify(
      { ...myLessonsActions, ...panelActions },
      {
        include: [
          'getMyClasses',
          'getStudentFilterDisciplines',
          'getScheduledLessonsDates',
          'managePlanScheduledAnalytics',
        ],
      },
    ),
  ),
)(MyLessonsContainer);
