/* eslint-disable react/no-danger */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './ModalButton.scss';

function renderLabel({ label }) {
  if (typeof label === 'string') {
    return <div dangerouslySetInnerHTML={{ __html: label }} />;
  }
  return label;
}

const ModalButton = ({ primary, onClick, disabled, ...props }) => {
  const label = renderLabel(props);
  return (
    <a
      {...props}
      className={classNames('modal-button', { primary, disabled }, props.className)}
      onClick={disabled ? undefined : onClick}
    >
      {primary ? <strong>{label}</strong> : label}
    </a>
  );
};

ModalButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  primary: PropTypes.bool,
  label: PropTypes.any.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

ModalButton.defaultProps = {
  primary: false,
  disabled: false,
  className: '',
};
export default ModalButton;
