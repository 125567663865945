import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'changePassword.title',
    defaultMessage: 'Create new password',
  },
  button: {
    id: 'changePassword.button',
    defaultMessage: 'Send',
  },
  errorHash: {
    id: 'changePassword.errorHash',
    defaultMessage: 'The link used is not valid',
  },
  errorUser: {
    id: 'changePassword.errorUser',
    defaultMessage: 'User not found',
  },
  confirmedChangePassword: {
    id: 'changePassword.confirmedChangePassword',
    defaultMessage: 'Password changed with success, you will be redirect to login page',
  },
  errorDefault: {
    id: 'changePassword.errorDefault',
    defaultMessage: '',
  },
  errorEmail: {
    id: 'changePassword.errorEmail',
    defaultMessage: 'There is an user already registered with this e-mail',
  },
  errorVoucherCountry: {
    id: 'changePassword.errorVoucherCountry',
    defaultMessage: 'This code dont belong for your country',
  },
  errorVoucherDeactivated: {
    id: 'changePassword.errorVoucherDeactivated',
    defaultMessage: 'Code already been deactivated',
  },
  errorVoucherOutDate: {
    id: 'changePassword.errorVoucherOutDate',
    defaultMessage: 'This access code is currently unavailable',
  },
  errorVoucherLimits: {
    id: 'changePassword.errorVoucherLimits',
    defaultMessage: 'This code reached the limit of activations',
  },
  errorVoucher: {
    id: 'changePassword.errorVoucher',
    defaultMessage: 'Code not found',
  },
  error503: {
    id: 'changePassword.error503',
    defaultMessage: 'Service unavailable, try again later',
  },
  email: {
    id: 'changePassword.email',
    defaultMessage: 'Email',
  },
  password: {
    id: 'changePassword.password',
    defaultMessage: 'Password',
  },
  confirmPassword: {
    id: 'changePassword.confirmPassword',
    defaultMessage: 'Confirm Password',
  },
  passCriterio: {
    id: 'changePassword.passCriterio',
    defaultMessage: 'The minimum criteria for setting the password are:',
  },
  caracterNumber: {
    id: 'changePassword.caracterNumber',
    defaultMessage: 'Minimum number of 8 characters',
  },
  letterUppercase: {
    id: 'changePassword.letterUppercase',
    defaultMessage: 'Capital letters, from A to Z',
  },
  letterLowercase: {
    id: 'changePassword.letterLowercase',
    defaultMessage: 'Lower case letters, from a to z',
  },
  number: {
    id: 'changePassword.number',
    defaultMessage: 'Use of numeric characters from 0 to 9',
  },
  specialCaracter: {
    id: 'changePassword.specialCaracter',
    defaultMessage: 'Use of special characters (for example, ',
  },
  requiredField: {
    id: 'changePassword.requiredField',
    defaultMessage: 'Required field',
  },
  confirmPassDontMatch: {
    id: 'changePassword.confirmPassDontMatch',
    defaultMessage: 'Password not match',
  },
  passwordNotStrong: {
    id: 'changePassword.passwordDontMatch',
    defaultMessage: 'The password does not meet the minimum requirements',
  },
});
