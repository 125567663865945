import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'classConfiguration.title',
    defaultMessage: 'Content Configuration',
  },
  resources: {
    id: 'classConfiguration.resources',
    defaultMessage: 'Resources',
  },
  onlineClass: {
    id: 'classConfiguration.onlineClass',
    defaultMessage: 'Online class',
  },
  simplifica: {
    id: 'classConfiguration.simplifica',
    defaultMessage: 'Simplifica',
  },
  uploadFile: {
    id: 'classConfiguration.uploadFile',
    defaultMessage: 'Upload file',
  },
  deleteDraft: {
    id: 'classConfiguration.deleteDraft',
    defaultMessage: 'Delete draft',
  },
  saveDraft: {
    id: 'classConfiguration.saveDraft',
    defaultMessage: 'Save draft',
  },
  publish: {
    id: 'classConfiguration.publish',
    defaultMessage: 'Publish',
  },
  classPlanning: {
    id: 'classConfiguration.classPlanning',
    defaultMessage: 'Class planning',
  },
  classProposal: {
    id: 'classConfiguration.classProposal',
    defaultMessage: 'Class proposal',
  },
  dragDrop: {
    id: 'classConfiguration.dragDrop',
    defaultMessage: 'Or drag and drop files here or click to upload',
  },
  quarterly: {
    id: 'classConfiguration.quarterly',
    defaultMessage: 'Quarterly',
  },
  bimonthly: {
    id: 'classConfiguration.bimonthly',
    defaultMessage: 'Bimonthly',
  },
  save: {
    id: 'classConfiguration.save',
    defaultMessage: 'Save',
  },
  cancel: {
    id: 'classConfiguration.cancel',
    defaultMessage: 'Cancel',
  },
  copyMeetLink: {
    id: 'classConfiguration.copyMeetLink',
    defaultMessage: 'Copy and paste the meeting link in the field below',
  },
  copyArchiveLink: {
    id: 'classConfiguration.copyArchiveLink',
    defaultMessage:
      'If you have the shared link of a file on OneDrive or Google Drive, you can copy and paste in the field below',
  },
  copySimplificaLink: {
    id: 'classConfiguration.copySimplificaLink',
    defaultMessage: 'Copy and paste the simplifica link in the field below',
  },
  enterUrlCorrectly: {
    id: 'classConfiguration.enterUrlCorrectly',
    defaultMessage: 'Please, enter the url correctly',
  },
  materialOfTheDay: {
    id: 'classConfiguration.materialOfTheDay',
    defaultMessage: 'Materials of the day',
  },
  planningMaterial: {
    id: 'classConfiguration.planningMaterial',
    defaultMessage: 'Planning materials',
  },
  class: {
    id: 'classConfiguration.class',
    defaultMessage: 'Class',
  },
  studentView: {
    id: 'classConfiguration.studentView',
    defaultMessage: 'Visible to my students',
  },
  search: {
    id: 'classConfiguration.search',
    defaultMessage: 'Search...',
  },
  message: {
    id: 'classConfiguration.message',
    defaultMessage: 'Message',
  },
  back: {
    id: 'classConfiguration.back',
    defaultMessage: 'Back',
  },
  noLinkUploadedYet: {
    id: 'classConfiguration.noLinkUploadedYet',
    defaultMessage: 'No link uploaded yet',
  },
  noMaterialUploadedYet: {
    id: 'classConfiguration.noMaterialUploadedYet',
    defaultMessage: 'No material uploaded yet',
  },
  teacher: {
    id: 'classConfiguration.teacher',
    defaultMessage: 'Teacher',
  },
  studentsFiles: {
    id: 'classConfiguration.studentsFiles',
    defaultMessage: 'Students files',
  },
  close: {
    id: 'classConfiguration.close',
    defaultMessage: 'Close',
  },
  files: {
    id: 'classConfiguration.files',
    defaultMessage: 'Files',
  },
  date: {
    id: 'classConfiguration.date',
    defaultMessage: 'Date',
  },
  sentBy: {
    id: 'classConfiguration.sentBy',
    defaultMessage: 'Sent by',
  },
  newLesson: {
    id: 'classConfiguration.newLesson',
    defaultMessage: 'New lesson',
  },
  actions: {
    id: 'classConfiguration.actions',
    defaultMessage: 'Actions',
  },
  fileUrl: {
    id: 'classConfiguration.fileUrl',
    defaultMessage: 'File/URL',
  },
  pendingUpload: {
    id: 'classConfiguration.pendingUpload',
    defaultMessage: 'pending upload',
  },
  assessment: {
    id: 'classConfiguration.assessment',
    defaultMessage: 'Assessment',
  },
  schedule: {
    id: 'classConfiguration.schedule',
    defaultMessage: 'Schedule',
  },
  edit: {
    id: 'classConfiguration.edit',
    defaultMessage: 'Edit',
  },
  seeReport: {
    id: 'classConfiguration.seeReport',
    defaultMessage: 'See report',
  },
  downloadPDF: {
    id: 'classConfiguration.downloadPDF',
    defaultMessage: 'Download PDF',
  },
  at: {
    id: 'classConfiguration.at',
    defaultMessage: 'at',
  },
  to: {
    id: 'classConfiguration.to',
    defaultMessage: 'to',
  },
  withDurationOf: {
    id: 'classConfiguration.withDurationOf',
    defaultMessage: 'with duration of',
  },
});
