import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'panelFilters.title',
    defaultMessage: 'Panel',
  },
  stageYear: {
    id: 'panelFilters.stageYear',
    defaultMessage: 'Stage - year',
  },
  component: {
    id: 'panelFilters.component',
    defaultMessage: 'Curricular Component',
  },
  period: {
    id: 'panelFilters.period',
    defaultMessage: 'Period',
  },
  courses: {
    id: 'panelFilters.courses',
    defaultMessage: 'Courses',
  },
  allTeachers: {
    id: 'panelFilters.allTeachers',
    defaultMessage: 'All teachers',
  },
});
