/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-danger */
/* eslint-disable no-sequences */
/* eslint-disable react/jsx-fragments */
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { injectIntl, intlShape } from 'react-intl';
import moment from 'moment';

import { Row, Col } from 'react-bootstrap';

import messages from './plansValidationMessages';

import placeholderSrc from 'app/images/placeholder-avatar.svg';

import { Avatar } from 'lec-ui';
import { getUserAvatarUrl } from 'app/appHelpers';

import './PlansValidationCard.scss';

const PlansValidationCard = (props) => {
  const { intl, plan, viewPlan } = props;

  const idPlanAux = plan.idPlan;

  return (
    <Row className="plansValidationCard">
      <div className="planIdLabel">ID #{plan.id}</div>
      <div className="planInfo">
        <Col>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <i className={plan.icon} style={{ fontSize: '54px', marginRight: '10px' }} />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <b>{plan.courseName}</b>
              <b style={{ marginBottom: '5px' }}>{plan.segmentName}</b>
              <span style={{ lineHeight: '20px' }}>{plan.componentName}</span>
              <span style={{ fontSize: '12px', lineHeight: '18px' }}>({plan.collectionName})</span>
            </div>
          </div>
        </Col>
        <Col xs={3} style={{ alignItems: 'flex-start' }}>
          <div className="updatedTeacher">
            <b>{intl.formatMessage(messages.updatedBy)}:</b>
            <Avatar
              src={getUserAvatarUrl(plan.photo)}
              placeholderSrc={placeholderSrc}
              size="28px"
            />
            {plan.username}
          </div>
          <div>
            <b>{intl.formatMessage(messages.updatedAt)}:</b>{' '}
            {moment(plan.updated).format('DD/MM/YYYY')}
          </div>
        </Col>
        <Col xs={3}>
          STATUS:{' '}
          {plan.status === '1'
            ? intl.formatMessage(messages.approved)
            : plan.status === '2'
            ? intl.formatMessage(messages.disapproved)
            : intl.formatMessage(messages.underConstruction)}
        </Col>
        <Col xs={1}>
          <button
            type="button"
            className="iconCard"
            onClick={() => viewPlan(plan.id, plan.idCataloging, plan.idParent, idPlanAux)}
          >
            <i className="icon pe-7s-search" />
            <div className="labelButton">{intl.formatMessage(messages.seePlan)}</div>
          </button>
        </Col>
      </div>
    </Row>
  );
};

PlansValidationCard.propTypes = {
  intl: intlShape.isRequired,
  plan: PropTypes.any.isRequired,
};

export default compose(injectIntl)(PlansValidationCard);
