import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'myPlans.title',
    defaultMessage: 'My Class',
  },
  buttonLabel: {
    id: 'myPlans.buttonLabel',
    defaultMessage: 'Add Group',
  },
  buttonLabelStudent: {
    id: 'myPlans.buttonLabelStudent',
    defaultMessage: 'Join Group',
  },
  modalRemoveTitle: {
    id: 'myPlans.modalRemoveTitle',
    defaultMessage: 'Remove group',
  },
  modalRemoveMessage: {
    id: 'myPlans.modalRemoveMessage',
    defaultMessage: 'Are you sure about that?',
  },
  modalRemoveError: {
    id: 'myPlans.modalRemoveError',
    defaultMessage: "There's a problem to remove the group... Try again later",
  },
  delete: {
    id: 'myPlans.delete',
    defaultMessage: 'Delete',
  },
  cancel: {
    id: 'myPlans.cancel',
    defaultMessage: 'Cancel',
  },
  confirm: {
    id: 'myPlans.confirm',
    defaultMessage: 'Confirm',
  },
  errorEva: {
    id: 'myPlans.errorEva',
    defaultMessage: 'Not allowed to delete a class from Eva',
  },
  addClassModalTitle: {
    id: 'myPlans.addClassModalTitle',
    defaultMessage: 'Add Class',
  },
  subtitle: {
    id: 'myPlans.subtitle',
    defaultMessage: 'To add a class, you must:',
  },
  message: {
    id: 'myPlans.message',
    defaultMessage:
      'Download the template available below, fill in the excel document and upload this file, so that the registration is automatic.',
  },
  downloadListTemplate: {
    id: 'myPlans.downloadListTemplate',
    defaultMessage: 'DOWNLOAD TEMPLATE LIST',
  },
  alreadyFilledFile: {
    id: 'myPlans.alreadyFilledFile',
    defaultMessage: 'If you already have the file filled in:',
  },
  dragAndDropMessage: {
    id: 'myPlans.dragAndDropMessage',
    defaultMessage: 'Drag and drop the file to upload it',
  },
  clickHereAlternative: {
    id: 'myPlans.clickHereAlternative',
    defaultMessage: 'or click here',
  },
  manuallyUpload: {
    id: 'myPlans.manuallyUpload',
    defaultMessage: 'Register manually',
  },
  continue: {
    id: 'myPlans.continue',
    defaultMessage: 'Continue',
  },
  loadingMessage: {
    id: 'myPlans.loadingMessage',
    defaultMessage: 'Loading...',
  },
  errorGeneric: {
    id: 'myPlans.errorGeneric',
    defaultMessage: 'Error loading file',
  },
  uploadedSuccess: {
    id: 'myPlans.uploadedSuccess',
    defaultMessage: 'Successfully uploaded',
  },
  uploading: {
    id: 'myPlans.uploading',
    defaultMessage: 'Loading...',
  },
  noData: {
    id: 'myPlans.noData',
    defaultMessage: "There's no classes to show",
  },
  students: {
    id: 'myPlans.students',
    defaultMessage: 'Students',
  },
  loadMore: {
    id: 'myPlans.loadMore',
    defaultMessage: 'Load More',
  },
  tests: {
    id: 'myPlans.tests',
    defaultMessage: 'TESTS',
  },
  viewStudents: {
    id: 'myPlans.viewStudents',
    defaultMessage: 'View students',
  },
  modalFieldGroupName: {
    id: 'myPlans.groupName',
    defaultMessage: 'Group Name',
  },
  modalFieldVoucher: {
    id: 'myPlans.voucher',
    defaultMessage: 'Code',
  },
  modalFieldSchool: {
    id: 'myPlans.school',
    defaultMessage: 'School',
  },
  selectSchoolPlaceholder: {
    id: 'myPlans.selectSchoolPlaceholder',
    defaultMessage: 'Select an option...',
  },
  modalErrorMessage: {
    id: 'myPlans.modalErrorMessage',
    defaultMessage: 'A course with this name already exists',
  },
  modalError15Message: {
    id: 'myPlans.error15',
    defaultMessage: 'Code not found',
  },
  modalError11Message: {
    id: 'myPlans.error11',
    defaultMessage: 'This code have same course already added',
  },
  singularTitle: {
    id: 'myPlans.singularTitle',
    defaultMessage: 'ANSWER CARD UPLOAD',
  },
  pluralTitle: {
    id: 'myPlans.pluralTitle',
    defaultMessage: 'ANSWER CARDS UPLOAD',
  },
  loading: {
    id: 'myPlans.loading',
    defaultMessage:
      '<div style="display:flex">Please wait... <span class="loading show simple"></span></div>',
  },
  listUploaded: {
    id: 'myPlans.listUploaded',
    defaultMessage: 'List Uploaded',
  },
  uploadListSuccessfull: {
    id: 'myPlans.uploadListSuccessfull',
    defaultMessage: 'List loaded successfully',
  },
  uploadListError: {
    id: 'myPlans.uploadListError',
    defaultMessage: '{messageError}',
  },
  uploadListErrorGeneric: {
    id: 'myPlans.uploadListErrorGeneric',
    defaultMessage: 'File not found please try again later',
  },
  orderBy: {
    id: 'myPlans.YearsTabs.orderBy',
    defaultMessage: 'Order by',
  },
  all: {
    id: 'myPlans.YearsTabs.all',
    defaultMessage: 'All',
  },
  of: {
    id: 'myPlans.YearsTabs.of',
    defaultMessage: 'of',
  },
  next: {
    id: 'myPlans.YearsTabs.next',
    defaultMessage: 'Next',
  },
  previous: {
    id: 'myPlans.YearsTabs.previous',
    defaultMessage: 'Previous',
  },
  back: {
    id: 'myPlans.back',
    defaultMessage: 'Back',
  },
});
