import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { injectIntl, intlShape } from 'react-intl';

import messages from '../myClassMessages';
import '../MyClass.scss';

const AddGroupButton = (props) => {
  const { intl, onClick, isStudent } = props;

  return (
    <div className="btn btn-primary my-class-button" data-toggle="modal" onClick={onClick}>
      {isStudent
        ? intl.formatMessage(messages.buttonLabelStudent)
        : intl.formatMessage(messages.buttonLabel)}
    </div>
  );
};

AddGroupButton.propTypes = {
  intl: intlShape.isRequired,
  onClick: PropTypes.func.isRequired,
  isStudent: PropTypes.bool.isRequired,
};

export default compose(injectIntl)(AddGroupButton);
