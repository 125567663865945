import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'classManagement.title',
    defaultMessage: 'Select the school year',
  },
  save: {
    id: 'classManagement.save',
    defaultMessage: 'Save',
  },
  back: {
    id: 'classManagement.back',
    defaultMessage: 'Back',
  },
  selectClass: {
    id: 'classManagement.selectClass',
    defaultMessage: 'Select the class',
  },
  class: {
    id: 'classManagement.class',
    defaultMessage: 'Class',
  },
  selectDaysAndClasses: {
    id: 'classManagement.selectDaysAndClasses',
    defaultMessage: 'Mark the days and classes of the week that work the discipline of',
  },
  classesAndDays: {
    id: 'classManagement.classesAndDays',
    defaultMessage: 'classes/days',
  },
  monday: {
    id: 'classManagement.monday',
    defaultMessage: 'M',
  },
  tuesday: {
    id: 'classManagement.tuesday',
    defaultMessage: 'T',
  },
  wednesday: {
    id: 'classManagement.wednesday',
    defaultMessage: 'W',
  },
  thursday: {
    id: 'classManagement.thursday',
    defaultMessage: 'T',
  },
  friday: {
    id: 'classManagement.friday',
    defaultMessage: 'F',
  },
  saturday: {
    id: 'classManagement.saturday',
    defaultMessage: 'S',
  },
  sunday: {
    id: 'classManagement.sunday',
    defaultMessage: 'S',
  },
  errorCode500: {
    id: 'classManagement.errorCode.500',
    defaultMessage: 'An error occurred. Please try again later.',
  },
  errorCode12: {
    id: 'classManagement.errorCode.12',
    defaultMessage:
      'The plan for {courseName} requires more classes than is available. This plan has {planLessonsCount} classes and the group has {teacherLessonsCount} classes available.',
  },
  errorCode14: {
    id: 'classManagement.errorCode.14',
    defaultMessage: 'It is necessary to configure the school year information parameters.',
  },
  errorStartAfterEnd: {
    id: 'classManagement.errorStartAfterEnd',
    defaultMessage: 'Start date must be equal or before the end date.',
  },
  errorRequired: {
    id: 'classManagement.errorRequired',
    defaultMessage: 'Required field.',
  },
  errorMustInform: {
    id: 'classManagement.errorMustInform',
    defaultMessage: 'Inform the date.',
  },
  startSchoolYear: {
    id: 'classManagement.field.earlySchoolYear',
    defaultMessage: 'Start School Year',
  },
  startVacation: {
    id: 'classManagement.field.startVacation',
    defaultMessage: 'Start Vacation',
  },
  endVacation: {
    id: 'classManagement.field.endVacation',
    defaultMessage: 'End Vacation',
  },
  endSchoolYear: {
    id: 'classManagement.field.endSchoolYear',
    defaultMessage: 'End School Year',
  },
  schoolYear: {
    id: 'classManagement.field.schoolYear',
    defaultMessage: 'School Year',
  },
  addVacationPeriod: {
    id: 'classManagement.addVacationPeriod',
    defaultMessage: 'Add vacation period',
  },
  cancel: {
    id: 'classManagement.cancel',
    defaultMessage: 'Cancel',
  },
});
