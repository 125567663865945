/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
// @styleguide

import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import moment from 'moment';
import { capitalize } from '../app/utils/strings';
import * as helpers from './calendarHelpers';
import CardMonthHeader from './CardMonthHeader';
import CardMonthOffset from './CardMonthOffset';
import CardMonthDayCell from './CardMonthDayCell';

import './CardMonth.scss';

const CardMonth = ({ month, year, appointments, onClickDate }) => {
  const dates = helpers.enumerateDatesByMonthAndYear(month, year);

  // const appointments = [{ date: '2023-03-06' }, { date: '2023-03-16' }, { date: '2023-03-20' }];

  // console.log('appointments', appointments);

  return (
    <div className="card card-month">
      <div className="card-body">
        <div className="month-name">{capitalize(moment(dates[0]).format('MMMM'))}</div>
        <div className="card-month--days">
          <CardMonthHeader />
          <CardMonthOffset startDate={dates[0]} />
          {dates.map((date) => (
            <CardMonthDayCell
              date={date}
              appointments={appointments}
              key={date}
              onClick={onClickDate}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

CardMonth.propTypes = {
  month: PropTypes.number.isRequired,
  year: PropTypes.number.isRequired,
  appointments: PropTypes.arrayOf(PropTypes.any),
  onClickDate: PropTypes.func.isRequired,
};

export default pure(CardMonth);
