/* eslint-disable react/jsx-fragments */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import CardMonthCell from './CardMonthCell';

import messages from './cardMonthMessages';

const CardMonthHeader = () => (
  <React.Fragment>
    <FormattedMessage {...messages.sunday}>
      {(txt) => (
        <CardMonthCell className="week" disabled>
          {txt}
        </CardMonthCell>
      )}
    </FormattedMessage>
    <FormattedMessage {...messages.monday}>
      {(txt) => <CardMonthCell className="week">{txt}</CardMonthCell>}
    </FormattedMessage>
    <FormattedMessage {...messages.tuesday}>
      {(txt) => <CardMonthCell className="week">{txt}</CardMonthCell>}
    </FormattedMessage>
    <FormattedMessage {...messages.wednesday}>
      {(txt) => <CardMonthCell className="week">{txt}</CardMonthCell>}
    </FormattedMessage>
    <FormattedMessage {...messages.thursday}>
      {(txt) => <CardMonthCell className="week">{txt}</CardMonthCell>}
    </FormattedMessage>
    <FormattedMessage {...messages.friday}>
      {(txt) => <CardMonthCell className="week">{txt}</CardMonthCell>}
    </FormattedMessage>
    <FormattedMessage {...messages.saturday}>
      {(txt) => (
        <CardMonthCell className="week" disabled>
          {txt}
        </CardMonthCell>
      )}
    </FormattedMessage>
  </React.Fragment>
);

CardMonthHeader.shouldComponentUpdate = false;

export default CardMonthHeader;
