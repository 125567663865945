// @flow

import { apiTask, watchApiTask } from 'app/sagas';
import { authApi, api } from 'app/api';
import { fork, take, all } from 'redux-saga/effects';
import * as actions from './configurationActions';

export const uploadTask = watchApiTask(actions.UPLOAD, authApi.post, '/uploadUserPhoto');
export const removePhotoTask = watchApiTask(
  actions.REMOVE_PHOTO,
  api.post,
  '/users/removeUserPhoto.php',
);

export const saveTask = apiTask(actions.SAVE, api.post, '/users/updateUserInfo.php');
export function* watchSave() {
  while (true) {
    const { payload } = yield take(actions.SAVE);
    yield fork(saveTask, payload);
  }
}

export const getSchoolsTask = apiTask(actions.GET_SCHOOLS, api.get, '/teach/getSchoolsList.php');
export function* watchGetSchools() {
  while (yield take(actions.GET_SCHOOLS)) {
    yield fork(getSchoolsTask);
  }
}

export const getSchoolDetailsTask = apiTask(
  actions.GET_SCHOOL_DETAILS,
  api.post,
  '/teach/getSchoolDetail.php',
);
export function* watchGetSchoolDetails() {
  while (true) {
    const { payload } = yield take(actions.GET_SCHOOL_DETAILS);
    yield fork(getSchoolDetailsTask, payload);
  }
}

export const saveSchoolDetailsTask = apiTask(
  actions.SAVE_SCHOOL_DETAILS,
  api.post,
  '/teach/saveSchoolDetail.php',
);
export function* watchSaveSchoolDetails() {
  while (true) {
    const { payload } = yield take(actions.SAVE_SCHOOL_DETAILS);
    yield fork(saveSchoolDetailsTask, payload);
  }
}

export function* sagas() {
  yield all([
    fork(watchSave),
    fork(uploadTask),
    fork(removePhotoTask),
    fork(watchGetSchools),
    fork(watchGetSchoolDetails),
    fork(watchSaveSchoolDetails),
  ]);
}
