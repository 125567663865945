import { apiTask } from 'app/sagas';
import { authApi, api, apiCi } from 'app/api';
import { take, fork, all, takeEvery } from 'redux-saga/effects';
import * as loginActions from '../login/loginActions';
import { success } from './actions';

const isDev = process.env.NODE_ENV === 'development';

export function* watchLogin() {
  while (true) {
    const { payload } = yield take(
      success(loginActions.LOGIN),
      success(loginActions.EXTERNAL_AUTH),
      success(loginActions.AUTH),
    );
    if (isDev) {
      // api.defaults.headers.common['X-Auth-Token'] = payload.token;
      localStorage.setItem('X-Auth-Token', payload.token);
      localStorage.setItem('X-Token', payload.cookieToken);
    }
  }
}

export const manageCookies = apiTask(
  loginActions.MANAGE_COOKIES,
  api.post,
  '/users/manageCookies.php',
);
export function* watchManageCookies() {
  while (true) {
    const { payload } = yield take(loginActions.MANAGE_COOKIES);
    yield fork(manageCookies, payload);
  }
}

export function* sagas() {
  yield all([fork(watchLogin), fork(watchManageCookies)]);
}
