// @flow
import { fork, all, take } from 'redux-saga/effects';
import * as task from 'app/sagas';
import * as a from 'app/api';
import * as actions from './viewPlanActions';

export const getPlansData = task.apiTask(
  actions.GET_PLANS_DATA,
  a.api.post,
  '/teach/getPlansData.php',
);
export function* watchGetPlansData() {
  while (true) {
    const { payload } = yield take(actions.GET_PLANS_DATA);
    yield fork(getPlansData, payload);
  }
}

export const getCatalogingAbilities = task.apiTask(
  actions.GET_CATALOGING_ABILITIES,
  a.api.post,
  '/teach/getCatalogingAbilities.php',
);
export function* watchGetCatalogingAbilities() {
  while (true) {
    const { payload } = yield take(actions.GET_CATALOGING_ABILITIES);
    yield fork(getCatalogingAbilities, payload);
  }
}

export const getLessonData = task.apiTask(
  actions.GET_LESSON_DATA,
  a.api.post,
  '/teach/getLessonData.php',
);
export function* watchGetLessonData() {
  while (true) {
    const { payload } = yield take(actions.GET_LESSON_DATA);
    yield fork(getLessonData, payload);
  }
}

export const manageTeacherPlan = task.apiTask(
  actions.MANAGE_TEACHER_PLAN,
  a.api.post,
  '/teach/manageTeacherPlan.php',
);
export function* watchManageTeacherPlan() {
  while (true) {
    const { payload } = yield take(actions.MANAGE_TEACHER_PLAN);
    yield fork(manageTeacherPlan, payload);
  }
}

// Root module saga
export function* sagas() {
  yield all([
    fork(watchGetPlansData),
    fork(watchGetCatalogingAbilities),
    fork(watchGetLessonData),
    fork(watchManageTeacherPlan),
  ]);
}
