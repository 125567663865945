import { defineMessages } from 'react-intl';

export default defineMessages({
  resources: {
    id: 'studentLesson.resources',
    defaultMessage: 'Resources',
  },
  onlineClass: {
    id: 'studentLesson.onlineClass',
    defaultMessage: 'Online class',
  },
  simplifica: {
    id: 'studentLesson.simplifica',
    defaultMessage: 'Simplifica',
  },
  uploadFile: {
    id: 'studentLesson.uploadFile',
    defaultMessage: 'Upload file',
  },
  class: {
    id: 'studentLesson.class',
    defaultMessage: 'Class',
  },
  search: {
    id: 'studentLesson.search',
    defaultMessage: 'Search...',
  },
  message: {
    id: 'studentLesson.message',
    defaultMessage: 'Message',
  },
  back: {
    id: 'studentLesson.back',
    defaultMessage: 'Back',
  },
  materialOfTheDay: {
    id: 'studentLesson.materialOfTheDay',
    defaultMessage: 'Materials of the day',
  },
  teacher: {
    id: 'studentLesson.teacher',
    defaultMessage: 'Teacher',
  },
  save: {
    id: 'studentLesson.save',
    defaultMessage: 'Save',
  },
  cancel: {
    id: 'studentLesson.cancel',
    defaultMessage: 'Cancel',
  },
  noMaterialUploadedYet: {
    id: 'studentLesson.noMaterialUploadedYet',
    defaultMessage: 'No material uploaded yet',
  },
  dragDrop: {
    id: 'studentLesson.dragDrop',
    defaultMessage: 'Or drag and drop files here or click to upload',
  },
  teacherFiles: {
    id: 'studentLesson.teacherFiles',
    defaultMessage: 'Teacher files',
  },
  close: {
    id: 'studentLesson.close',
    defaultMessage: 'Close',
  },
  fileUrl: {
    id: 'studentLesson.fileUrl',
    defaultMessage: 'File/URL',
  },
  date: {
    id: 'studentLesson.date',
    defaultMessage: 'Date',
  },
  sentBy: {
    id: 'studentLesson.sentBy',
    defaultMessage: 'Sent by',
  },
  pendingUpload: {
    id: 'studentLesson.pendingUpload',
    defaultMessage: 'pending upload',
  },
  actions: {
    id: 'studentLesson.actions',
    defaultMessage: 'Actions',
  },
  notPossibleToUpload: {
    id: 'studentLesson.notPossibleToUpload',
    defaultMessage: 'It is not possible to upload files outside the class day',
  },
});
