import React from 'react';

import './tooltip.scss';

function Tooltip({
  initials,
  id,
  name,
  email,
  edit,
  see,
  color,
  isOpen,
  canChange,
  onMouseEnter,
  onMouseLeave,
  onChangeEdit,
  onChangeSee,
  contentAbility,
}) {
  if (isOpen) {
    return (
      <div id="tooltip" className="share-settings" onMouseLeave={onMouseLeave}>
        <div className="row align-items-center">
          {initials ? (
            <div className="col-auto pr-0">
              <span className="initials initials-md inline" style={{ backgroundColor: color }}>
                {initials}
              </span>
            </div>
          ) : (
            ''
          )}

          <div className="col pr-0 text-left">
            <h2>{name || contentAbility}</h2>
            <span className="email">{email}</span>
          </div>
        </div>
        {canChange && (
          <ul>
            <li>
              Ver prova
              <span className="switch mr-0 float-right">
                <input
                  id={`switch-see${id}`}
                  type="checkbox"
                  value={see}
                  checked={see}
                  onChange={onChangeSee}
                />
                <label htmlFor={`switch-see${id}`} />
              </span>
            </li>
            <li>
              Edição
              <span className="switch mr-0 float-right">
                <input
                  id={`switch${id}`}
                  type="checkbox"
                  value={edit}
                  checked={edit}
                  onChange={onChangeEdit}
                />
                <label htmlFor={`switch${id}`} />
              </span>
            </li>
          </ul>
        )}
      </div>
    );
  }

  return null;
}

export default Tooltip;
