/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { injectIntl } from 'react-intl';
import './PlanCards.scss';

import LessonCard from './LessonCard';

// This is the main component
const PlanCards = (props) =>
  props.lessons.length > 0 &&
  props.lessons.map((lesson, idx) => (
    <LessonCard
      lesson={lesson}
      number={idx}
      key={idx}
      intl={props.intl}
      period={props.period}
      moveLesson={(direction, idLesson, blockPosition) =>
        props.moveLesson(direction, idLesson, blockPosition)
      }
      removeLesson={(idLesson) => props.removeLesson(idLesson)}
      restoreLesson={(idLesson) => props.restoreLesson(idLesson)}
      createClass={(where, value, type, periodSelected) =>
        props.createClass(where, value, type, periodSelected)
      }
    />
  ));

PlanCards.propTypes = {
  lessons: PropTypes.arrayOf(PropTypes.any),
};

export default compose(injectIntl)(PlanCards);
