import { defineMessages } from 'react-intl';

export default defineMessages({
  bimonthly: {
    id: 'plan-lessons.bimonthly',
    defaultMessage: 'bimonthly',
  },
  quarterly: {
    id: 'plan-lessons.quarterly',
    defaultMessage: 'quarterly',
  },
  applied: {
    id: 'plan-lessons.applied',
    defaultMessage: 'applied',
  },
  scheduled: {
    id: 'plan-lessons.scheduled',
    defaultMessage: 'scheduled',
  },
  notApplied: {
    id: 'plan-lessons.notApplied',
    defaultMessage: 'not applied',
  },
  class: {
    id: 'classConfiguration.class',
    defaultMessage: 'Class',
  },
  configureLessonContent: {
    id: 'classConfiguration.configureLessonContent',
    defaultMessage: 'Configure lesson content',
  },
  changeStateApplied: {
    id: 'classConfiguration.changeStateApplied',
    defaultMessage: 'Change state to applied',
  },
  changeStateNotApplied: {
    id: 'classConfiguration.changeStateNotApplied',
    defaultMessage: 'Change state to not applied',
  },
  progress: {
    id: 'classConfiguration.progress',
    defaultMessage: 'Progress',
  },
});
