import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { Modal } from 'lec-ui';
import messages from './appMessages';

const AppLogoutModal = (props) => {
  const { intl, show, onLogout, onClose } = props;
  return (
    <Modal
      show={show}
      icon="pe-7s-power"
      onClose={onClose}
      title={intl.formatMessage(messages.logout)}
      message={intl.formatMessage(messages.confirmLogout)}
      buttons={[
        {
          label: intl.formatMessage(messages.cancel),
          onClick: onClose,
        },
        {
          label: intl.formatMessage(messages.logout),
          onClick: onLogout,
          primary: true,
        },
      ]}
    />
  );
};

AppLogoutModal.propTypes = {
  intl: intlShape,
  show: PropTypes.bool,
  onLogout: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

AppLogoutModal.defaultProps = {
  intl: [],
  show: false,
};

export default injectIntl(AppLogoutModal);
