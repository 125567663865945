import * as act from 'app/actions';

export const GET_PLANS_DATA = 'view-plan/GET_PLANS_DATA';
export const getPlansData = (payload) => act.action(GET_PLANS_DATA, { payload });

export const GET_CATALOGING_ABILITIES = 'view-plan/GET_CATALOGING_ABILITIES';
export const getCatalogingAbilities = (payload) =>
  act.action(GET_CATALOGING_ABILITIES, { payload });

export const GET_LESSON_DATA = 'view-plan/GET_LESSON_DATA';
export const getLessonData = (payload) => act.action(GET_LESSON_DATA, { payload });

export const MANAGE_TEACHER_PLAN = 'view-plan/MANAGE_TEACHER_PLAN';
export const manageTeacherPlan = (payload) => act.action(MANAGE_TEACHER_PLAN, { payload });
