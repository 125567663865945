/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { injectIntl, intlShape } from 'react-intl';
import { withRouter } from 'react-router-dom';

import { Navbar } from 'lec-ui';

import brand from 'app/images/brand-sm.svg';
import brandApp from 'app/images/brand-planno.svg';
import placeholderSrc from 'app/images/placeholder-avatar.svg';

import { getUserAvatarUrl } from './appHelpers';
// import { getLogsRead } from '../control-panel/controlPanelActions';

import messages from './appMessages';

// function AppNavbar({ user, logsNotRead, intl, history, dispatch, ...props }) {
function AppNavbar({ user, intl, history, dispatch, ...props }) {
  const [isReady, setReadyStatus] = useState(false);

  useEffect(() => {
    if (!isReady) {
      // dispatch(getLogsRead());
      setReadyStatus(true);
    }
  }, [isReady]);

  return (
    <Navbar
      title={intl.formatMessage(messages.title)}
      userName={user.firstName}
      country={user.abbreviation}
      greeting={intl.formatMessage(messages.greeting)}
      brandSrc={brand}
      brandAppSrc={brandApp}
      avatarSrc={getUserAvatarUrl(user.photo)}
      avatarPlaceholderSrc={placeholderSrc}
      home="/profiles"
      // logsNotRead={logsNotRead}
      // handleLogsNotRead={() => history.push('/control-panel')}
      menuItems={[
        {
          label: intl.formatMessage(messages.navbarConfigurationLabel),
          onClick: () => history.push('/configuration'),
        },
        {
          label: intl.formatMessage(messages.navbarLogoutLabel),
          onClick: props.onRequestLogout,
        },
      ]}
    />
  );
}

function mapStateToProps({ app }) {
  return { user: app.user, pathname: app.pathname };
}
// function mapStateToProps({ app, controlPanel }) {
//   return { user: app.user, pathname: app.pathname, logsNotRead: controlPanel.logsNotRead };
// }

AppNavbar.propTypes = {
  intl: intlShape.isRequired,
  history: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.shape({
    abbreviation: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    photo: PropTypes.string,
  }),
  onRequestLogout: PropTypes.func.isRequired,
};

AppNavbar.defaultProps = {
  user: null,
};

export default compose(connect(mapStateToProps), withRouter, injectIntl)(AppNavbar);
