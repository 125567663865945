import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const CardBody = ({ className, ...props }) => (
  <div className={classNames('card-body', className)} {...props} />
);

CardBody.propTypes = {
  className: PropTypes.string,
};

export default CardBody;
