import { success, failure, request } from 'app/actions';
import * as actions from './configurationActions';

export const EditionStatus = {
  PENDING: 1,
  SAVING: 2,
  SAVED: 3,
  FAILED: 4,
};

const initialState = {
  userInfo: '',
  userInfoStatus: null,
  isLoading: false,
  schools: [],
  schoolDetails: null,
  schoolsSearch: [],
  states: [],
  cities: [],
  error: {},
  schoolFormStatus: EditionStatus.PENDING,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case request(actions.SAVE):
      return {
        ...state,
        userInfo: initialState.userInfo,
        userInfoStatus: initialState.userInfoStatus,
        isLoading: true,
      };
    case failure(actions.SAVE):
      return {
        ...state,
        userInfo: action.payload.error,
        userInfoStatus: action.payload.errCode,
        isLoading: initialState.isLoading,
      };
    case success(actions.SAVE):
      return {
        ...state,
        userInfo: action.payload.content,
        userInfoStatus: action.payload.status,
        isLoading: initialState.isLoading,
      };

    case request(actions.GET_SCHOOL_DETAILS):
      return { ...state, schoolDetails: initialState.schoolDetails };
    case request(actions.GET_CITIES):
      return { ...state, schoolsSearch: initialState.schoolsSearch };
    case success(actions.GET_SCHOOLS):
      return { ...state, schools: action.payload.content };
    case success(actions.GET_SCHOOL_DETAILS):
      return { ...state, schoolDetails: action.payload.content, error: {} };
    case success(actions.GET_STATES):
      return { ...state, states: action.payload.content };
    case success(actions.GET_CITIES):
      return { ...state, cities: action.payload.content };
    case actions.RESET_SCHOOL_FORM:
      return { ...state, schoolFormStatus: initialState.schoolFormStatus };
    case request(actions.SAVE_SCHOOL_DETAILS):
      return { ...state, schoolFormStatus: EditionStatus.SAVING, saved: false };
    case success(actions.SAVE_SCHOOL_DETAILS):
      return { ...state, schoolFormStatus: EditionStatus.SAVED, saved: true };
    case failure(actions.SAVE_SCHOOL_DETAILS):
      return { ...state, schoolFormStatus: EditionStatus.FAILED, saved: false };
    default:
      return state;
  }
};

export default reducer;
