/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Avatar from '../avatars/Avatar';
import './Navbar.scss';

const Navbar = (props) => {
  const [menuVisible, setMenuVisible] = useState(false);

  const dispatchAndCloseMenu = (action) => () => {
    if (action) action();
    setMenuVisible(false);
  };

  return (
    <nav className="navbar">
      <div className="container-fluid justify-content-start">
        <a className="navbar-brand" href="/">
          <img src={props.brandSrc} alt="PLANNO" />
        </a>
        <div className="navbar-content">
          <img className="navbar-icon" src={props.brandAppSrc} alt={props.title} />
          {/* <span className="navbar-title">{props.title}</span> */}
        </div>
        <button
          type="button"
          className="hamburger"
          id="mobile-menu"
          onClick={() => setMenuVisible(true)}
        >
          <i className="fa fa-bars" />
        </button>
        {/* <div className={classNames('user-dropdown ml-auto')}>
          <div className="ud-greeting">
            <div className="ud-username">{props.userName}</div>
            {props.menuItems.map((item) => (
              <div style={{ cursor: 'pointer' }} key={item.label}>
                <a onClick={item.onClick}>{item.label}</a>
              </div>
            ))}
          </div>
          <div className="ud-dropdown">
            <div className="ud-link" href="#">
              <div className="avatar-profile">
                <Avatar
                  src={props.avatarSrc}
                  placeholderSrc={props.avatarPlaceholderSrc}
                  fullSize
                />
                <div className="avatar-country" />
              </div>
            </div>
          </div>
        </div> */}
        <div className={classNames('user-dropdown ml-auto', { active: menuVisible })}>
          <div className="ud-greeting">
            {props.greeting}
            <div className="ud-username">{props.userName}</div>
          </div>
          <div className="ud-dropdown">
            <div className="ud-link" href="#">
              <div className="avatar-profile">
                <Avatar
                  src={props.avatarSrc}
                  placeholderSrc={props.avatarPlaceholderSrc}
                  alt=""
                  fullSize
                />
                {props.logsNotRead === 0 ? (
                  <div className="avatar-country">{props.country}</div>
                ) : (
                  <div className="avatar-country logs-not-read" onClick={props.handleLogsNotRead}>
                    {props.logsNotRead}
                  </div>
                )}
              </div>
              <div className="fa fa-angle-down navbar-toogle-menu" />
            </div>
            <div className="ud-menu-wrapper">
              <ul className="ud-menu">
                <li>
                  <a className="sr-only" />
                </li>
                {props.menuItems.map((item) => (
                  <li key={item.label}>
                    <a onClick={dispatchAndCloseMenu(item.onClick)}>{item.label}</a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};
Navbar.propTypes = {
  avatarSrc: PropTypes.string.isRequired,
  avatarPlaceholderSrc: PropTypes.string.isRequired,
  brandSrc: PropTypes.string.isRequired,
  brandAppSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  greeting: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string.isRequired, onClick: PropTypes.func.isRequired }),
  ),
};

Navbar.defaultProps = {
  style: undefined,
  menuItems: [],
};

export default Navbar;
