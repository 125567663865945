/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-danger */
/* eslint-disable no-sequences */
/* eslint-disable react/jsx-fragments */
/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { injectIntl, intlShape } from 'react-intl';

import { Row, Col } from 'react-bootstrap';

import messages from './classManagementMessages';
import './ClassManagement.scss';

const ClassGrid = (props) => {
  const { intl, arrAllDays, handleSelectDayClass, handleAddRow } = props;
  const [allDays, setAllDays] = useState(arrAllDays);

  useEffect(() => {
    setAllDays(arrAllDays);
  }, [arrAllDays]);

  return (
    <div className="class-grid position-relative">
      <Row className="mt-4 d-flex align-items-center grid-gap">
        <Col xs={2} className="grid-label pb-2">
          {intl.formatMessage(messages.classesAndDays)}
        </Col>
        <Col xs={1} className="column-label pb-2 disabled">
          {intl.formatMessage(messages.sunday)}
        </Col>
        <Col xs={1} className="column-label pb-2">
          {intl.formatMessage(messages.monday)}
        </Col>
        <Col xs={1} className="column-label pb-2">
          {intl.formatMessage(messages.tuesday)}
        </Col>
        <Col xs={1} className="column-label pb-2">
          {intl.formatMessage(messages.wednesday)}
        </Col>
        <Col xs={1} className="column-label pb-2">
          {intl.formatMessage(messages.thursday)}
        </Col>
        <Col xs={1} className="column-label pb-2">
          {intl.formatMessage(messages.friday)}
        </Col>
        <Col xs={1} className="column-label pb-2">
          {intl.formatMessage(messages.saturday)}
        </Col>
      </Row>
      {allDays.map((days, index) => (
        <Row key={`index${index}`} className="mt-2 grid-gap">
          <Col xs={2} className="row-label">
            {index + 1}
          </Col>
          <Col xs={1} className="square disabled" />
          {days.map((day, index2) => (
            <Col
              xs={1}
              key={`index2${index2}`}
              className={`square ${day.status === 1 ? 'active' : ''}`}
              onClick={() => handleSelectDayClass(index, index2)}
            />
          ))}
        </Row>
      ))}

      <button type="button" className="add-row position-absolute" onClick={() => handleAddRow()}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="21.328"
          height="21.328"
          viewBox="0 0 21.328 21.328"
        >
          <g id="plus" transform="translate(-2.172 -2.172)">
            <path
              id="Caminho_165"
              data-name="Caminho 165"
              d="M12.836,2.672A10.164,10.164,0,1,0,23,12.836,10.163,10.163,0,0,0,12.836,2.672Zm0,19.515a9.351,9.351,0,1,1,9.351-9.351A9.362,9.362,0,0,1,12.836,22.187Z"
              fill="#9a9a9a"
              stroke="#707070"
              strokeWidth="1"
            />
            <path
              id="Caminho_166"
              data-name="Caminho 166"
              d="M14.517,8H13.7V13.7H8.039v.813H13.7v5.665h.813V14.508h5.692V13.7H14.517Z"
              transform="translate(-1.274 -1.266)"
              fill="#9a9a9a"
              stroke="#707070"
              strokeWidth="1"
            />
          </g>
        </svg>
      </button>
    </div>
  );
};

ClassGrid.propTypes = {
  intl: intlShape,
  arrAllDays: PropTypes.array.isRequired,
  handleAddRow: PropTypes.func.isRequired,
  handleSelectDayClass: PropTypes.func.isRequired,
};

ClassGrid.defaultProps = {
  intl: [],
};

export default compose(injectIntl)(ClassGrid);
