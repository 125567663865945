import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { injectIntl, intlShape } from 'react-intl';

import messages from '../myClassStudentsMessages';
import '../MyClassStudents.scss';

const AddStudentButton = (props) => {
  const { intl, onClick } = props;

  return (
    <div className="btn btn-primary my-class-button-student" data-toggle="modal" onClick={onClick}>
      {intl.formatMessage(messages.buttonLabel)}
    </div>
  );
};

AddStudentButton.propTypes = {
  intl: intlShape.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default compose(injectIntl)(AddStudentButton);
