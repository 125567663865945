import * as act from 'app/actions';
import * as actions from './classConfigurationActions';

const initialState = {
  isLoading: false,
  lessonData: {},
  canSendMessages: false,
  conversationsList: [],
  messagesList: [],
  errorAddScheduledLesson: '',
  successAddScheduledLesson: '',
  scheduledPlanCoursesHours: [],
  mediaFile: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case act.request(actions.GET_SCHEDULED_LESSON_DATA):
      return {
        ...state,
        isLoading: true,
        lessonData: initialState.lessonData,
      };
    case act.success(actions.GET_SCHEDULED_LESSON_DATA):
      return {
        ...state,
        lessonData: action.payload.content,
        isLoading: initialState.isLoading,
      };
    case act.failure(actions.GET_SCHEDULED_LESSON_DATA):
      return {
        ...state,
        isLoading: initialState.isLoading,
      };
    case act.request(actions.MANAGE_SCHEDULED_LESSON_DATA):
      return {
        ...state,
        isLoading: true,
        successManageLesson: initialState.successMenageLesson,
      };
    case act.success(actions.MANAGE_SCHEDULED_LESSON_DATA):
      return {
        ...state,
        isLoading: initialState.isLoading,
      };
    case act.failure(actions.MANAGE_SCHEDULED_LESSON_DATA):
      return {
        ...state,
        isLoading: initialState.isLoading,
      };
    case act.request(actions.EDIT_SCHEDULED_LESSON_RESOURCES):
      return {
        ...state,
        isLoading: true,
      };
    case act.success(actions.EDIT_SCHEDULED_LESSON_RESOURCES):
      return {
        ...state,
        isLoading: initialState.isLoading,
      };
    case act.failure(actions.EDIT_SCHEDULED_LESSON_RESOURCES):
      return {
        ...state,
        isLoading: initialState.isLoading,
      };
    case act.request(actions.DELETE_SCHEDULED_LESSON_RESOURCES):
      return {
        ...state,
        isLoading: true,
      };
    case act.success(actions.DELETE_SCHEDULED_LESSON_RESOURCES):
      return {
        ...state,
        isLoading: initialState.isLoading,
      };
    case act.failure(actions.DELETE_SCHEDULED_LESSON_RESOURCES):
      return {
        ...state,
        isLoading: initialState.isLoading,
      };
    case act.request(actions.CHANGE_SCHEDULED_LESSON_VISIBILITY):
      return {
        ...state,
        isLoading: true,
      };
    case act.success(actions.CHANGE_SCHEDULED_LESSON_VISIBILITY):
      return {
        ...state,
        isLoading: initialState.isLoading,
      };
    case act.failure(actions.CHANGE_SCHEDULED_LESSON_VISIBILITY):
      return {
        ...state,
        isLoading: initialState.isLoading,
      };
    case act.request(actions.GET_SCHEDULED_LESSON_CHAT):
      return {
        ...state,
        isLoading: true,
        canSendMessages: initialState.canSendMessages,
        conversationsList: initialState.conversationsList,
        messagesList: initialState.messagesList,
      };
    case act.success(actions.GET_SCHEDULED_LESSON_CHAT):
      return {
        ...state,
        isLoading: initialState.isLoading,
        canSendMessages: action.payload.canSendMessages,
        conversationsList: action.payload.conversationsList,
        messagesList: action.payload.messagesList,
      };
    case act.failure(actions.GET_SCHEDULED_LESSON_CHAT):
      return {
        ...state,
        isLoading: initialState.isLoading,
        canSendMessages: initialState.canSendMessages,
        conversationsList: initialState.conversationsList,
        messagesList: initialState.messagesList,
      };
    case act.request(actions.ADD_SCHEDULED_LESSON_CHAT_MESSAGE):
      return {
        ...state,
        isLoading: true,
      };
    case act.success(actions.ADD_SCHEDULED_LESSON_CHAT_MESSAGE):
      return {
        ...state,
        isLoading: initialState.isLoading,
      };
    case act.failure(actions.ADD_SCHEDULED_LESSON_CHAT_MESSAGE):
      return {
        ...state,
        isLoading: initialState.isLoading,
      };
    case act.request(actions.DOWNLOAD_LESSON_RESOURCE_FILE):
      return {
        ...state,
        isLoading: true,
      };
    case act.success(actions.DOWNLOAD_LESSON_RESOURCE_FILE):
      return {
        ...state,
        isLoading: initialState.isLoading,
      };
    case act.failure(actions.DOWNLOAD_LESSON_RESOURCE_FILE):
      return {
        ...state,
        isLoading: initialState.isLoading,
      };
    case act.request(actions.ADD_SCHEDULED_LESSON):
      return {
        ...state,
        errorAddScheduledLesson: initialState.errorAddScheduledLesson,
        successAddScheduledLesson: initialState.successAddScheduledLesson,
        isLoading: true,
      };
    case act.success(actions.ADD_SCHEDULED_LESSON):
      return {
        ...state,
        errorAddScheduledLesson: initialState.errorAddScheduledLesson,
        successAddScheduledLesson: action.payload.success,
        isLoading: initialState.isLoading,
      };
    case act.failure(actions.ADD_SCHEDULED_LESSON):
      return {
        ...state,
        errorAddScheduledLesson: action.payload.error,
        successAddScheduledLesson: initialState.successAddScheduledLesson,
        isLoading: initialState.isLoading,
      };
    case act.request(actions.GET_SCHEDULED_PLAN_COURSES_HOURS):
      return {
        ...state,
        isLoading: true,
        scheduledPlanCoursesHours: initialState.scheduledPlanCoursesHours,
      };
    case act.success(actions.GET_SCHEDULED_PLAN_COURSES_HOURS):
      return {
        ...state,
        isLoading: initialState.isLoading,
        scheduledPlanCoursesHours: action.payload.content,
      };
    case act.failure(actions.GET_SCHEDULED_PLAN_COURSES_HOURS):
      return {
        ...state,
        isLoading: initialState.isLoading,
        scheduledPlanCoursesHours: initialState.scheduledPlanCoursesHours,
      };
    case act.request(actions.VIEW_LESSON_RESOURCE_FILE):
      return {
        ...state,
        isLoading: true,
        mediaFile: initialState.mediaFile,
      };
    case act.success(actions.VIEW_LESSON_RESOURCE_FILE):
      return {
        ...state,
        isLoading: initialState.isLoading,
        mediaFile: action.payload,
      };
    case act.failure(actions.VIEW_LESSON_RESOURCE_FILE):
      return {
        ...state,
        isLoading: initialState.isLoading,
        mediaFile: initialState.mediaFile,
      };
    default:
      return state;
  }
};

export default reducer;
