/* eslint-disable max-lines */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-fragments */
import React, { Fragment, useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import * as act from 'app/actions';
import groupBy from 'lodash/groupBy';
import first from 'lodash/first';

import { Alert, Select } from 'lec-ui';
import LoginSignTabs from '../login/LoginSignTabs';
import SchoolSelect from './SchoolSelect';
import * as registerActions from './registerActions';
import * as loginActions from '../login/loginActions';

import messages from './registerMessages';

import './RegisterForm.scss';

const RegisterShapeSchema = {
  firstname: Yup.string().required('errorRequired'),
  lastname: Yup.string().required('errorRequired'),
  email: Yup.string().email('errorInvalidEmail').required('errorRequired'),
  password: Yup.string()
    .required('errorRequired')
    .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/, 'errorStrong'),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref('password'), null], 'errorDontMatch')
    .required('errorRequired'),
  voucher: Yup.string().required('errorRequired'),
  idType: Yup.string().required('errorRequired'),
  idSchool: Yup.number().when('idType', {
    is: (value) => value !== '4',
    then: () => Yup.number().required('errorRequired'),
    otherwise: () => Yup.number(),
  }),
  idCountry: Yup.number().when('idType', {
    is: (value) => value !== '4',
    then: () => Yup.number().required('errorRequired'),
    otherwise: () => Yup.number(),
  }),
  state: Yup.string().when('idType', {
    is: (value) => value !== '4',
    then: () => Yup.string().required('errorRequired'),
    otherwise: () => Yup.string(),
  }),
  city: Yup.string().when('idType', {
    is: (value) => value !== '4',
    then: () => Yup.string().required('errorRequired'),
    otherwise: () => Yup.string(),
  }),
  teachingType: Yup.string().when('idType', {
    is: (value) => value !== '4',
    then: () => Yup.string().required('errorRequired'),
    otherwise: () => Yup.string(),
  }),
};

const RegisterSchema = Yup.object().shape(RegisterShapeSchema);

const initialValues = {
  firstname: '',
  lastname: '',
  email: '',
  password: '',
  passwordConfirm: '',
  voucher: '',
  terms: 0,
  receiveCommercial: 0,
  city: '',
  state: '',
  idSchool: '',
  profile: '',
  courses: [],
  selectedGroups: [],
  idType: '4',
  teachingType: '',
};

const enhanceForm = withFormik({
  mapPropsToValues: () => ({}),
  initialValues,
  validationSchema: RegisterSchema,
  handleSubmit: async (values, { props }) => {
    const { countries, locale, onSubmit } = props;
    const country = countries.find((c) => c.language === locale);
    const payload = { ...values, idCountry: country.idCountry, language: country.language };
    console.log('payload', payload);
    onSubmit(payload);
  },
});
const RegisterForm = (props) => {
  const {
    values,
    login,
    errorType,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    status,
    intl,
    isValid,
    countries,
    states,
    cities,
    setFieldValue,
    schoolsSearch,
    getStates,
    getCities,
    // getSchools,
    // getSchoolDetails,
    searchSchools,
  } = props;
  const [viewPassword, setViewPassword] = useState(false);
  const [viewPasswordConfirm, setViewPasswordConfirm] = useState(false);
  const [school, setSchool] = useState([]);
  const [statesSelect, setStatesSelect] = useState([]);
  const [citiesSelect, setCitiesSelect] = useState([]);
  const [required, setRequired] = useState({
    minimum: false,
    uppercase: false,
    lowercase: false,
    numbers: false,
    specialCaracter: false,
  });

  const [terms, setTerms] = useState(false);
  const [receiveCommercial, setReceiveCommercial] = useState(false);

  const prevValuesRef = useRef();
  const prevStatusRef = useRef();

  const countriesGroupByName = groupBy(countries, 'name');

  const selectCountries = Object.keys(countriesGroupByName).map((el) => ({
    id: first(countriesGroupByName[el]).idCountry,
    value: first(countriesGroupByName[el]).idCountry,
    label: el,
  }));

  async function handleLogin() {
    const form = {
      email: values.email,
      password: values.password,
    };

    await login(form);
  }

  function handleViewPassword() {
    setViewPassword(!viewPassword);
  }

  function handleViewPasswordConfirm() {
    setViewPasswordConfirm(!viewPasswordConfirm);
  }

  function checkError({ field, type }) {
    if (errors[field] && touched[field]) {
      if (type === 'border') {
        return { border: 'solid 1px red' };
      }
      if (type === 'label') {
        return { color: 'red' };
      }
    }
    return {};
  }

  function handleTerms() {
    handleChange({ target: { name: 'terms', value: !terms } });
    setTerms(!terms);
  }

  function handleReceiveCommercial() {
    handleChange({ target: { name: 'receiveCommercial', value: !receiveCommercial } });
    setReceiveCommercial(!receiveCommercial);
  }

  function handleSelectCountry(event) {
    let value = '14';
    if (event) {
      value = event.target.value;
    }

    setSchool(null);

    getStates({ idCountry: value });
    setFieldValue('idCountry', value);
    setFieldValue('state', initialValues.state);
    setFieldValue('city', initialValues.city);
    setFieldValue('idSchool', initialValues.idSchool);
    setFieldValue('firstname', initialValues.firstname);
    setFieldValue('lastname', initialValues.lastname);
    setFieldValue('email', initialValues.email);
    setFieldValue('emailConfirm', initialValues.emailConfirm);
    setFieldValue('password', initialValues.password);
    setFieldValue('passwordConfirm', initialValues.passwordConfirm);
    setFieldValue('idType', initialValues.idType);
    setFieldValue('teachingType', initialValues.teachingType);
    setFieldValue('terms', initialValues.terms);
    setFieldValue('receiveCommercial', initialValues.receiveCommercial);
  }

  function handleSelectState(event) {
    const { value } = event.target;
    setFieldValue('state', value);
    setFieldValue('city', initialValues.city);
    setFieldValue('idSchool', initialValues.idSchool);
    getCities({ idCountry: props.values.idCountry, state: value });
    setSchool(null);
  }

  function handleSelectCity(event) {
    const { value } = event.target;
    setFieldValue('city', value);
    setFieldValue('idSchool', initialValues.idSchool);
    searchSchools({ idCountry: props.values.idCountry, state: props.values.state, city: value });
    setSchool(null);
  }

  function handleSelectSchool(event) {
    setSchool(event);
    setFieldValue('idSchool', event.id);
  }

  useEffect(() => {
    if (prevStatusRef.current !== status.register && status.register === 3) {
      prevStatusRef.current = status;
      handleLogin();
    }
  }, [status]);

  useEffect(() => {
    if (prevValuesRef.current !== values.password) {
      prevValuesRef.current = values.password;
      setRequired({
        minimum: values.password.length >= 8,
        uppercase: !!values.password.match(/[A-Z]/g),
        lowercase: !!values.password.match(/[a-z]/g),
        numbers: !!values.password.match(/\d+/g),
        specialCaracter: !!values.password.match(/[!@#$%&*?]/g),
      });
    }
  }, [values]);

  useEffect(() => {
    handleSelectCountry();
  }, []);

  useEffect(() => {
    if (states && states.length > 0) {
      const newStates = states.map((el) => ({
        value: el.state,
        label: el.state,
      }));
      setStatesSelect(newStates);
    }
  }, [states]);

  useEffect(() => {
    if (cities && cities.length > 0) {
      const newCities = cities.map((el) => ({
        value: el.city,
        label: el.city,
      }));
      setCitiesSelect(newCities);
    }
  }, [cities]);

  return (
    <Fragment>
      <form onSubmit={handleSubmit} className="col ml-auto mr-auto">
        {/* <LanguageSelect /> */}
        <LoginSignTabs />
        <div className="form-group form-group-mob">
          {errorType && Object.keys(errorType).length > 0 && (
            <Alert type="danger">{intl.formatMessage(messages[`error${errorType || 500}`])}</Alert>
          )}
          <label htmlFor="form-name" style={checkError({ field: 'firstname', type: 'label' })}>
            {intl.formatMessage(messages.firstName)}
          </label>
          <input
            className="form-control"
            type="text"
            name="firstname"
            onChange={handleChange}
            onBlur={handleBlur}
            style={checkError({ field: 'firstname', type: 'border' })}
          />
          {errors.firstname && touched.firstname && (
            <div style={{ color: 'red', fontSize: '10px' }}>
              {intl.formatMessage(messages.requiredField)}
            </div>
          )}
        </div>
        <div className="form-group form-group-mob">
          <label htmlFor="form-name" style={checkError({ field: 'lastname', type: 'label' })}>
            {intl.formatMessage(messages.lastName)}
          </label>
          <input
            className="form-control"
            type="text"
            name="lastname"
            onChange={handleChange}
            onBlur={handleBlur}
            style={checkError({ field: 'lastname', type: 'border' })}
          />
          {errors.lastname && touched.lastname && (
            <div style={{ color: 'red', fontSize: '10px' }}>
              {intl.formatMessage(messages.requiredField)}
            </div>
          )}
        </div>
        <div className="form-group form-group-mob">
          <label htmlFor="form-email" style={checkError({ field: 'email', type: 'label' })}>
            {intl.formatMessage(messages.email)}
          </label>
          <input
            className="form-control"
            name="email"
            type="email"
            onChange={handleChange}
            onBlur={handleBlur}
            style={checkError({ field: 'email', type: 'border' })}
          />
          {errors.email && touched.email && (
            <div style={{ color: 'red', fontSize: '10px' }}>
              {intl.formatMessage(messages.requiredField)}
            </div>
          )}
        </div>
        <Fragment>
          <div className="form-group form-group-mob" style={{ position: 'relative' }}>
            <label htmlFor="form-password" style={checkError({ field: 'password', type: 'label' })}>
              {intl.formatMessage(messages.password)}
            </label>
            <input
              className="form-control input-password"
              onChange={handleChange}
              name="password"
              onBlur={handleBlur}
              type={viewPassword ? 'text' : 'password'}
              style={checkError({ field: 'password', type: 'border' })}
            />
            <a className="pass-icon" onClick={handleViewPassword}>
              <i className="far fa-eye" />
            </a>
            {errors.password === 'errorStrong' && touched.password && (
              <div style={{ color: 'red', fontSize: '10px' }}>
                {intl.formatMessage(messages.passwordNotStrong)}
              </div>
            )}
            {errors.password === 'errorRequired' && touched.password && (
              <div style={{ color: 'red', fontSize: '10px' }}>
                {intl.formatMessage(messages.requiredField)}
              </div>
            )}
            <div className="card card-password required-pass-card">
              {intl.formatMessage(messages.passCriterio)}

              <ul>
                <li className={`relative ${required.minimum ? 'checked' : ''}`}>
                  {required.minimum ? <i className="ti-check" /> : <i className="ti-close" />}
                  {intl.formatMessage(messages.caracterNumber)}
                </li>
                <li className={`relative ${required.uppercase ? 'checked' : ''}`}>
                  {required.uppercase ? <i className="ti-check" /> : <i className="ti-close" />}
                  {intl.formatMessage(messages.letterUppercase)}
                </li>
                <li className={`relative ${required.lowercase ? 'checked' : ''}`}>
                  {required.lowercase ? <i className="ti-check" /> : <i className="ti-close" />}
                  {intl.formatMessage(messages.letterUppercase)}
                </li>
                <li className={`relative ${required.numbers ? 'checked' : ''}`}>
                  {required.numbers ? <i className="ti-check" /> : <i className="ti-close" />}
                  {intl.formatMessage(messages.number)}
                </li>
                <li className={`relative ${required.specialCaracter ? 'checked' : ''}`}>
                  {required.specialCaracter ? (
                    <i className="ti-check" />
                  ) : (
                    <i className="ti-close" />
                  )}
                  {intl.formatMessage(messages.specialCaracter)} {` ! @ # $ % & * ?)`}
                </li>
              </ul>
            </div>
          </div>
          <div className="form-group form-group-mob" style={{ position: 'relative' }}>
            <label
              htmlFor="form-password"
              style={checkError({
                field: 'passwordConfirm',
                type: 'label',
              })}
            >
              {intl.formatMessage(messages.confirmPassword)}
            </label>
            <input
              className="form-control input-password"
              name="passwordConfirm"
              type={viewPasswordConfirm ? 'text' : 'password'}
              onChange={handleChange}
              onBlur={handleBlur}
              style={checkError({ field: 'passwordConfirm', type: 'border' })}
            />
            <a className="pass-icon" onClick={handleViewPasswordConfirm}>
              <i className="far fa-eye" />
            </a>
            {errors.passwordConfirm === 'errorDontMatch' && touched.password && (
              <div style={{ color: 'red', fontSize: '10px' }}>
                {intl.formatMessage(messages.confirmPassDontMatch)}
              </div>
            )}
            {errors.passwordConfirm === 'errorRequired' && touched.passwordConfirm && (
              <div style={{ color: 'red', fontSize: '10px' }}>
                {intl.formatMessage(messages.requiredField)}
              </div>
            )}
          </div>
          <div className="form-group form-group-mob">
            <label htmlFor="form-school" style={checkError({ field: 'voucher', type: 'label' })}>
              {intl.formatMessage(messages.accessCode)}
            </label>
            <input
              className="form-control"
              type="text"
              name="voucher"
              onChange={handleChange}
              onBlur={handleBlur}
              style={checkError({ field: 'voucher', type: 'border' })}
            />
            {errors.voucher && touched.voucher && (
              <div style={{ color: 'red', fontSize: '10px' }}>
                {intl.formatMessage(messages.requiredField)}
              </div>
            )}
          </div>
          <div>
            <h5 className="label" style={checkError({ field: 'idType', type: 'label' })}>
              {intl.formatMessage(messages.profileField)}
            </h5>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                label={intl.formatMessage(messages.student)}
                name="idType"
                value="4"
                onChange={handleChange}
                checked={values.idType === '4'}
                style={{ width: 'inherit' }}
              />
              <label className="form-check-label" htmlFor="aluno">
                {intl.formatMessage(messages.student)}
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                label={intl.formatMessage(messages.teacher)}
                name="idType"
                value="3"
                onChange={handleChange}
                style={{ width: 'inherit' }}
              />
              <label className="form-check-label" htmlFor="professor">
                {intl.formatMessage(messages.teacher)}
              </label>
            </div>
            {/* <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                label={intl.formatMessage(messages.manager)}
                name="idType"
                value="2"
                onChange={handleChange}
                style={{ width: 'inherit' }}
              />
              <label className="form-check-label" htmlFor="coordenador">
                {intl.formatMessage(messages.manager)}
              </label>
            </div> */}

            {errors.idType && touched.idType && (
              <div style={{ color: 'red', fontSize: '10px' }}>
                {intl.formatMessage(messages.requiredField)}
              </div>
            )}
          </div>
          {values.idType !== '4' && (
            <div>
              <h5 className="label" style={checkError({ field: 'teachingType', type: 'label' })}>
                {intl.formatMessage(messages.teachingTypes)}
              </h5>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  label={intl.formatMessage(messages.public)}
                  name="teachingType"
                  value="2"
                  onChange={handleChange}
                  style={{ width: 'inherit' }}
                />
                <label className="form-check-label" htmlFor="publico">
                  {intl.formatMessage(messages.public)}
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  label={intl.formatMessage(messages.private)}
                  name="teachingType"
                  value="1"
                  onChange={handleChange}
                  style={{ width: 'inherit' }}
                />
                <label className="form-check-label" htmlFor="privado">
                  {intl.formatMessage(messages.private)}
                </label>
              </div>

              {errors.teachingType && touched.teachingType && (
                <div style={{ color: 'red', fontSize: '10px' }}>
                  {intl.formatMessage(messages.requiredField)}
                </div>
              )}
            </div>
          )}
          {values.idType !== '4' && (
            <div className="form-select-container">
              <div>
                <div className="form-select-block">
                  <Select
                    label={intl.formatMessage(messages.country)}
                    placeholder={intl.formatMessage(messages.selectCountryPlaceholder)}
                    name="idCountry"
                    value={values.idCountry}
                    options={selectCountries}
                    onChange={(e) => handleSelectCountry(e)}
                    disabled
                  />
                </div>
                {errors.idCountry && touched.idCountry && (
                  <div style={{ color: 'red', fontSize: '10px' }}>
                    {intl.formatMessage(messages.requiredField)}
                  </div>
                )}
              </div>
              <div>
                <div className="form-select-block">
                  <Select
                    label={intl.formatMessage(messages.state)}
                    placeholder={intl.formatMessage(messages.selectStatePlaceholder)}
                    name="state"
                    disabled={!values.idCountry}
                    value={values.state}
                    options={statesSelect}
                    onChange={(e) => handleSelectState(e)}
                  />
                </div>
                {errors.state && touched.state && (
                  <div style={{ color: 'red', fontSize: '10px' }}>
                    {intl.formatMessage(messages.requiredField)}
                  </div>
                )}
              </div>
              <div>
                <div className="form-select-block">
                  <Select
                    label={intl.formatMessage(messages.city)}
                    placeholder={intl.formatMessage(messages.selectCityPlaceholder)}
                    disabled={!values.state}
                    value={values.city}
                    name="city"
                    options={citiesSelect}
                    onChange={(e) => handleSelectCity(e)}
                  />
                </div>
                {errors.city && touched.city && (
                  <div style={{ color: 'red', fontSize: '10px' }}>
                    {intl.formatMessage(messages.requiredField)}
                  </div>
                )}
              </div>
            </div>
          )}
          {values.idType !== '4' && values.city && (
            <div>
              <SchoolSelect
                label={intl.formatMessage(messages.school)}
                value={school}
                disabled={cities.length === 0}
                placeholder={intl.formatMessage(messages.searchSchoolPlaceholder)}
                // noOptionsMessage={intl.formatMessage(messages.noSchoolFounded)}
                options={schoolsSearch}
                name="school"
                onChange={(e) => handleSelectSchool(e)}
              />
              {errors.school && touched.school && (
                <div style={{ color: 'red', fontSize: '10px' }}>
                  {intl.formatMessage(messages.requiredField)}
                </div>
              )}
            </div>
          )}
          {values.idType !== '4' && (
            <div>
              <div className="school-add-modal-form-details">
                {school && (
                  <div>
                    <strong>{school.name}</strong>
                    <br />
                    <span>{school.address}</span>
                    <br />
                    <span>{school.zipcode}</span>
                    <br />
                    <span>{school.city}</span> - <span>{school.state}</span>
                  </div>
                )}
                {!school && <span>{intl.formatMessage(messages.noSchoolSelected)}</span>}
              </div>
            </div>
          )}

          <div className="form-group">
            <input
              className="switch"
              id="check"
              name="terms"
              onChange={() => handleTerms()}
              onBlur={handleBlur}
              type="checkbox"
              checked={terms}
              style={checkError({ field: 'terms', type: 'border' })}
            />
            <label htmlFor="check">
              <span
                dangerouslySetInnerHTML={{
                  __html: intl.formatHTMLMessage(messages.readTerms),
                }}
              />
            </label>

            {!terms && touched.terms && (
              <div style={{ color: 'red', fontSize: '10px' }}>
                {intl.formatMessage(messages.requiredField)}
              </div>
            )}
          </div>
          <div className="form-group">
            <input
              className="switch"
              id="check2"
              name="receiveCommercial"
              onChange={() => handleReceiveCommercial()}
              onBlur={handleBlur}
              type="checkbox"
              checked={receiveCommercial}
            />
            <label htmlFor="check2">
              <span
                dangerouslySetInnerHTML={{
                  __html: intl.formatHTMLMessage(messages.receiveCommercial),
                }}
              />
            </label>
          </div>
        </Fragment>
        <button
          className="btn btn-warning btn-lg btn-block"
          type="submit"
          disabled={!isValid || Object.keys(touched).length === 0 || !terms}
          style={{
            cursor:
              !isValid || Object.keys(touched).length === 0 || !terms ? 'not-allowed' : 'pointer',
          }}
        >
          {intl.formatMessage(messages.button)}
        </button>
      </form>
    </Fragment>
  );
};

RegisterForm.propTypes = {
  intl: intlShape.isRequired,
  register: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  error: PropTypes.object,
  errorType: PropTypes.string,
  language: PropTypes.string,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  status: PropTypes.object,
  isValid: PropTypes.bool.isRequired,
  selectCountries: PropTypes.array,
  countries: PropTypes.array,
  states: PropTypes.array,
  cities: PropTypes.array,
  schoolsSearch: PropTypes.array,
  setFieldValue: PropTypes.func.isRequired,
  getStates: PropTypes.func.isRequired,
  getCities: PropTypes.func.isRequired,
  searchSchools: PropTypes.func.isRequired,
};

RegisterForm.defaultProps = {
  error: {},
  status: {},
  language: '',
  errorType: '',
  selectCountries: [],
  countries: [],
  states: [],
  cities: [],
  schoolsSearch: [],
};

const mapStateToProps = ({ login, lang, register }) => ({
  ...register,
  ...login,
  ...lang,
});

export default compose(
  withRouter,
  injectIntl,
  connect(mapStateToProps, act.promisify({ ...registerActions, ...loginActions })),
  enhanceForm,
)(RegisterForm);
