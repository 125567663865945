/* eslint-disable react/no-multi-comp */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import classNames from 'classnames';
import _ from 'lodash';

import CheckIcon from '../app/images/check.svg';

import './RegisterForm.scss';

const SingleValue = ({ data }) => <span style={{ marginLeft: 15 }}>{data.name}</span>;

const OptionLabel = (school, selectedSchools) => {
  const alreadyHasTheSchool = _.find(selectedSchools, (elem) => elem.idSchool === school.id);
  return (
    <div className="school-select-option-label">
      <strong>
        {school.name} {!!alreadyHasTheSchool && <img src={CheckIcon} width="20px" />}
      </strong>
      <br />
      <span>{school.address}</span>
      <br />
      <span>{school.zipcode}</span>
      <br />
      <span>{school.city}</span> - <span>{school.state}</span>
    </div>
  );
};

const SchoolSelect = (props) => {
  const { label, selectedSchools, valid, invalidFeedback, onChange, options, ...rest } = props;
  const [valueSelect, setValueSelect] = useState(null);
  const [optionsSelect, setOptionsSelect] = useState([]);

  useEffect(() => {
    if (options !== optionsSelect) {
      setValueSelect(null);
      setOptionsSelect(options);
    }
  }, [options]);

  function handleChange(value) {
    setValueSelect(value);
    if (onChange) {
      onChange(value);
    }
  }

  function handleInputChange(value) {
    setOptionsSelect(options.filter((d) => new RegExp(value, 'ig').test(JSON.stringify(d))));
  }

  function filter() {
    return options;
  }

  return (
    <div className="form-group form-group-mob">
      {label && (
        <label
          className={classNames('form-control-label', {
            'is-invalid': valid === false,
            'is-valid': valid === true,
          })}
        >
          {label}
        </label>
      )}
      <Select
        {...rest}
        value={valueSelect}
        onChange={(e) => handleChange(e)}
        options={optionsSelect}
        components={{ SingleValue }}
        classNamePrefix="school-select"
        filterOption={() => filter()}
        formatOptionLabel={(school) => OptionLabel(school, selectedSchools)}
        onInputChange={(e) => handleInputChange(e)}
        className={(valid === false ? 'is-invalid' : '') || (valid === true ? 'is-valid' : '')}
      />
      {valid === false && (
        <div className={`invalid-feedback ${valid === false ? 'show' : 'hide'}`}>
          {invalidFeedback}
        </div>
      )}
    </div>
  );
};

export default SchoolSelect;
