import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'validationPlans.title',
    defaultMessage: 'Plans created',
  },
  planned: {
    id: 'validationPlans.planned',
    defaultMessage: 'Planned',
  },
  underConstruction: {
    id: 'validationPlans.underConstruction',
    defaultMessage: 'Under construction',
  },
  updatedAt: {
    id: 'validationPlans.updatedAt',
    defaultMessage: 'Updated at',
  },
  updatedBy: {
    id: 'validationPlans.updatedBy',
    defaultMessage: 'Updated by',
  },
  noPlansFound: {
    id: 'validationPlans.noPlansFound',
    defaultMessage: 'No plans found',
  },
  cancel: {
    id: 'validationPlans.cancel',
    defaultMessage: 'Cancel',
  },
  confirm: {
    id: 'validationPlans.confirm',
    defaultMessage: 'Confirm',
  },
  close: {
    id: 'validationPlans.close',
    defaultMessage: 'Close',
  },
  lessons: {
    id: 'validationPlans.lessons',
    defaultMessage: 'Lessons',
  },
  seePlan: {
    id: 'validationPlans.seePlan',
    defaultMessage: 'See plan',
  },
  approved: {
    id: 'validationPlans.approved',
    defaultMessage: 'Approved',
  },
  disapproved: {
    id: 'validationPlans.disapproved',
    defaultMessage: 'Disapproved',
  },
});
