// @flow
import { combineReducers } from 'redux';
import { success } from 'app/actions';

import app from 'app/appReducer';
import lang from 'app/lang/langReducer';
import login from 'login/loginReducer';
import register from 'register/registerReducer';
import panel from 'panel/panelReducer';
import createPlan from 'createPlan/createPlanReducer';
import myPlans from 'myPlans/myPlansReducer';
import validation from 'validation/validationReducer';
import configuration from 'configuration/configurationReducer';
import classManagement from 'classManagement/classManagementReducer';
import planLessons from 'planLessons/planLessonsReducer';
import classConfiguration from 'classConfiguration/classConfigurationReducer';
import myClass from 'myClass/myClassReducer';
import myLessons from 'myLessons/myLessonsReducer';
import studentLesson from 'studentLesson/studentLessonReducer';
import plansValidation from 'plansValidation/plansValidationReducer';
import viewPlan from 'viewPlan/viewPlanReducer';

import * as loginActions from 'login/loginActions';

const appReducer = combineReducers({
  app,
  lang,
  login,
  register,
  panel,
  createPlan,
  myPlans,
  validation,
  configuration,
  classManagement,
  planLessons,
  classConfiguration,
  myClass,
  myLessons,
  studentLesson,
  plansValidation,
  viewPlan,
});

const rootReducer = (state: any, action: any) => {
  console.log('action', action);
  if (action.type === success(loginActions.LOGOUT)) {
    return appReducer(undefined, action); // reset the state when user logouts
  }
  return appReducer(state, action);
};

export default rootReducer;
