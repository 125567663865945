import * as act from 'app/actions';

export const GET_PLANS_DATA = 'create-plan/GET_PLANS_DATA';
export const getPlansData = (payload) => act.action(GET_PLANS_DATA, { payload });

export const GET_CATALOGING_ABILITIES = 'create-plan/GET_CATALOGING_ABILITIES';
export const getCatalogingAbilities = (payload) =>
  act.action(GET_CATALOGING_ABILITIES, { payload });

export const GET_LESSON_DATA = 'create-plan/GET_LESSON_DATA';
export const getLessonData = (payload) => act.action(GET_LESSON_DATA, { payload });

export const SAVE_PLAN = 'create-plan/SAVE_PLAN';
export const savePlan = (payload) => act.action(SAVE_PLAN, { payload });

export const ADD_PLAN_TABLE_LESSON = 'create-plan/ADD_PLAN_TABLE_LESSON';
export const addPlanTableLesson = (payload) => act.action(ADD_PLAN_TABLE_LESSON, { payload });

export const EDIT_PLAN_TABLE_LESSON = 'create-plan/EDIT_PLAN_TABLE_LESSON';
export const editPlanTableLesson = (payload) => act.action(EDIT_PLAN_TABLE_LESSON, { payload });

export const MOVE_PLAN_TABLE_LESSON = 'create-plan/MOVE_PLAN_TABLE_LESSON';
export const movePlanTableLesson = (payload) => act.action(MOVE_PLAN_TABLE_LESSON, { payload });

export const DELETE_PLAN_TABLE_LESSON = 'create-plan/DELETE_PLAN_TABLE_LESSON';
export const deletePlanTableLesson = (payload) => act.action(DELETE_PLAN_TABLE_LESSON, { payload });

export const RESTORE_PLAN_TABLE_LESSON = 'create-plan/RESTORE_PLAN_TABLE_LESSON';
export const restorePlanTableLesson = (payload) =>
  act.action(RESTORE_PLAN_TABLE_LESSON, { payload });

export const RESTORE_TO_ORIGINAL = 'create-plan/RESTORE_TO_ORIGINAL';
export const restoreToOriginal = (payload) => act.action(RESTORE_TO_ORIGINAL, { payload });
