// @flow
export const REQUEST = 'REQUEST';
export const SUCCESS = 'SUCCESS';
export const FAILURE = 'FAILURE';

export const success = (prefix: string) => `${prefix}_${SUCCESS}`;
export const failure = (prefix: string) => `${prefix}_${FAILURE}`;
export const request = (prefix: string) => `${prefix}_${REQUEST}`;

export function action(type: string, payload: any = {}, args?: any = {}) {
  return { type, ...payload, ...args };
}

export const promisify = (actions, options) => dispatch =>
  Object.keys(actions)
    .filter(k => typeof actions[k] === 'function')
    .map(k => ({
      [k]: payload => {
        if (options && options.exclude && options.exclude.includes(k)) {
          return dispatch(actions[k](payload));
        }
        if (options && options.include && !options.include.includes(k)) {
          return dispatch(actions[k](payload));
        }
        if (payload instanceof FormData) {
          return new Promise((resolve, reject) =>
            dispatch(actions[k]({ payload, resolve, reject })),
          );
        }
        return new Promise((resolve, reject) =>
          dispatch(actions[k]({ ...payload, resolve, reject })),
        );
      },
    }))
    .reduce((a1, a2) => ({ ...a1, ...a2 }), {});
