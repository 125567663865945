/* eslint-disable react/jsx-fragments */
import React, { Component, Fragment, useState } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { promisify } from 'app/actions';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage, injectIntl, intlShape } from 'react-intl';
// import LanguageSelect from 'app/lang/LanguageSelect';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import { Input, Modal, Loader } from 'lec-ui';
import { Alert } from 'react-bootstrap';

import * as loginActions from './loginActions';

import messages from './loginMessages';
import LoginSignTabs from './LoginSignTabs';

const SigninSchema = Yup.object().shape({
  email: Yup.string().email('errorInvalidEmail').required('errorRequired'),
  password: Yup.string().required('errorRequired'),
  emailForgot: Yup.string().email('errorInvalidEmail'),
});

const enhanceForm = withFormik({
  initialValues: { email: '', password: '', emailForgot: '' },
  validationSchema: SigninSchema,
  handleSubmit: (values, { props }) => {
    props.onSubmit(values);
  },
});

const initialState = {
  showModal: {},
  emailSentAlert: null,
  errorsAlerts: null,
  emailForgot: '',
  showExpiredModal: false,
  experied: false,
};

class LoginForm extends Component {
  state = initialState;

  componentDidMount() {
    document.addEventListener('keydown', this.pegaTecla);
  }

  componentWillUnount() {
    document.removeEventListener('keydown');
  }

  pegaTecla = () => {
    const tecla = event.keyCode;
    if ((tecla === 13 || tecla === 27) && this.state.showModal.forgotPassword) {
      this.sendPassword(this.state.emailForgot)();
    }
  };

  getMessageError = (field) => {
    const { intl, errors, error } = this.props;
    if (errors && errors[field]) {
      return intl.formatMessage(messages[errors[field]]);
    }
    return undefined;
  };

  isValid = (field) => {
    const { errors, error, touched } = this.props;

    if (errors && errors[field] && touched[field]) {
      return false;
    }
    if (error.login) return false;
    return touched[field] ? true : undefined;
  };

  showModal = (modalName) => () => {
    this.setState(({ showModal }) => ({ showModal: { ...showModal, [modalName]: true } }));
  };

  closeModal = (modalName) => () => {
    this.setState(({ showModal }) => ({
      showModal: { ...showModal, [modalName]: false },
      emailSentAlert: initialState.emailSentAlert,
      errorsAlerts: initialState.errorsAlerts,
    }));
  };

  sendPassword = (email) => async () => {
    const selectedCountry = this.props.countries.find((c) => c.language === this.props.locale);
    const idCountry = selectedCountry ? selectedCountry.idCountry : undefined;
    let err = null;
    try {
      await this.props.changePasswordLink({ email, idCountry, idAccess: '16' });
    } catch (e) {
      this.setState({ errorsAlerts: this.renderErrors() });
      this.setState({ emailSentAlert: initialState.emailSentAlert });
      err = e;
    } finally {
      if (!err) {
        this.setState({ errorsAlerts: initialState.errorsAlerts });
        this.setState({ emailSentAlert: this.renderEmailSent() });
      }
    }
  };

  handleChangeEmailForgot = (e) => {
    this.setState({ emailForgot: e.target.value });
  };

  handleModalExpired = () => {
    const { values } = this.props;
    const selectedCountry = this.props.countries.find((c) => c.language === this.props.locale);
    const idCountry = selectedCountry ? selectedCountry.idCountry : undefined;
    this.handleShowExpiredModal({ status: true });
    this.props.changePasswordLink({ email: values.email, idCountry, idAccess: '16' });
  };

  handleShowExpiredModal = ({ status }) => {
    this.setState({
      showExpiredModal: status,
      experied: status,
    });
  };

  renderErrors = () => {
    const { errorSent, errorStatus } = this.props;

    const loginErrorAttempts = errorSent ? errorSent.loginErrorAttempts : false;
    const isUserCi = errorSent ? errorSent.isUserCi : false;
    const passwordExpired = errorSent ? errorSent.passwordExpired : false;

    if (errorStatus === 400) {
      /* Login or Password Empty */
      return <FormattedHTMLMessage {...messages[`error${errorStatus}`]} />;
    }

    if ((errorSent && errorSent.message === 'Only CI user') || this.props.userCI || isUserCi) {
      return <FormattedHTMLMessage {...messages.errorOnlyCi} />;
    }
    if (
      errorSent &&
      errorSent.message === 'Invalid Credentials' &&
      (this.props.userCI || isUserCi)
    ) {
      return <FormattedHTMLMessage {...messages.errorInvalidCredentialsCI} />;
    }
    if (passwordExpired && !this.state.experied) {
      this.handleModalExpired();
    }
    if (loginErrorAttempts) {
      if (loginErrorAttempts === 5) {
        /* Attempts reached */
        return <FormattedHTMLMessage {...messages[`error${errorStatus}1`]} />;
      }
      if (loginErrorAttempts <= 4 && loginErrorAttempts > 0) {
        /* Show how many attempts remain */
        return <FormattedHTMLMessage {...messages[`error${errorStatus}0${loginErrorAttempts}`]} />;
      }
    } else if (errorStatus) {
      /* Error 503 */
      return <FormattedHTMLMessage {...messages[`error${errorStatus}`]} />;
    }
  };

  renderEmailSent() {
    const { emailForgot } = this.state;
    return (
      <div>
        <Alert variant="success" className="text-left">
          <FormattedMessage {...messages.emailSent} values={{ email: emailForgot }} />
        </Alert>
      </div>
    );
  }

  render() {
    const { handleSubmit, handleChange, handleBlur, values, isValid, countries, intl, isLoading } =
      this.props;
    const { showModal, emailSentAlert, errorsAlerts, emailForgot } = this.state;

    const closeButton = {
      label: this.props.intl.formatMessage(messages.close),
      onClick: this.closeModal('forgotPassword'),
    };
    const sendButton = {
      id: 2,
      label: this.props.intl.formatMessage(messages.send),
      onClick: this.sendPassword(emailForgot),
      primary: true,
    };

    const modalButtons = this.state.emailSentAlert ? [closeButton] : [closeButton, sendButton];
    const errors = this.renderErrors();

    return (
      <Fragment>
        <form className="col ml-auto mr-auto" onSubmit={handleSubmit}>
          <Loader show={isLoading} />
          {/* <LanguageSelect /> */}
          <LoginSignTabs />
          {!this.state.showModal.forgotPassword && errors && (
            <Alert variant="danger" className="text-left">
              {errors}
            </Alert>
          )}
          <div className="form-group form-group-mob">
            <FormattedMessage {...messages.nameField}>
              {(txt) => (
                <Input
                  label={txt}
                  autoComplete="username"
                  name="email"
                  valid={this.isValid('email')}
                  invalidfeedback={this.getMessageError('email')}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              )}
            </FormattedMessage>
          </div>
          <div className="form-group form-group-mob">
            <FormattedMessage {...messages.passwordField}>
              {(txt) => (
                <Input
                  label={txt}
                  type="password"
                  autoComplete="password"
                  valid={this.isValid('password')}
                  invalidfeedback={this.getMessageError('password')}
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              )}
            </FormattedMessage>
          </div>
          <div className="forgotPassword">
            <FormattedMessage {...messages.forgotPassword}>
              {(txt) => (
                <a href="#" onClick={this.showModal('forgotPassword')}>
                  {txt}
                </a>
              )}
            </FormattedMessage>
          </div>
          <FormattedMessage {...messages.enter}>
            {(txt) => (
              <input
                className="btn btn-primary btn-lg btn-block"
                type="submit"
                value={txt}
                disabled={!isValid}
                style={{ marginTop: '20px' }}
              />
            )}
          </FormattedMessage>
        </form>
        <Modal
          icon="pe-7s-lock"
          title={<FormattedMessage {...messages.forgotPassword} />}
          message={<FormattedMessage {...messages.forgotMessage} />}
          show={showModal.forgotPassword}
          onClose={this.closeModal('forgotPassword')}
          buttons={modalButtons}
        >
          {errorsAlerts}
          {emailSentAlert}
          {!this.state.emailSentAlert && (
            <div className="form-group form-group-mob" style={{ textAlign: 'left' }}>
              <FormattedMessage {...messages.forgotEmail}>
                {(txt) => (
                  <Input
                    label={txt}
                    autoComplete="emailForgot"
                    name="emailForgot"
                    valid={this.isValid('emailForgot')}
                    invalidfeedback={this.getMessageError('emailForgot')}
                    value={this.state.emailForgot}
                    onChange={this.handleChangeEmailForgot}
                    onBlur={handleBlur}
                  />
                )}
              </FormattedMessage>
            </div>
          )}
        </Modal>
        <Modal
          icon="pe-7s-lock"
          title={<FormattedMessage {...messages.expiredTitle} />}
          subtitle={<FormattedMessage {...messages.expiredText} />}
          show={this.state.showExpiredModal}
          onClose={() => this.handleShowExpiredModal({ status: false })}
        />
      </Fragment>
    );
  }
}

LoginForm.propTypes = {
  intl: intlShape.isRequired,
};

const mapStateToProps = ({ login, lang }) => ({ ...login, locale: lang.locale });

export default compose(
  connect(mapStateToProps, promisify({ ...loginActions })),
  enhanceForm,
  injectIntl,
)(LoginForm);
