import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import messages from './appMessages';
import './AppCookiesBanner.scss';

export class AppCookiesBanner extends React.PureComponent {
  // eslint-disable-line react/prefer-stateless-function

  render() {
    return (
      <div>
        <div className={this.props.cookiesBannerShow ? 'cookiesBanner' : 'cookiesBanner hide'}>
          <div className="container">
            <div className="text">
              <p>
                <FormattedMessage {...messages.generalText} />
                <a href="http://linkte.me/politicacookiesapp" target="_blank" rel="noreferrer">
                  <FormattedMessage {...messages.cookiesPolicy} />
                </a>
              </p>
            </div>
            <div className="buttons">
              <a onClick={this.props.showCookiesConfig}>
                <FormattedMessage {...messages.rejectOrConfig} />
              </a>
              <a onClick={() => this.props.saveCookiesConfig(16, 1, 1, 1)}>
                <FormattedMessage {...messages.acceptAll} />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AppCookiesBanner.propTypes = {
  user: PropTypes.object,
  queryParams: PropTypes.object.isRequired,
  intl: intlShape,
};

const mapStateToProps = ({ app }) => ({
  user: app.user,
  queryParams: app.queryParams,
  token: app.token,
});

export default compose(injectIntl, connect(mapStateToProps))(AppCookiesBanner);
