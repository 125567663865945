import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import Input from './Input';

const FieldWrapper = (props) => (
  <Field
    name={props.name}
    render={({ field, form, ...rest }) => {
      const hasError = form.touched[field.name] && form.errors[field.name];
      const isValid = form.touched[field.name] && !form.errors[field.name];
      const helpertext = hasError ? form.errors[field.name] : props.helpertext;
      const value = field.value === 'undefined' ? '' : field.value;
      return (
        <props.component
          {...field}
          {...props}
          error={hasError}
          value={value}
          valid={isValid}
          invalidfeedback={forms.errros[field.name]}
          helpertext={helpertext}
        />
      );
    }}
  />
);

FieldWrapper.defaultProps = {
  component: Input,
};

FieldWrapper.propTypes = {};

export default FieldWrapper;
