/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { injectIntl, intlShape } from 'react-intl';
import { Loader } from 'lec-ui';

import { AppFooter } from 'app/AppFooter';

import brandApp from 'app/images/brand-planno.svg';
import brandSm from 'app/images/brand-sm.svg';
import messages from './loginMessages';

import './Login.scss';

const Login = (props) => {
  const { entering, children, footer, intl } = props;

  return (
    <div>
      <Loader show={entering} />
      <div className="brand-mob">
        <img src={brandApp} alt="" />
        <h1 className="cover-title">{intl.formatMessage(messages.title)}</h1>
      </div>
      <div className="form-wrapper form-login row align-items-center">{children}</div>
      <a className="sm-brand-mob" href="/">
        <img src={brandSm} alt="" />
      </a>
      <div className="cover-wrapper">
        <div className="brand display-none">
          <img src={brandApp} alt="" />
        </div>
        <a className="sm-brand display-none" href="/">
          <img src={brandSm} alt="" />
        </a>
      </div>
      {footer && <AppFooter />}
    </div>
  );
};

Login.propTypes = {
  entering: PropTypes.bool,
  children: PropTypes.any,
  footer: PropTypes.bool,
  intl: intlShape,
};

Login.defaultProps = {
  entering: false,
  children: {},
  footer: false,
  intl: [],
};

export default compose(injectIntl)(Login);
