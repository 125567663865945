import React from 'react';
import './Steps.scss';

function Steps({ dates, setDelayMessage }) {
  return (
    <div className="row steps">
      {dates.map((elem, index) => {
        let className = 'col step';
        if (elem.complete) className += ' done';
        if (elem.ongoing) className += ' active';

        return (
          <div key={elem.id} className={className}>
            {elem.delay && <div className="date delay">{`(${setDelayMessage(elem.delay)})`}</div>}
            <div className="date">{elem.dateString}</div>
            <div className="circle">{elem.complete ? <i className="ti-check" /> : index + 1}</div>
            <div className="text">{elem.name}</div>
          </div>
        );
      })}
    </div>
  );
}

export default Steps;
