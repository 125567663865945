// @flow

import { apiTask } from 'app/sagas';
import { success } from 'app/actions';
import { authApi, api, apiCi } from 'app/api';
import { take, fork, all, takeEvery } from 'redux-saga/effects';
import * as actions from './loginActions';

export const authTask = apiTask(actions.AUTH, authApi.post, '/auth');
export const externalAuthTask = apiTask(actions.EXTERNAL_AUTH, authApi.post, '/auth');
export const loginTask = apiTask(actions.LOGIN, apiCi.post, '/login');
export const logoutTask = apiTask(actions.LOGOUT, authApi.get, '/logout');
export const getCountries = apiTask(actions.COUNTRIES, api.post, '/teach/getCountries.php');
export const forgotPassword = apiTask(
  actions.FORGOT_PASSWORD,
  api.post,
  '/teach/forgotPassword.php',
);
export const changePasswordLink = apiTask(
  actions.CHANGE_PASSWORD_LINK,
  api.post,
  '/users/changePasswordLink.php',
);
export const manageUserTerms = apiTask(
  actions.MANAGE_USER_TERMS,
  api.post,
  '/users/manageUserTerms.php',
);

/**
 * Watch for every dispatch of LOGIN action and call the login task
 */
export function* watchLogin() {
  while (true) {
    const { payload } = yield take(actions.LOGIN);
    yield fork(loginTask, payload);
  }
}

export function* watchAuth(): Iterable<T> {
  while (true) {
    const { payload } = yield take(actions.AUTH);
    yield fork(authTask, { access: 16 });
  }
}

export function* watchExternalAuth(): Iterable<T> {
  while (true) {
    const { payload } = yield take(actions.EXTERNAL_AUTH);
    yield fork(externalAuthTask, { ...payload, access: 16 });
  }
}

export function* watchLogout(): Iterable<T> {
  while (yield take(actions.LOGOUT)) {
    yield fork(logoutTask);
  }
}

export function* watchGetCountries() {
  while (true) {
    const { payload } = yield take(actions.COUNTRIES);
    yield fork(getCountries, payload);
  }
}

export function* watchForgotPassword() {
  while (true) {
    const { payload } = yield take(actions.FORGOT_PASSWORD);
    yield fork(forgotPassword, payload);
  }
}

export const checkCoordinatorPermission = apiTask(
  actions.CHECK_COORDINATOR_PERMISSION,
  api.post,
  '/evaluation-system/checkCoordinatorPermission.php',
);

// export function* watchAuthSuccess() {
//   yield takeEvery(
//     [success(actions.LOGIN), success(actions.AUTH), success(actions.EXTERNAL_AUTH)],
//     checkCoordinatorPermission,
//   );
// }

export function* watchCheckCoordinatorPermission() {
  while (true) {
    const { payload } = yield take(actions.CHECK_COORDINATOR_PERMISSION);
    yield fork(checkCoordinatorPermission, payload);
  }
}

export function* watchChangePasswordLink() {
  while (true) {
    const { payload } = yield take(actions.CHANGE_PASSWORD_LINK);
    yield fork(changePasswordLink, payload);
  }
}

export function* watchManageUserTerms() {
  while (true) {
    const { payload } = yield take(actions.MANAGE_USER_TERMS);
    yield fork(manageUserTerms, payload);
  }
}

export function* sagas(): Iterable<T> {
  yield all([
    fork(authTask, { access: 16 }),
    fork(watchLogin),
    fork(watchAuth),
    fork(watchExternalAuth),
    fork(watchLogout),
    fork(watchGetCountries),
    fork(watchForgotPassword),
    fork(watchCheckCoordinatorPermission),
    // fork(watchAuthSuccess),
    fork(watchChangePasswordLink),
    fork(watchManageUserTerms),
  ]);
}
