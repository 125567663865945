import * as act from 'app/actions';
import * as actions from './panelActions';

const initialState = {
  isLoading: false,
  hasError: false,
  dashboardCards: {},
  filters: [],
  scheduledLessonsDates: [],
  registerSchoolSuccess: false,
  latestAccesses: [],
  abilitiesHeatmap: [],
  abilitiesHeatmapFilter: [],
  segmentFilterType: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case act.request(actions.GET_FILTER_CATALOGINGS):
      return {
        ...state,
        isLoading: true,
        hasError: false,
        filters: initialState.filters,
        segmentFilterType: false,
      };
    case act.success(actions.GET_FILTER_CATALOGINGS):
      return {
        ...state,
        filters: action.payload.content,
        isLoading: initialState.isLoading,
        segmentFilterType: action.payload.segmentFilter,
      };
    case act.success(actions.GET_DASHBOARD_INFO):
      return { ...state, dashboardCards: action.payload.content };
    case act.success(actions.GET_SCHEDULED_LESSONS_DATES):
      return { ...state, scheduledLessonsDates: action.payload.content };
    case act.request(actions.MANAGE_PLAN_SCHEDULED_ANALYTICS):
      return {
        ...state,
        isLoading: true,
      };
    case act.success(actions.MANAGE_PLAN_SCHEDULED_ANALYTICS):
      return { ...state, isLoading: initialState.isLoading };
    case act.request(actions.REGISTER_SCHOOL):
      return {
        ...state,
        isLoading: true,
        registerSchoolSuccess: initialState.registerSchoolSuccess,
      };
    case act.success(actions.REGISTER_SCHOOL):
      return {
        ...state,
        registerSchoolSuccess: true,
        isLoading: initialState.isLoading,
      };
    case act.request(actions.REGISTER_STUDENT_SCHOOL):
      return {
        ...state,
        isLoading: true,
        registerSchoolSuccess: initialState.registerSchoolSuccess,
      };
    case act.success(actions.REGISTER_STUDENT_SCHOOL):
      return {
        ...state,
        registerSchoolSuccess: true,
        isLoading: initialState.isLoading,
      };
    case act.request(actions.GET_LATEST_ACCESSES):
      return { ...state, isLoading: true };
    case act.success(actions.GET_LATEST_ACCESSES):
      return {
        ...state,
        latestAccesses: action.payload.content,
        isLoading: initialState.isLoading,
      };
    case act.request(actions.GET_ABILITIES_HEATMAP):
      return { ...state, isLoading: true };
    case act.success(actions.GET_ABILITIES_HEATMAP):
      return {
        ...state,
        abilitiesHeatmap: action.payload.content,
        isLoading: initialState.isLoading,
      };
    case act.request(actions.GET_ABILITIES_HEATMAP_FILTER):
      return { ...state, isLoading: true };
    case act.success(actions.GET_ABILITIES_HEATMAP_FILTER):
      return {
        ...state,
        abilitiesHeatmapFilter: action.payload.content,
        isLoading: initialState.isLoading,
      };
    case act.failure(actions.GET_FILTER_CATALOGINGS):
    case act.failure(actions.GET_DASHBOARD_INFO):
    case act.failure(actions.GET_SCHEDULED_LESSONS_DATES):
    case act.failure(actions.MANAGE_PLAN_SCHEDULED_ANALYTICS):
    case act.failure(actions.REGISTER_SCHOOL):
    case act.failure(actions.REGISTER_STUDENT_SCHOOL):
    case act.failure(actions.GET_LATEST_ACCESSES):
    case act.failure(actions.GET_ABILITIES_HEATMAP):
    case act.failure(actions.GET_ABILITIES_HEATMAP_FILTER):
      return { ...state, isLoading: initialState.isLoading, hasError: true };

    default:
      return state;
  }
};

export default reducer;
