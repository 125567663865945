// @flow

import { success, failure, request } from 'app/actions';
import { getError } from 'app/appHelpers';
import * as actions from './loginActions';

const initialState = {
  authorizing: null,
  entering: null,
  leaving: null,
  countries: [],
  errorSent: {
    code: null,
    info: null,
    status: null,
    errorSent: '',
  },
  error: {},
  isLoading: false,
  isCoordinator: { permission: null, edit: null },
  forgotFeedback: '',
  forgotError: false,
  manageUserTermsPass: false,
};

const reducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case request(actions.LOGOUT):
      return { ...state, leaving: true };
    case request(actions.FORGOT_PASSWORD):
      return { ...state, isLoading: true, errorSent: initialState.errorSent };
    case success(actions.FORGOT_PASSWORD):
      return {
        ...state,
        message: action.payload.success,
        errorSent: initialState.errorSent,
        isLoading: initialState.isLoading,
      };
    case failure(actions.FORGOT_PASSWORD):
      return { ...state, errorSent: getError(action), isLoading: initialState.isLoading };
    case request(actions.CHANGE_PASSWORD_LINK):
      return {
        ...state,
        isLoading: true,
        errorSent: {
          errorSent: initialState.errorSent.errorSent,
          code: initialState.errorSent.code,
        },
        forgotError: false,
      };
    case success(actions.CHANGE_PASSWORD_LINK):
      return {
        ...state,
        isLoading: false,
        errorSent: {
          errorSent: initialState.errorSent.errorSent,
          code: initialState.errorSent.code,
        },
        forgotError: false,
      };
    case failure(actions.CHANGE_PASSWORD_LINK):
      return {
        ...state,
        isLoading: false,
        errorSent: {
          errorSent: action.payload.error,
          code: action.payload.errCode,
        },
        forgotError: true,
      };
    // case failure(actions.CHANGE_PASSWORD_LINK):
    //   return { ...state, errorSent: getError(action), isLoading: initialState.isLoading };
    case request(actions.COUNTRIES):
      return { ...state };
    case success(actions.COUNTRIES):
      return { ...state, countries: action.payload.content.filter((d) => Number(d.access) === 1) };
    case request(actions.LOGIN):
      return { ...state, entering: true };
    case request(actions.AUTH):
    case request(actions.EXTERNAL_AUTH):
      return { ...state, entering: true, authorizing: true };
    case success(actions.LOGIN):
    case success(actions.AUTH):
    case success(actions.EXTERNAL_AUTH):
      return {
        ...state,
        entering: false,
        authorizing: false,
        manageUserTermsPass: initialState.manageUserTermsPass,
      };
    case success(actions.LOGOUT):
      return { ...state, leaving: false };
    case success(actions.CHECK_COORDINATOR_PERMISSION):
      return {
        ...state,
        isCoordinator: action.payload.contents.isCoordinator,
      };
    case failure(actions.CHECK_COORDINATOR_PERMISSION):
      return {
        ...state,
        isCoordinator: initialState.isCoordinator,
      };
    case failure(actions.AUTH):
    case failure(actions.EXTERNAL_AUTH):
      return { ...state, entering: false, authorizing: false };
    case failure(actions.LOGIN): {
      return {
        ...state,
        entering: false,
        errorSent: action.payload.error,
        errorStatus: action.payload.status,
        error: {
          ...state.error,
          login: getError(action),
        },
      };
    }
    case request(actions.MANAGE_USER_TERMS): {
      return {
        ...state,
        manageUserTermsPass: initialState.manageUserTermsPass,
      };
    }
    case success(actions.MANAGE_USER_TERMS): {
      return {
        ...state,
        manageUserTermsPass: true,
      };
    }
    default:
      return state;
  }
};

export default reducer;
