import * as act from 'app/actions';

export const GET_MY_CLASSES = 'my-lessons/GET_MY_CLASSES';
export const GET_STUDENT_FILTER_DISCIPLINES = 'my-lessons/GET_STUDENT_FILTER_DISCIPLINES';

export const getMyClasses = (payload) => act.action(GET_MY_CLASSES, { payload });
export const getStudentFilterDisciplines = (payload) =>
  act.action(GET_STUDENT_FILTER_DISCIPLINES, { payload });

export const GET_SCHEDULED_LESSONS_DATES = 'my-lessons/GET_SCHEDULED_LESSONS_DATES';
export const getScheduledLessonsDates = (payload) =>
  act.action(GET_SCHEDULED_LESSONS_DATES, { payload });
