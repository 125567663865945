import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import CreatableSelect from 'react-select/creatable';
import './CreatableSelect.scss';

const CreatetableSelectWrapper = ({ readOnly, ...props }) => {
  const handleChange = (value) => {
    if (props.onChange) {
      props.onChange({ target: { value, name: props.name } });
    }
  };
  const handleBlur = () => {
    if (props.onBlur) {
      props.onBlur({ target: { name: props.name } });
    }
  };

  return (
    <div
      className={classNames('form-group form-group-mob', props.containerclassname, {
        'is-invalid': props.valid === false,
        'is-valid': props.valid === true,
        'is-readonly': readOnly,
      })}
    >
      {props.label && (
        <label
          className={classNames('form-control-label', props.labelclassname, {
            'is-invalid': props.valid === false,
            'is-valid': props.valid === true,
          })}
          htmlFor={props.id}
        >
          {props.label}
        </label>
      )}
      <CreatableSelect
        isClearable
        {...props}
        // styles={customStyles}
        className={classNames(
          {
            'hide-indicator': props.hideIndicator,
            'is-invalid': props.valid === false,
            'is-valid': props.valid === true,
          },
          props.className,
        )}
        classNamePrefix="creatable-select"
        onChange={handleChange}
        onBlur={handleBlur}
      />
      {props.valid === false && (
        <div className="invalid-feedback text-left d-block">{props.invalidfeedback}</div>
      )}
      {props.valid === true && (
        <div className="valid-feedback text-left d-block">{props.validfeedback}</div>
      )}
      {props.helpertext && props.valid === undefined && (
        <small className="form-text text-muted text-left d-block">{props.helpertext}</small>
      )}
    </div>
  );
};

CreatetableSelectWrapper.propTypes = {
  hideIndicator: PropTypes.bool,
};

CreatetableSelectWrapper.defaultProps = {
  hideIndicator: false,
};

export default CreatetableSelectWrapper;
