/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-fragments */
import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { injectIntl, intlShape } from 'react-intl';
import { Modal } from 'lec-ui';
import moment from 'moment';

import './StudentLesson.scss';

import messages from './studentLessonMessages';

import placeholderIco from '../app/images/placeholder-avatar.svg';

const LessonFilesCard = (props) => {
  const {
    intl,
    content,
    handleDownloadFile,
    handleManagePlanScheduledAnalytics,
    handleViewFile,
    mediaFile,
  } = props;
  const [showUploadFileModal, setShowUploadFileModal] = useState(false);
  const [uploadFileInput, setUploadFileInput] = useState({});
  const [hasUploadFileLink, setHasUploadFileLink] = useState(false);
  const [filesFromAPI, setFilesFromAPI] = useState([]);
  const [numberOfFiles, setNumberOfFiles] = useState(0);
  const [media, setMedia] = useState([]);
  const [mediaFileType, setMediaFileType] = useState('');

  function handleUploadFileModal() {
    handleManagePlanScheduledAnalytics('file');
    setShowUploadFileModal(!showUploadFileModal);
  }

  function hasHttp(obj) {
    if (obj === undefined) {
      return false;
    }

    if (obj.resource && (obj.resource.includes('http://') || obj.resource.includes('https://'))) {
      return true;
    }

    return false;
  }

  function isRenderMedia(file) {
    const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
    const acceptedVideoTypes = ['video/mp4', 'video/webm', 'video/ogg'];
    const acceptedAudioTypes = ['audio/mpeg', 'audio/ogg', 'audio/wav'];

    if (acceptedImageTypes.includes(file.fileType)) {
      return 'image';
    }
    if (acceptedVideoTypes.includes(file.fileType)) {
      return 'video';
    }
    if (acceptedAudioTypes.includes(file.fileType)) {
      return 'audio';
    }
    return false;
  }

  function handleViewFileToRender(file) {
    const mediaType = isRenderMedia(file);
    setMediaFileType(mediaType);

    handleViewFile(file.id, file.type);
  }

  useEffect(() => {
    setFilesFromAPI([]);
    setHasUploadFileLink(false);
    setUploadFileInput({});
    setMediaFileType('');

    if (Array.isArray(content) && content.length > 0) {
      setHasUploadFileLink(true);
      content.forEach((el) => {
        setFilesFromAPI((oldArray) => [
          ...oldArray,
          {
            id: el.id,
            file: el.resource,
            date: moment(el.added).format('DD/MM/YYYY, HH:mm'),
            photo: el.photo
              ? `https://dev-teapi.smbrasil.com.br/node/uploads/${el.photo}`
              : placeholderIco,
            name: el.name,
            isFile: !!el.file,
            fileType: el.fileType,
            viewFileLink: el.viewFileLink,
            resource: el.resource,
          },
        ]);
      });
    } else {
      setUploadFileInput({});
      setFilesFromAPI([]);
      setHasUploadFileLink(false);
    }
  }, [content]);

  useEffect(() => {
    let number = filesFromAPI.length;

    if (Object.keys(uploadFileInput).length > 0) {
      number += 1;
    }

    setNumberOfFiles(number);
  }, [filesFromAPI, uploadFileInput]);

  useEffect(() => {
    if (mediaFile) {
      setMedia(mediaFile);
    }
  }, [mediaFile]);

  return (
    <Fragment>
      <button
        type="button"
        onClick={() => handleUploadFileModal()}
        className="card-resource no-fill"
      >
        {hasUploadFileLink && <div className="lessonFiles-badge">{numberOfFiles}</div>}
        <svg
          width="36px"
          height="36px"
          viewBox="0 0 36 36"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          stroke="#9a9a9a"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-file"
        >
          <path d="M19.5 3H9a3 3 0 0 0 -3 3v24A3 3 0 0 0 9 33.001h18a3 3 0 0 0 3 -3V13.5z" />
          <path points="13 2 13 9 20 9" d="M19.5 3V13.5h10.501" />
        </svg>
        {intl.formatMessage(messages.teacherFiles)}
      </button>

      <Modal
        show={showUploadFileModal}
        onClose={() => setShowUploadFileModal(false)}
        title={intl.formatMessage(messages.teacherFiles)}
        buttons={[
          {
            label: intl.formatMessage(messages.close),
            onClick: () => setShowUploadFileModal(false),
          },
        ]}
      >
        <div>
          <div
            className={`linkBlock ${!uploadFileInput && filesFromAPI.length <= 0 && 'inactive'}`}
          >
            {uploadFileInput || filesFromAPI.length > 0 ? (
              <div>
                <div className="table-container">
                  <table className="lessonFilesTable">
                    <thead>
                      <tr>
                        <th>{intl.formatMessage(messages.fileUrl)}</th>
                        <th>{intl.formatMessage(messages.actions)}</th>
                        <th>{intl.formatMessage(messages.date)}</th>
                        <th>{intl.formatMessage(messages.sentBy)}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filesFromAPI.map((fileContainer, idx) => (
                        <tr className="text-left" key={`${fileContainer.file}-${idx}`}>
                          <td>
                            <div>
                              <i className="far fa-file-alt mr-2" />
                              {fileContainer.file}
                            </div>
                          </td>
                          <td className="actions">
                            {fileContainer.isFile && (
                              <a
                                onClick={() =>
                                  handleDownloadFile(fileContainer.id, fileContainer.file)
                                }
                              >
                                <i className="fas fa-download" />
                              </a>
                            )}
                            {isRenderMedia(fileContainer) && (
                              <a onClick={() => handleViewFileToRender(fileContainer)}>
                                <i className="fas fa-eye ml-2" />
                              </a>
                            )}
                            {hasHttp(fileContainer) && (
                              <a
                                href={fileContainer.resource}
                                target="_blank"
                                rel="noreferrer"
                                onClick={() =>
                                  handleManagePlanScheduledAnalytics('file', fileContainer.id)
                                }
                              >
                                <i className="fas fa-external-link-alt" />
                              </a>
                            )}
                          </td>
                          <td>{fileContainer.date}</td>
                          <td>
                            <div className="userPhoto">
                              <div className="avatar-profile">
                                <div
                                  className="avatar-sm"
                                  style={{
                                    background: `url(${fileContainer.photo}) center center no-repeat`,
                                  }}
                                />
                              </div>
                              <div className="userName">{fileContainer.name}</div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {media && mediaFileType === 'video' && (
                  <div className="media-container">
                    {/* close btn */}
                    <button type="button" onClick={() => setMedia(null)} className="close-btn">
                      <i className="fas fa-times" />
                    </button>
                    <video src={media} controls />
                  </div>
                )}
                {media && mediaFileType === 'audio' && (
                  <div className="media-container">
                    <button type="button" onClick={() => setMedia(null)} className="close-btn">
                      <i className="fas fa-times" />
                    </button>
                    <audio src={media} controls />
                  </div>
                )}
                {media && mediaFileType === 'image' && (
                  <div className="media-container">
                    <button type="button" onClick={() => setMedia(null)} className="close-btn">
                      <i className="fas fa-times" />
                    </button>
                    <img src={media} />
                  </div>
                )}
              </div>
            ) : (
              intl.formatMessage(messages.noMaterialUploadedYet)
            )}
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

LessonFilesCard.propTypes = {
  intl: intlShape,
  // files: PropTypes.arrayOf(PropTypes.any),
  content: PropTypes.arrayOf(PropTypes.any),
  handleDownloadFile: PropTypes.func.isRequired,
  handleManagePlanScheduledAnalytics: PropTypes.func.isRequired,
  handleViewFile: PropTypes.func.isRequired,
  mediaFile: PropTypes.arrayOf(PropTypes.any).isRequired,
};

LessonFilesCard.defaultProps = {
  intl: [],
  // files: [],
  content: [],
};

export default compose(injectIntl)(LessonFilesCard);
