import 'babel-polyfill';
import 'lec-ui/scss/main.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import configureStore, { history } from 'app/config/configureStore';
import App from 'app/App';
import rootSaga from 'app/sagas';
import LanguageProvider from 'app/lang/LanguageProvider';
import { translationMessages } from 'app/lang/messages';
import registerServiceWorker from './registerServiceWorker';

import './index.scss';

const store = configureStore(window.__INITIAL_STATE__);
store.runSaga(rootSaga);

const MOUNT_NODE = document.getElementById('root');

ReactDOM.render(
  <Provider store={store}>
    <LanguageProvider messages={translationMessages}>
      <Router history={history}>
        <App />
      </Router>
    </LanguageProvider>
  </Provider>,
  MOUNT_NODE,
);

if (location.protocol === 'https:') {
  registerServiceWorker();
}
