import * as act from 'app/actions';

export const GET_STUDENT_LESSON = 'student-lesson/GET_STUDENT_LESSON';
export const getStudentLesson = (payload) => act.action(GET_STUDENT_LESSON, { payload });

export const GET_SCHEDULED_LESSON_CHAT = 'student-lesson/GET_SCHEDULED_LESSON_CHAT';
export const getScheduledLessonChat = (payload) =>
  act.action(GET_SCHEDULED_LESSON_CHAT, { payload });

export const ADD_SCHEDULED_LESSON_CHAT_MESSAGE = 'student-lesson/ADD_SCHEDULED_LESSON_CHAT_MESSAGE';
export const addScheduledLessonChatMessage = (payload) =>
  act.action(ADD_SCHEDULED_LESSON_CHAT_MESSAGE, { payload });

export const DOWNLOAD_LESSON_RESOURCE_FILE = 'student-lesson/DOWNLOAD_LESSON_RESOURCE_FILE';
export const downloadLessonResourseFile = (payload) =>
  act.action(DOWNLOAD_LESSON_RESOURCE_FILE, { payload });

export const DELETE_SCHEDULED_LESSON_RESOURCES = 'student-lesson/DELETE_SCHEDULED_LESSON_RESOURCES';
export const deleteScheduledLessonResources = (payload) =>
  act.action(DELETE_SCHEDULED_LESSON_RESOURCES, { payload });

export const VIEW_LESSON_RESOURCE_FILE = 'student-lesson/VIEW_LESSON_RESOURCE_FILE';
export const viewLessonResourseFile = (payload) =>
  act.action(VIEW_LESSON_RESOURCE_FILE, { payload });
