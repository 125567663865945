import React from 'react';
import PropTypes from 'prop-types';
import { BaseCard as Card, CardBody, Row, Col, Avatar, CardIcon } from 'lec-ui';
import { getUserAvatarUrl } from 'app/appHelpers';

const SchoolTeacherCard = ({ fullname, photo }) => (
  <Card>
    <CardBody>
      <Row className="align-items-center">
        <Col auto>
          {photo && <Avatar src={getUserAvatarUrl(photo)} />}
          {!photo && <CardIcon icon="pe-7s-study" />}
        </Col>
        <Col class="text-left">
          <h5 className="card-title mb-2">{fullname}</h5>
        </Col>
      </Row>
    </CardBody>
  </Card>
);

SchoolTeacherCard.propTypes = {
  fullname: PropTypes.string.isRequired,
  photo: PropTypes.string.isRequired,
};

export default SchoolTeacherCard;
