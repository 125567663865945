/* eslint-disable import/named */
/* eslint-disable react/prop-types */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/forbid-prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { externalAuth } from 'login/loginActions';

function toArray(object) {
  return Array.isArray(object) ? object : [object];
}

class ExternalAuth extends Component {
  componentDidMount() {
    this.authorize();
  }

  componentDidUpdate() {
    const { history, user, authorizing, queryParams } = this.props;

    if (user) {
      let redirectUrl = 'simulated';
      if (queryParams && queryParams.view) {
        redirectUrl = toArray(queryParams.view)[0];
      }
      console.log('redirect url', `/${redirectUrl}`);
      history.push(`/${redirectUrl}`);
    } else {
      history.goBack();
    }
  }

  authorize = () => {
    const {
      queryParams: { token, appToken },
    } = this.props;
    if (token) {
      this.props.externalAuth({ token });
    } else if (appToken) {
      this.props.externalAuth({ appToken });
    }
  };

  render() {
    // const {
    //   queryParams: { token, appToken },
    // } = this.props;
    if (this.props.errorExternalAuth) {
      return <Redirect to="/login" />;
    }
    return null;
  }
}

ExternalAuth.propTypes = {
  externalAuth: PropTypes.func.isRequired,
  queryParams: PropTypes.object.isRequired,
};

const mapStateToProps = ({ app, login }) => ({ queryParams: app.queryParams, ...login });

export default connect(mapStateToProps, { externalAuth })(ExternalAuth);
