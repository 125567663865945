import * as act from 'app/actions';
import * as actions from './validationActions';

const initialState = {
  isLoading: false,
  courses: [],
  errorCourses: '',
  planAppliedMessage: '',
  planAppliedMessageError: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case act.request(actions.GET_COURSES):
      return {
        ...state,
        isLoading: true,
        errorCourses: initialState.errorCourses,
        courses: initialState.courses,
      };
    case act.success(actions.GET_COURSES):
      return {
        ...state,
        courses: action.payload.content,
        isLoading: initialState.isLoading,
        errorCourses: initialState.errorCourses,
      };
    case act.failure(actions.GET_COURSES):
      return { ...state, errorCourses: action.payload.error, isLoading: initialState.isLoading };
    case act.request(actions.APPLY_PLAN):
      return {
        ...state,
        isLoading: true,
        planAppliedMessageError: initialState.planAppliedMessageError,
        planAppliedMessage: initialState.planAppliedMessage,
      };
    case act.success(actions.APPLY_PLAN):
      return {
        ...state,
        planAppliedMessage: action.payload.success,
        isLoading: initialState.isLoading,
        planAppliedMessageError: initialState.planAppliedMessageError,
      };
    case act.failure(actions.APPLY_PLAN):
      return {
        ...state,
        planAppliedMessageError: action.payload.error,
        isLoading: initialState.isLoading,
      };
    default:
      return state;
  }
};

export default reducer;
