import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './InputGroup.scss';

class InputGroup extends Component {
  // support controlled input
  static getDerivedStateFromProps(props, state) {
    if (props.value !== state.prevValue) {
      return {
        value: props.value,
        prevValue: props.value,
      };
    }
    return null;
  }

  state = {};

  handleChange = (e) => {
    const { readOnly } = this.props;
    if (readOnly) return;
    const { value } = e.target;
    this.setState({ value });
    if (this.props.onChange) {
      this.props.onChange(e);
    }
  };

  render() {
    const { id, label, containerclassname, validfeedback, invalidfeedback, valid, icon, ...rest } =
      this.props;
    const { value } = this.state;
    return (
      <div
        className={classNames('input-group-container', {
          'is-invalid': valid === false,
          'is-valid': valid === true,
        })}
      >
        {label && (
          <label
            className={classNames('form-control-label', {
              'is-invalid': valid === false,
              'is-valid': valid === true,
            })}
          >
            {label}
          </label>
        )}
        <div className={classNames('input-group mb-3', containerclassname)}>
          <div className="input-group-prepend">
            <label className="input-group-text" htmlFor={id} id="inputGroup-sizing-default">
              {icon}
            </label>
          </div>
          <input
            {...rest}
            className={classNames('form-control', {
              'is-invalid': valid === false,
              'is-valid': valid === true,
            })}
            onChange={this.handleChange}
            id={id}
            value={value}
          />
        </div>
        {valid === false && <div className="invalid-feedback">{invalidfeedback}</div>}
        {valid === true && <div className="valid-feedback">{validfeedback}</div>}
      </div>
    );
  }
}

InputGroup.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  valid: PropTypes.oneOf([null, undefined, false, true]),
  containerclassname: PropTypes.string,
  validfeedback: PropTypes.string,
  invalidfeedback: PropTypes.string,
  icon: PropTypes.node,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool.isRequired,
};

InputGroup.defaultProps = {
  id: undefined,
  name: undefined,
  label: '',
  type: 'text',
  valid: undefined,
  className: '',
  icon: undefined,
  containerclassname: undefined,
  validfeedback: undefined,
  invalidfeedback: undefined,
  onChange: undefined,
};

export default InputGroup;
