// @flow
import { fork, all, take } from 'redux-saga/effects';
import * as task from 'app/sagas';
import * as a from 'app/api';
import * as actions from './plansValidationActions';

export const getPlansValidation = task.apiTask(
  actions.GET_PLANS_VALIDATION,
  a.api.post,
  '/teach/getPlansValidation.php',
);

export function* watchGetPlansValidation() {
  while (true) {
    const { payload } = yield take(actions.GET_PLANS_VALIDATION);
    yield fork(getPlansValidation, payload);
  }
}

// Root module saga
export function* sagas() {
  yield all([fork(watchGetPlansValidation)]);
}
