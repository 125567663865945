import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { pathToRegexp } from 'path-to-regexp/dist';
import { logout, manageUserTerms, auth } from 'login/loginActions';

import AppRoute from './AppRoute';
// import AppSidebar from './AppSidebar';
import AppNavbar from './AppNavbar';
import AppLogoutModal from './AppLogoutModal';
import ModalOptIn from './ModalOptIn';

import AppFooter from './AppFooter';

function getActiveRoute(routes) {
  return routes.find((route) => pathToRegexp(route.path).test(window.location.pathname));
}

function isExpanded(routes) {
  const activeRoute = getActiveRoute(routes);
  return activeRoute ? activeRoute.expanded : false;
}

const AppContainer = ({ routes, app, ...props }) => {
  const [showLogout, setShowLogout] = React.useState(false);
  const expanded = isExpanded(routes);
  const toggleLogout = (show) => () => {
    setShowLogout(show);
  };

  React.useEffect(() => {
    if (props.manageUserTermsPass) {
      props.auth();
    }
  }, [props]);

  return (
    <div>
      {props.user.termsUse === null && (
        <ModalOptIn
          user={props.user}
          userName={props.user.firstName}
          onClose={props.logout}
          onSubmit={props.manageUserTerms}
        />
      )}
      <AppNavbar onRequestLogout={toggleLogout(true)} />
      {/* <AppSidebar routes={routes} collapsed={expanded} /> */}
      <div className={classNames('content-wrapper', { 'content-wrapper--expanded': expanded })}>
        <div>
          <Switch>
            {routes.map((route) => (
              <AppRoute key={route.path} {...route} />
            ))}
          </Switch>
        </div>
      </div>
      <AppLogoutModal show={showLogout} onLogout={props.logout} onClose={toggleLogout(false)} />
      <AppFooter />
    </div>
  );
};

AppContainer.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.shape({ path: PropTypes.string })).isRequired,
  logout: PropTypes.func.isRequired,
  manageUserTerms: PropTypes.func.isRequired,
  auth: PropTypes.func.isRequired,
};

function mapStateToProps({ app, login }) {
  return { user: app.user, manageUserTermsPass: login.manageUserTermsPass };
}

export default compose(connect(mapStateToProps, { logout, manageUserTerms, auth }))(AppContainer);
