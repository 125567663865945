import * as act from 'app/actions';
import * as actions from './createPlanActions';

const initialState = {
  lessons: [],
  period: [],
  idPlan: '',
  filterPeriod: [],
  isLoading: false,
  idPlanSaved: null,
  catalogingAbilities: [],
  lessonData: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case act.request(actions.GET_PLANS_DATA):
      return {
        ...state,
        isLoading: true,
        hasError: false,
        filterPeriod: initialState.filterPeriod,
      };
    case act.success(actions.GET_PLANS_DATA):
      return {
        ...state,
        lessons: action.payload.lessons,
        filterPeriod: action.payload.period,
        idPlan: action.payload.idPlan,
        updatedDate: action.payload.updated,
        isLoading: initialState.isLoading,
        hasPlanCustomChanges: action.payload.hasPlanCustomChanges,
      };
    case act.request(actions.GET_CATALOGING_ABILITIES):
      return {
        ...state,
        isLoading: true,
        catalogingAbilities: initialState.catalogingAbilities,
      };
    case act.success(actions.GET_CATALOGING_ABILITIES):
      return {
        ...state,
        catalogingAbilities: action.payload.content,
        isLoading: initialState.isLoading,
      };
    case act.request(actions.GET_LESSON_DATA):
      return {
        ...state,
        isLoading: true,
      };
    case act.success(actions.GET_LESSON_DATA):
      return {
        ...state,
        lessonData: action.payload.content.values,
        isLoading: initialState.isLoading,
      };
    case act.request(actions.SAVE_PLAN):
      return {
        ...state,
        isLoading: true,
      };
    case act.success(actions.SAVE_PLAN):
      return {
        ...state,
        planSaved: action.payload.content,
        isLoading: initialState.isLoading,
        idPlanSaved: action.payload.idPlanSaved,
      };
    case act.request(actions.RESTORE_TO_ORIGINAL):
      return {
        ...state,
        isLoading: true,
      };
    case act.success(actions.RESTORE_TO_ORIGINAL):
      return {
        ...state,
        isLoading: initialState.isLoading,
      };
    case act.request(actions.ADD_PLAN_TABLE_LESSON):
      return {
        ...state,
        isLoading: true,
      };
    case act.success(actions.ADD_PLAN_TABLE_LESSON):
      return {
        ...state,
        isLoading: initialState.isLoading,
      };
    case act.request(actions.EDIT_PLAN_TABLE_LESSON):
      return {
        ...state,
        isLoading: true,
      };
    case act.success(actions.EDIT_PLAN_TABLE_LESSON):
      return {
        ...state,
        isLoading: initialState.isLoading,
      };
    case act.request(actions.DELETE_PLAN_TABLE_LESSON):
      return {
        ...state,
        isLoading: true,
      };
    case act.success(actions.DELETE_PLAN_TABLE_LESSON):
      return {
        ...state,
        isLoading: initialState.isLoading,
      };
    case act.request(actions.MOVE_PLAN_TABLE_LESSON):
      return {
        ...state,
        isLoading: true,
      };
    case act.success(actions.MOVE_PLAN_TABLE_LESSON):
      return {
        ...state,
        isLoading: initialState.isLoading,
      };
    case act.request(actions.RESTORE_PLAN_TABLE_LESSON):
      return {
        ...state,
        isLoading: true,
      };
    case act.success(actions.RESTORE_PLAN_TABLE_LESSON):
      return {
        ...state,
        isLoading: initialState.isLoading,
      };

    default:
      return state;
  }
};

export default reducer;
