/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';
import { Loader } from 'lec-ui';
import * as loginActions from '../login/loginActions';
import * as registerActions from './registerActions';
import RegisterForm from './RegisterForm';
import Login from '../login/Login';

const RegisterContainer = (props) => {
  const {
    entering,
    countries,
    error,
    getCountries,
    register,
    intl,
    status,
    // history,
    isLoading,
    login,
  } = props;
  const [registerValues, setRegisterValues] = useState({});

  async function handleSubmit(values) {
    await register(values);

    setRegisterValues(values);
  }

  function containsFooter() {
    countries.some(
      (country) =>
        country.access === '2' &&
        country.emailCustomerService !== null &&
        intl.locale === country.language,
    );
  }

  // function redirectLogin() {
  //   history.push('/login');
  // }

  useEffect(() => {
    getCountries();
    document.body.classList.remove('modal-open');
  }, []);

  useEffect(() => {
    if (status === 3 && registerValues) {
      // redirectLogin();
      const form = {
        email: registerValues.email,
        password: registerValues.password,
      };
      login(form);
    }
  }, [status]);

  return (
    <Login footer={containsFooter()} entering={entering}>
      <Loader show={isLoading} />
      <RegisterForm onSubmit={handleSubmit} countries={countries} errorType={error} />
    </Login>
  );
};

RegisterContainer.propTypes = {
  register: PropTypes.func.isRequired,
  getCountries: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  entering: PropTypes.bool,
  countries: PropTypes.any.isRequired,
  error: PropTypes.any,
  status: PropTypes.number.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  login: PropTypes.func.isRequired,
};

RegisterContainer.defaultProps = {
  entering: false,
  error: null,
};

const mapStateToProps = ({ login, register }) => ({ ...login, ...register });

export default compose(
  connect(mapStateToProps, { ...loginActions, ...registerActions }),
  injectIntl,
  withRouter,
)(RegisterContainer);
