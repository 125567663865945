import React from 'react';
import PropTypes from 'prop-types';
import BaseCard from './BaseCard';
import CardBody from './CardBody';

const Card = ({ children, ...props }) => (
  <BaseCard {...props}>
    <CardBody>{children}</CardBody>
  </BaseCard>
);

Card.propTypes = {
  children: PropTypes.node,
};

Card.defaultProps = {
  children: undefined,
};

export default Card;
