/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

const Title = ({ type, className, h, ...props }) => {
  switch (h) {
    case '1':
      return <h1 className={[`${type}-title`, className].join(' ')} {...props} />;
    case '2':
      return <h2 className={[`${type}-title`, className].join(' ')} {...props} />;
    case '3':
      return <h3 className={[`${type}-title`, className].join(' ')} {...props} />;
    case '4':
      return <h4 className={[`${type}-title`, className].join(' ')} {...props} />;
    case '5':
      return <h5 className={[`${type}-title`, className].join(' ')} {...props} />;
  }
};

Title.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  h: PropTypes.string,
};

export default Title;
