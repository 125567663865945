/* eslint-disable react/jsx-props-no-spreading */
// import React from 'react';
// import { FormattedMessage } from 'react-intl';

import LoginContainer from 'login/LoginContainer';
import ExternalAuth from 'login/ExternalAuth';
import RegisterContainer from 'register/RegisterContainer';
import PanelContainer from 'panel/PanelContainer';
import ChangePassword from 'changePassword/ChangePasswordContainer';
import CreatePlanContainer from 'createPlan/CreatePlanContainer';
import MyPlansContainer from 'myPlans/MyPlansContainer';
import PlansValidationContainer from 'plansValidation/PlansValidationContainer';
import ValidationContainer from 'validation/ValidationContainer';
import ConfigurationContainer from 'configuration/ConfigurationContainer';
import ClassManagementContainer from 'classManagement/ClassManagementContainer';
import PlanLessonsContainer from 'planLessons/PlanLessonsContainer';
import ClassConfigurationContainer from 'classConfiguration/ClassConfigurationContainer';
import MyClassContainer from 'myClass/MyClassContainer';
import MyClassStudentsContainer from 'myClassStudents/MyClassStudentsContainer';
import MyLessonsContainer from 'myLessons/MyLessonsContainer';
import StudentLessonContainer from 'studentLesson/StudentLessonContainer';
import ViewPlanContainer from 'viewPlan/ViewPlanContainer';

import AppContainer from './AppContainer';

// import messages from './appMessages';

// const isProd = process.env.SM_ENV === 'production';

export const loginRoutes = [
  {
    path: '/login',
    exact: true,
    component: LoginContainer,
  },
  {
    path: '/auth',
    component: ExternalAuth,
  },
  {
    path: '/register',
    exact: true,
    component: RegisterContainer,
  },
  {
    path: '/change-password',
    exact: true,
    component: ChangePassword,
  },
];

export const appRoutes = [
  {
    path: '/',
    component: AppContainer,
    routes: [
      {
        path: '/panel',
        exact: true,
        component: PanelContainer,
        // sidebar: {
        //   active: (location) => /^\/panel/.test(location.pathname),
        //   label: <FormattedMessage {...messages.sidebarPanel} />,
        //   icon: <i className="pe-7s-note" />,
        // },
      },
      {
        path: '/create-plan',
        exact: true,
        component: CreatePlanContainer,
      },
      {
        path: '/my-plans',
        exact: true,
        component: MyPlansContainer,
      },
      {
        path: '/plans-validation',
        exact: true,
        component: PlansValidationContainer,
      },
      {
        path: '/validation',
        exact: true,
        component: ValidationContainer,
      },
      {
        path: '/configuration',
        exact: true,
        component: ConfigurationContainer,
      },
      {
        path: '/class-management',
        exact: true,
        component: ClassManagementContainer,
      },
      {
        path: '/plan-lessons',
        exact: true,
        component: PlanLessonsContainer,
      },
      {
        path: '/class-configuration',
        exact: true,
        component: ClassConfigurationContainer,
      },
      {
        path: '/my-class',
        exact: true,
        component: MyClassContainer,
      },
      {
        path: '/my-class/:id',
        exact: true,
        component: MyClassStudentsContainer,
      },
      {
        path: '/my-lessons',
        exact: true,
        component: MyLessonsContainer,
      },
      {
        path: '/student-lesson',
        exact: true,
        component: StudentLessonContainer,
      },
      {
        path: '/view-plan',
        exact: true,
        component: ViewPlanContainer,
      },
    ],
  },
];
