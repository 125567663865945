// @flow
import { fork, all, take } from 'redux-saga/effects';
import * as task from 'app/sagas';
import * as a from 'app/api';
import * as actions from './myClassActions';

export const getCourses = task.apiTask(actions.GET_COURSES, a.api.post, '/users/getCourses.php');
export const getCoursesStudent = task.apiTask(
  actions.GET_COURSES_STUDENTS,
  a.api.post,
  '/users/getCoursesStudent.php',
);
export const getSchoolList = task.apiTask(
  actions.GET_SCHOOL_LIST,
  a.api.post,
  '/teach/getSchoolsList.php',
);
export const addGroup = task.apiTask(actions.ADD_GROUP, a.api.post, '/teach/addGroup.php');
export const joinGroup = task.apiTask(
  actions.JOIN_GROUP,
  a.api.post,
  '/teach/voucherActivatedStudent.php',
);
export const removeGroup = task.apiTask(actions.REMOVE_GROUP, a.api.post, '/teach/removeGroup.php');
export const addStudent = task.apiTask(actions.ADD_STUDENT, a.api.post, '/teach/addStudent.php');
export const editStudent = task.apiTask(actions.EDIT_STUDENT, a.api.post, '/teach/editStudent.php');
export const getStudents = task.apiTask(actions.GET_STUDENTS, a.api.post, '/users/getStudents.php');

export function* watchGetCourses() {
  while (true) {
    const { payload } = yield take(actions.GET_COURSES);
    yield fork(getCourses, payload);
  }
}
export function* watchGetCoursesStudent() {
  while (true) {
    const { payload } = yield take(actions.GET_COURSES_STUDENTS);
    yield fork(getCoursesStudent, payload);
  }
}
export function* watchGetSchoolList() {
  while (true) {
    const { payload } = yield take(actions.GET_SCHOOL_LIST);
    yield fork(getSchoolList, payload);
  }
}
export function* watchAddGroup() {
  while (true) {
    const { payload } = yield take(actions.ADD_GROUP);
    yield fork(addGroup, payload);
  }
}
export function* watchJoinGroup() {
  while (true) {
    const { payload } = yield take(actions.JOIN_GROUP);
    yield fork(joinGroup, payload);
  }
}
export function* watchRemoveGroup() {
  while (true) {
    const { payload } = yield take(actions.REMOVE_GROUP);
    yield fork(removeGroup, payload);
  }
}
export function* watchAddStudent() {
  while (true) {
    const { payload } = yield take(actions.ADD_STUDENT);
    yield fork(addStudent, payload);
  }
}
export function* watchEditStudent() {
  while (true) {
    const { payload } = yield take(actions.EDIT_STUDENT);
    yield fork(editStudent, payload);
  }
}
export function* watchGetStudents() {
  while (true) {
    const { payload } = yield take(actions.GET_STUDENTS);
    yield fork(getStudents, payload);
  }
}

// Root module saga
export function* sagas() {
  yield all([
    fork(watchGetCourses),
    fork(watchGetCoursesStudent),
    fork(watchGetSchoolList),
    fork(watchAddGroup),
    fork(watchJoinGroup),
    fork(watchRemoveGroup),
    fork(watchAddStudent),
    fork(watchEditStudent),
    fork(watchGetStudents),
  ]);
}
