// @flow

import { action } from 'app/actions';

export const SAVE = 'configuration/SAVE';
export const UPLOAD = 'configuration/UPLOAD';
export const REMOVE_PHOTO = 'configuration/REMOVE_PHOTO';
export const GET_SCHOOLS = 'school/GET_SCHOOLS';
export const GET_SCHOOL_DETAILS = 'school/GET_SCHOOL_DETAILS';

export const SAVE_SCHOOL_DETAILS = 'school/SAVE_SCHOOL_DETAILS';

export const save = (payload) => action(SAVE, { payload });
export const removePhoto = () => action(REMOVE_PHOTO);
export const upload = (payload) => action(UPLOAD, { payload });

export const getSchools = () => action(GET_SCHOOLS);

export const getSchoolDetails = (idUserSchool) =>
  action(GET_SCHOOL_DETAILS, { payload: { idUserSchool } });

export const saveSchoolDetails = (payload) => action(SAVE_SCHOOL_DETAILS, { payload });
