/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import messages from './appMessages';

import logoSM from './images/logo-sm-red-black.png';
import './AppCookiesConfig.scss';

export class AppCookiesConfig extends React.PureComponent {
  // eslint-disable-line react/prefer-stateless-function

  render() {
    return (
      <div>
        <div className={this.props.cookiesConfigShow ? 'modal fade show visible' : 'modal fade'}>
          <div className="cookiesConfig">
            <div className="content">
              <div className="info">
                <div className="logo-sm">
                  <Link className="navbar-brand" to="/">
                    <img alt="" src={logoSM} />
                  </Link>
                </div>
                <div className="text">
                  <p>
                    <FormattedMessage {...messages.configInfo} />
                  </p>
                  <div className="cookie-option technicalCookies">
                    <div className="option">
                      <span>
                        <FormattedMessage {...messages.technicalCookies} />
                      </span>
                      <input
                        className="switch disabled"
                        id="technicalCookies"
                        name="technicalCookies"
                        type="radio"
                        value="1"
                        defaultChecked="1"
                        disabled
                        readOnly
                      />
                      <label className="value" htmlFor="technicalCookies">
                        <FormattedMessage {...messages.alwaysActive} />
                      </label>
                    </div>
                    <div className="description">
                      <span>
                        <FormattedMessage {...messages.descriptionTechnicalCookies} />
                      </span>
                    </div>
                  </div>
                  <div className="cookie-option cookiesCustomization">
                    <div className="option">
                      <span>
                        <FormattedMessage {...messages.cookiesCustomization} />
                      </span>
                      <input
                        className="switch"
                        id="cookiesCustomization"
                        name="cookiesCustomization"
                        onChange={() => this.props.handleCookiesCustomizationChange()}
                        type="radio"
                        value="1"
                        checked={this.props.cookiesCustomization}
                      />
                      <label className="value" htmlFor="cookiesCustomization">
                        {this.props.cookiesCustomization ? (
                          <FormattedMessage {...messages.allowed} />
                        ) : (
                          <FormattedMessage {...messages.notAllowed} />
                        )}
                      </label>
                    </div>
                    <div className="description">
                      <span>
                        <FormattedMessage {...messages.descriptionCookiesCustomization} />
                      </span>
                    </div>
                  </div>
                  <div className="cookie-option cookiesAnalytical">
                    <div className="option">
                      <span>
                        <FormattedMessage {...messages.cookiesAnalytical} />
                      </span>
                      <input
                        className="switch"
                        id="cookiesAnalytical"
                        name="cookiesAnalytical"
                        onChange={() => this.props.handleCookiesAnalyticalChange()}
                        type="radio"
                        value="1"
                        checked={this.props.cookiesAnalytical}
                      />
                      <label className="value" htmlFor="cookiesAnalytical">
                        {this.props.cookiesAnalytical ? (
                          <FormattedMessage {...messages.allowed} />
                        ) : (
                          <FormattedMessage {...messages.notAllowed} />
                        )}
                      </label>
                    </div>
                    <div className="description">
                      <span>
                        <FormattedMessage {...messages.descriptionCookiesAnalytical} />
                      </span>
                    </div>
                  </div>
                  <div className="cookie-option cookiesPublicity">
                    <div className="option">
                      <span>
                        <FormattedMessage {...messages.cookiesPublicity} />
                      </span>
                      <input
                        className="switch"
                        id="cookiesPublicity"
                        name="cookiesPublicity"
                        onChange={() => this.props.handleCookiesPublicityChange()}
                        type="radio"
                        value="1"
                        checked={this.props.cookiesPublicity}
                      />
                      <label className="value" htmlFor="cookiesPublicity">
                        {this.props.cookiesPublicity ? (
                          <FormattedMessage {...messages.allowed} />
                        ) : (
                          <FormattedMessage {...messages.notAllowed} />
                        )}
                      </label>
                    </div>
                    <div className="description">
                      <span>
                        <FormattedMessage {...messages.descriptionCookiesPublicity} />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="buttons">
                <a onClick={() => this.props.saveCookiesSettings()}>
                  <FormattedMessage {...messages.saveSettings} />
                </a>
                <a onClick={this.props.dismissCookiesConfig}>
                  <FormattedMessage {...messages.cancel} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AppCookiesConfig.propTypes = {
  user: PropTypes.objectOf(PropTypes.any),
  queryParams: PropTypes.objectOf(PropTypes.any).isRequired,
  intl: intlShape,
  cookiesConfigShow: PropTypes.bool.isRequired,
  saveCookiesSettings: PropTypes.func.isRequired,
  dismissCookiesConfig: PropTypes.func.isRequired,
  handleCookiesCustomizationChange: PropTypes.func.isRequired,
  handleCookiesAnalyticalChange: PropTypes.func.isRequired,
  handleCookiesPublicityChange: PropTypes.func.isRequired,
  cookiesCustomization: PropTypes.number.isRequired,
  cookiesAnalytical: PropTypes.number.isRequired,
  cookiesPublicity: PropTypes.number.isRequired,
};

AppCookiesConfig.defaultProps = {
  user: {},
  intl: [],
};

const mapStateToProps = ({ app }) => ({
  user: app.user,
  queryParams: app.queryParams,
  token: app.token,
});

export default compose(injectIntl, connect(mapStateToProps))(AppCookiesConfig);
