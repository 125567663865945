import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './FakeInput.scss';

const FakeInput = ({ className, label, ...rest }) => (
  <div className="form-group form-group-mob text-left">
    {label && <label className="form-control-label text-left">{label}</label>}
    <div className={classNames('fake-input', className)} {...rest} />
  </div>
);

FakeInput.propTypes = {};

export default FakeInput;
