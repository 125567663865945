/* eslint-disable react/forbid-prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import CardMonthCell from './CardMonthCell';
import { withCalendar } from './CalendarContext';

const today = new Date();

class CardMonthDayCell extends Component {
  handleClickDate = (e) => {
    const { date, onClick } = this.props;
    if (onClick) {
      onClick(date, e);
    }
  };

  render() {
    const { date, appointments } = this.props;
    const dateMoment = moment(date);
    const current = dateMoment.isSame(today, 'day');
    const featured = appointments.some(
      (a) =>
        moment(a.date).isSame(date, 'day') &&
        a.lessons.find((l) => l.outOfSchoolDays === false && l.vacationDays === false),
    );
    const disabled = [0, 6].includes(dateMoment.day());
    const muted = false; // afterwards, check whats the meaning of muted
    const outOfSchoolDays = appointments.some(
      (a) =>
        moment(a.date).isSame(date, 'day') && a.lessons.find((l) => l.outOfSchoolDays === true),
    );
    const vacationDays = appointments.some(
      (a) => moment(a.date).isSame(date, 'day') && a.lessons.find((l) => l.vacationDays === true),
    );

    const holidays = appointments.some(
      (a) => moment(a.date).isSame(date, 'day') && a.lessons.find((l) => l.holiday === true),
    );

    const lessons = appointments
      .filter((a) => moment(a.date).isSame(date, 'day'))
      .map((obj) => obj.lessons)[0];

    return (
      <CardMonthCell
        current={current}
        disabled={disabled}
        muted={muted}
        featured={featured}
        lessons={lessons}
        onClick={this.handleClickDate}
        outOfSchoolDays={outOfSchoolDays}
        vacationDays={vacationDays}
        holidays={holidays}
      >
        {date.getDate()}
      </CardMonthCell>
    );
  }
}

CardMonthDayCell.propTypes = {
  appointments: PropTypes.arrayOf(PropTypes.any),
  date: PropTypes.instanceOf(Date).isRequired,
  onClick: PropTypes.func.isRequired,
};

CardMonthDayCell.defaultProps = {
  appointments: [],
};

export default withCalendar(CardMonthDayCell);
