/* eslint-disable no-nested-ternary */
import * as act from 'app/actions';
import * as actions from './registerActions';

export const EditionStatus = {
  PENDING: 1,
  SAVING: 2,
  SAVED: 3,
  FAILED: 4,
};

const initialState = {
  status: EditionStatus.PENDING,
  error: null,
  isLoading: false,
  // schools: [],
  // schoolDetails: null,
  schoolsSearch: [],
  states: [],
  cities: [],
  countries: [],
  registeredSchool: false,
};

const schoolReducer = (state = initialState, action) => {
  switch (action.type) {
    case act.request(actions.REGISTER):
      return { ...state, status: EditionStatus.SAVING, isLoading: true };
    case act.success(actions.REGISTER):
      return { ...state, status: EditionStatus.SAVED, error: null, isLoading: false };
    case act.failure(actions.REGISTER):
      return {
        ...state,
        status: EditionStatus.FAILED,
        isLoading: false,
        error: String(action.payload.error).includes('Voucher')
          ? 'Voucher'
          : String(action.payload.error).includes('dont belong')
          ? 'VoucherCountry'
          : String(action.payload.error).includes('been deactivated')
          ? 'VoucherDeactivated'
          : String(action.payload.error).includes('access code is currently unavailable')
          ? 'VoucherOutDate'
          : String(action.payload.error).includes('voucher reached the limit of activations')
          ? 'VoucherLimits'
          : 'Email',
      };
    case act.success(actions.SEARCH_SCHOOLS):
      return { ...state, schoolsSearch: action.payload.content };
    case act.request(actions.GET_CITIES):
      return { ...state, schoolsSearch: initialState.schoolsSearch };
    case act.request(actions.GET_STATES):
      return {
        ...state,
        cities: initialState.cities,
        schools: initialState.schools,
        schoolsSearch: initialState.schoolsSearch,
      };
    case act.success(actions.GET_STATES):
      return { ...state, states: action.payload.content };
    case act.success(actions.GET_CITIES):
      return { ...state, cities: action.payload.content, schools: initialState.schools };
    default:
      return state;
  }
};

export default schoolReducer;
