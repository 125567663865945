import { defineMessages } from 'react-intl';

export default defineMessages({
  plansCreated: {
    id: 'panel.plansCreated',
    defaultMessage: 'Plans Created',
  },
  plannedClasses: {
    id: 'panel.plannedClasses',
    defaultMessage: 'Planned Classes',
  },
  annualCoverage: {
    id: 'panel.annualCoverage',
    defaultMessage: 'Annual Coverage',
  },
  courses: {
    id: 'panel.courses',
    defaultMessage: 'Courses',
  },
  students: {
    id: 'panel.students',
    defaultMessage: 'Students',
  },
  noPlansCreated: {
    id: 'panel.noPlansCreated',
    defaultMessage: 'You havent created any annual plan yet :(',
  },
  createPlansText: {
    id: 'panel.createPlansText',
    defaultMessage:
      'Create your lesson plans and monitor the evolution of contents planned in Teach!',
  },
  createPlanButton: {
    id: 'panel.createPlanButton',
    defaultMessage: 'Create annual plan',
  },
  SelectAStage: {
    id: 'panel.SelectAStage',
    defaultMessage: 'Select a stage',
  },
  SelectACurricularComponent: {
    id: 'panel.SelectACurricularComponent',
    defaultMessage: 'Select a curricular component',
  },
  availableClasses: {
    id: 'panel.availableClasses',
    defaultMessage: 'Available Classes',
  },
  overallProgress: {
    id: 'panel.overallProgress',
    defaultMessage: 'Overall Progress',
  },
  needRegisterSchoolTitle: {
    id: 'panel.needRegisterSchoolTitle',
    defaultMessage: 'Register School',
  },
  needRegisterSchool: {
    id: 'panel.needRegisterSchool',
    defaultMessage: 'First you need to register a school',
  },
  skillsCoverage: {
    id: 'panel.skillsCoverage',
    defaultMessage: 'Skills Coverage',
  },
  latestAccesses: {
    id: 'panel.latestAccesses',
    defaultMessage: 'Latest Accesses',
  },
  loadMore: {
    id: 'panel.loadmore',
    defaultMessage: 'Load more',
  },
  noAccessFounded: {
    id: 'panel.noAccessFounded',
    defaultMessage: 'No access founded',
  },
  noAbilitiesFounded: {
    id: 'panel.noAbilitiesFounded',
    defaultMessage: 'No abilities founded',
  },
  selectPlanAndClass: {
    id: 'panel.selectPlanAndClass',
    defaultMessage: 'Select a plan/class',
  },
  planValidation: {
    id: 'panel.planValidation',
    defaultMessage: 'Plan Validation',
  },
});
