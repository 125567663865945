import { defineMessages } from 'react-intl';

export default defineMessages({
  save: {
    id: 'addNewLessonModal.save',
    defaultMessage: 'Save',
  },
  cancel: {
    id: 'addNewLessonModal.cancel',
    defaultMessage: 'Cancel',
  },
  addNewLesson: {
    id: 'addNewLessonModal.addNewLesson',
    defaultMessage: 'Add new lesson',
  },
  date: {
    id: 'addNewLessonModal.field.date',
    defaultMessage: 'Date',
  },
  fullTimePeriod: {
    id: 'addNewLessonModal.field.fullTimePeriod',
    defaultMessage: 'Full time period',
  },
  whichClassesWillBeOccupied: {
    id: 'addNewLessonModal.field.whichClassesWillBeOccupied',
    defaultMessage: 'Which classes will be occupied?',
  },
  errorRequired: {
    id: 'addNewLessonModal.errorRequired',
    defaultMessage: 'Required field',
  },
  successfullySaved: {
    id: 'addNewLessonModal.successfullySaved',
    defaultMessage: 'Data was successfully saved',
  },
  notAllowedInSameDate: {
    id: 'addNewLessonModal.notAllowedInSameDate',
    defaultMessage: 'Date informed is already register in this schedule plan for this lesson',
  },
});
