/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable radix */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withFormik } from 'formik';
import { injectIntl, intlShape } from 'react-intl';

import { AvatarInput, Input, InputGroup, Button, Row, Col, RoundButton, Alert } from 'lec-ui';
import Role from 'app/role';
import placeholderSrc from 'app/images/placeholder-school.svg';
import SchoolTeacherCard from './SchoolTeacherCard';

import { EditionStatus } from './configurationReducer';
import messages from './schoolFormMessages';
import './SchoolForm.scss';

const enhanceForm = withFormik({
  mapPropsToValues: ({ data }) => ({ ...data }),
  validate: (values) => {
    const errors = {};
    const requiredFields = ['schoolYearStart', 'schoolYearEnd', 'vacationStart', 'vacationEnd'];
    const optionalFields = ['vacation2Start', 'vacation2End'];
    const allFields = requiredFields.concat(optionalFields);

    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = 'errorRequired';
      }
    });
    allFields.forEach((field, index) => {
      if (index % 2 !== 0) {
        if (values[allFields[index - 1]] > values[field]) {
          errors[allFields[index - 1]] = 'errorStartAfterEnd';
          errors[field] = 'errorStartAfterEnd';
        }
      }
    });
    if (values.vacation2Start && !values.vacation2End) {
      errors.vacation2End = 'errorMustInform';
    }
    if (!values.vacation2Start && values.vacation2End) {
      errors.vacation2Start = 'errorMustInform';
    }
    return errors;
  },

  // this.props.saveSchoolDetails({ idUser, idUserSchool: id, ...values });

  handleSubmit: (values, { props }) =>
    props.onSubmit({ idUser: props.user.idUser, idUserSchool: props.idUserSchool, ...values }),
  enableReinitialize: true,
});

function isCodeAllowed(role) {
  return [Role.COORDINATOR, Role.ADMINISTRADOR].includes(parseInt(role, 0));
}

const SchoolForm = ({
  statusEdition,
  values,
  role,
  intl,
  handleChange,
  handleBlur,
  handleSubmit,
  isValid,
  errors,
  submitCount,
  schoolFormStatus,
  modalSchoolForm,
}) => {
  const [hasAditionalVacationPeriod, setHasAditionalVacationPeriod] = useState(false);
  const [alerts, setAlerts] = useState([]);
  const prevAmount = useRef({ statusEdition }).current;

  const calendarIcon = <i className="ti-calendar" />;

  const {
    accessCode,
    address,
    city,
    name,
    schoolDays,
    schoolYearEnd,
    schoolYearStart,
    state,
    teachers,
    coordinators,
    vacation2End,
    vacation2Start,
    vacationEnd,
    vacationStart,
  } = values || {};

  const isNotCoordinator = role !== 2;
  const hasTeacher = !!(teachers && teachers.length > 0);
  const hasCoordinator = !!coordinators;

  function getMessageError(field) {
    if (errors && errors[field]) {
      return intl.formatMessage(messages[errors[field]]);
    }
    return undefined;
  }

  function addVacationPeriod() {
    setHasAditionalVacationPeriod(true);
  }

  function handleAlert(message, type) {
    setAlerts(alerts.concat({ type, message, timeout: 3000 }));
  }

  function removeAlert(alert) {
    setAlerts(alerts.filter((a) => a !== alert));
  }

  function handleIsValid(field) {
    if (errors && errors[field] && submitCount > 0) {
      return false;
    }
    return undefined;
  }

  useEffect(() => {
    if (prevAmount.statusEdition !== statusEdition && statusEdition === EditionStatus.SAVED) {
      handleAlert(intl.formatMessage(messages.successfullySaved), 'success');
    }
  }, [statusEdition]);

  useEffect(() => {
    if (schoolFormStatus === 3) {
      setTimeout(() => {
        window.location.reload(false);
      }, 1000);
    }
  }, [schoolFormStatus]);

  return (
    <div>
      <Row>
        {!modalSchoolForm && (
          <Col sm={3} className="text-center">
            <AvatarInput
              placeholderSrc={placeholderSrc}
              label={intl.formatMessage(messages.updatePhoto)}
              readOnly
            />
            <br />
            {isCodeAllowed(role) && (
              <div className="cod text-center">
                {intl.formatMessage(messages.code)} {accessCode}
                <i className="pe-7s-check" />
              </div>
            )}
            <br />
            {schoolDays && (
              <div className="dias-letivos">
                <div className="text">{intl.formatMessage(messages.schoolDays)}</div>
                <div className="number">{schoolDays}</div>
              </div>
            )}
          </Col>
        )}
        <Col sm={modalSchoolForm ? 12 : 9}>
          {!modalSchoolForm && (
            <form action="nova-escola.pug" method="post">
              <Input label={intl.formatMessage(messages.name)} value={name} readOnly />
              <Input label={intl.formatMessage(messages.address)} value={address} readOnly />
              <div className="row">
                <div className="col-8">
                  <Input label={intl.formatMessage(messages.city)} value={city} readOnly />
                </div>
                <div className="col-4">
                  <Input label={intl.formatMessage(messages.state)} value={state} readOnly />
                </div>
              </div>
            </form>
          )}
          {alerts.map((alert) => (
            <Alert
              type={alert.type}
              onClose={removeAlert(alert)}
              onExpire={removeAlert(alert)}
              timeout={alert.timeout}
            >
              {alert.message}
            </Alert>
          ))}
          <div className="school-form-vacation">
            <h2
              className="section-title my-5"
              style={{ textAlign: modalSchoolForm ? 'left' : 'center' }}
            >
              <i className="pe-7s-tools" />
              {intl.formatMessage(messages.schoolYear)}
            </h2>
            <Button type="submit" variant="outline" onClick={handleSubmit} disabled={!isValid}>
              {intl.formatMessage(messages.save)}
            </Button>
            <Row>
              <Col>
                <InputGroup
                  icon={calendarIcon}
                  label={intl.formatMessage(messages.startSchoolYear)}
                  name="schoolYearStart"
                  valid={handleIsValid('schoolYearStart')}
                  invalidfeedback={getMessageError('schoolYearStart')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="date"
                  value={schoolYearStart || ''}
                  readOnly={isNotCoordinator && hasCoordinator}
                  disabled={isNotCoordinator && hasCoordinator}
                />
              </Col>
              <Col>
                <InputGroup
                  icon={calendarIcon}
                  label={intl.formatMessage(messages.endSchoolYear)}
                  name="schoolYearEnd"
                  valid={handleIsValid('schoolYearEnd')}
                  invalidfeedback={getMessageError('schoolYearEnd')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="date"
                  value={schoolYearEnd || ''}
                  readOnly={isNotCoordinator && hasCoordinator}
                  disabled={isNotCoordinator && hasCoordinator}
                />
              </Col>
            </Row>
            <Row className="school-form-row-vacation mt-2">
              <Col>
                <InputGroup
                  icon={calendarIcon}
                  label={intl.formatMessage(messages.startVacation)}
                  name="vacationStart"
                  valid={handleIsValid('vacationStart')}
                  invalidfeedback={getMessageError('vacationStart')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="date"
                  value={vacationStart || ''}
                  readOnly={isNotCoordinator && hasCoordinator}
                  disabled={isNotCoordinator && hasCoordinator}
                />
              </Col>
              <Col>
                <InputGroup
                  icon={calendarIcon}
                  label={intl.formatMessage(messages.endVacation)}
                  name="vacationEnd"
                  valid={handleIsValid('vacationEnd')}
                  invalidfeedback={getMessageError('vacationEnd')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="date"
                  value={vacationEnd || ''}
                  readOnly={isNotCoordinator && hasCoordinator}
                  disabled={isNotCoordinator && hasCoordinator}
                />
              </Col>
              {!hasAditionalVacationPeriod && !hasCoordinator && (
                <div className="school-form-add-vacation">
                  <RoundButton
                    title={intl.formatMessage(messages.addVacationPeriod)}
                    onClick={() => addVacationPeriod()}
                  >
                    <i className="ti-plus" />
                  </RoundButton>
                </div>
              )}
            </Row>
            {hasAditionalVacationPeriod && (
              <Row className="mt-2">
                <Col>
                  <InputGroup
                    icon={calendarIcon}
                    label={intl.formatMessage(messages.startVacation)}
                    name="vacation2Start"
                    valid={handleIsValid('vacation2Start')}
                    invalidfeedback={getMessageError('vacation2Start')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="date"
                    value={vacation2Start || ''}
                  />
                </Col>
                <Col>
                  <InputGroup
                    icon={calendarIcon}
                    label={intl.formatMessage(messages.endVacation)}
                    type="date"
                    name="vacation2End"
                    valid={handleIsValid('vacation2End')}
                    invalidfeedback={getMessageError('vacation2End')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={vacation2End || ''}
                  />
                </Col>
              </Row>
            )}
          </div>
          {hasTeacher && (
            <span>
              <h2 className="section-title mt-5">
                <i className="pe-7s-tools" />
                {intl.formatMessage(messages.teachers)}
              </h2>
              {teachers.map((teacher) => (
                <Row key={teacher.fullname || teacher.fullName}>
                  <Col sm={12}>
                    <SchoolTeacherCard {...teacher} />
                  </Col>
                </Row>
              ))}
            </span>
          )}
          {hasCoordinator && (
            <span>
              <h2 className="section-title mt-5">
                <i className="pe-7s-tools" />
                {intl.formatMessage(messages.coordinators)}
              </h2>
              <Row>
                <Col sm={12}>
                  <SchoolTeacherCard {...coordinators} />
                </Col>
              </Row>
            </span>
          )}
        </Col>
      </Row>
    </div>
  );
};

SchoolForm.propTypes = {
  intl: intlShape.isRequired,
  statusEdition: PropTypes.number,
  errors: PropTypes.shape([]).isRequired,
  submitCount: PropTypes.number.isRequired,
  role: PropTypes.number,
  values: PropTypes.shape({}).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  schoolFormStatus: PropTypes.string,
  modalSchoolForm: PropTypes.bool.isRequired,
};

SchoolForm.defaultProps = {
  statusEdition: 0,
  role: 0,
  schoolFormStatus: '',
};

export default compose(injectIntl, enhanceForm)(SchoolForm);
