import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'app.title',
    defaultMessage: 'Teach',
  },
  navbarEditLabel: {
    id: 'app.navbar.editLabel',
    defaultMessage: 'Edit',
  },
  navbarProfileLabel: {
    id: 'app.navbar.profileLabel',
    defaultMessage: 'My profile',
  },
  navbarLogoutLabel: {
    id: 'app.navbar.logoutLabel',
    defaultMessage: 'Logout',
  },
  navbarConfigurationLabel: {
    id: 'app.navbar.configurationLabel',
    defaultMessage: 'Configuration',
  },
  sidebarPanel: {
    id: 'app.sidebar.panel',
    defaultMessage: 'Panel',
  },
  sidebarCreatePlan: {
    id: 'app.sidebar.createPlan',
    defaultMessage: 'Create Plan',
  },
  contact: {
    id: 'app.AppFooter.contact',
    defaultMessage: 'Contact',
  },
  privacyPolicy: {
    id: 'app.AppFooter.privacyPolicy',
    defaultMessage: 'Privacy policy',
  },
  cookiesPolicy: {
    id: 'app.AppFooter.cookiesPolicy',
    defaultMessage: 'Cookies policy',
  },
  termsOfUse: {
    id: 'app.AppFooter.termsOfUse',
    defaultMessage: 'Terms of use',
  },
  cookiesSettings: {
    id: 'app.AppFooter.cookiesSettings',
    defaultMessage: 'Cookies settings',
  },
  generalText: {
    id: 'app.AppCookiesBanner.generalText',
    defaultMessage:
      'We use cookies and other proprietary and third-party technologies to properly and safely operate our website and personalize its content. We also use them to analyze your browsing experience and to adapt advertising to your tastes and preferences.',
  },
  rejectOrConfig: {
    id: 'app.AppCookiesBanner.rejectOrConfig',
    defaultMessage: 'Reject or config',
  },
  acceptAll: {
    id: 'app.AppCookiesBanner.acceptAll',
    defaultMessage: 'Accept all',
  },
  configInfo: {
    id: 'app.AppCookiesConfig.configInfo',
    defaultMessage: 'Here you can set your cookies, more information:',
  },
  technicalCookies: {
    id: 'app.AppCookiesConfig.technicalCookies',
    defaultMessage: 'Technical cookies',
  },
  descriptionTechnicalCookies: {
    id: 'app.AppCookiesConfig.descriptionTechnicalCookies',
    defaultMessage:
      'These cookies are necessary for the website to function and cannot be disabled.',
  },
  cookiesCustomization: {
    id: 'app.AppCookiesConfig.cookiesCustomization',
    defaultMessage: 'Customization cookies',
  },
  descriptionCookiesCustomization: {
    id: 'app.AppCookiesConfig.descriptionCookiesCustomization',
    defaultMessage:
      'With these cookies we can adapt the display of the website and personalize your options.',
  },
  cookiesAnalytical: {
    id: 'app.AppCookiesConfig.cookiesAnalytical',
    defaultMessage: 'Analytical cookies',
  },
  descriptionCookiesAnalytical: {
    id: 'app.AppCookiesConfig.descriptionCookiesAnalytical',
    defaultMessage:
      'With these cookies we can analyze your navigation on our website to carry out statistical studies on your use.',
  },
  cookiesPublicity: {
    id: 'app.AppCookiesConfig.cookiesPublicity',
    defaultMessage: 'Publicity cookies',
  },
  descriptionCookiesPublicity: {
    id: 'app.AppCookiesConfig.descriptionCookiesPublicity',
    defaultMessage:
      'With these cookies, we can customize the ads displayed for your tastes, preferences and navigation on our website, as well as display SM ads on third party websites.',
  },
  alwaysActive: {
    id: 'app.AppCookiesConfig.alwaysActive',
    defaultMessage: 'Always active',
  },
  allowed: {
    id: 'app.AppCookiesConfig.allowed',
    defaultMessage: 'Allowed',
  },
  notAllowed: {
    id: 'app.AppCookiesConfig.notAllowed',
    defaultMessage: 'Not allowed',
  },
  saveSettings: {
    id: 'app.AppCookiesConfig.saveSettings',
    defaultMessage: 'Save settings',
  },
  cancel: {
    id: 'app.logout.cancel',
    defaultMessage: 'Cancel',
  },
  logout: {
    id: 'app.logout.logout',
    defaultMessage: 'Logout',
  },
  confirmLogout: {
    id: 'app.logout.confirm',
    defaultMessage: 'Are you sure you want to logout?',
  },
  errorRequired: {
    id: 'app.error.required',
    defaultMessage: 'Required field.',
  },
  errorFallback: {
    id: 'app.error.errorFallback',
    defaultMessage: 'Error.',
  },
  applications: {
    id: 'app.applications',
    defaultMessage: 'Applications',
  },
  titleOptin: {
    id: 'app.modalOptIn.titleOptin',
    defaultMessage: 'Welcome, {userName}',
  },
  subtitleOptin: {
    id: 'app.modalOptIn.subtitleOptin',
    defaultMessage: 'To continue you need to agree to our </br> Terms of Use and Privacy Policy',
  },
  readTerms: {
    id: 'app.modalOptIn.readTerms',
    defaultMessage:
      "I read and accept the <a style='color:blue; text-decoration:underline' href='http://linkte.me/politicaprivacidadeapp' target='_blank'>conditions of use</a> and the <a style='color:blue; text-decoration:underline' href='http://linkte.me/politicaprivacidadeapp' target='_blank'>privacy policy</a>.",
  },
  receiveCommercial: {
    id: 'app.modalOptIn.receiveCommercial',
    defaultMessage: 'I wish to receive commercial communications from the SM Group.',
  },
  submit: {
    id: 'app.modalOptIn.submit',
    defaultMessage: 'Submit',
  },
  mustAcceptTerms: {
    id: 'app.modalOptIn.error.mustAcceptTerms',
    defaultMessage: 'Must Accept Terms and Conditions',
  },
  greeting: {
    id: 'app.greeting',
    defaultMessage: 'Hello, ',
  },
});
