/**
 * Check .env files to see other variables available to the app
 * These .env files defines settings according the type of the environment (state, production, dev)
 */

const priorityLanguages = ['pt', 'es', 'en'];

function getDefaultLocale() {
  const languages = navigator.languages || [navigator.language];
  const ordinaryLanguages = languages.map((l) => l.split('-')[0]);
  for (let i = 0; i < priorityLanguages.length; i++) {
    const priorityLanguage = priorityLanguages[i];
    if (ordinaryLanguages.includes(priorityLanguage)) {
      return priorityLanguage;
    }
  }
  return priorityLanguages[priorityLanguages.length - 1];
}

export const DEFAULT_LOCALE = getDefaultLocale();
export const UPLOAD_PATH = process.env.SM_UPLOAD_PATH;
