// @flow
import { fork, all, take } from 'redux-saga/effects';
import * as task from 'app/sagas';
import * as a from 'app/api';
import * as actions from './myLessonsActions';

export const getMyClasses = task.apiTask(
  actions.GET_MY_CLASSES,
  a.api.post,
  '/teach/getMyClasses.php',
);
export const getStudentFilterDisciplines = task.apiTask(
  actions.GET_STUDENT_FILTER_DISCIPLINES,
  a.api.post,
  '/teach/getStudentFilterDisciplines.php',
);

export function* watchGetMyClasses() {
  while (true) {
    const { payload } = yield take(actions.GET_MY_CLASSES);
    yield fork(getMyClasses, payload);
  }
}
export function* watchGetStudentFilterDisciplines() {
  while (true) {
    const { payload } = yield take(actions.GET_STUDENT_FILTER_DISCIPLINES);
    yield fork(getStudentFilterDisciplines, payload);
  }
}

export const getScheduledLessonsDates = task.apiTask(
  actions.GET_SCHEDULED_LESSONS_DATES,
  a.api.post,
  '/teach/getScheduledLessonsDates.php',
);

export function* watchGetScheduledLessonsDates() {
  while (true) {
    const { payload } = yield take(actions.GET_SCHEDULED_LESSONS_DATES);
    yield fork(getScheduledLessonsDates, payload);
  }
}

// Root module saga
export function* sagas() {
  yield all([fork(watchGetMyClasses), fork(watchGetStudentFilterDisciplines), fork(watchGetScheduledLessonsDates)]);
}
