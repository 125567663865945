// @flow
import { take, fork, all, call, put } from 'redux-saga/effects';
import { saveAs } from 'file-saver';
import * as task from 'app/sagas';
import * as a from 'app/api';
import { success, failure, request, action } from '../app/actions';
import * as actions from './classConfigurationActions';

export const getScheduledLessonData = task.apiTask(
  actions.GET_SCHEDULED_LESSON_DATA,
  a.api.post,
  '/teach/getScheduledLessonData.php',
);
export function* watchGetScheduledLessonData() {
  while (true) {
    const { payload } = yield take(actions.GET_SCHEDULED_LESSON_DATA);
    yield fork(getScheduledLessonData, payload);
  }
}

export const manageScheduledLessonData = task.apiTask(
  actions.MANAGE_SCHEDULED_LESSON_DATA,
  a.api.post,
  '/teach/manageScheduledLessonData.php',
);
export function* watchManageScheduledLessonData() {
  while (true) {
    const { payload } = yield take(actions.MANAGE_SCHEDULED_LESSON_DATA);
    yield fork(manageScheduledLessonData, payload);
  }
}

export const editScheduledLessonResources = task.apiTask(
  actions.EDIT_SCHEDULED_LESSON_RESOURCES,
  a.api.post,
  '/teach/editScheduledLessonResources.php',
);
export function* watchEditScheduledLessonResources() {
  while (true) {
    const { payload } = yield take(actions.EDIT_SCHEDULED_LESSON_RESOURCES);
    yield fork(editScheduledLessonResources, payload);
  }
}

export const deleteScheduledLessonResources = task.apiTask(
  actions.DELETE_SCHEDULED_LESSON_RESOURCES,
  a.api.post,
  '/teach/deleteScheduledLessonResources.php',
);
export function* watchDeleteScheduledLessonResources() {
  while (true) {
    const { payload } = yield take(actions.DELETE_SCHEDULED_LESSON_RESOURCES);
    yield fork(deleteScheduledLessonResources, payload);
  }
}

export const changeScheduledLessonVisibility = task.apiTask(
  actions.CHANGE_SCHEDULED_LESSON_VISIBILITY,
  a.api.post,
  '/teach/changeScheduledLessonVisibility.php',
);
export function* watchChangeScheduledLessonVisibility() {
  while (true) {
    const { payload } = yield take(actions.CHANGE_SCHEDULED_LESSON_VISIBILITY);
    yield fork(changeScheduledLessonVisibility, payload);
  }
}

export const getScheduledLessonChat = task.apiTask(
  actions.GET_SCHEDULED_LESSON_CHAT,
  a.api.post,
  '/teach/getScheduledLessonChat.php',
);
export function* watchGetScheduledLessonChat() {
  while (true) {
    const { payload } = yield take(actions.GET_SCHEDULED_LESSON_CHAT);
    yield fork(getScheduledLessonChat, payload);
  }
}

export const addScheduledLessonChatMessage = task.apiTask(
  actions.ADD_SCHEDULED_LESSON_CHAT_MESSAGE,
  a.api.post,
  '/teach/addScheduledLessonChatMessage.php',
);
export function* watchAddScheduledLessonChatMessage() {
  while (true) {
    const { payload } = yield take(actions.ADD_SCHEDULED_LESSON_CHAT_MESSAGE);
    yield fork(addScheduledLessonChatMessage, payload);
  }
}

export const downloadLessonResourseFile = task.apiTask(
  actions.DOWNLOAD_LESSON_RESOURCE_FILE,
  a.api.post,
  '/teach/downloadLessonResourseFile.php',
);
// export function* watchDownloadLessonResourseFile() {
//   while (true) {
//     const { payload } = yield take(actions.DOWNLOAD_LESSON_RESOURCE_FILE);
//     yield fork(downloadLessonResourseFile, payload);
//   }
// }
export function* watchDownloadLessonResourseFile() {
  while (true) {
    const { payload } = yield take(actions.DOWNLOAD_LESSON_RESOURCE_FILE);
    const { resolve, reject } = payload;
    yield put(action(request(actions.DOWNLOAD_LESSON_RESOURCE_FILE)));
    try {
      const { data } = yield call(a.api.post, '/teach/downloadLessonResourseFile.php', payload, {
        responseType: 'blob',
      });

      if (data && data instanceof Blob) {
        yield put(action(success(actions.DOWNLOAD_LESSON_RESOURCE_FILE)));
        saveAs(data, payload.filename);
      }
      resolve && resolve();
    } catch (error) {
      yield put(action(failure(actions.DOWNLOAD_LESSON_RESOURCE_FILE)));
      reject && reject(error);
    }
  }
}

export const addScheduledLesson = task.apiTask(
  actions.ADD_SCHEDULED_LESSON,
  a.api.post,
  '/teach/addScheduledLesson.php',
);
export function* watchAddScheduledLesson() {
  while (true) {
    const { payload } = yield take(actions.ADD_SCHEDULED_LESSON);
    yield fork(addScheduledLesson, payload);
  }
}

export const getScheduledPlanCoursesHours = task.apiTask(
  actions.GET_SCHEDULED_PLAN_COURSES_HOURS,
  a.api.post,
  '/teach/getScheduledPlanCoursesHours.php',
);
export function* watchGetScheduledPlanCoursesHours() {
  while (true) {
    const { payload } = yield take(actions.GET_SCHEDULED_PLAN_COURSES_HOURS);
    yield fork(getScheduledPlanCoursesHours, payload);
  }
}

export const viewLessonResourseFile = task.apiTask(
  actions.VIEW_LESSON_RESOURCE_FILE,
  a.api.post,
  '/teach/viewLessonResourseFile.php',
);
export function* watchViewLessonResourseFile() {
  while (true) {
    const { payload } = yield take(actions.VIEW_LESSON_RESOURCE_FILE);
    yield fork(viewLessonResourseFile, payload);
  }
}

// Root module saga
export function* sagas() {
  yield all([
    fork(watchGetScheduledLessonData),
    fork(watchManageScheduledLessonData),
    fork(watchEditScheduledLessonResources),
    fork(watchDeleteScheduledLessonResources),
    fork(watchChangeScheduledLessonVisibility),
    fork(watchGetScheduledLessonChat),
    fork(watchAddScheduledLessonChatMessage),
    fork(watchDownloadLessonResourseFile),
    fork(watchAddScheduledLesson),
    fork(watchGetScheduledPlanCoursesHours),
    fork(watchViewLessonResourseFile),
  ]);
}
