import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { injectIntl, intlShape } from 'react-intl';

import messages from '../myClassStudentsMessages';
import '../MyClassStudents.scss';

const ExportSheetButton = (props) => {
  const { intl, onClick, className } = props;

  return (
    <div
      className={['btn btn-primary my-class-button-student', className || ''].join(' ')}
      onClick={onClick}
    >
      {intl.formatMessage(messages.exportSheet)}
    </div>
  );
};

ExportSheetButton.propTypes = {
  intl: intlShape.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
};

export default compose(injectIntl)(ExportSheetButton);
