/* eslint-disable no-sequences */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-multi-comp */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withFormik } from 'formik';
import { injectIntl, intlShape } from 'react-intl';
import * as Yup from 'yup';
import { getUserAvatarUrl } from 'app/appHelpers';
import { Input, AvatarInput, Loader } from 'lec-ui';
import placeholderSrc from '../app/images/placeholder-avatar.svg';

import messages from './configurationMessages';
import './ConfigurationForm.scss';

const shapeConfigurationSchema = {
  firstname: Yup.string(),
  lastname: Yup.string(),
  email: Yup.string(),
  oldPassword: Yup.string(),
  newPassword: Yup.string().matches(
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
    'errorStrong',
  ),
  confirmNewPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], 'errorDontMatch'),
  school: Yup.string(),
};

const ConfigurationSchema = Yup.object().shape(shapeConfigurationSchema);

const initialValues = {
  firstname: '',
  lastname: '',
  email: '',
  oldPassword: '',
  newPassword: '',
  confirmNewPassword: '',
  schoolName: '',
};

const enhanceForm = withFormik({
  mapPropsToValues: ({ values }) => ({ ...values }),
  initialValues,
  validationSchema: ConfigurationSchema,
  handleSubmit: (values, { props }) => {
    console.log('props', props);
    const payload = { ...values, idAccess: 16, idUser: props.user.idUser };
    props.onSubmit(payload);
  },
  enableReinitialize: true,
});

function fileToPayload(file, idUser) {
  const formData = new FormData();
  formData.append('idUser', idUser);
  formData.append('file', file);
  return formData;
}

const ConfigurationForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  handleSubmit,
  onUpload,
  onRemove,
  user,
  isLoading,
  // userInfo,
  userInfoStatus,
  intl,
}) => {
  const [editForm, setEditForm] = useState(false);
  const [userCI] = useState(!!user.mdlUserGuid); // setUserCI

  function handleEditForm() {
    setEditForm(true);
  }

  function handleInputValues() {
    setFieldValue('firstname', user.firstName);
    setFieldValue('lastname', user.lastName);
    setFieldValue('email', user.email);
    setFieldValue('schoolName', user.schoolName);
  }

  function handleCancelForm() {
    setEditForm(false);
    handleInputValues();
  }

  useEffect(() => {
    handleInputValues();
  }, []);

  useEffect(() => {
    if (userInfoStatus === 200) {
      setTimeout(() => {
        window.location.reload(false);
      }, 1000);
    }
  }, [userInfoStatus]);

  const ConfirmPassDontMatch = () => (
    <div style={{ color: 'red', fontSize: '10px' }}>
      {intl.formatMessage(messages.confirmPassDontMatch)}
    </div>
  );

  const PassStrongField = () => (
    <div style={{ color: 'red', fontSize: '10px' }}>
      {intl.formatMessage(messages.passwordNotStrong)}
    </div>
  );

  const PassNotEqual = () => (
    <div style={{ color: 'red', fontSize: '10px' }}>
      {intl.formatMessage(messages.passwordNotEqual)}
    </div>
  );

  return (
    <div>
      <Loader show={isLoading} />
      <div className="row">
        <div className="col-sm-3">
          {user.photo && (
            <button type="button" className="photo-btn-close pe-7s-close" onClick={onRemove}>
              {' '}
            </button>
          )}
          <AvatarInput
            src={getUserAvatarUrl(user.photo)}
            placeholderSrc={placeholderSrc}
            label={intl.formatMessage(messages.updatePhoto)}
            id="avatar"
            name="avatar"
            alt={values.name}
            onChange={(file) => onUpload(fileToPayload(file, user.idUser))}
          />
        </div>
        <div className="col-sm-9">
          <form onSubmit={handleSubmit}>
            {userInfoStatus === 200 && (
              <div className="boxMsg alert alert-success">
                {intl.formatMessage(messages.confirmedEditConfig)}
              </div>
            )}
            {userInfoStatus && userInfoStatus !== 200 && (
              <div className="boxMsg alert alert-danger">
                {userInfoStatus
                  ? intl.formatMessage(messages[`error${userInfoStatus}`])
                  : intl.formatMessage(messages.error503)}
              </div>
            )}

            <Input
              id="firstname"
              name="firstname"
              label={intl.formatMessage(messages.firstName)}
              disabled={!editForm || userCI}
              value={user.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            <Input
              id="lastname"
              name="lastname"
              label={intl.formatMessage(messages.lastName)}
              disabled={!editForm || userCI}
              value={user.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            <Input
              id="email"
              name="email"
              value={user.email}
              type="email"
              disabled
              label={intl.formatMessage(messages.email)}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            <Input
              id="oldPassword"
              name="oldPassword"
              type="password"
              label={intl.formatMessage(messages.olderPassword)}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!editForm || userCI}
            />

            <div className="input-password">
              <Input
                id="newPassword"
                name="newPassword"
                type="password"
                label={intl.formatMessage(messages.newPassword)}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={!editForm || userCI}
              />
              {editForm && errors.newPassword === 'errorNotEqual' && touched.newPassword && (
                <PassNotEqual />
              )}
              {editForm && errors.newPassword === 'errorStrong' && touched.newPassword && (
                <PassStrongField />
              )}
            </div>

            <div className="card2 card-password">
              {intl.formatMessage(messages.passCriterio)}
              <ul>
                <li>{intl.formatMessage(messages.caracterNumber)}</li>
                <li>{intl.formatMessage(messages.letterUppercase)}</li>
                <li>{intl.formatMessage(messages.letterLowercase)}</li>
                <li>{intl.formatMessage(messages.number)}</li>
                <li>
                  {intl.formatMessage(messages.specialCaracter)}{' '}
                  {` ' ~ ! @ # $ % ^ & * () _ + - = {} | \\ : " ; ' < > ? , . / )`}
                </li>
              </ul>
            </div>

            <Input
              id="confirmNewPassword"
              name="confirmNewPassword"
              type="password"
              label={intl.formatMessage(messages.confirmNewPassword)}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!editForm || userCI}
            />
            {editForm && errors.confirmNewPassword === 'errorDontMatch' && touched.newPassword && (
              <ConfirmPassDontMatch />
            )}

            <div style={{ display: !editForm ? 'none' : 'block' }}>
              <button
                className="btn btn-primary btn-lg btn-block"
                type="submit"
                style={{ marginTop: '20px' }}
                // setFieldValue={('firstname', 'lastname', 'email', 'schoolName')}
                value="submit"
              >
                {intl.formatMessage(messages.save)}
              </button>

              <button
                className="btn btn-outline-primary btn-lg btn-block"
                type="button"
                onClick={handleCancelForm}
              >
                {intl.formatMessage(messages.cancel)}
              </button>
            </div>
          </form>

          <button
            className="btn btn-primary btn-lg btn-block"
            type="button"
            onClick={handleEditForm}
            style={{ display: editForm || userCI ? 'none' : 'block', marginTop: '20px' }}
          >
            {intl.formatMessage(messages.edit)}
          </button>
        </div>
      </div>
    </div>
  );
};

ConfigurationForm.propTypes = {
  intl: intlShape.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  userInfoStatus: PropTypes.string,
  user: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

ConfigurationForm.defaultProps = {
  userInfoStatus: '',
};

export default compose(injectIntl, enhanceForm)(ConfigurationForm);
