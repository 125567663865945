/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { withFormik } from 'formik';
import * as act from 'app/actions';
import * as Yup from 'yup';
import groupBy from 'lodash/groupBy';
import first from 'lodash/first';

import { Select, Row, Col, Alert, Button } from 'lec-ui';

import SchoolSelect from '../register/SchoolSelect';

import messages from '../register/registerMessages';

import * as registerActions from '../register/registerActions';
import * as loginActions from '../login/loginActions';

import '../register/RegisterForm.scss';

const RegisterSchema = Yup.object().shape({
  idSchool: Yup.number().required('errorRequired'),
  idCountry: Yup.number().required('errorRequired'),
  city: Yup.string().required('errorRequired'),
  state: Yup.string().required('errorRequired'),
});

const initialValues = {
  city: '',
  state: '',
  idSchool: '',
};

const enhanceForm = withFormik({
  mapPropsToValues: () => ({}),
  initialValues,
  validationSchema: RegisterSchema,
  handleSubmit: (values, { props }) => {
    props.onSubmit(values);
  },
});
const RegisterSchoolForm = (props) => {
  const {
    handleSubmit,
    values,
    schoolsSearch,
    cities,
    states,
    intl,
    countries,
    setFieldValue,
    isValid,
    error,
    errorType,
    errors,
    getStates,
    getCities,
    searchSchools,
    touched,
  } = props;

  const [school, setSchool] = useState([]);
  const [statesSelect, setStatesSelect] = useState([]);
  const [citiesSelect, setCitiesSelect] = useState([]);

  const countriesGroupByName = groupBy(countries, 'name');

  const selectCountries = Object.keys(countriesGroupByName).map((el) => ({
    id: first(countriesGroupByName[el]).idCountry,
    value: first(countriesGroupByName[el]).idCountry,
    label: el,
  }));

  // function getMessageError(field) {
  //   if (errors && errors[field]) {
  //     return intl.formatMessage(messages[errors[field]]);
  //   }
  //   return undefined;
  // }

  function handleSelectCountry(event) {
    let value = '14';
    if (event) {
      value = event.target.value;
    }

    setSchool(null);

    getStates({ idCountry: value });
    setFieldValue('idCountry', value);
    setFieldValue('state', initialValues.state);
    setFieldValue('city', initialValues.city);
    setFieldValue('idSchool', initialValues.idSchool);
  }

  function handleSelectState(event) {
    const { value } = event.target;
    setFieldValue('state', value);
    setFieldValue('city', initialValues.city);
    setFieldValue('idSchool', initialValues.idSchool);
    getCities({ idCountry: values.idCountry, state: value });
    setSchool(null);
  }

  function handleSelectCity(event) {
    const { value } = event.target;
    setFieldValue('city', value);
    setFieldValue('idSchool', initialValues.idSchool);
    searchSchools({ idCountry: values.idCountry, state: values.state, city: value });
    setSchool(null);
  }

  function handleSelectSchool(event) {
    setSchool(event);
    setFieldValue('idSchool', event.id);
  }

  useEffect(() => {
    handleSelectCountry();
  }, []);

  useEffect(() => {
    if (states && states.length > 0) {
      const newStates = states.map((el) => ({
        value: el.state,
        label: el.state,
      }));
      setStatesSelect(newStates);
    }
  }, [states]);

  useEffect(() => {
    if (cities && cities.length > 0) {
      const newCities = cities.map((el) => ({
        value: el.city,
        label: el.city,
      }));
      setCitiesSelect(newCities);
    }
  }, [cities]);

  return (
    <div>
      {/* <Loader show={isLoading} /> */}
      {errorType && Object.keys(errorType).length > 0 && (
        <Alert type="danger">{intl.formatMessage(messages[`error${errorType || 500}`])}</Alert>
      )}
      <form className="col ml-auto mr-auto register-form" onSubmit={handleSubmit}>
        <Row style={{ textAlign: 'left' }}>
          <Col style={{ display: 'none' }}>
            <Select
              label={intl.formatMessage(messages.country)}
              placeholder={intl.formatMessage(messages.selectCountryPlaceholder)}
              name="idCountry"
              value={values.idCountry}
              options={selectCountries}
              onChange={(e) => handleSelectCountry(e)}
              disabled
            />
            {errors.idCountry && touched.idCountry && (
              <div style={{ color: 'red', fontSize: '10px' }}>
                {intl.formatMessage(messages.requiredField)}
              </div>
            )}
          </Col>
          <Col>
            <Select
              label={intl.formatMessage(messages.state)}
              placeholder={intl.formatMessage(messages.selectStatePlaceholder)}
              name="state"
              disabled={!values.idCountry}
              value={values.state}
              options={statesSelect}
              onChange={(e) => handleSelectState(e)}
            />
            {errors.state && touched.state && (
              <div style={{ color: 'red', fontSize: '10px' }}>
                {intl.formatMessage(messages.requiredField)}
              </div>
            )}
          </Col>
          <Col>
            <Select
              label={intl.formatMessage(messages.city)}
              placeholder={intl.formatMessage(messages.selectCityPlaceholder)}
              disabled={!values.state}
              value={values.city}
              name="city"
              options={citiesSelect}
              onChange={(e) => handleSelectCity(e)}
            />
            {errors.city && touched.city && (
              <div style={{ color: 'red', fontSize: '10px' }}>
                {intl.formatMessage(messages.requiredField)}
              </div>
            )}
          </Col>
        </Row>
        {values.city && (
          <Row>
            <Col>
              <div>
                <SchoolSelect
                  label={intl.formatMessage(messages.school)}
                  value={school}
                  disabled={cities.length === 0}
                  placeholder={intl.formatMessage(messages.searchSchoolPlaceholder)}
                  // noOptionsMessage={intl.formatMessage(messages.noSchoolFounded)}
                  options={schoolsSearch}
                  name="school"
                  onChange={(e) => handleSelectSchool(e)}
                />
                {errors.school && touched.school && (
                  <div style={{ color: 'red', fontSize: '10px' }}>
                    {intl.formatMessage(messages.requiredField)}
                  </div>
                )}
              </div>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <div className="school-add-modal-form-details">
              {school && (
                <div>
                  <strong>{school.name}</strong>
                  <br />
                  <span>{school.address}</span>
                  <br />
                  <span>{school.zipcode}</span>
                  <br />
                  <span>{school.city}</span> - <span>{school.state}</span>
                </div>
              )}
              {!school && <span>{intl.formatMessage(messages.noSchoolSelected)}</span>}
            </div>
          </Col>
        </Row>
        <Button
          color="primary"
          large
          className="btn-block"
          type="submit"
          value={intl.formatMessage(messages.save)}
          style={{ marginTop: '30px' }}
          disabled={!isValid}
        >
          {intl.formatMessage(messages.save)}
        </Button>
      </form>
    </div>
  );
};

RegisterSchoolForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  schoolsSearch: PropTypes.array.isRequired,
  cities: PropTypes.array.isRequired,
  states: PropTypes.array.isRequired,
  intl: PropTypes.object.isRequired,
  countries: PropTypes.array.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  error: PropTypes.object,
  errorType: PropTypes.string,
  touched: PropTypes.object,
  errors: PropTypes.object,
  getStates: PropTypes.func.isRequired,
  getCities: PropTypes.func.isRequired,
  searchSchools: PropTypes.func.isRequired,
  school: PropTypes.object,
};

RegisterSchoolForm.defaultProps = {
  error: null,
  errorType: null,
  touched: null,
  errors: null,
  school: null,
};

const mapStateToProps = ({ lang, app, register, login }) => ({
  ...app,
  ...lang,
  ...register,
  ...login,
});

export default compose(
  withRouter,
  injectIntl,
  connect(mapStateToProps, act.promisify({ ...registerActions, ...loginActions })),
  enhanceForm,
)(RegisterSchoolForm);
