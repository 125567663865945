// @flow
import { fork, all, take } from 'redux-saga/effects';
import * as task from 'app/sagas';
import * as a from 'app/api';
import * as actions from './panelActions';

export const getFilterCatalogings = task.apiTask(
  actions.GET_FILTER_CATALOGINGS,
  a.api.post,
  '/teach/getFilterCatalogings.php',
);

export function* watchGetFilterCatalogings() {
  while (true) {
    const { payload } = yield take(actions.GET_FILTER_CATALOGINGS);
    yield fork(getFilterCatalogings, payload);
  }
}

export const getDashboardInfo = task.apiTask(
  actions.GET_DASHBOARD_INFO,
  a.api.post,
  '/teach/getDashboardInfo.php',
);

export function* watchGetDashboardInfo() {
  while (true) {
    const { payload } = yield take(actions.GET_DASHBOARD_INFO);
    yield fork(getDashboardInfo, payload);
  }
}

export const getScheduledLessonsDates = task.apiTask(
  actions.GET_SCHEDULED_LESSONS_DATES,
  a.api.post,
  '/teach/getScheduledLessonsDates.php',
);

export function* watchGetScheduledLessonsDates() {
  while (true) {
    const { payload } = yield take(actions.GET_SCHEDULED_LESSONS_DATES);
    yield fork(getScheduledLessonsDates, payload);
  }
}

export const managePlanScheduledAnalytics = task.apiTask(
  actions.MANAGE_PLAN_SCHEDULED_ANALYTICS,
  a.api.post,
  '/teach/managePlanScheduledAnalytics.php',
);

export function* watchManagePlanScheduledAnalytics() {
  while (true) {
    const { payload } = yield take(actions.MANAGE_PLAN_SCHEDULED_ANALYTICS);
    yield fork(managePlanScheduledAnalytics, payload);
  }
}

export const registerSchool = task.apiTask(
  actions.REGISTER_SCHOOL,
  a.api.post,
  '/teach/registerSchool.php',
);

export function* watchRegisterSchool() {
  while (true) {
    const { payload } = yield take(actions.REGISTER_SCHOOL);
    yield fork(registerSchool, payload);
  }
}

export const registerStudentSchool = task.apiTask(
  actions.REGISTER_STUDENT_SCHOOL,
  a.api.post,
  '/teach/registerStudentSchool.php',
);

export function* watchRegisterStudentSchool() {
  while (true) {
    const { payload } = yield take(actions.REGISTER_STUDENT_SCHOOL);
    yield fork(registerStudentSchool, payload);
  }
}

export const getLatestAccesses = task.apiTask(
  actions.GET_LATEST_ACCESSES,
  a.api.post,
  '/teach/getLatestAccesses.php',
);

export function* watchGetLatestAccesses() {
  while (true) {
    const { payload } = yield take(actions.GET_LATEST_ACCESSES);
    yield fork(getLatestAccesses, payload);
  }
}

export const getAbilitiesHeatmap = task.apiTask(
  actions.GET_ABILITIES_HEATMAP,
  a.api.post,
  '/teach/getAbilitiesHeatmap.php',
);

export function* watchGetAbilitiesHeatmap() {
  while (true) {
    const { payload } = yield take(actions.GET_ABILITIES_HEATMAP);
    yield fork(getAbilitiesHeatmap, payload);
  }
}

export const getAbilitiesHeatmapFilter = task.apiTask(
  actions.GET_ABILITIES_HEATMAP_FILTER,
  a.api.post,
  '/teach/getAbilitiesHeatmapFilter.php',
);

export function* watchGetAbilitiesHeatmapFilter() {
  while (true) {
    const { payload } = yield take(actions.GET_ABILITIES_HEATMAP_FILTER);
    yield fork(getAbilitiesHeatmapFilter, payload);
  }
}

// Root module saga
export function* sagas() {
  yield all([
    fork(watchGetFilterCatalogings),
    fork(watchGetDashboardInfo),
    fork(watchGetScheduledLessonsDates),
    fork(watchManagePlanScheduledAnalytics),
    fork(watchRegisterSchool),
    fork(watchRegisterStudentSchool),
    fork(watchGetLatestAccesses),
    fork(watchGetAbilitiesHeatmap),
    fork(watchGetAbilitiesHeatmapFilter),
  ]);
}
