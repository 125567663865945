import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './StatusBadge.scss';

const StatusBadge = props => (
  <div
    className={classNames(
      'status-badge',
      `status-badge--variant-${props.variant}`,
      props.className,
    )}
  >
    {props.children}
  </div>
);

StatusBadge.propTypes = {
  variant: PropTypes.oneOf(['info', 'warning', 'danger', 'info', 'primary']),
};

StatusBadge.defaultProps = {
  variant: 'info',
};

export default StatusBadge;
