import { defineMessages } from 'react-intl';

export default defineMessages({
  validationTitle: {
    id: 'validation.validationTitle',
    defaultMessage: 'Your plan has been successfully scheduled for the class',
  },
  validationTitlePlural: {
    id: 'validation.validationTitlePlural',
    defaultMessage: 'Your plan has been successfully scheduled for the classes',
  },
  validationText: {
    id: 'validation.validationText',
    defaultMessage:
      'Your coordinator will validate your plan, you will be notified in case of update or process.',
  },
  back: {
    id: 'validation.back',
    defaultMessage: 'Back',
  },
  save: {
    id: 'validation.save',
    defaultMessage: 'Save',
  },
  downloadPDF: {
    id: 'validation.downloadPDF',
    defaultMessage: 'Download PDF version',
  },
  continueWithoutValidation: {
    id: 'validation.continueWithoutValidation',
    defaultMessage: 'Continue without validation',
  },
  moreClassesForPlan: {
    id: 'validation.moreClassesForPlan',
    defaultMessage:
      'You have more than one class for the selected school year. </br> Select the classes that will have the plan applied.',
  },
  oneClassForPlan: {
    id: 'validation.oneClassForPlan',
    defaultMessage:
      'You have just one class for the selected school year. </br> Click on save button to have the plan applied.',
  },
  selectAll: {
    id: 'validation.selectAll',
    defaultMessage: 'Select All',
  },
  noCoursesFound: {
    id: 'validation.noCoursesFound',
    defaultMessage: 'No courses were found, please talk with your coordinator',
  },
  genericError: {
    id: 'validation.genericError',
    defaultMessage: 'An error occurred, please try again later.',
  },
  appliedWithSuccess: {
    id: 'validation.appliedWithSuccess',
    defaultMessage: 'Annual plan successfully applied',
  },
  home: {
    id: 'validation.home',
    defaultMessage: 'go to home',
  },
  planNextStep: {
    id: 'validation.planNextStep',
    defaultMessage: 'Plan next step',
  },
  continue: {
    id: 'validation.continue',
    defaultMessage: 'Continue',
  },
  and: {
    id: 'validation.and',
    defaultMessage: 'and',
  },
});
