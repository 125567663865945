import * as act from 'app/actions';

export const GET_PLAN_SCHEDULED_DATA = 'plan-lessons/GET_PLAN_SCHEDULED_DATA';
export const getPlanScheduledData = (payload) => act.action(GET_PLAN_SCHEDULED_DATA, { payload });

export const GET_SCHEDULED_PLAN_COURSES = 'plan-lessons/GET_SCHEDULED_PLAN_COURSES';
export const getScheduledPlanCourses = (payload) =>
  act.action(GET_SCHEDULED_PLAN_COURSES, { payload });

export const EDIT_SCHEDULED_LESSON_STATUS = 'plan-lessons/EDIT_SCHEDULED_LESSON_STATUS';
export const editScheduledLessonStatus = (payload) =>
  act.action('plan-lessons/EDIT_SCHEDULED_LESSON_STATUS', { payload });
