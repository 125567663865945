import * as act from 'app/actions';
import * as actions from './planLessonsActions';

const initialState = {
  lessons: [],
  idPlan: '',
  filterPeriod: [],
  planLessonsIsLoading: false,
  scheduledPlanCourses: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case act.request(actions.GET_PLAN_SCHEDULED_DATA):
      return {
        ...state,
        planLessonsIsLoading: true,
        hasError: false,
      };
    case act.success(actions.GET_PLAN_SCHEDULED_DATA):
      return {
        ...state,
        lessons: action.payload.lessons,
        planLessonsIsLoading: initialState.planLessonsIsLoading,
      };
    case act.request(actions.GET_SCHEDULED_PLAN_COURSES):
      return {
        ...state,
        planLessonsIsLoading: true,
        hasError: false,
        scheduledPlanCourses: initialState.scheduledPlanCourses,
      };
    case act.success(actions.GET_SCHEDULED_PLAN_COURSES):
      return {
        ...state,
        scheduledPlanCourses: action.payload.content,
        planLessonsIsLoading: initialState.planLessonsIsLoading,
      };
    case act.request(actions.EDIT_SCHEDULED_LESSON_STATUS):
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case act.success(actions.EDIT_SCHEDULED_LESSON_STATUS):
      return {
        ...state,
        isLoading: initialState.isLoading,
      };
    default:
      return state;
  }
};

export default reducer;
