// @flow
import { fork, all, take } from 'redux-saga/effects';
import * as task from 'app/sagas';
import * as a from 'app/api';
import * as actions from './createPlanActions';

export const getPlansData = task.apiTask(
  actions.GET_PLANS_DATA,
  a.api.post,
  '/teach/getPlansData.php',
);
export function* watchGetPlansData() {
  while (true) {
    const { payload } = yield take(actions.GET_PLANS_DATA);
    yield fork(getPlansData, payload);
  }
}

export const getCatalogingAbilities = task.apiTask(
  actions.GET_CATALOGING_ABILITIES,
  a.api.post,
  '/teach/getCatalogingAbilities.php',
);
export function* watchGetCatalogingAbilities() {
  while (true) {
    const { payload } = yield take(actions.GET_CATALOGING_ABILITIES);
    yield fork(getCatalogingAbilities, payload);
  }
}

export const getLessonData = task.apiTask(
  actions.GET_LESSON_DATA,
  a.api.post,
  '/teach/getLessonData.php',
);
export function* watchGetLessonData() {
  while (true) {
    const { payload } = yield take(actions.GET_LESSON_DATA);
    yield fork(getLessonData, payload);
  }
}

export const savePlan = task.apiTask(actions.SAVE_PLAN, a.api.post, '/teach/savePlan.php');
export function* watchSavePlan() {
  while (true) {
    const { payload } = yield take(actions.SAVE_PLAN);
    yield fork(savePlan, payload);
  }
}

export const addPlanTableLesson = task.apiTask(
  actions.ADD_PLAN_TABLE_LESSON,
  a.api.post,
  '/teach/addPlanTableLesson.php',
);
export function* watchAddPlanTableLesson() {
  while (true) {
    const { payload } = yield take(actions.ADD_PLAN_TABLE_LESSON);
    yield fork(addPlanTableLesson, payload);
  }
}

export const editPlanTableLesson = task.apiTask(
  actions.EDIT_PLAN_TABLE_LESSON,
  a.api.post,
  '/teach/editPlanTableLesson.php',
);
export function* watchEditPlanTableLesson() {
  while (true) {
    const { payload } = yield take(actions.EDIT_PLAN_TABLE_LESSON);
    yield fork(editPlanTableLesson, payload);
  }
}

export const movePlanTableLesson = task.apiTask(
  actions.MOVE_PLAN_TABLE_LESSON,
  a.api.post,
  '/teach/movePlanTableLesson.php',
);
export function* watchMovePlanTableLesson() {
  while (true) {
    const { payload } = yield take(actions.MOVE_PLAN_TABLE_LESSON);
    yield fork(movePlanTableLesson, payload);
  }
}

export const deletePlanTableLesson = task.apiTask(
  actions.DELETE_PLAN_TABLE_LESSON,
  a.api.post,
  '/teach/deletePlanTableLesson.php',
);
export function* watchDeletePlanTableLesson() {
  while (true) {
    const { payload } = yield take(actions.DELETE_PLAN_TABLE_LESSON);
    yield fork(deletePlanTableLesson, payload);
  }
}

export const restorePlanTableLesson = task.apiTask(
  actions.RESTORE_PLAN_TABLE_LESSON,
  a.api.post,
  '/teach/restorePlanTableLesson.php',
);
export function* watchRestorePlanTableLesson() {
  while (true) {
    const { payload } = yield take(actions.RESTORE_PLAN_TABLE_LESSON);
    yield fork(restorePlanTableLesson, payload);
  }
}

export const restoreToOriginal = task.apiTask(
  actions.RESTORE_TO_ORIGINAL,
  a.api.post,
  '/teach/restorePlanToOriginal.php',
);
export function* watchRestoreToOriginal() {
  while (true) {
    const { payload } = yield take(actions.RESTORE_TO_ORIGINAL);
    yield fork(restoreToOriginal, payload);
  }
}

// Root module saga
export function* sagas() {
  yield all([
    fork(watchGetPlansData),
    fork(watchGetCatalogingAbilities),
    fork(watchGetLessonData),
    fork(watchSavePlan),
    fork(watchRestoreToOriginal),
    fork(watchAddPlanTableLesson),
    fork(watchEditPlanTableLesson),
    fork(watchMovePlanTableLesson),
    fork(watchDeletePlanTableLesson),
    fork(watchRestorePlanTableLesson),
  ]);
}
