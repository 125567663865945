/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-fragments */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { injectIntl, intlShape } from 'react-intl';

import './StudentLesson.scss';

import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  ConversationHeader,
  Avatar,
  Sidebar,
  ConversationList,
  Conversation,
} from '@chatscope/chat-ui-kit-react';

import messages from './studentLessonMessages';
import placeholderIco from '../app/images/placeholder-avatar.svg';

const Messagebox = (props) => {
  const {
    intl,
    sendMessage,
    canSendMessages,
    conversationsList,
    messagesList,
    courseName,
    daySelected,
    userName,
  } = props;

  const [messageInputValue, setMessageInputValue] = useState('');
  const [activeConversation, setActiveConversation] = useState(null);
  const [filteredMessage, setFilteredMessage] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [userSelected, setUserSelected] = useState([]);

  useEffect(() => {
    if (conversationsList.length > 0) {
      setActiveConversation(conversationsList[0].id);
      setUserSelected({
        name: conversationsList[0].name,
        photo: conversationsList[0].photo,
        id: conversationsList[0].id,
      });
      setFilteredMessage(
        // messagesList.filter(
        //   (item) =>
        //     item.idUser === conversationsList[0].id || item.addedBy === conversationsList[0].id,
        // ),
        messagesList,
      );
      setFilteredList(conversationsList);
    }
  }, [conversationsList, messagesList]);

  function handleSendMessage(val) {
    sendMessage(val, userSelected);
    setMessageInputValue('');
  }

  return (
    <MainContainer style={{ height: '500px' }}>
      <Sidebar position="left" scrollable={false}>
        <ConversationList>
          {filteredList.map((item, idx) => (
            <Conversation
              key={idx}
              name={item.name}
              lastSenderName={item.lastMsgInfo && intl.formatMessage(messages.teacher)} // item.lastMsgInfo.lastSenderName
              // info={item.lastMsgInfo && item.lastMsgInfo.lastMsg}
              active={activeConversation === item.id}
            >
              <Avatar
                src={
                  item.photo
                    ? `${process.env.SM_API_NODE_URL}/uploads/${item.photo}`
                    : placeholderIco
                }
                name={item.name}
              />
            </Conversation>
          ))}
        </ConversationList>
      </Sidebar>
      <ChatContainer>
        <ConversationHeader>
          <ConversationHeader.Content userName={`${courseName} - ${daySelected}`} />
        </ConversationHeader>
        <MessageList>
          {filteredMessage.length > 0 &&
            filteredMessage.map((item, idx) => (
              <Message
                key={idx}
                model={{
                  message: item.message,
                  sentTime: item.sentTime,
                  sender: item.sender,
                  sener: item.sener,
                  direction: item.direction,
                  position: item.position,
                }}
              >
                <Avatar
                  src={
                    item.photo
                      ? `${process.env.SM_API_NODE_URL}/uploads/${item.photo}`
                      : placeholderIco
                  }
                  name={item.name}
                />
                <Message.Footer
                  sender={item.sender || (item.sener === 'me' && userName)}
                  sentTime={item.sentTime}
                />
              </Message>
            ))}
        </MessageList>
        {canSendMessages && (
          <MessageInput
            placeholder={intl.formatMessage(messages.message)}
            value={messageInputValue}
            onChange={(val) => setMessageInputValue(val)}
            onSend={(val) => handleSendMessage(val)}
            attachButton={false}
          />
        )}
      </ChatContainer>
    </MainContainer>
  );
};

Messagebox.propTypes = {
  intl: intlShape,
  sendMessage: PropTypes.func.isRequired,
  canSendMessages: PropTypes.bool.isRequired,
  conversationsList: PropTypes.array.isRequired,
  messagesList: PropTypes.array.isRequired,
  courseName: PropTypes.string.isRequired,
  daySelected: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
};

Messagebox.defaultProps = {
  intl: [],
  courseName: "",
  daySelected: "",
};

export default compose(injectIntl)(Messagebox);
