import { defineMessages } from 'react-intl';

export default defineMessages({
  register: {
    id: 'register.register',
    defaultMessage: 'Create an account',
  },
  login: {
    id: 'register.login',
    defaultMessage: 'Login',
  },
  name: {
    id: 'register.name',
    defaultMessage: 'Name',
  },
  firstName: {
    id: 'register.firstName',
    defaultMessage: 'Name',
  },
  lastName: {
    id: 'register.lastName',
    defaultMessage: 'Last Name',
  },
  email: {
    id: 'register.email',
    defaultMessage: 'Email',
  },
  password: {
    id: 'register.password',
    defaultMessage: 'Password',
  },
  confirmPassword: {
    id: 'register.confirmPassword',
    defaultMessage: 'Confirm Password',
  },
  school: {
    id: 'register.school',
    defaultMessage: 'School',
  },
  button: {
    id: 'register.button',
    defaultMessage: 'Register',
  },
  question: {
    id: 'register.question',
    defaultMessage: 'What is your profile?',
  },
  student: {
    id: 'register.student',
    defaultMessage: 'Student',
  },
  teacher: {
    id: 'register.teacher',
    defaultMessage: 'Teacher',
  },
  textTerms: {
    id: 'register.textTerms',
    defaultMessage: 'By registering, you accept the ',
  },
  linkTerms: {
    id: 'register.linkTerms',
    defaultMessage: 'Terms of use',
  },
  title: {
    id: 'register.title',
    defaultMessage: 'Evaluation assistant',
  },
  accessCode: {
    id: 'register.accessCode',
    defaultMessage: 'Access Code',
  },
  errorDefault: {
    id: 'register.errorDefault',
    defaultMessage: '',
  },
  errorEmail: {
    id: 'register.errorEmail',
    defaultMessage: 'There is an user already registered with this e-mail',
  },
  errorVoucherCountry: {
    id: 'register.errorVoucherCountry',
    defaultMessage: 'This code dont belong for your country',
  },
  errorVoucherDeactivated: {
    id: 'register.errorVoucherDeactivated',
    defaultMessage: 'Code already been deactivated',
  },
  errorVoucherOutDate: {
    id: 'register.errorVoucherOutDate',
    defaultMessage: 'This access code is currently unavailable',
  },
  errorVoucherLimits: {
    id: 'register.errorVoucherLimits',
    defaultMessage: 'This code reached the limit of activations',
  },
  errorVoucher: {
    id: 'register.errorVoucher',
    defaultMessage: 'Code not found',
  },
  error503: {
    id: 'register.error503',
    defaultMessage: 'Service unavailable, try again later',
  },
  saved: {
    id: 'register.saved',
    defaultMessage: 'Registered with success.',
  },
  save: {
    id: 'register.save',
    defaultMessage: 'Save',
  },
  readTerms: {
    id: 'register.readTerms',
    defaultMessage:
      "I read and accept the <a style='color:blue; text-decoration:underline' href='http://linkte.me/politicaprivacidadeapp' target='_blank'>conditions of use</a> and the <a style='color:blue; text-decoration:underline' href='http://linkte.me/politicaprivacidadeapp' target='_blank'>privacy policy</a>.",
  },
  receiveCommercial: {
    id: 'register.receiveCommercial',
    defaultMessage: 'I wish to receive commercial communications from the SM Group.',
  },
  passCriterio: {
    id: 'register.passCriterio',
    defaultMessage: 'The minimum criteria for setting the password are:',
  },
  caracterNumber: {
    id: 'register.caracterNumber',
    defaultMessage: 'Minimum number of 8 characters',
  },
  letterUppercase: {
    id: 'register.letterUppercase',
    defaultMessage: 'Capital letters, from A to Z',
  },
  letterLowercase: {
    id: 'register.letterLowercase',
    defaultMessage: 'Lower case letters, from a to z',
  },
  number: {
    id: 'register.number',
    defaultMessage: 'Use of numeric characters from 0 to 9',
  },
  specialCaracter: {
    id: 'register.specialCaracter',
    defaultMessage: 'Use of special characters (use only ',
  },
  requiredField: {
    id: 'register.requiredField',
    defaultMessage: 'Required field',
  },
  confirmPassDontMatch: {
    id: 'register.confirmPassDontMatch',
    defaultMessage: 'Password not match',
  },
  passwordNotStrong: {
    id: 'register.passwordDontMatch',
    defaultMessage: 'The password does not meet the minimum requirements',
  },
  profileField: {
    id: 'register.profile',
    defaultMessage: 'Select your profile type',
  },
  teachingTypes: {
    id: 'register.teachingType',
    defaultMessage: 'Select your teaching type',
  },
  gradeField: {
    id: 'register.grade',
    defaultMessage: 'Select the classes you coordinate',
  },
  manager: {
    id: 'register.manager',
    defaultMessage: 'Coordinator',
  },
  private: {
    id: 'register.private',
    defaultMessage: 'Private',
  },
  public: {
    id: 'register.public',
    defaultMessage: 'Public',
  },
  country: {
    id: 'register.country',
    defaultMessage: 'Country',
  },
  state: {
    id: 'register.state',
    defaultMessage: 'State',
  },
  city: {
    id: 'register.city',
    defaultMessage: 'City',
  },
  autocompletePlaceholder: {
    id: 'register.autocompletePlaceholder',
    defaultMessage: 'Search for school...',
  },
  selectCountryPlaceholder: {
    id: 'register.selectCountryPlaceholder',
    defaultMessage: 'Select your country',
  },
  selectCityPlaceholder: {
    id: 'register.selectCityPlaceholder',
    defaultMessage: 'Select your city',
  },
  selectStatePlaceholder: {
    id: 'register.selectStatePlaceholder',
    defaultMessage: 'Select your state',
  },
  searchSchoolPlaceholder: {
    id: 'register.selectSchoolPlaceholder',
    defaultMessage: 'Search for school...',
  },
  noSchoolSelected: {
    id: 'register.noSchoolSelected',
    defaultMessage: 'No school selected yet.',
  },
  noSchoolFounded: {
    id: 'register.noSchoolFounded',
    defaultMessage: 'No school founded.',
  },
});
