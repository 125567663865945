import * as act from 'app/actions';
import * as actions from './plansValidationActions';

const initialState = {
  plansValidation: [],
  filterTeachers: [],
  isLoading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case act.request(actions.GET_PLANS_VALIDATION):
      return {
        ...state,
        isLoading: true,
        plansValidation: initialState.plansValidation,
        filterTeachers: initialState.filterTeachers,
      };
    case act.success(actions.GET_PLANS_VALIDATION):
      return {
        ...state,
        plansValidation: action.payload.content,
        filterTeachers: action.payload.filterTeachers,
        isLoading: initialState.isLoading,
      };
    default:
      return state;
  }
};

export default reducer;
