/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { injectIntl, intlShape } from 'react-intl';

import { Modal } from 'lec-ui';
import { Alert } from 'react-bootstrap';

import messages from '../myClassStudentsMessages';
import '../MyClassStudents.scss';

const AddStudentModal = (props) => {
  const {
    intl,
    isOpen,
    externalError,
    externalSuccess,
    touched,
    errors,
    values,
    onClose,
    onConfirm,
    handleBlur,
    handleChange,
  } = props;

  const hasExternalError = Object.keys(externalError).length > 0;

  return (
    <Modal
      show={isOpen}
      onClose={() => onClose()}
      title={intl.formatMessage(messages.modalTitle)}
      buttons={[
        {
          label: externalSuccess
            ? intl.formatMessage(messages.modalButtonClose)
            : intl.formatMessage(messages.modalButtonCancel),
          onClick: () => onClose(),
        },
        {
          label: intl.formatMessage(messages.modalButtonConfirm),
          onClick: () => onConfirm(),
          primary: true,
        },
      ]}
    >
      {hasExternalError && externalError.errCode && (
        <div style={{ maxWidth: '50%', margin: '10px auto' }}>
          <Alert variant="danger">
            {intl.formatMessage(messages[`errorCode${externalError.errCode || 500}`])}
          </Alert>
        </div>
      )}
      {hasExternalError && externalError.errorEmail1 && (
        <Alert variant="danger">{intl.formatMessage(messages.errorEmailRepeat)}</Alert>
      )}
      {hasExternalError && externalError.errorEmail2 && (
        <Alert variant="danger">{intl.formatMessage(messages.errorInvalidEmail)}</Alert>
      )}
      {externalSuccess && <Alert variant="success">{externalSuccess}</Alert>}

      <div className="my-class-modal-content">
        <div className="form-group">
          <label>{intl.formatMessage(messages.modalFieldGroupName)}</label>
          <input
            className="form-control"
            type="text"
            name="studentName"
            value={values.studentName !== undefined ? values.studentName : ''}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
        {touched.studentName && errors.studentName && (
          <div className="my-class-error">{intl.formatMessage(messages[errors.studentName])}</div>
        )}
        <div className="form-group">
          <label>
            {intl.formatMessage(messages.modalFieldGroupEmail)}(
            {intl.formatMessage(messages.optional)})
          </label>
          <input
            className="form-control"
            type="email"
            name="email"
            value={values.email !== undefined ? values.email : ''}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
        {touched.email && errors.email && (
          <div className="my-class-error">{intl.formatMessage(messages[errors.email])}</div>
        )}
      </div>
    </Modal>
  );
};

AddStudentModal.propTypes = {
  intl: intlShape.isRequired,
  isOpen: PropTypes.bool.isRequired,
  email: PropTypes.string,
  externalError: PropTypes.object,
  externalSuccess: PropTypes.string,
  touched: PropTypes.object,
  errors: PropTypes.object,
  values: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
};

AddStudentModal.defaultProps = {
  email: '',
  externalError: {},
  externalSuccess: '',
  touched: {},
  errors: {},
  values: {},
};

export default compose(injectIntl)(AddStudentModal);
