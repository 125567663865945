import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'myLessons.title',
    defaultMessage: 'My lessons',
  },
  back: {
    id: 'myLessons.back',
    defaultMessage: 'Back',
  },
  disciplines: {
    id: 'myLessons.disciplines',
    defaultMessage: 'Disciplines',
  },
  classesFound: {
    id: 'myLessons.classesFound',
    defaultMessage: 'Classes found',
  },
  class: {
    id: 'myLessons.class',
    defaultMessage: 'Class',
  },
  viewMore: {
    id: 'myLessons.viewMore',
    defaultMessage: 'View more',
  },
  loadMore: {
    id: 'myLessons.loadMore',
    defaultMessage: 'Load more',
  },
  messages: {
    id: 'myLessons.messages',
    defaultMessage: 'Messages',
  },
  onlineClassLink: {
    id: 'myLessons.onlineClassLink',
    defaultMessage: 'Online class',
  },
  simplificaLink: {
    id: 'myLessons.simplificaLink',
    defaultMessage: 'Simplifica',
  },
  attachments: {
    id: 'myLessons.attachments',
    defaultMessage: 'Attachments',
  },
});
