import * as act from 'app/actions';

export const GET_COURSES = 'class-management/GET_COURSES';
export const SCHEDULE_PLAN = 'class-management/SCHEDULE_PLAN';

export const GET_PLAN_COURSES_HOUR = 'schedule/classManagement/GET_PLAN_COURSES_HOUR';
export const ADD_HOUR = 'schedule/classManagement/ADD_HOUR';
export const DELETE_HOUR = 'schedule/classManagement/DELETE_HOUR';

export const getCourses = (payload) => act.action(GET_COURSES, { payload });
export const schedulePlan = (payload) => act.action(SCHEDULE_PLAN, { payload });

export const getPlanCoursesHour = (payload) => act.action(GET_PLAN_COURSES_HOUR, { payload });
export const addHour = (payload) => act.action(ADD_HOUR, { payload });
export const deleteHour = (payload) => act.action(DELETE_HOUR, { payload });
