import * as act from 'app/actions';

export const GET_FILTER_CATALOGINGS = 'panel/GET_FILTER_CATALOGINGS';
export const getFilterCatalogings = (payload) => act.action(GET_FILTER_CATALOGINGS, { payload });

export const GET_DASHBOARD_INFO = 'panel/GET_DASHBOARD_INFO';
export const getDashboardInfo = (payload) => act.action(GET_DASHBOARD_INFO, { payload });

export const GET_SCHEDULED_LESSONS_DATES = 'panel/GET_SCHEDULED_LESSONS_DATES';
export const getScheduledLessonsDates = (payload) =>
  act.action(GET_SCHEDULED_LESSONS_DATES, { payload });

export const MANAGE_PLAN_SCHEDULED_ANALYTICS = 'panel/MANAGE_PLAN_SCHEDULED_ANALYTICS';
export const managePlanScheduledAnalytics = (payload) =>
  act.action(MANAGE_PLAN_SCHEDULED_ANALYTICS, { payload });

export const REGISTER_SCHOOL = 'panel/REGISTER_SCHOOL';
export const registerSchool = (payload) => act.action(REGISTER_SCHOOL, { payload });

export const REGISTER_STUDENT_SCHOOL = 'panel/REGISTER_STUDENT_SCHOOL';
export const registerStudentSchool = (payload) => act.action(REGISTER_STUDENT_SCHOOL, { payload });

export const GET_LATEST_ACCESSES = 'panel/GET_LATEST_ACCESSES';
export const getLatestAccesses = (payload) => act.action(GET_LATEST_ACCESSES, { payload });

export const GET_ABILITIES_HEATMAP = 'panel/GET_ABILITIES_HEATMAP';
export const getAbilitiesHeatmap = (payload) => act.action(GET_ABILITIES_HEATMAP, { payload });
export const GET_ABILITIES_HEATMAP_FILTER = 'panel/GET_ABILITIES_HEATMAP_FILTER';

export const getAbilitiesHeatmapFilter = (payload) =>
  act.action(GET_ABILITIES_HEATMAP_FILTER, { payload });
