// @flow
import { take, fork, all, call, put } from 'redux-saga/effects';
import { saveAs } from 'file-saver';
import * as task from 'app/sagas';
import * as a from 'app/api';
import { success, failure, request, action } from '../app/actions';
import * as actions from './studentLessonActions';

export const getStudentLesson = task.apiTask(
  actions.GET_STUDENT_LESSON,
  a.api.post,
  '/teach/getStudentLesson.php',
);
export function* watchGetStudentLesson() {
  while (true) {
    const { payload } = yield take(actions.GET_STUDENT_LESSON);
    yield fork(getStudentLesson, payload);
  }
}

export const getScheduledLessonChat = task.apiTask(
  actions.GET_SCHEDULED_LESSON_CHAT,
  a.api.post,
  '/teach/getScheduledLessonChat.php',
);
export function* watchGetScheduledLessonChat() {
  while (true) {
    const { payload } = yield take(actions.GET_SCHEDULED_LESSON_CHAT);
    yield fork(getScheduledLessonChat, payload);
  }
}

export const addScheduledLessonChatMessage = task.apiTask(
  actions.ADD_SCHEDULED_LESSON_CHAT_MESSAGE,
  a.api.post,
  '/teach/addScheduledLessonChatMessage.php',
);
export function* watchAddScheduledLessonChatMessage() {
  while (true) {
    const { payload } = yield take(actions.ADD_SCHEDULED_LESSON_CHAT_MESSAGE);
    yield fork(addScheduledLessonChatMessage, payload);
  }
}

export const downloadLessonResourseFile = task.apiTask(
  actions.DOWNLOAD_LESSON_RESOURCE_FILE,
  a.api.post,
  '/teach/downloadLessonResourseFile.php',
);
export function* watchDownloadLessonResourseFile() {
  while (true) {
    const { payload } = yield take(actions.DOWNLOAD_LESSON_RESOURCE_FILE);
    const { resolve, reject } = payload;
    yield put(action(request(actions.DOWNLOAD_LESSON_RESOURCE_FILE)));
    try {
      const { data } = yield call(a.api.post, '/teach/downloadLessonResourseFile.php', payload, {
        responseType: 'blob',
      });

      if (data && data instanceof Blob) {
        yield put(action(success(actions.DOWNLOAD_LESSON_RESOURCE_FILE)));
        saveAs(data, payload.filename);
      }
      resolve && resolve();
    } catch (error) {
      yield put(action(failure(actions.DOWNLOAD_LESSON_RESOURCE_FILE)));
      reject && reject(error);
    }
  }
}

export const deleteScheduledLessonResources = task.apiTask(
  actions.DELETE_SCHEDULED_LESSON_RESOURCES,
  a.api.post,
  '/teach/deleteScheduledLessonResources.php',
);
export function* watchDeleteScheduledLessonResources() {
  while (true) {
    const { payload } = yield take(actions.DELETE_SCHEDULED_LESSON_RESOURCES);
    yield fork(deleteScheduledLessonResources, payload);
  }
}

export const viewLessonResourseFile = task.apiTask(
  actions.VIEW_LESSON_RESOURCE_FILE,
  a.api.post,
  '/teach/viewLessonResourseFile.php',
);
export function* watchViewLessonResourseFile() {
  while (true) {
    const { payload } = yield take(actions.VIEW_LESSON_RESOURCE_FILE);
    yield fork(viewLessonResourseFile, payload);
  }
}

// Root module saga
export function* sagas() {
  yield all([
    fork(watchGetStudentLesson),
    fork(watchGetScheduledLessonChat),
    fork(watchAddScheduledLessonChatMessage),
    fork(watchDownloadLessonResourseFile),
    fork(watchDeleteScheduledLessonResources),
    fork(watchViewLessonResourseFile),
  ]);
}
