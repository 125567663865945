// @flow

import axios from 'axios';

const defaultOptions = { withCredentials: true };

function createOptions(headers) {
  if (process.env.NODE_ENV !== 'development' || !headers) {
    return {};
  }
  const token = localStorage.getItem('X-Auth-Token');
  const cookieToken = localStorage.getItem('X-Token');
  if (token && cookieToken) {
    return {
      headers: {
        'X-Auth-Token': token,
        'X-Token': cookieToken,
      },
    };
  }
  return {};
}

const devOptions = process.env.NODE_ENV === 'development' ? {} : {};

export const createApi = (url, options) =>
  axios.create({
    baseURL: url,
    ...defaultOptions,
    ...options,
    ...devOptions,
  });

console.log('SM_API_NODE', process.env.SM_API_NODE_URL);
console.log('SM_API_CI_URL', process.env.SM_API_CI_URL);

// $FlowFixMe
// export const authApi = createApi(process.env.SM_API_NODE_URL);
// export const nodeApi = createApi(process.env.SM_API_NODE_URL);
export const authApi = createApi(process.env.SM_API_NODE_URL, createOptions(true));
export const nodeApi = createApi(process.env.SM_API_NODE_URL, createOptions(true));
// $FlowFixMe
export const api = createApi(process.env.SM_API_URL, { withCredentials: false });
// $FlowFixMe
export const apiCi = createApi(process.env.SM_API_CI_URL);
