// @flow
import { put, call, fork, all, take } from 'redux-saga/effects';
import { success, failure, request, action } from 'app/actions';
import * as appSagas from 'app/appSagas';
import * as loginSagas from 'login/loginSagas';
import * as registerSagas from 'register/registerSagas';
import * as panelSagas from 'panel/panelSagas';
import * as createPlanSagas from 'createPlan/createPlanSagas';
import * as myPlansSagas from 'myPlans/myPlansSagas';
import * as validationSagas from 'validation/validationSagas';
import * as configurationSagas from 'configuration/configurationSagas';
import * as classManagementSagas from 'classManagement/classManagementSagas';
import * as planLessonsSagas from 'planLessons/planLessonsSagas';
import * as classConfigurationSagas from 'classConfiguration/classConfigurationSagas';
import * as myClassSagas from 'myClass/myClassSagas';
import * as myLessonsSagas from 'myLessons/myLessonsSagas';
import * as studentLessonSagas from 'studentLesson/studentLessonSagas';
import * as plansValidationSagas from 'plansValidation/plansValidationSagas';
import * as viewPlanSagas from 'viewPlan/viewPlanSagas';

import omit from 'lodash/omit';

/**
 * Reusable generator for fetch entities using REQUEST, SUCCESS AND FAILURE
 * suffixes to identify transitions
 * */

export function* genericApiTask(
  prefix: string,
  apiFn: () => Promise<any>,
  apiUrl: string,
  apiArgs: any,
  actionArgs: any,
): Iterable<T> {
  try {
    const pureApiArgs = apiArgs instanceof FormData ? apiArgs : omit(apiArgs, ['resolve, reject']);
    const { resolve } = apiArgs || {};
    yield put(action(request(prefix), actionArgs));
    const { data } = yield call(apiFn, apiUrl, pureApiArgs);
    yield put(action(success(prefix), { ...actionArgs, payload: data }));
    if (resolve) {
      resolve({ data });
    }
    return { data };
  } catch (error) {
    yield put(
      action(failure(prefix), {
        ...actionArgs,
        error,
        payload: error && error.response ? error.response.data : {},
      }),
    );
    const { reject } = apiArgs || {};
    if (reject) {
      reject(error);
    }
    return { error };
  }
}

export function* genericWatchApiTask(actionType: string, task: Generator) {
  while (true) {
    const { payload } = yield take(actionType);
    yield fork(task, payload);
  }
}

// bind the generic generator
export function apiTask(actionType: string, apiFn: () => Promise<any>, ...args: any) {
  return genericApiTask.bind(null, actionType, apiFn, ...args);
}

export function watchApiTask(actionType: string, apiFn: () => Promise<any>, ...args: any) {
  const task = genericApiTask.bind(null, actionType, apiFn, ...args);
  return genericWatchApiTask.bind(null, actionType, task);
}

// only register the root module saga
export default function* rootSaga(): Iterable<T> {
  yield all([
    fork(appSagas.sagas),
    fork(loginSagas.sagas),
    fork(registerSagas.sagas),
    fork(panelSagas.sagas),
    fork(createPlanSagas.sagas),
    fork(myPlansSagas.sagas),
    fork(validationSagas.sagas),
    fork(configurationSagas.sagas),
    fork(classManagementSagas.sagas),
    fork(planLessonsSagas.sagas),
    fork(classConfigurationSagas.sagas),
    fork(myClassSagas.sagas),
    fork(myLessonsSagas.sagas),
    fork(studentLessonSagas.sagas),
    fork(plansValidationSagas.sagas),
    fork(viewPlanSagas.sagas),
  ]);
}
