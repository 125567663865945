import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ModalButton from './ModalButton';

import './Modal.scss';

const SCROLL_WIDTH = 18;
class Modal extends Component {
  state = {
    hidden: !this.props.show,
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.show && this.props.show) {
      this.onShow();
    } else if (prevProps.show && !this.props.show) {
      this.onHide();
    }
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.handleClickOutside);
  }

  onShow = () => {
    document.body.addEventListener('click', this.handleClickOutside);
    setTimeout(() => {
      this.setState({
        hidden: false,
      });
      document.body.classList.add('modal-open');
    });
  };

  onHide = () => {
    document.body.removeEventListener('click', this.handleClickOutside);
    const hasAnotherModalOpen = document.querySelector('.modal.show');
    if (!hasAnotherModalOpen) document.body.classList.remove('modal-open');
  };

  handleClickOutside = (e) => {
    if (!e.target.classList.contains('modal')) return;
    this.handleClose(e);
  };

  handleClickOutside = (e) => {
    if (!e.target.classList.contains('modal')) return;
    this.handleClose(e);
  };

  handleClose = () => {
    // document.body.classList.remove('modal-open');
    if (this.props.show && this.props.onClose) {
      this.props.onClose();
    }
    this.setState({
      hidden: true,
    });
  };

  render() {
    const {
      id,
      icon,
      title,
      subtitle,
      message,
      messageClass,
      buttons,
      large,
      small,
      children,
      onClose,
      fullscreen,
      show,
      medium,
      half,
      modalBodyStyle,
    } = this.props;
    return (
      <Fragment>
        <div
          className={classNames('modal fade', { show, fullscreen, hidden: this.state.hidden })}
          tabIndex="-1"
          role="dialog"
          id={id}
        >
          <div
            className={classNames(
              'modal-dialog modal-dialog-centered',
              { 'modal-lg': large },
              { 'modal-md': medium },
              { 'modal-sm': small },
              { 'modal-half': half },
            )}
            role="document"
            style={modalBodyStyle}
          >
            {show && (
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    className="close"
                    type="button"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={this.handleClose}
                  >
                    <i className="icon pe-7s-close-circle" />
                  </button>
                </div>
                <div className={classNames('modal-body', { fullscreen })}>
                  {icon && <i className={`icon ${icon}`} />}
                  {title && <h5 className="modal-title">{title}</h5>}
                  {subtitle && <p className="modal-subtitle">{subtitle}</p>}
                  {message && (
                    <p className={classNames('modal-message', messageClass)}>{message}</p>
                  )}
                  {children}
                </div>
                <div className="modal-footer">
                  {buttons &&
                    buttons.map((button, index) => (
                      <ModalButton {...button} key={button.id || index} />
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

Modal.propTypes = {
  id: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.any,
  message: PropTypes.any,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
      label: PropTypes.any,
      onClick: PropTypes.func,
    }),
  ),
  children: PropTypes.node,
  large: PropTypes.bool,
  show: PropTypes.bool,
  onClose: PropTypes.func,
  modalBodyStyle: PropTypes.any,
  fullscreen: PropTypes.bool,
};

Modal.defaultProps = {
  id: undefined,
  icon: null,
  title: null,
  message: null,
  buttons: null,
  children: null,
  large: false,
  medium: false,
  show: false,
  onClose: undefined,
  modalBodyStyle: undefined,
  fullscreen: false,
};

export default Modal;
