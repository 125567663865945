// @flow
import { fork, all, take } from 'redux-saga/effects';
import * as task from 'app/sagas';
import * as a from 'app/api';
import * as actions from './validationActions';

export const getCourses = task.apiTask(actions.GET_COURSES, a.api.post, '/teach/getCourses.php');
export const applyPlan = task.apiTask(actions.APPLY_PLAN, a.api.post, '/teach/applyPlan.php');

export function* watchGetCourses() {
  while (true) {
    const { payload } = yield take(actions.GET_COURSES);
    yield fork(getCourses, payload);
  }
}

export function* watchApplyPlan() {
  while (true) {
    const { payload } = yield take(actions.APPLY_PLAN);
    yield fork(applyPlan, payload);
  }
}

// Root module saga
export function* sagas() {
  yield all([fork(watchGetCourses), fork(watchApplyPlan)]);
}
