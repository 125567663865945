import he from 'he';

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getAbbreviation(string) {
  const initials = string
    .split(' ')
    .map((el) => el.charAt(0))
    .join('');

  return initials.slice(0, 2).toUpperCase();
}

export function decodeText(input) {
  if (!input || typeof input !== 'string') return input;
  let result = input;
  result = result.replace(/&amp;quot;/gm, '"');
  result = he.decode(result);
  // result = result.replace(/^(font-family|font-size|color)\s*:.*;/gm, '');
  // result = result.replace(/(font-family|font-size|color)[^>]*;/gm, '');
  return result;
}
