/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { injectIntl, intlShape } from 'react-intl';

import messages from './panelMessages';

function AvailableClassesProgress(props) {
  const { disciplinesProgress, intl, myLessons } = props;
  return (
    <div className="available-classes">
      <h1 className="page-title mr-3">{intl.formatMessage(messages.availableClasses)}</h1>
      <div className="disciplines-progress">
        {disciplinesProgress.map((item, idx) => (
          <div className="discipline-progress" key={`idx-${idx}`} onClick={() => myLessons(item.idPlanScheduled)}>
            <span className="discipline">
              <i className={`icon ${item.icon}`} />
              <span className="discipline-name">{item.name}</span>
            </span>
            <span className="progress-percent">{item.overallProgress}%</span>
          </div>
        ))}
      </div>
    </div>
  );
}

AvailableClassesProgress.propTypes = {
  disciplinesProgress: PropTypes.arrayOf(PropTypes.any),
  intl: intlShape,
  myLessons: PropTypes.func.isRequired,
};

AvailableClassesProgress.defaultProps = {
  disciplinesProgress: {},
  intl: [],
};

export default compose(injectIntl)(AvailableClassesProgress);
