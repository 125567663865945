import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { injectIntl, intlShape } from 'react-intl';
import { Alert } from 'react-bootstrap';

import { Modal } from 'lec-ui';

import messages from '../myClassMessages';
import '../MyClass.scss';

const RemoveGroupModal = (props) => {
  const { intl, isOpen, hasError, handleClose, handleConfirm, errCode } = props;

  return (
    <Modal
      show={isOpen}
      onClose={handleClose}
      title={intl.formatMessage(messages.modalRemoveTitle)}
      icon="pe-7s-drawer"
      buttons={[
        {
          label: intl.formatMessage(messages.cancel),
          onClick: handleClose,
        },
        {
          label: intl.formatMessage(messages.confirm),
          onClick: () => handleConfirm(),
          primary: true,
        },
      ]}
    >
      {hasError && (
        <div style={{ maxWidth: '50%', margin: '10px auto' }}>
          <Alert>
            {errCode === '12'
              ? intl.formatMessage(messages.errorEva)
              : intl.formatMessage(messages.modalRemoveError)}
          </Alert>
        </div>
      )}
      <div className="my-class-modal-content">
        {intl.formatMessage(messages.modalRemoveMessage)}
      </div>
    </Modal>
  );
};

RemoveGroupModal.propTypes = {
  intl: intlShape.isRequired,
  isOpen: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  errCode: PropTypes.string.isRequired,
};

export default compose(injectIntl)(RemoveGroupModal);
