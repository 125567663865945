/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-fragments */
import React, { useEffect, useState, useRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';
import orderBy from 'lodash/orderBy';
import first from 'lodash/first';
import last from 'lodash/last';
import * as yup from 'yup';

import { Row, Col } from 'react-bootstrap';
import { Loader, Card, CardBody, Text, View, Avatar } from 'lec-ui';

import * as act from 'app/actions';
import * as XLSX from 'xlsx';
import placeholderSrc from 'app/images/placeholder-avatar.svg';
import AddStudentModal from './components/AddStudentModal';
import AddStudentButton from './components/AddStudentButton';
import ExportSheetButton from './components/ExportSheetButton';
import * as myClassActions from '../myClass/myClassActions';

import './MyClassStudents.scss';

import messages from './myClassStudentsMessages';

export function exportXLSX(data = [], title, filename) {
  const ws = XLSX.utils.aoa_to_sheet(data);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, title);
  /* generate XLSX file and send to client */
  XLSX.writeFile(wb, filename);
}

const studentSchema = yup.object().shape({
  studentName: yup.string().required('errorRequired'),
  email: yup.string().email('errorInvalidEmail'),
});

const MyClassStudentsContainer = (props) => {
  const {
    intl,
    history,
    location,
    isLoading,
    getCourses,
    getSchoolList,
    getStudents,
    addStudent,
    editStudent,
    schools,
    myClass,
    students,
    courseName,
    match,
  } = props;
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  // const [hasError, setHasError] = useState(false);
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  // const [block, setBlock] = useState(true);
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [values, setValues] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState({});
  const [idStudent] = useState(null); // setIdStudent
  const [ordenedStudents, setOrdenedStudents] = useState([]);

  const prevIsOpen = useRef();

  async function fetchData() {
    await getCourses();
    await getSchoolList();
    await getStudents({ courseId: match.params.id });

    if (schools.length === 1) {
      setSelectedSchool(first(schools));
      setLoading(false);
      // setBlock(false);
      return;
    }
    setLoading(false);
  }

  function validate() {
    return new Promise((resolve) => {
      studentSchema
        .validate(values, { abortEarly: false })
        .then(() => {
          resolve({});
        })
        .catch((err) => {
          if (err.name === 'ValidationError') {
            const elError = err.inner
              .map((e) => ({ [e.path]: e.message }))
              .reduce((e1, e2) => ({ ...e1, ...e2 }));
            resolve(elError);
          }
          resolve({});
        });
    });
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  }
  async function handleBlur(e) {
    const { name } = e.target;
    const err = await validate();
    setErrors(err);
    setTouched({ ...touched, [name]: true });
  }
  // function handleChangeSelect(el) {
  //   setSelectedSchool(el);
  // }
  function handleOpenModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
    setIsEditing(false);
    setValues({});
    setErrors({});
    setTouched({});
    setSuccessMessage('');
    setErrorMessage({});
  }
  // function handleEditStudent(student) {
  //   setIsEditing(true);
  //   setIdStudent(student.id);
  //   setValues({
  //     ...student,
  //     studentName: `${student.firstname} ${student.lastname}`,
  //   });
  //   handleOpenModal();
  // }

  function handleConfirmModal() {
    validate()
      .then((err) => {
        if (Object.keys(err).length) {
          setErrors(err);
          setTouched({
            studentName: true,
            email: true,
          });
          return;
        }
        setLoading(true);
        // setBlock(false);
        const { studentName, email } = values;

        const idCourse = last(location.pathname.split('/'));
        const action = isEditing ? editStudent : addStudent;
        const params = isEditing
          ? { idStudent, newFullname: studentName }
          : { idCourse, fullname: studentName, email };
        action(params).then(() => {
          getStudents({ courseId: match.params.id });
          if (!Object.keys(myClass.error).length) {
            setSuccessMessage(intl.formatMessage(messages.myClassSuccess));
            setErrorMessage({});
          } else {
            setErrorMessage(myClass.error);
            setSuccessMessage('');
          }
          setLoading(false);
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  function handleExport() {
    const order = orderBy(students, ['firstname', 'lastname'], ['asc']);
    const data = [
      [
        intl.formatMessage(messages.studentName),
        intl.formatMessage(messages.voucher),
        'Login',
        'E-mail',
      ],
    ].concat(
      order.map((student) => [
        `${student.firstname} ${student.lastname}`,
        student.code,
        student.login,
        student.email,
      ]),
    );
    exportXLSX(
      data,
      intl.formatMessage(messages.sheet),
      `${intl.formatMessage(messages.sheet)}${match.params.id}.xlsx`,
    );
  }

  const goBack = () => {
    history.push('/my-class');
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (prevIsOpen.current === true && isOpen === false) {
      getStudents({ courseId: match.params.id });
    }
    prevIsOpen.current = isOpen;
  }, [isOpen]);

  useEffect(() => {
    if (schools.length === 1 && !selectedSchool) {
      setSelectedSchool(first(schools));
    }
  }, [schools]);

  useEffect(() => {
    if (students) {
      const order = orderBy(students, ['firstname', 'lastname'], ['asc']);
      setOrdenedStudents(order);
    }
  }, [students]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (successMessage !== '') {
      setTimeout(() => {
        closeModal()
      }, 1500);
    }
  }, [successMessage]);

  return (
    <Fragment>
      <Row>
        <Col>
          <div className="cp-container mb-5">
            <div>
              <h3 className="cp-title page-title">
                {courseName
                  ? `${courseName} - ${intl.formatMessage(messages.title)}`
                  : intl.formatMessage(messages.title)}
              </h3>
            </div>
            <div className="col-12 col-lg-7 my-class-actions d-flex justify-content-center justify-content-lg-end mt-4 mt-lg-0">
              <button type="button" onClick={() => goBack()} className="btn-back">
                {intl.formatMessage(messages.back)}
              </button>
              <AddStudentButton onClick={() => handleOpenModal()} />
              <ExportSheetButton onClick={() => handleExport()} className="ml-2" />
            </div>
          </div>
        </Col>
      </Row>
      <Loader show={isLoading || loading} />
      <div className="my-class-container">
        <div className="my-class-students-container">
          <Row className="mb-5">
            <Col>
              {students.length === 0 && (
                <Text style={{ paddingRight: '15px', paddingLeft: '15px' }}>
                  {intl.formatMessage(messages.noData)}
                </Text>
              )}
            </Col>
            {ordenedStudents.map((student) => (
              <View key={student.id} className="col-lg-12">
                <Card className="card-relatorio-aluno mb-5 card-reports">
                  <CardBody>
                    <Row className="d-flex justify-content-between align-items-center">
                      <Col sm="1">
                        {/* {student.photo ? (
                          <View>Photo</View>
                        ) : ( */}
                        <div style={{ width: '50px', height: '50px' }}>
                          <Avatar
                            src={
                              student.photo
                                ? `${process.env.SM_API_NODE_URL}/uploads/${student.photo}`
                                : placeholderSrc
                            }
                            placeholderSrc={
                              student.photo
                                ? `${process.env.SM_API_NODE_URL}/uploads/${student.photo}`
                                : placeholderSrc
                            }
                            fullSize
                          />
                        </div>
                        {/* // <Icon className="avatar avatar-sm ti-user" /> */}
                        {/* )} */}
                      </Col>
                      <Col sm="9">
                        <div className="card-title">{`${student.firstname} ${student.lastname}`}</div>
                        <div>
                          {student.email && student.login
                            ? `${student.email} - ${student.login}`
                            : student.email || student.login}
                        </div>
                      </Col>
                      <Col sm="2">
                        <div className="id">ID: {student.id}</div>
                        <div className="float-info-wrapper">
                          <div className="float-info-code">{student.code || ''}</div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </View>
            ))}
          </Row>
          <AddStudentModal
            isOpen={isOpen}
            errors={errors}
            externalError={errorMessage}
            externalSuccess={successMessage}
            values={values}
            onClose={closeModal}
            onConfirm={handleConfirmModal}
            handleBlur={handleBlur}
            handleChange={handleChange}
            touched={touched}
          />
        </div>
      </div>
    </Fragment>
  );
};

MyClassStudentsContainer.propTypes = {
  intl: intlShape,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  getCourses: PropTypes.func.isRequired,
  getSchoolList: PropTypes.func.isRequired,
  getStudents: PropTypes.func.isRequired,
  addStudent: PropTypes.func.isRequired,
  editStudent: PropTypes.func.isRequired,
  students: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  courseName: PropTypes.string,
  errorMessage: PropTypes.shape({}),
  isLoading: PropTypes.bool.isRequired,
  schools: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  myClass: PropTypes.shape({
    error: PropTypes.shape({}),
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

MyClassStudentsContainer.defaultProps = {
  intl: [],
  courseName: '',
  errorMessage: {},
};

const mapStateToProps = ({ myClass, panel }) => ({
  ...myClass,
  ...panel,
});

export default compose(
  withRouter,
  injectIntl,
  connect(
    mapStateToProps,
    act.promisify(
      { ...myClassActions },
      {
        include: ['getCourses', 'getSchoolList', 'getStudents', 'addStudent', 'editStudent'],
      },
    ),
  ),
)(MyClassStudentsContainer);
