import * as act from 'app/actions';

export const GET_COURSES = 'my-plans/GET_COURSES';
export const GET_COURSES_STUDENTS = 'my-plans/GET_COURSES_STUDENTS';
export const GET_SCHOOL_LIST = 'my-plans/GET_SCHOOL_LIST';
export const ADD_GROUP = 'my-plans/ADD_GROUP';
export const JOIN_GROUP = 'my-plans/JOIN_GROUP';
export const REMOVE_GROUP = 'my-plans/REMOVE_GROUP';
export const ADD_STUDENT = 'my-plans/ADD_STUDENT';
export const EDIT_STUDENT = 'my-plans/EDIT_STUDENT';
export const GET_STUDENTS = 'my-plans/GET_STUDENTS';

export const getCourses = (payload) => act.action(GET_COURSES, { payload });
export const getCoursesStudent = (payload) => act.action(GET_COURSES_STUDENTS, { payload });
export const getSchoolList = (payload) => act.action(GET_SCHOOL_LIST, { payload });
export const addGroup = (payload) => act.action(ADD_GROUP, { payload });
export const joinGroup = (payload) => act.action(JOIN_GROUP, { payload });
export const removeGroup = (payload) => act.action(REMOVE_GROUP, { payload });
export const addStudent = (payload) => act.action(ADD_STUDENT, { payload });
export const editStudent = (payload) => act.action(EDIT_STUDENT, { payload });
export const getStudents = (payload) => act.action(GET_STUDENTS, { payload });
