import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './IconButton.scss';

const IconButton = ({ label, children, ...props }) => (
  <div className={classNames('icon-button-container', props.className)}>
    <button {...props} className="icon-button" style={{ fontSize: props.size }}>
      {children}
    </button>
    {label && <span>{label}</span>}
  </div>
);

IconButton.propTypes = {
  size: PropTypes.number,
};

IconButton.defaultProps = {
  size: 10,
};
export default IconButton;
