/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { injectIntl, intlShape } from 'react-intl';
import { Row, Col } from 'react-bootstrap';
import { Card } from 'lec-ui';

import messages from './panelMessages';

function DashboardCards(props) {
  const { cards, intl, myPlans, myClass, createPlan, planValidation, idType } = props;
  return (
    <div className="dashboard-card">
      <Row>
        {idType < 3 && (
          <Col xs={6} md={6} lg={3} xl={3}>
            <Card
              className="card purple"
              onClick={() => planValidation()}
              style={{ cursor: 'pointer' }}
            >
              <div className="label-text">{intl.formatMessage(messages.planValidation)}</div>
            </Card>
          </Col>
        )}
        <Col xs={6} md={6} lg={3} xl={3}>
          <Card className="card purple" onClick={() => createPlan()} style={{ cursor: 'pointer' }}>
            <div className="label-text">{intl.formatMessage(messages.createPlanButton)}</div>
          </Card>
        </Col>

        <Col xs={6} md={6} lg={3} xl={3}>
          <Card className="card" onClick={() => myPlans()} style={{ cursor: 'pointer' }}>
            <div className="number">{cards.plansCreated}</div>
            <div className="label-text">{intl.formatMessage(messages.plansCreated)}</div>
          </Card>
        </Col>
        <Col xs={6} md={6} lg={3} xl={3}>
          <Card className="card">
            <div className="number">{cards.plannedClasses}</div>
            <div className="label-text">{intl.formatMessage(messages.plannedClasses)}</div>
          </Card>
        </Col>
        <Col xs={6} md={6} lg={3} xl={3}>
          <Card className="card">
            <div className="number">{cards.annualCoverage}%</div>
            <div className="label-text">{intl.formatMessage(messages.annualCoverage)}</div>
          </Card>
        </Col>
        <Col xs={6} md={6} lg={3} xl={3}>
          <Card className="card" onClick={() => myClass()} style={{ cursor: 'pointer' }}>
            <div className="number">{cards.courses}</div>
            <div className="label-text">{intl.formatMessage(messages.courses)}</div>
          </Card>
        </Col>
        <Col xs={6} md={6} lg={3} xl={3}>
          <Card className="card">
            <div className="number">{cards.students}</div>
            <div className="label-text">{intl.formatMessage(messages.students)}</div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

DashboardCards.propTypes = {
  cards: PropTypes.objectOf(PropTypes.any),
  intl: intlShape,
  myPlans: PropTypes.func.isRequired,
  myClass: PropTypes.func.isRequired,
};

DashboardCards.defaultProps = {
  cards: {},
  intl: [],
};

export default compose(injectIntl)(DashboardCards);
