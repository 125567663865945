/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';
import { Row, Col } from 'react-bootstrap';
import { Button, Loader } from 'lec-ui';
import queryString from 'query-string';

import imgPlaceholder from 'app/images/placeholder-createdPlan.svg';

import PanelFilters from 'app/common/filters/panelFiltersContainer';

import * as act from 'app/actions';
import CardMonth from '../calendar/CardMonth';
import * as validationActions from './validationActions';
import * as panelActions from '../panel/panelActions';

import messages from './validationMessages';

import './ValidationContainer.scss';
import 'react-calendar/dist/Calendar.css';

const ValidationContainer = (props) => {
  const params = queryString.parse(location.search.replace('?', ''));
  const {
    intl,
    history,
    isLoading,
    filters,
    getFilterCatalogings,
    // getScheduledLessonsDates,
    // scheduledLessonsDates,
    segmentFilterType,
  } = props;

  const [idSegment, setIdSegment] = useState(null);
  const [idComponent, setIdComponent] = useState(null);

  const [idSegmentSelected, setIdSegmentSelected] = useState(null);
  const [idComponentSelected, setIdComponentSelected] = useState(null);

  const [filterSegment, setFilterSegment] = useState([]);
  const [filterComponent, setFilterComponent] = useState([]);

  const [arrNameClasses, setArrNameClasses] = useState([]);

  // function toDayDate(date) {
  //   return moment(date).startOf('day').toDate();
  // }

  // const [currentDate, setCurrentDate] = useState(toDayDate());

  useEffect(() => {
    (async () => {
      setFilterSegment([]);
      setFilterComponent([]);
      await getFilterCatalogings();
    })();
    setIdSegmentSelected(params.segment);
    setIdComponentSelected(params.component);

    // clean up on unmount component
    return () => {
      setFilterSegment([]);
      setFilterComponent([]);
    };
  }, []);

  useEffect(() => {
    if (filterSegment.length <= 0 && segmentFilterType) {
      setFilterSegment(filters);
    }
    if (filterSegment.length > 0 && idSegment && !segmentFilterType) {
      setFilterComponent(filters);
    }
  }, [filters]);

  useEffect(async () => {
    if (idSegment && filterSegment.length > 0) {
      await getFilterCatalogings({ idCataloging: idSegment });
    }
  }, [idSegment, filterSegment]);

  // useEffect(() => {
  //   const currentMonthNumber = moment(currentDate).month() + 1;
  //   const oneMonthNumberFromNow = moment(currentDate).add(1, 'month').month() + 1;
  //   const twoMonthsNumberFromNow = moment(currentDate).add(2, 'month').month() + 1;

  //   getScheduledLessonsDates({
  //     months: [currentMonthNumber, oneMonthNumberFromNow, twoMonthsNumberFromNow],
  //   });
  // }, [currentDate]);

  function goBack() {
    history.push(
      // `/create-plan?idPlan=${params.idPlan}&planSaved=${params.planSaved}&segment=${params.segment}&component=${params.component}&period=${params.period}`,
      `/panel`,
    );
  }

  // function handleDownloadPDF() {
  //   console.log('downloadPDF');
  // }

  function handleNextStep() {
    history.push(
      `/plan-lessons?idPlan=${params.idPlan}&planSaved=${params.planSaved}&segment=${params.segment}&component=${params.component}&period=${params.period}&scheduled=${params.scheduled}`,
    );
  }

  // function selectDate(date) {
  //   setCurrentDate(date);
  // }

  // function nextDate() {
  //   const nextCurrentDate = moment(currentDate).add(1, 'month').toDate();
  //   selectDate(nextCurrentDate);
  // }

  // function previousDate() {
  //   const nextCurrentDate = moment(currentDate).subtract(1, 'month').toDate();
  //   selectDate(nextCurrentDate);
  // }

  // const oneMonthFromNow = moment(currentDate).add(1, 'month').toDate();
  // const twoMonthsFromNow = moment(currentDate).add(2, 'month').toDate();

  useEffect(() => {
    setArrNameClasses(params.className.split('|'));
  }, [params.className]);

  return (
    <Row>
      <Loader show={isLoading} />
      <Col xs={12} md={12} lg={12} xl={12}>
        <Row className="mb-5">
          <Col>
            <PanelFilters
              idSegment={idSegment}
              idComponent={idComponent}
              filterSegment={filterSegment}
              filterComponent={filterComponent}
              setIdSegment={(value) => setIdSegment(value)}
              setIdComponent={(value) => setIdComponent(value)}
              idSegmentSelected={idSegmentSelected || params.segment}
              idComponentSelected={idComponentSelected || params.component}
              disabled
            />
          </Col>
        </Row>
        <Row className="mt-5">
          <div className="noPlansBlock validation">
            <img src={imgPlaceholder} alt="placeholder" className="noPlansImage" />
            <div style={{ textAlign: 'center' }}>
              {arrNameClasses.length === 1 ? (
                <h3>
                  {intl.formatMessage(messages.validationTitle)} <b>{params.className}</b>
                </h3>
              ) : (
                <h3>
                  {intl.formatMessage(messages.validationTitlePlural)}{' '}
                  <b>
                    {params.className
                      .replace(/\|/g, ' , ')
                      .replace(/,(?=[^,]*$)/, ` ${intl.formatMessage(messages.and)} `)}
                  </b>
                </h3>
              )}
              {/* <p>{intl.formatMessage(messages.validationText)}</p> */}
              <div className="groupBtn">
                <Button className="btn btn-light" onClick={() => goBack()}>
                  <i className="icon pe-7s-angle-left-circle" />
                  {intl.formatMessage(messages.back)}
                </Button>
                {/* <Button className="btn btn-light" onClick={() => handleDownloadPDF()}>
                  <i className="icon pe-7s-cloud-download" />
                  {intl.formatMessage(messages.downloadPDF)}
                </Button> */}
                <Button className="btn" onClick={() => handleNextStep()}>
                  <i className="icon pe-7s-angle-right-circle" />
                  {intl.formatMessage(messages.continue)}
                </Button>
              </div>
            </div>
          </div>
        </Row>
      </Col>
      {/* <Col xs={6} md={6} lg={3} xl={3} className="calendar-container">
        <button type="button" className="calendar-navButton" onClick={() => previousDate()}>
          <i className="fa fa-angle-up" />
        </button>
        <CardMonth
          month={currentDate.getMonth() + 1}
          year={currentDate.getFullYear()}
          onClickDate={selectDate}
          appointments={scheduledLessonsDates}
        />
        <CardMonth
          month={oneMonthFromNow.getMonth() + 1}
          year={oneMonthFromNow.getFullYear()}
          onClickDate={() => selectDate()}
          appointments={scheduledLessonsDates}
        />
        <CardMonth
          month={twoMonthsFromNow.getMonth() + 1}
          year={twoMonthsFromNow.getFullYear()}
          onClickDate={() => selectDate()}
          appointments={scheduledLessonsDates}
        />

        <button type="button" className="calendar-navButton" onClick={() => nextDate()}>
          <i className="fa fa-angle-down" />
        </button>
      </Col> */}
    </Row>
  );
};

ValidationContainer.propTypes = {
  intl: intlShape,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  filters: PropTypes.arrayOf(PropTypes.any).isRequired,
  getFilterCatalogings: PropTypes.func.isRequired,
  // getScheduledLessonsDates: PropTypes.func.isRequired,
  // scheduledLessonsDates: PropTypes.arrayOf(PropTypes.any),
  segmentFilterType: PropTypes.bool.isRequired,
};

ValidationContainer.defaultProps = {
  intl: [],
  // scheduledLessonsDates: [],
};

const mapStateToProps = ({ validation, panel }) => ({
  ...validation,
  ...panel,
});

export default compose(
  withRouter,
  injectIntl,
  connect(
    mapStateToProps,
    act.promisify(
      { ...validationActions, ...panelActions },
      {
        include: [
          'getFilterCatalogings',
          // 'getScheduledLessonsDates'
        ],
      },
    ),
  ),
)(ValidationContainer);
