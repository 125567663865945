/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './Select.scss';

const Select = ({ options, className, ...props }) => (
  <div className={classNames('form-group form-group-mob', props.containerclassname)}>
    {props.label && (
      <label
        className={classNames('form-control-label', props.labelclassname, {
          'is-invalid': props.valid === false,
          'is-valid': props.valid === true,
        })}
        htmlFor={props.id}
      >
        {props.label}
      </label>
    )}
    <select
      {...props}
      className={classNames(
        'form-control sm-select',
        {
          'is-invalid': props.valid === false,
          'is-valid': props.valid === true,
        },
        className,
      )}
    >
      {props.placeholder && (
        <option
          value=""
          // disabled={!props.nullable}
          disabled={!props.nullable}
          defaultValue={['', undefined, null].includes(props.value)}
        >
          {props.placeholder || ''}
        </option>
      )}
      {options.map((option) => (
        <option
          data-id-plan={option.idPlan || ''}
          key={
            option[props.itemvalue] === undefined && typeof option !== 'object'
              ? option
              : option[props.itemvalue]
          }
          value={
            option[props.itemvalue] === undefined && typeof option !== 'object'
              ? option
              : option[props.itemvalue]
          }
        >
          {option[props.itemlabel] === undefined && typeof option !== 'object'
            ? option
            : option[props.itemlabel]}
        </option>
      ))}
    </select>
    {props.valid === false && (
      <div className="invalid-feedback text-left">{props.invalidfeedback}</div>
    )}
    {props.valid === true && <div className="valid-feedback text-left">{props.validfeedback}</div>}
    {props.helpertext && (
      <small className="form-text text-muted text-left">{props.helpertext}</small>
    )}
  </div>
);

Select.propTypes = {
  options: PropTypes.array,
  className: PropTypes.string,
  itemvalue: PropTypes.string,
  itemlabel: PropTypes.string,
  nullable: PropTypes.any,
  valid: PropTypes.bool,
  helpertext: PropTypes.string,
  placeholder: PropTypes.string,
  containerclassname: PropTypes.string,
  labelclassname: PropTypes.string,
  label: PropTypes.string,
  invalidfeedback: PropTypes.string,
  validfeedback: PropTypes.string,
  value: PropTypes.any,
  idPlan: PropTypes.string,
  id: PropTypes.string,
};

Select.defaultProps = {
  options: [],
  className: '',
  itemvalue: 'value',
  itemlabel: 'label',
  nullable: 'true' || true,
  valid: null,
  helpertext: '',
  placeholder: '',
  containerclassname: '',
  labelclassname: '',
  label: '',
  invalidfeedback: '',
  validfeedback: '',
  value: '',
  idPlan: '',
  id: '',
};

export default Select;
