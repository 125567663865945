import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import ExternalAuth from '../login/ExternalAuth';

function AppRoute(props) {
  let allowedRoutes = props.routes;

  if (props.routes) {
    allowedRoutes = props.routes.filter(
      (r) =>
        r.allow === undefined ||
        r.allow({
          userType: props.userType,
        }),
    );
  }

  return (
    <div>
      <Route exact path="/" render={() => <Redirect to="/panel" />} />
      <Route
        path={props.path}
        render={(innerProps) => (
          // pass the sub-routes down to keep nesting
          <props.component {...innerProps} routes={allowedRoutes} />
        )}
      />
      <Route path="/auth" component={ExternalAuth} />
    </div>
  );
}

function mapStateToProps({ login, app }) {
  return {
    userType: app.user ? app.user.idType : null,
  };
}

export default connect(mapStateToProps)(AppRoute);
