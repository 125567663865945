/* eslint-disable max-lines */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-fragments */
import React, { useState, useEffect, Fragment, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { injectIntl, intlShape } from 'react-intl';
import { Modal } from 'lec-ui';
import classNames from 'classnames';
import { useDropzone } from 'react-dropzone';
import moment from 'moment';
import './ClassConfiguration.scss';

// import oneDrive from 'app/images/one-drive.svg';
// import googleDrive from 'app/images/google-drive.svg';

import placeholderIco from '../app/images/placeholder-avatar.svg';

import messages from './classConfigurationMessages';

const UploadFileCard = (props) => {
  const {
    intl,
    handleSaveUploadFileLink,
    handleRemoveUploadFileLink,
    content,
    handleDownloadFileLink,
    handleViewFile,
    mediaFile,
  } = props;
  const [showUploadFileModal, setShowUploadFileModal] = useState(false);
  const [uploadFileLinkInput, setUploadFileLinkInput] = useState('');
  const [emptyUploadFileLinkInput, setEmptyUploadFileLinkInput] = useState(false);
  const [invalidUploadFileLink, setInvalidUploadFileLink] = useState(false);
  const [action] = useState('add');
  const [idResource, setIdResource] = useState(null);

  const [newFilesArr, setNewFilesArr] = useState([]);
  const [filesFromAPI, setFilesFromAPI] = useState([]);
  const uploadFileLinkInputRef = useRef();

  const [numberOfFiles, setNumberOfFiles] = useState(0);

  const [media, setMedia] = useState([]);
  const [mediaFileType, setMediaFileType] = useState('');

  const onDrop = useCallback(
    (acceptedFiles) => {
      setNewFilesArr([...newFilesArr, ...acceptedFiles]);
    },
    [newFilesArr],
  );

  const { getRootProps, getInputProps, inputRef } = useDropzone({
    onDrop,
    noKeyboard: true,
    maxSize: 10971520,
    multiple: true,
  });

  function validURL(urlString) {
    const urlPattern = new RegExp(
      '^(https?|ftp)://' + // Scheme (http, https, or ftp)
        '(?:(?:[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?\\.)+(?:[A-Z]{2,6}\\.?|[A-Z0-9-]{2,}\\.?))' + // Domain
        '(?::\\d+)?' + // Optional port
        '(?:/?|[/?]\\S+)$',
      'i',
    );
    return !!urlPattern.test(urlString);
  }

  function handleUploadFileModal() {
    setShowUploadFileModal(!showUploadFileModal);
  }

  function handleUploadFileLink(e) {
    setUploadFileLinkInput(e.target.value);

    // uploadFileLinkInputRef.current.value = link !== undefined ? link : '';
  }

  function saveUploadFileModal() {
    const validUrl = uploadFileLinkInput ? validURL(uploadFileLinkInput) : true;

    if (validUrl || newFilesArr.length > 0) {
      // const arrFiles = newFilesArr.length > 0 ? newFilesArr.map((el) => el) : [];

      try {
        const payload = {
          link: uploadFileLinkInput,
          resourceType: 'file',
          files: newFilesArr || [],
          action,
          idResource,
        };

        handleSaveUploadFileLink(payload);
      } finally {
        setShowUploadFileModal(false);
      }
    } else {
      setInvalidUploadFileLink(true);
    }
  }

  async function removeAPIFiles(id) {
    await handleRemoveUploadFileLink(id);
  }

  function isRenderMedia(file) {
    const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
    const acceptedVideoTypes = ['video/mp4', 'video/webm', 'video/ogg'];
    const acceptedAudioTypes = ['audio/mpeg', 'audio/ogg', 'audio/wav'];

    if (acceptedImageTypes.includes(file.fileType)) {
      return 'image';
    }
    if (acceptedVideoTypes.includes(file.fileType)) {
      return 'video';
    }
    if (acceptedAudioTypes.includes(file.fileType)) {
      return 'audio';
    }
    return false;
  }

  function handleViewFileToRender(file) {
    const mediaType = isRenderMedia(file);
    setMediaFileType(mediaType);

    handleViewFile(file.id, file.type);
  }

  const removeNewFiles = useCallback(
    (fileName) => {
      const dt = new DataTransfer();
      const files = Array.from(inputRef.current.files);

      // Add selected fiels to DataTransfer object
      for (const file of files) {
        file.name !== fileName && dt.items.add(file); // Add only file name not matched files
      }

      inputRef.current.files = dt.files; // Overwrite files
      setNewFilesArr(Array.from(dt.files)); // Set states to render file list
    },
    [inputRef],
  );

  useEffect(() => {
    if (newFilesArr.length > 0 || uploadFileLinkInput !== undefined) {
      setEmptyUploadFileLinkInput(false);
    } else {
      setEmptyUploadFileLinkInput(true);
      setInvalidUploadFileLink(false);
    }
  }, [newFilesArr, uploadFileLinkInput]);

  useEffect(() => {
    if (newFilesArr.length <= 0 && (!uploadFileLinkInput || uploadFileLinkInput === '')) {
      setEmptyUploadFileLinkInput(true);
    } else {
      setEmptyUploadFileLinkInput(false);
    }
  }, []);

  function hasHttp(obj) {
    if (obj === undefined) {
      return false;
    }

    if (obj.resource && (obj.resource.includes('http://') || obj.resource.includes('https://'))) {
      return true;
    }

    return false;
  }

  useEffect(() => {
    setFilesFromAPI([]);
    setNewFilesArr([]);
    setUploadFileLinkInput('');
    setIdResource(null);
    setMediaFileType('');

    if (Array.isArray(content) && content.length > 0) {
      content.forEach((el) => {
        if (hasHttp(el)) {
          setUploadFileLinkInput(el.resource);
          setIdResource(el.id);
        } // else {
        setFilesFromAPI((oldArray) => [
          ...oldArray,
          {
            id: el.id,
            file: el.resource,
            date: moment(el.added).format('DD/MM/YYYY, HH:mm'),
            photo: el.photo
              ? `${process.env.SM_API_NODE_URL}/uploads/${el.photo}`
              : placeholderIco,
            name: el.name,
            isFile: !!el.file,
            fileType: el.fileType,
            viewFileLink: el.viewFileLink,
            resource: el.resource,
          },
        ]);
        // }
      });
    } else {
      setUploadFileLinkInput('');
      setFilesFromAPI([]);
      setNewFilesArr([]);
      setIdResource(null);
    }
  }, [content]);

  useEffect(() => {
    const number = filesFromAPI.length;

    // if (uploadFileLinkInput) {
    //   number += 1;
    // }

    setNumberOfFiles(number);
  }, [filesFromAPI, uploadFileLinkInput]);

  function handleClose() {
    setShowUploadFileModal(false);
    setNewFilesArr([]);
    setInvalidUploadFileLink(false);
    setMediaFileType('');
  }

  useEffect(() => {
    if (mediaFile) {
      setMedia(mediaFile);
    }
  }, [mediaFile]);

  // console.log('PROPS', props)

  return (
    <Fragment>
      <button type="button" onClick={() => handleUploadFileModal()} className="card-resource">
        {numberOfFiles !== 0 && <div className="uploadFile-badge">{numberOfFiles}</div>}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="36.005"
          height="29.997"
          viewBox="0 0 36.005 29.997"
        >
          <g
            id="Icon_feather-upload-cloud"
            data-name="Icon feather-upload-cloud"
            transform="translate(0.009 -3.003)"
          >
            <path
              id="Caminho_490"
              data-name="Caminho 490"
              d="M24,25.5a1.5,1.5,0,0,1-1.061-.439L18,20.121l-4.939,4.939a1.5,1.5,0,0,1-2.121-2.121l6-6a1.5,1.5,0,0,1,2.121,0l6,6A1.5,1.5,0,0,1,24,25.5Z"
              fill="#9a9a9a"
            />
            <path
              id="Caminho_491"
              data-name="Caminho 491"
              d="M18,33a1.5,1.5,0,0,1-1.5-1.5V18a1.5,1.5,0,0,1,3,0V31.5A1.5,1.5,0,0,1,18,33Z"
              fill="#9a9a9a"
            />
            <path
              id="Caminho_492"
              data-name="Caminho 492"
              d="M30.586,29.085a1.5,1.5,0,0,1-.719-2.817A6,6,0,0,0,27,15H25.11a1.5,1.5,0,0,1-1.452-1.125A10.5,10.5,0,1,0,5.624,23.457a1.5,1.5,0,1,1-2.248,1.987A13.5,13.5,0,1,1,26.219,12H27a9,9,0,0,1,4.3,16.9A1.494,1.494,0,0,1,30.586,29.085Z"
              fill="#9a9a9a"
            />
            <path
              id="Caminho_493"
              data-name="Caminho 493"
              d="M24,25.5a1.5,1.5,0,0,1-1.061-.439L18,20.121l-4.939,4.939a1.5,1.5,0,0,1-2.121-2.121l6-6a1.5,1.5,0,0,1,2.121,0l6,6A1.5,1.5,0,0,1,24,25.5Z"
              fill="#9a9a9a"
            />
          </g>
        </svg>
        {intl.formatMessage(messages.uploadFile)}
      </button>
      <Modal
        show={showUploadFileModal}
        onClose={() => handleClose()}
        title={intl.formatMessage(messages.uploadFile)}
        buttons={[
          {
            label: intl.formatMessage(messages.cancel),
            onClick: () => handleClose(),
          },
          {
            label: intl.formatMessage(messages.save),
            onClick: () => saveUploadFileModal(),
            primary: true,
            disabled: emptyUploadFileLinkInput,
          },
        ]}
      >
        <div>
          {invalidUploadFileLink && (
            <div className="boxMsg alert alert-danger">
              {intl.formatMessage(messages.enterUrlCorrectly)}
            </div>
          )}
          {!uploadFileLinkInput && filesFromAPI.length <= 0 && (
            <div className="linkBlock inactive">
              <div>{intl.formatMessage(messages.noMaterialUploadedYet)}</div>
            </div>
          )}
          {(uploadFileLinkInput || filesFromAPI.length > 0) && (
            <div className="linkBlock">
              <div>
                <div className="table-container">
                  <table className="lessonFilesTable">
                    <thead>
                      <tr>
                        <th>{intl.formatMessage(messages.fileUrl)}</th>
                        <th>{intl.formatMessage(messages.actions)}</th>
                        <th>{intl.formatMessage(messages.date)}</th>
                        <th>{intl.formatMessage(messages.sentBy)}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filesFromAPI.map((fileContainer, idx) => (
                        <tr className="text-left" key={`${fileContainer.file}-${idx}`}>
                          <td>
                            <div>
                              <i className="far fa-file-alt mr-2" />
                              {fileContainer.file}
                            </div>
                          </td>
                          <td className="actions">
                            {fileContainer.isFile && (
                              <a
                                onClick={() =>
                                  handleDownloadFileLink(fileContainer.id, fileContainer.file)
                                }
                              >
                                <i className="fas fa-download" />
                              </a>
                            )}
                            {isRenderMedia(fileContainer) && (
                              <a onClick={() => handleViewFileToRender(fileContainer)}>
                                <i className="fas fa-eye ml-2" />
                              </a>
                            )}
                            {hasHttp(fileContainer) && (
                              <a href={fileContainer.resource} target="_blank" rel="noreferrer">
                                <i className="fas fa-external-link-alt" />
                              </a>
                            )}
                            <i
                              onClick={() => removeAPIFiles(fileContainer.id)}
                              className="fa fa-trash ml-2"
                              style={{ cursor: 'pointer' }}
                            />
                          </td>
                          <td>{fileContainer.date}</td>
                          <td>
                            <div className="userPhoto">
                              <div className="avatar-profile">
                                <div
                                  className="avatar-sm"
                                  style={{
                                    background: `url(${fileContainer.photo}) center center no-repeat`,
                                  }}
                                />
                              </div>
                              <div className="userName">{fileContainer.name}</div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {media && mediaFileType === 'video' && (
                  <div className="media-container">
                    {/* close btn */}
                    <button type="button" onClick={() => setMedia(null)} className="close-btn">
                      <i className="fas fa-times" />
                    </button>
                    <video src={media} controls />
                  </div>
                )}
                {media && mediaFileType === 'audio' && (
                  <div className="media-container">
                    <button type="button" onClick={() => setMedia(null)} className="close-btn">
                      <i className="fas fa-times" />
                    </button>
                    <audio src={media} controls />
                  </div>
                )}
                {media && mediaFileType === 'image' && (
                  <div className="media-container">
                    <button type="button" onClick={() => setMedia(null)} className="close-btn">
                      <i className="fas fa-times" />
                    </button>
                    <img src={media} />
                  </div>
                )}
              </div>
            </div>
          )}
          {newFilesArr.length > 0 && (
            <div className="linkBlock blockNewFiles">
              <div>
                <div className="table-container">
                  <table>
                    <tbody>
                      {newFilesArr.map((newfile, idx2) => (
                        <tr className={classNames('text-left')} key={`${newfile.name}-${idx2}`}>
                          <td className="newFiles">
                            <i className="far fa-file-alt mr-2" />
                            {newfile.name}
                            <i
                              onClick={() => removeNewFiles(newfile.name)}
                              className="fa fa-trash ml-2"
                              style={{ cursor: 'pointer' }}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
          <div className="uploadFile-input d-flex">
            {intl.formatMessage(messages.copyArchiveLink)}
            <input
              type="text"
              className={invalidUploadFileLink ? 'danger' : ''}
              value={uploadFileLinkInput}
              onChange={(e) => handleUploadFileLink(e)}
              ref={uploadFileLinkInputRef}
            />
          </div>
        </div>

        <hr />

        <div className="upload-archive">
          <div {...getRootProps()} className="upload-background">
            <br />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="36.005"
              height="29.997"
              viewBox="0 0 36.005 29.997"
            >
              <g
                id="Icon_feather-upload-cloud"
                data-name="Icon feather-upload-cloud"
                transform="translate(0.009 -3.003)"
              >
                <path
                  id="Caminho_490"
                  data-name="Caminho 490"
                  d="M24,25.5a1.5,1.5,0,0,1-1.061-.439L18,20.121l-4.939,4.939a1.5,1.5,0,0,1-2.121-2.121l6-6a1.5,1.5,0,0,1,2.121,0l6,6A1.5,1.5,0,0,1,24,25.5Z"
                  fill="#9a9a9a"
                />
                <path
                  id="Caminho_491"
                  data-name="Caminho 491"
                  d="M18,33a1.5,1.5,0,0,1-1.5-1.5V18a1.5,1.5,0,0,1,3,0V31.5A1.5,1.5,0,0,1,18,33Z"
                  fill="#9a9a9a"
                />
                <path
                  id="Caminho_492"
                  data-name="Caminho 492"
                  d="M30.586,29.085a1.5,1.5,0,0,1-.719-2.817A6,6,0,0,0,27,15H25.11a1.5,1.5,0,0,1-1.452-1.125A10.5,10.5,0,1,0,5.624,23.457a1.5,1.5,0,1,1-2.248,1.987A13.5,13.5,0,1,1,26.219,12H27a9,9,0,0,1,4.3,16.9A1.494,1.494,0,0,1,30.586,29.085Z"
                  fill="#9a9a9a"
                />
                <path
                  id="Caminho_493"
                  data-name="Caminho 493"
                  d="M24,25.5a1.5,1.5,0,0,1-1.061-.439L18,20.121l-4.939,4.939a1.5,1.5,0,0,1-2.121-2.121l6-6a1.5,1.5,0,0,1,2.121,0l6,6A1.5,1.5,0,0,1,24,25.5Z"
                  fill="#9a9a9a"
                />
              </g>
            </svg>
            <h6 className="subtitle subtitle-bigger mt-2">
              {intl.formatHTMLMessage(messages.dragDrop)}
              <br />
              <span>
                <input accept=".pdf, image/jpeg, image/png, .zip" {...getInputProps()} />
              </span>
            </h6>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

UploadFileCard.propTypes = {
  intl: intlShape,
  handleSaveUploadFileLink: PropTypes.func.isRequired,
  handleRemoveUploadFileLink: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  files: PropTypes.arrayOf(PropTypes.any),
  content: PropTypes.arrayOf(PropTypes.any),
  handleDownloadFileLink: PropTypes.func.isRequired,
  handleViewFile: PropTypes.func.isRequired,
  mediaFile: PropTypes.arrayOf(PropTypes.any).isRequired,
};

UploadFileCard.defaultProps = {
  intl: [],
  isLoading: false,
  files: [],
  content: [],
};

export default compose(injectIntl)(UploadFileCard);
