/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { injectIntl, intlShape } from 'react-intl';

import { View, Text, Row, Icon } from 'lec-ui';

import messages from '../myClassMessages';
import '../MyClass.scss';

const Schedule = (props) => {
  const { intl, courseId, schedules, showQtd, history } = props;

  const showItems = schedules.filter((schedule, index) => index <= showQtd);

  const scheduleStatus = {
    1: {
      name: 'SCHEDULE',
      className: 'status-finalizada',
    },
    2: {
      name: 'APPLIED',
      className: 'status-aplicada',
    },
    3: {
      name: 'CANCELLED',
      className: 'status-incompleta',
    },
  };

  function handleClick() {
    history.push(`/my-class/${courseId}`);
  }

  return (
    <View className="cr-wrapper">
      <Text className="report-title">
        <strong>{intl.formatMessage(messages.tests)}</strong>
      </Text>
      <Row className="row">
        {showItems.length > 0 &&
          showItems.map((schedule, index) => (
            <View key={`${index}-${schedule.id}`} className="col-4 mt-3">
              <button type="button" onClick={() => handleClick()} className="report-item">
                <Icon className={`pe-7s-note2 ${scheduleStatus[schedule.status].className}`} />
                <Text className="report-name">{schedule.title}</Text>
              </button>
            </View>
          ))}
      </Row>
    </View>
  );
};

Schedule.propTypes = {
  intl: intlShape.isRequired,
  courseId: PropTypes.string.isRequired,
  schedules: PropTypes.arrayOf(PropTypes.any).isRequired,
  showQtd: PropTypes.number.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default compose(injectIntl)(Schedule);
