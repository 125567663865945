/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-fragments */
/* eslint-disable react/jsx-no-bind */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { injectIntl, intlShape } from 'react-intl';
import { Button } from 'lec-ui';

import messages from './panelMessages';

import './PanelContainer.scss';

function CreateAnnualPlans(props) {
  const {
    intl,
    segments,
    components,
    setSegment,
    setComponent,
    setPlan,
    segmentSelected,
    componentSelected,
  } = props;

  function handleCards(value, type, idPlan = null) {
    if (type === 'segment') {
      setSegment(value);
    } else {
      setComponent(value);
      setPlan(idPlan);
    }
  }

  return (
    <div className="createPlanContainer">
      {!segmentSelected && (
        <Fragment>
          <h3>{intl.formatMessage(messages.SelectAStage)}</h3>
          <div className="cards">
            {segments.map((el, idx) => (
              <Button
                key={`${el.value}-${idx}-${Math.random()}`}
                className="card btn btn-light"
                value={el.value}
                onClick={() => handleCards(el.value, 'segment')}
              >
                {el.label}
              </Button>
            ))}
          </div>
        </Fragment>
      )}
      {segmentSelected && !componentSelected && (
        <Fragment>
          <h3>{intl.formatMessage(messages.SelectACurricularComponent)}</h3>
          <div className="cards">
            {components.length > 0 &&
              components.map((el, idx) => (
                <Button
                  key={`${el.value}-${idx}-${Math.random()}`}
                  className="card btn btn-light"
                  value={el.value}
                  onClick={() => handleCards(el.value, 'component', el.idPlan)}
                >
                  <i className={el.icon} />
                  {el.label}
                  <span className="collection-name">({el.colectionName})</span>
                </Button>
              ))}
          </div>
        </Fragment>
      )}
    </div>
  );
}

CreateAnnualPlans.propTypes = {
  intl: intlShape,
  segments: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
  components: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
  setSegment: PropTypes.func.isRequired,
  setComponent: PropTypes.func.isRequired,
  setPlan: PropTypes.func.isRequired,
  segmentSelected: PropTypes.any,
  componentSelected: PropTypes.any,
};

CreateAnnualPlans.defaultProps = {
  intl: [],
  segments: [],
  components: [],
  segmentSelected: '',
  componentSelected: '',
};

export default compose(injectIntl)(CreateAnnualPlans);
