import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './Input.scss';

const Wrapper = ({ type, children }) => {
  if (type === 'password') {
    return <div className="toggle-password-wrapper">{children}</div>;
  }
  return children;
};

class Input extends React.Component {
  // support controlled input
  static getDerivedStateFromProps(props, state) {
    if (props.value !== state.prevProps) {
      return {
        value: props.value,
        prevProps: props.value,
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    const { value } = this.props;
    this.state = {
      value,
    };
  }

  handleChange = (e) => {
    const { readOnly, onChange } = this.props;
    if (readOnly) return;
    const { value } = e.target;
    this.setState({ value });
    if (onChange) {
      onChange(e);
    }
  };

  toggleShowPassword = () => this.setState(({ showPassword }) => ({ showPassword: !showPassword }));

  render() {
    const {
      id,
      label,
      className,
      containerclassname,
      type,
      validfeedback,
      invalidfeedback,
      labelclassname,
      valid,
      ...props
    } = this.props;
    const { value, showPassword } = this.state;
    const { helpertext } = this.props;
    return (
      <div className={classNames('form-group form-group-mob', containerclassname)}>
        {label && (
          <label
            className={classNames('form-control-label', labelclassname, {
              'is-invalid': valid === false,
              'is-valid': valid === true,
            })}
            htmlFor={id}
          >
            {label}
          </label>
        )}
        <Wrapper type={type}>
          <input
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            id={id}
            className={classNames('form-control', {
              'is-invalid': valid === false,
              'is-valid': valid === true,
            })}
            /* eslint-disable */
            type={type === 'password' ? (showPassword ? 'text' : 'password') : type}
            /* eslint-enable */
            onChange={this.handleChange}
            value={value}
          />
          {type === 'password' && (
            <button className="toggle-password" type="button" onClick={this.toggleShowPassword}>
              <i className="fas fa-eye" />
            </button>
          )}
          {valid === false && <div className="invalid-feedback text-left">{invalidfeedback}</div>}
          {valid === true && <div className="valid-feedback text-left">{validfeedback}</div>}
          {helpertext && valid === undefined && (
            <small className="form-text text-muted text-left">{helpertext}</small>
          )}
        </Wrapper>
      </div>
    );
  }
}

Input.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.array]),
  type: PropTypes.string,
  className: PropTypes.string,
  valid: PropTypes.oneOf([null, undefined, false, true]),
  containerclassname: PropTypes.string,
  validfeedback: PropTypes.string,
  invalidfeedback: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  labelclassname: PropTypes.string,
  helpertext: PropTypes.string,
  value: PropTypes.string,
};

Input.defaultProps = {
  id: undefined,
  name: undefined,
  label: '',
  type: 'text',
  valid: undefined,
  className: '',
  containerclassname: undefined,
  validfeedback: undefined,
  invalidfeedback: undefined,
  onChange: undefined,
  labelclassname: '',
  helpertext: '',
  value: '',
  readOnly: false,
};

export default Input;
