import * as act from 'app/actions';
import * as actions from './viewPlanActions';

const initialState = {
  lessons: [],
  idPlan: '',
  filterPeriod: [],
  isLoading: false,
  catalogingAbilities: [],
  lessonData: [],
  teacher: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case act.request(actions.GET_PLANS_DATA):
      return {
        ...state,
        isLoading: true,
        hasError: false,
        filterPeriod: initialState.filterPeriod,
      };
    case act.success(actions.GET_PLANS_DATA):
      return {
        ...state,
        lessons: action.payload.lessons,
        filterPeriod: action.payload.period,
        idPlan: action.payload.idPlan,
        updatedDate: action.payload.updated,
        isLoading: initialState.isLoading,
        hasPlanCustomChanges: action.payload.hasPlanCustomChanges,
        teacher: action.payload.teacher,
      };
    case act.request(actions.GET_CATALOGING_ABILITIES):
      return {
        ...state,
        isLoading: true,
        catalogingAbilities: initialState.catalogingAbilities,
      };
    case act.success(actions.GET_CATALOGING_ABILITIES):
      return {
        ...state,
        catalogingAbilities: action.payload.content,
        isLoading: initialState.isLoading,
      };
    case act.request(actions.GET_LESSON_DATA):
      return {
        ...state,
        isLoading: true,
      };
    case act.success(actions.GET_LESSON_DATA):
      return {
        ...state,
        lessonData: action.payload.content.values,
        isLoading: initialState.isLoading,
      };
    case act.request(actions.MANAGE_TEACHER_PLAN):
      return {
        ...state,
        isLoading: true,
      };
    case act.success(actions.MANAGE_TEACHER_PLAN):
      return {
        ...state,
        isLoading: initialState.isLoading,
      };
    default:
      return state;
  }
};

export default reducer;
