import * as task from 'app/sagas';
import * as a from 'app/api';
import { take, fork, all } from 'redux-saga/effects';
import * as actions from './registerActions';

export const registerTask = task.apiTask(actions.REGISTER, a.api.post, '/teach/registerUser.php');
export const getStatesTask = task.apiTask(
  actions.GET_STATES,
  a.api.post,
  '/teach/schoolSearch.php',
);
export const getCitiesTask = task.apiTask(
  actions.GET_CITIES,
  a.api.post,
  '/teach/schoolSearch.php',
);
export const searchSchoolTask = task.apiTask(
  actions.SEARCH_SCHOOLS,
  a.api.post,
  '/teach/schoolSearch.php',
);

export function* watchRegister() {
  while (true) {
    const { payload } = yield take(actions.REGISTER);
    yield fork(registerTask, payload);
  }
}
export function* watchGetStates() {
  while (true) {
    const { payload } = yield take(actions.GET_STATES);
    yield fork(getStatesTask, payload);
  }
}
export function* watchGetCities() {
  while (true) {
    const { payload } = yield take(actions.GET_CITIES);
    yield fork(getCitiesTask, payload);
  }
}
export function* watchSchoolSearch() {
  while (true) {
    const { payload } = yield take(actions.SEARCH_SCHOOLS);
    yield fork(searchSchoolTask, payload);
  }
}
export function* sagas() {
  yield all([
    fork(watchRegister),
    fork(watchSchoolSearch),
    fork(watchGetStates),
    fork(watchGetCities),
  ]);
}
