/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import * as loginActions from './loginActions';
import LoginForm from './LoginForm';
import Login from './Login';

const LoginContainer = (props) => {
  const { entering, countries, error, getCountries, login, intl } = props;

  useEffect(() => {
    getCountries();
    document.body.classList.remove('modal-open');
  }, []);

  function handleSubmit(values) {
    login(values);
  }

  function containsFooter() {
    countries.some(
      (country) =>
        country.access === '2' &&
        country.emailCustomerService !== null &&
        intl.locale === country.language,
    );
  }

  return (
    <Login footer={containsFooter()} entering={entering}>
      <LoginForm onSubmit={handleSubmit} countries={countries} error={error} />
    </Login>
  );
};

LoginContainer.propTypes = {
  login: PropTypes.func.isRequired,
  getCountries: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  entering: PropTypes.bool,
  countries: PropTypes.any.isRequired,
  error: PropTypes.any.isRequired,
};

LoginContainer.defaultProps = {
  entering: false,
};

const mapStateToProps = ({ login }) => ({ ...login });

export default compose(connect(mapStateToProps, { ...loginActions }), injectIntl)(LoginContainer);
