/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-danger */
/* eslint-disable no-sequences */
/* eslint-disable react/jsx-fragments */
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { injectIntl, intlShape } from 'react-intl';
import moment from 'moment';

import { Row, Col } from 'react-bootstrap';

import messages from './myPlansMessages';
import MyPlanScheduledCourseCard from './MyPlanScheduledCourseCard';

import './MyPlanCard.scss';

const MyPlanCard = (props) => {
  const {
    intl,
    plan,
    editPlan,
    deletePlan,
    handleShowScheduledPlanInfo,
    handleShowDeleteScheduledPlanModal,
  } = props;

  const idPlanAux = plan.idPlan;

  return (
    <Row className="myPlanCard">
      <div className="planIdLabel">ID #{plan.id}</div>
      <div className="planInfo">
        <Col>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <i className={plan.icon} style={{ fontSize: '54px', marginRight: '10px' }} />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <b>{plan.courseName}</b>
              <b style={{ marginBottom: '5px' }}>{plan.segmentName}</b>
              <span style={{ lineHeight: '20px' }}>{plan.componentName}</span>
              <span style={{ fontSize: '12px', lineHeight: '18px' }}>({plan.collectionName})</span>
            </div>
          </div>
        </Col>
        <Col xs={3}>
          <div style={{ marginBottom: '5px' }}>
            <b>{intl.formatMessage(messages.updatedBy)}:</b> {plan.username}
          </div>
          <div>
            <b>{intl.formatMessage(messages.updatedAt)}:</b>{' '}
            {moment(plan.updated).format('DD/MM/YYYY')}
          </div>
        </Col>
        <Col xs={3}>
          STATUS:{' '}
          {plan.scheduledInfo && plan.scheduledInfo.length > 0
            ? intl.formatMessage(messages.planned)
            : intl.formatMessage(messages.underConstruction)}
        </Col>
        <Col xs={1}>
          <button
            type="button"
            className="iconCard"
            onClick={() => editPlan(plan.id, plan.idCataloging, plan.idParent, idPlanAux)}
          >
            <i className="icon pe-7s-search" />
            <div className="labelButton">{intl.formatMessage(messages.seePlan)}</div>
          </button>
          <button
            type="button"
            className="iconCard"
            onClick={() => deletePlan(plan.id, plan.courseName)}
          >
            <i className="icon pe-7s-close-circle" />
            <div className="labelButton">{intl.formatMessage(messages.removePlan)}</div>
          </button>
        </Col>
      </div>
      <div className="planScheduledInfo">
        {plan.scheduledInfo && plan.scheduledInfo.length > 0 && (
          <Col>
            <b>{intl.formatMessage(messages.scheduledForCourses)}</b>
          </Col>
        )}
        {plan.scheduledInfo &&
          plan.scheduledInfo.length > 0 &&
          plan.scheduledInfo.map((scheduledPlan, idx) => (
            <MyPlanScheduledCourseCard
              plan={plan}
              scheduledPlan={scheduledPlan}
              showScheduledPlanInfo={(planSaved, segment, component, period, idPlanScheduled) =>
                handleShowScheduledPlanInfo(
                  planSaved,
                  segment,
                  component,
                  period,
                  idPlanScheduled,
                  idPlanAux,
                )
              }
              deleteScheduledPlan={(idScheduledPlan) =>
                handleShowDeleteScheduledPlanModal(idScheduledPlan)
              }
              key={`${scheduledPlan.idPlanScheduled}-${idx}`}
            />
          ))}
      </div>
    </Row>
  );
};

MyPlanCard.propTypes = {
  intl: intlShape.isRequired,
  plan: PropTypes.any.isRequired,
  editPlan: PropTypes.func.isRequired,
  deletePlan: PropTypes.func.isRequired,
  handleShowScheduledPlanInfo: PropTypes.func.isRequired,
  handleShowDeleteScheduledPlanModal: PropTypes.func.isRequired,
};

export default compose(injectIntl)(MyPlanCard);
