import * as act from 'app/actions';
import * as actions from './myLessonsActions';

const initialState = {
  isLoading: false,
  myClassesInfo: {},
  filterDisciplines: [],
  scheduledLessonsDates: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case act.request(actions.GET_MY_CLASSES):
      return {
        ...state,
        isLoading: true,
        myClassesInfo: initialState.myClassesInfo,
      };
    case act.success(actions.GET_MY_CLASSES):
      return {
        ...state,
        myClassesInfo: action.payload.content,
        isLoading: initialState.isLoading,
      };
    case act.failure(actions.GET_MY_CLASSES):
      return {
        ...state,
        isLoading: initialState.isLoading,
      };
    case act.request(actions.GET_STUDENT_FILTER_DISCIPLINES):
      return {
        ...state,
        isLoading: true,
        filterDisciplines: initialState.filterDisciplines,
      };
    case act.success(actions.GET_STUDENT_FILTER_DISCIPLINES):
      return {
        ...state,
        filterDisciplines: action.payload.content,
        isLoading: initialState.isLoading,
      };
    case act.failure(actions.GET_STUDENT_FILTER_DISCIPLINES):
      return {
        ...state,
        isLoading: initialState.isLoading,
      };

    case act.request(actions.GET_SCHEDULED_LESSONS_DATES):
      return {
        ...state,
        isLoading: true,
        scheduledLessonsDates: initialState.scheduledLessonsDates,
      };
    case act.success(actions.GET_SCHEDULED_LESSONS_DATES):
      return {
        ...state,
        isLoading: false,
        scheduledLessonsDates: action.payload.content,
      };
    default:
      return state;
  }
};

export default reducer;
