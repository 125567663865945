import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './SortBar.scss';

export const SortMode = {
  GRID: 1,
  LIST: 2,
};

class SortBar extends Component {
  state = { sortMode: SortMode.GRID };

  selectMode = sortMode => {
    this.setState({
      sortMode,
    });
    this.props.onChange(sortMode);
  };

  render() {
    const { sortMode } = this.state;
    const { labelGrid, labelList } = this.props;
    return (
      <div className="sort-bar">
        <div className="fb-icons">
          <a
            className={['sort-bar-selector', sortMode === SortMode.GRID ? 'active' : null].join(
              ' ',
            )}
            onClick={() => this.selectMode(SortMode.GRID)}
            title={labelGrid}
          >
            <i className="ti-layout-grid2-alt" />
          </a>
          <a
            className={['sort-bar-selector', sortMode === SortMode.LIST ? 'active' : null].join(
              ' ',
            )}
            onClick={() => this.selectMode(SortMode.LIST)}
            title={labelList}
          >
            <i className="ti-align-justify" />
          </a>
        </div>
      </div>
    );
  }
}

SortBar.propTypes = {
  onChange: PropTypes.func,
  labelList: PropTypes.string,
  labelGrid: PropTypes.string,
};

SortBar.defaultProps = {
  onChange: () => {},
  labelList: 'List',
  labelGrid: 'Grid',
};

export default SortBar;
