import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'myClassStudents.title',
    defaultMessage: 'Students',
  },
  noData: {
    id: 'myClassStudents.noData',
    defaultMessage: "There's no students to show",
  },
  view: {
    id: 'myClassStudents.view',
    defaultMessage: 'View',
  },
  buttonLabel: {
    id: 'myClassStudents.button',
    defaultMessage: 'Add Student',
  },
  modalTitle: {
    id: 'myClassStudents.modal.title',
    defaultMessage: 'Add Student',
  },
  modalFieldGroupName: {
    id: 'myClassStudents.field.groupName',
    defaultMessage: 'Student Name',
  },
  modalFieldGroupEmail: {
    id: 'myClassStudents.field.groupEmail',
    defaultMessage: 'Email',
  },
  modalFieldSchool: {
    id: 'myClass.field.school',
    defaultMessage: 'School',
  },
  modalButtonCancel: {
    id: 'myClassStudents.modal.button.cancel',
    defaultMessage: 'Cancel',
  },
  modalButtonClose: {
    id: 'myClassStudents.modal.button.close',
    defaultMessage: 'Close',
  },
  modalButtonConfirm: {
    id: 'myClassStudents.modal.button.confirm',
    defaultMessage: 'Confirm',
  },
  modalErrorMessageStudentAlreadyExists: {
    id: 'myClassStudents.modal.error',
    defaultMessage: 'A student with this name already exists',
  },
  modalErrorMessageNoSchoolSelected: {
    id: 'myClassStudents.modal.selectError',
    defaultMessage: 'You need to select a school',
  },
  modalErrorMessageNoStudent: {
    id: 'myClassStudents.modal.studentError',
    defaultMessage: 'You need to write a student name',
  },
  errorRequired: {
    id: 'myClassStudents.modal.errorRequired',
    defaultMessage: 'Required field',
  },
  errorInvalidEmail: {
    id: 'myClassStudents.modal.errorInvalidEmail',
    defaultMessage: 'Invalid email',
  },
  errorCode1: {
    id: 'myClassStudents.modal.errorCode1',
    defaultMessage: 'Server error',
  },
  errorCode15: {
    id: 'myClassStudents.modal.errorCode15',
    defaultMessage: 'A Student with this name already exists',
  },
  errorCode500: {
    id: 'myClassStudents.modal.errorCode500',
    defaultMessage: 'Server error',
  },
  errorEmailRepeat: {
    id: 'myClassStudents.modal.errorEmailRepeat',
    defaultMessage: 'There is an user already registered with this e-mail',
  },
  optional: {
    id: 'myClassStudents.modal.optional',
    defaultMessage: 'Optional',
  },
  studentName: {
    id: 'myClassStudents.studentName',
    defaultMessage: 'Student Name',
  },
  studentId: {
    id: 'myClassStudents.studentId',
    defaultMessage: 'Student Id',
  },
  sheet: {
    id: 'myClassStudents.sheet',
    defaultMessage: 'sheet-students',
  },
  exportSheet: {
    id: 'myClassStudents.exportSheet',
    defaultMessage: 'Export Sheet',
  },
  voucher: {
    id: 'myClassStudents.voucher',
    defaultMessage: 'Code',
  },
  myClassSuccess: {
    id: 'myClassStudents.myClassSuccess',
    defaultMessage: 'Student added with success.',
  },
  back: {
    id: 'myClassStudents.back',
    defaultMessage: 'Back',
  },
});
