import * as act from 'app/actions';
import moment from 'moment';
import uniq from 'lodash/uniq';
import * as actions from './myClassActions';

const initialState = {
  courses: [],
  students: [],
  schools: [],
  error: '',
  myClass: {
    loading: false,
    error: {},
    success: {},
  },
  alerts: [],
  years: [moment().year()],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case act.success(actions.GET_COURSES):
      return {
        ...state,
        courses: action.payload.content,
        years: uniq(action.payload.content.map((c) => Number(c.year)).concat([moment().year()]))
          .sort()
          .reverse(),
      };
    case act.failure(actions.GET_COURSES):
      return {
        ...state,
        error: action.payload.error,
      };
    case act.success(actions.GET_COURSES_STUDENTS):
      const courses = action.payload.content.map((elem) => ({
        ...elem,
        name: elem.fullname,
        year: moment(elem.added).year(),
      }));
      return {
        ...state,
        courses,
        years: uniq(courses.map((c) => c.year).concat([moment().year()]))
          .sort()
          .reverse(),
      };
    case act.failure(actions.GET_COURSES_STUDENTS):
      return {
        ...state,
        error: action.payload.error,
      };
    case act.success(actions.ADD_GROUP):
      return {
        ...state,
        error: false,
      };
    case act.failure(actions.ADD_GROUP):
      return {
        ...state,
        error: true,
      };
    case act.request(actions.ADD_STUDENT):
      return {
        ...state,
        myClass: {
          error: initialState.error,
          success: initialState.success,
          loading: true,
        },
      };
    case act.request(actions.EDIT_STUDENT):
      return {
        ...state,
        myClass: {
          error: initialState.error,
          success: initialState.success,
          loading: true,
        },
      };
    case act.success(actions.ADD_STUDENT):
      return {
        ...state,
        myClass: {
          error: initialState.error,
          loading: false,
          success: action.payload.success,
        },
      };
    case act.failure(actions.ADD_STUDENT):
      return {
        ...state,
        myClass: {
          success: initialState.success,
          loading: false,
          error: action.payload,
        },
      };
    case act.success(actions.EDIT_STUDENT):
      return {
        ...state,
        myClass: initialState.myClass,
      };
    case act.failure(actions.EDIT_STUDENT):
      return {
        ...state,
        myClass: {
          success: initialState.success,
          loading: false,
          error: action.payload,
        },
      };
    case act.success(actions.JOIN_GROUP):
      if (action.payload.success) {
        return {
          ...state,
          error: false,
        };
      }
      return {
        ...state,
        myClass: {
          success: initialState.success,
          errCode: action.payload.errCode,
          error: action.payload.error,
          errorEmail1: action.payload.errorEmail1,
          errorEmail2: action.payload.errorEmail2,
        },
      };

    case act.failure(actions.JOIN_GROUP):
      return {
        ...state,
        myClass: {
          success: initialState.success,
          errCode: action.payload.errCode,
          error: action.payload.error,
          errorEmail1: action.payload.errorEmail1,
          errorEmail2: action.payload.errorEmail2,
        },
      };
    case act.success(actions.REMOVE_GROUP):
      return {
        ...state,
        error: false,
      };
    case act.request(actions.REMOVE_GROUP):
      return {
        ...state,
        error: false,
      };
    case act.failure(actions.REMOVE_GROUP):
      return {
        ...state,
        error: true,
        errCode: action.payload.errCode,
      };
    case act.success(actions.GET_STUDENTS):
      return {
        ...state,
        students: action.payload.content.studentResults,
        courseName: action.payload.content.courseName,
      };
    case act.failure(actions.GET_STUDENTS):
      return {
        ...state,
        error: action.payload.error,
      };
    case act.success(actions.GET_SCHOOL_LIST):
      const schools = action.payload.content.reduce(
        (prevElem, elem) => [
          ...prevElem,
          {
            ...elem,
            value: elem.idSchool,
            label: elem.Name,
          },
        ],
        [],
      );
      return {
        ...state,
        schools,
      };
    case act.failure(actions.GET_SCHOOL_LIST):
      return {
        ...state,
        schools: state.schools || [],
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default reducer;
