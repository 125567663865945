/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

const Text = ({ className, ...props }) => <div className={[className].join(' ')} {...props} />;

Text.propTypes = {
  className: PropTypes.string,
};

Text.defaultProps = {
  className: '',
};

export default Text;
