import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'viewPlan.title',
    defaultMessage: 'Annual Plan',
  },
  subtitle: {
    id: 'viewPlan.subtitle',
    defaultMessage: 'Status',
  },
  underConstruction: {
    id: 'viewPlan.underConstruction',
    defaultMessage: 'Under Construction',
  },
  bimonthly: {
    id: 'viewPlan.bimonthly',
    defaultMessage: 'bimonthly',
  },
  quarterly: {
    id: 'viewPlan.quarterly',
    defaultMessage: 'Quarterly',
  },
  updatedIn: {
    id: 'viewPlan.updatedIn',
    defaultMessage: 'Update in',
  },
  periodChange: {
    id: 'viewPlan.periodChange',
    defaultMessage: 'Period change',
  },
  updatedBy: {
    id: 'viewPlan.updatedBy',
    defaultMessage: 'Updated by',
  },
  exportPlan: {
    id: 'viewPlan.exportPlan',
    defaultMessage: 'Export Plan',
  },
  approvePlan: {
    id: 'viewPlan.approvePlan',
    defaultMessage: 'Approve Plan',
  },
  disapprovePlan: {
    id: 'viewPlan.disapprovePlan',
    defaultMessage: 'Disapprove Plan',
  },
  comments: {
    id: 'viewPlan.comments',
    defaultMessage: 'Comments*',
  },
  cancel: {
    id: 'viewPlan.cancel',
    defaultMessage: 'Cancel',
  },
  confirm: {
    id: 'viewPlan.confirm',
    defaultMessage: 'Confirm',
  },
});
