/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';

import * as act from 'app/actions';
import * as panelActions from '../panel/panelActions';

import './CardMonthCell.scss';

import messages from './cardMonthMessages';

const CardMonthCell = ({
  className,
  current,
  disabled,
  muted,
  featured,
  lessons,
  children,
  // onClick,
  history,
  user,
  managePlanScheduledAnalytics,
  outOfSchoolDays,
  vacationDays,
  holidays,
  intl,
}) => {
  function goToScheduledLessonInfo(
    planSaved,
    segment,
    component,
    period,
    idPlanScheduled,
    idLesson,
    lessonIndex,
  ) {
    history.push(
      `/class-configuration?planSaved=${planSaved}&segment=${segment}&component=${component}&period=${period}&scheduled=${idPlanScheduled}&idLesson=${idLesson}&lessonIndex=${lessonIndex}`,
    );
  }

  function goToStudentScheduledLessonInfo(idPlanScheduled, idLesson, lessonIndex) {
    managePlanScheduledAnalytics({ idPlanScheduled, idLesson, lessonIndex });
    history.push(
      `/student-lesson?idPlanScheduled=${idPlanScheduled}&idLesson=${idLesson}&lessonIndex=${lessonIndex}`,
    );
  }

  return (
    <div
      className={classNames(className, {
        disabled,
        current,
        muted,
        featured,
        outOfSchoolDays,
        vacationDays,
        holidays,
      })}
      // onClick={onClick}
    >
      {featured === true ? (
        <Tooltip
          className="lessonsTooltip"
          position="bottom"
          interactive
          interactiveBorder="5"
          theme="light"
          html={lessons.map((i) => (
            <div className={`courseLesson${!i.canAccess ? ' blocked' : ''}`}>
              <span
                className="courseLink"
                onClick={() => {
                  user.idType === 4 && i.canAccess === true
                    ? goToStudentScheduledLessonInfo(i.idPlanScheduled, i.idLesson, i.lessonIndex)
                    : user.idType === 3
                    ? goToScheduledLessonInfo(
                        i.idPlanSaved,
                        i.segment,
                        i.component,
                        i.period,
                        i.idPlanScheduled,
                        i.idLesson,
                        i.lessonIndex,
                      )
                    : undefined;
                }}
              >
                {i.disciplineName ? i.disciplineName : i.courseName}
                {i.hasChatMessages && <i className="ti-comments-smiley" />}
              </span>
              <span className="lessonLabel">{i.lessonLabel}</span>
              {i.holidayName && (
                <span className="lessonLabel holiday">
                  <b>{intl.formatMessage(messages.holiday)}:</b>
                  <br />
                  {i.holidayName}
                </span>
              )}
            </div>
          ))}
        >
          {children}
        </Tooltip>
      ) : vacationDays === true ? (
        <Tooltip
          className="lessonsTooltip"
          position="bottom"
          interactive
          interactiveBorder="5"
          theme="light"
          html={lessons.map((i) => (
            <div className="courseLesson vacation">
              <span className="lessonLabel">{intl.formatMessage(messages.vacation)}</span>
              {i.holidayName && (
                <span className="lessonLabel holiday">
                  <b>{intl.formatMessage(messages.holiday)}:</b>
                  <br />
                  {i.holidayName}
                </span>
              )}
            </div>
          ))}
        >
          {children}
        </Tooltip>
      ) : holidays === true ? (
        <Tooltip
          className="lessonsTooltip"
          position="bottom"
          interactive
          interactiveBorder="5"
          theme="light"
          html={lessons.map((i) => (
            <div className="courseLesson holiday">
              <span className="lessonLabel holiday">
                <b>{intl.formatMessage(messages.holiday)}:</b>
                <br />
                {i.holidayName}
              </span>
            </div>
          ))}
        >
          {children}
        </Tooltip>
      ) : (
        <div>{children}</div>
      )}
    </div>
  );
};

CardMonthCell.propTypes = {
  className: PropTypes.string,
  current: PropTypes.bool,
  disabled: PropTypes.bool,
  featured: PropTypes.bool,
  lessons: PropTypes.arrayOf(PropTypes.any),
  muted: PropTypes.bool,
  children: PropTypes.node,
  // onClick: PropTypes.func,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  user: PropTypes.shape({}).isRequired,
};

CardMonthCell.defaultProps = {
  className: undefined,
  current: false,
  disabled: false,
  featured: false,
  lessons: [],
  muted: false,
  children: undefined,
  // onClick: undefined,
};

const mapStateToProps = ({ app, panelActions }) => ({
  user: app.user,
  ...panelActions,
});

// export default compose(withRouter)(CardMonthCell);

export default compose(
  withRouter,
  injectIntl,
  connect(
    mapStateToProps,
    act.promisify(
      { ...panelActions },
      {
        include: ['managePlanScheduledAnalytics'],
      },
    ),
  ),
)(CardMonthCell);
