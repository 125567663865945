import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const Sidebar = ({ style, children, collapsed }) => (
  <aside className={classNames('sidebar d-print-none', { 'sidebar-icons': collapsed })} style={style}>
    <ul className="sidebar-navigation">{children}</ul>
  </aside>
);

Sidebar.propTypes = {
  children: PropTypes.node,
  collapsed: PropTypes.bool
};

Sidebar.defaultProps = {
  children: null,
  collapsed: false
};

export default Sidebar;
