/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-fragments */
import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { injectIntl, intlShape } from 'react-intl';
import { Modal } from 'lec-ui';

import './StudentLesson.scss';

import messages from './studentLessonMessages';

const SimplificaCard = (props) => {
  const { intl, content, handleManagePlanScheduledAnalytics } = props;
  const [showSimplificaModal, setShowSimplificaModal] = useState(false);
  const [hasSimplificaLink, setHasSimplificaLink] = useState(false);
  const [simplificaValue, setSimplificaValue] = useState('');

  function handleSimplificaModal() {
    handleManagePlanScheduledAnalytics(content.type);
    setShowSimplificaModal(!showSimplificaModal);
  }

  useEffect(() => {
    if (Object.keys(content).length > 0) {
      setHasSimplificaLink(true);
      setSimplificaValue(content.resource);
    } else {
      setHasSimplificaLink(false);
      setSimplificaValue('');
    }
  }, [content]);

  return (
    <Fragment>
      <button type="button" onClick={() => handleSimplificaModal()} className="card-resource">
        {hasSimplificaLink && <div className="simplifica-badge">1</div>}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="38.73"
          height="48.413"
          viewBox="0 0 38.73 48.413"
        >
          <path
            id="Caminho_494"
            data-name="Caminho 494"
            d="M19.365,0,0,12.449V35.964L11.066,42.88,30.431,30.431V17.982L27.664,16.6,13.832,24.9V19.365l13.832-8.3,6.916,4.15V33.2L15.215,45.646l4.15,2.766L38.73,35.964V12.449L27.664,5.533,9.682,17.982V30.431l1.383,1.383,15.215-9.683v5.533L11.066,37.347,4.15,33.2V15.215L23.515,2.766Z"
            fill="#9a9a9a"
          />
        </svg>

        {intl.formatMessage(messages.simplifica)}
      </button>

      <Modal
        show={showSimplificaModal}
        onClose={() => setShowSimplificaModal(false)}
        title={intl.formatMessage(messages.simplifica)}
      >
        <div>
          <div className="linkBlock">
            {hasSimplificaLink && (
              <a href={simplificaValue} target="_blank" rel="noreferrer" onClick={() => handleManagePlanScheduledAnalytics(content.type, content.id)}>
                {simplificaValue}
              </a>
            )}
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

SimplificaCard.propTypes = {
  intl: intlShape,
  content: PropTypes.object,
  handleManagePlanScheduledAnalytics: PropTypes.func.isRequired,
};

SimplificaCard.defaultProps = {
  intl: [],
  content: {},
};

export default compose(injectIntl)(SimplificaCard);
