import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'createPlan.title',
    defaultMessage: 'Annual Plan',
  },
  subtitle: {
    id: 'createPlan.subtitle',
    defaultMessage: 'Annual Plan Status',
  },
  underConstruction: {
    id: 'createPlan.underConstruction',
    defaultMessage: 'Under Construction',
  },
  save: {
    id: 'createPlan.save',
    defaultMessage: 'Save',
  },
  bimonthly: {
    id: 'createPlan.bimonthly',
    defaultMessage: 'bimonthly',
  },
  quarterly: {
    id: 'createPlan.quarterly',
    defaultMessage: 'Quarterly',
  },
  updatedIn: {
    id: 'createPlan.updatedIn',
    defaultMessage: 'Update in',
  },
  continue: {
    id: 'createPlan.continue',
    defaultMessage: 'Continue',
  },
  remove: {
    id: 'createPlan.remove',
    defaultMessage: 'Remove',
  },
  restore: {
    id: 'createPlan.restore',
    defaultMessage: 'Restore',
  },
  moveDown: {
    id: 'createPlan.moveDown',
    defaultMessage: 'Move down',
  },
  moveUp: {
    id: 'createPlan.moveUp',
    defaultMessage: 'Move up',
  },
  removeClass: {
    id: 'createPlan.removeClass',
    defaultMessage: 'Remove Class',
  },
  confirmRemoveClass: {
    id: 'createPlan.confirmRemoveClass',
    defaultMessage: 'Are you sure you want to remove this class?',
  },
  cancel: {
    id: 'createPlan.cancel',
    defaultMessage: 'Cancel',
  },
  confirm: {
    id: 'createPlan.confirm',
    defaultMessage: 'Confirm',
  },
  restoreClass: {
    id: 'createPlan.restoreClass',
    defaultMessage: 'Restore Class',
  },
  confirmRestoreClass: {
    id: 'createPlan.confirmRestoreClass',
    defaultMessage: 'Are you sure you want to restore this class?',
  },
  createClass: {
    id: 'createPlan.createClass',
    defaultMessage: 'Create class',
  },
  editClass: {
    id: 'createPlan.editClass',
    defaultMessage: 'Edit class',
  },
  createClassTitle: {
    id: 'createPlan.createClassTitle',
    defaultMessage: 'Creating new class',
  },
  editClassTitle: {
    id: 'createPlan.editClassTitle',
    defaultMessage: 'Editing class',
  },
  select: {
    id: 'createPlan.select',
    defaultMessage: 'Select',
  },
  selectOrCreate: {
    id: 'createPlan.selectOrCreate',
    defaultMessage: 'Select or create',
  },
  noResults: {
    id: 'createPlan.noResults',
    defaultMessage: 'No results',
  },
  create: {
    id: 'createPlan.create',
    defaultMessage: 'Create',
  },
  restoreToOriginal: {
    id: 'createPlan.restoreToOriginal',
    defaultMessage: 'Restore to original',
  },
  confirmRestoreToOriginal: {
    id: 'createPlan.confirmRestoreToOriginal',
    defaultMessage:
      'All changes made to the annual plan will be lost. Do you want to continue anyway?',
  },
  periodChange: {
    id: 'createPlan.periodChange',
    defaultMessage: 'Period change',
  },
  chapter: {
    id: 'createPlan.chapter',
    defaultMessage: 'Chapter',
  },
  chapterName: {
    id: 'createPlan.chapterName',
    defaultMessage: 'Unit/chapter name',
  },
  components: {
    id: 'createPlan.components',
    defaultMessage: 'Unit components',
  },
  skillsWorked: {
    id: 'createPlan.skillsWorked',
    defaultMessage: 'Skills Worked',
  },
  procedures: {
    id: 'createPlan.procedures',
    defaultMessage: 'Checking procedures',
  },
  classes: {
    id: 'createPlan.classes',
    defaultMessage: 'Classes',
  },
  exportPlan: {
    id: 'createPlan.exportPlan',
    defaultMessage: 'Export Plan',
  },
});
