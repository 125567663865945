import { success } from 'app/actions';
import moment from 'moment';
import locale from 'app/common/moment-locale';
import { DEFAULT_LOCALE } from 'app/config/constants';

import * as loginActions from 'login/loginActions';
import * as actions from './langActions';

function defineMomentLocale(lang) {
  if (lang === 'en') {
    moment.updateLocale('en', locale.en);
  } else if (/pt/g.test(lang)) {
    moment.updateLocale('pt-br', locale.pt);
  } else if (lang === 'es') {
    moment.updateLocale('es', locale.es);
  }
}

defineMomentLocale(DEFAULT_LOCALE);

const initialState = {
  locale: DEFAULT_LOCALE,
};

const langReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SELECT_LANGUAGE:
      defineMomentLocale(action.payload);
      return {
        ...state,
        locale: action.payload,
      };
    case success(actions.COUNTRIES): {
      const countriesRestricted = action.payload.content.filter((d) => Number(d.access) === 7);
      switch (true) {
        case countriesRestricted.length === 0:
        case countriesRestricted.length > 1:
          return state;
        case countriesRestricted.length === 1: {
          const { language } = countriesRestricted[0];
          return { ...state, locale: language };
        }
        default:
          return state;
      }
    }
    case success(loginActions.LOGIN):
    case success(loginActions.EXTERNAL_AUTH):
    case success(loginActions.AUTH): {
      const { user } = action.payload;
      defineMomentLocale(user.language);
      return { ...state, locale: user.language };
    }
    default:
      return state;
  }
};

export default langReducer;
