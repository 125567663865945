/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { useDropzone } from 'react-dropzone';
import classNames from 'classnames';
import get from 'lodash/get';
import { Modal } from 'lec-ui';
import { api } from 'app/api';
import Select from 'react-select';

import messages from '../myClassMessages';

import Modelo from '../../static/PLANNO-MODELO-CARGA.xlsx';
import Plantilla from '../../static/PLANNO-PLANTILLA-CARGA.xlsx';

import '../MyClass.scss';

function chainPromises(arr) {
  if (!Array.isArray(arr)) return Promise.reject(new Error('Non array passed to each'));
  if (arr.length === 0) return Promise.resolve();
  return arr.reduce((prev, cur) => prev.then(() => cur()), Promise.resolve());
}

const Status = {
  PENDING: 0,
  UPLOADING: 1,
  UPLOADED: 2,
  ERROR: 3,
};

const AddClassModal = (props) => {
  const {
    intl,
    show,
    onClose,
    onCompleteUpload,
    onManuallyUpload,
    singularFile,
    schools,
    selectedSchool,
    isStudent,
    handleChangeSelect,
  } = props;

  const inputFileRef = useRef();
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);

  function uploadFile(file) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('idSchool', selectedSchool.idSchool);
    return api
      .post('/assessments/massiveLoadGroups.php', formData)
      .then(() => Promise.resolve())
      .catch((err) => {
        console.error('erro no upload', err.response.data);
        const serverErrorMessage = get(err, 'response.data.error');
        const messageError = serverErrorMessage.map((e) => e.error);
        return Promise.resolve(messageError);
      });
  }

  function enqueueFiles(newFiles) {
    const pendingFiles = newFiles.map((file, index) => ({
      file,
      id: index + files.length,
      status: Status.PENDING,
    }));
    const previousFiles = files.some((f) => f.status === Status.ERROR) ? [] : files;
    setFiles(previousFiles.concat(pendingFiles));
    console.log('uploading files...', pendingFiles, previousFiles.concat(pendingFiles));
  }

  async function sendFiles() {
    const pendingFiles = files.filter((c) => c.status === Status.PENDING);
    let currentFiles = files.map((f) => {
      if (f.status === Status.PENDING) {
        return {
          ...f,
          status: Status.UPLOADING,
          message: intl.formatMessage(messages.uploading),
        };
      }
      return f;
    });
    setFiles(currentFiles);
    setLoading(true);
    const promisesFiles = pendingFiles.map(
      (fileContainer) => () =>
        uploadFile(fileContainer.file).then((result) => {
          currentFiles = currentFiles.map((f) => {
            if (f.id === fileContainer.id) {
              return {
                ...f,
                status: result ? Status.ERROR : Status.UPLOADED,
                message: result || intl.formatMessage(messages.uploadedSuccess),
              };
            }
            return f;
          });
          setFiles(currentFiles);
        }),
    );
    try {
      await chainPromises(promisesFiles);
    } finally {
      setLoading(false);
      if (onCompleteUpload) onCompleteUpload(currentFiles);
    }
  }

  useEffect(() => {
    setFiles([]);
    setLoading(false);
  }, [show]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      enqueueFiles(acceptedFiles);
    },
    [enqueueFiles],
  );

  function handleUpload(e) {
    const acceptedFiles = Array.from(e.target.files);
    enqueueFiles(acceptedFiles);
  }

  function getTemplateHref() {
    switch (intl.locale) {
      case 'pt':
        return Modelo;
      default:
        return Plantilla;
    }
  }

  function handleTriggerUpload() {
    if (loading) return;
    inputFileRef.current.click();
  }

  const { getRootProps } = useDropzone({ onDrop });
  const uploadDisabled =
    loading ||
    (files.every((file) => file.status !== Status.PENDING) &&
      selectedSchool &&
      Object.keys(selectedSchool).length > 0);

  return (
    <Modal
      show={show}
      onClose={onClose}
      title={intl.formatMessage(messages.addClassModalTitle)}
      icon="ti ti-thumb-up"
      buttons={[
        {
          label: intl.formatMessage(messages.manuallyUpload),
          onClick: onManuallyUpload,
        },
        {
          label: loading
            ? intl.formatMessage(messages.loadingMessage)
            : intl.formatMessage(messages.continue),
          onClick: () => sendFiles(),
          primary: true,
          disabled: uploadDisabled,
        },
      ]}
    >
      <div className="add-class-modal">
        <div>
          <p>
            <strong>{intl.formatMessage(messages.subtitle)}</strong>
          </p>
          <p>1. {intl.formatMessage(messages.message)}</p>
        </div>
        <div>
          <a
            className="btn btn-default"
            href={getTemplateHref()}
            download={
              intl.locale === 'pt' ? 'PLANNO-MODELO-CARGA.xlsx' : 'PLANNO-PLANTILLA-CARGA.xlsx'
            }
          >
            {intl.formatMessage(messages.downloadListTemplate)}
          </a>
        </div>
        <div className="mt-4">
          <p>{intl.formatMessage(messages.alreadyFilledFile)}</p>
        </div>
        <div {...getRootProps()} onClick={handleTriggerUpload} className="upload-border">
          <br />
          <i className="icon icon-small ti-cloud-up" />
          <h6 className="subtitle subtitle-bigger mt-2">
            <strong>{intl.formatMessage(messages.dragAndDropMessage)}</strong>
            <br />
            <span>
              <input
                ref={inputFileRef}
                type="file"
                id="file"
                name="file"
                accept=".xlsx"
                style={{ display: 'none' }}
                onChange={handleUpload}
                disabled={loading}
                multiple={!singularFile}
              />

              {intl.formatMessage(messages.clickHereAlternative)}
            </span>
          </h6>
        </div>
        <div className="table-container">
          <table>
            <tbody>
              {files.map((fileContainer) => (
                <tr
                  className={classNames('text-left', {
                    'text-success': fileContainer.status === Status.UPLOADED,
                    'text-danger': fileContainer.status === Status.ERROR,
                  })}
                >
                  <td>
                    <i className="far fa-file-alt" />
                    {fileContainer.file.name}
                  </td>
                  <td>{fileContainer.message}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {!isStudent && schools.length > 0 && (
          <div className="form-group" style={{ marginTop: 0 }}>
            <label htmlFor="form-name">{intl.formatMessage(messages.modalFieldSchool)}</label>
            <Select
              defaultValue={selectedSchool && selectedSchool}
              options={schools}
              placeholder={intl.formatMessage(messages.selectSchoolPlaceholder)}
              onChange={handleChangeSelect}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#f2f2f2',
                  primary: '#7A2ED3',
                  dangerLight: '#ffff',
                  danger: '#7A2ED3',
                },
              })}
              styles={{
                menu: (base) => ({ ...base, zIndex: 99999 }),
              }}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

AddClassModal.propTypes = {
  intl: intlShape.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCompleteUpload: PropTypes.func.isRequired,
  onManuallyUpload: PropTypes.func.isRequired,
  singularFile: PropTypes.bool,
  isStudent: PropTypes.bool.isRequired,
  schools: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedSchool: PropTypes.shape({}).isRequired,
  handleChangeSelect: PropTypes.func.isRequired,
};

AddClassModal.defaultProps = {
  singularFile: false,
};

export default compose(injectIntl)(AddClassModal);
