import * as act from 'app/actions';
import * as actions from './classManagementActions';

const initialState = {
  isLoading: false,
  courses: [],
  coursesHours: [],
  idPlanScheduled: null,
  savedPlan: '',
  errorPlanMessage: '',
  errorPlanCode: null,
  idCourseSelected: null,
  errorPlanInfo: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case act.request(actions.GET_COURSES):
      return {
        ...state,
        isLoading: true,
        courses: initialState.courses,
        errorPlanMessage: initialState.errorPlanMessage,
        errorPlanCode: initialState.errorPlanCode,
        errorPlanInfo: initialState.errorPlanInfo,
      };
    case act.success(actions.GET_COURSES):
      return {
        ...state,
        courses: action.payload.content,
        isLoading: initialState.isLoading,
      };
    case act.failure(actions.GET_COURSES):
      return {
        ...state,
        isLoading: initialState.isLoading,
      };
    case act.request(actions.SCHEDULE_PLAN):
      return {
        ...state,
        isLoading: true,
        errorPlanMessage: initialState.errorPlanMessage,
        errorPlanCode: initialState.errorPlanCode,
        errorPlanInfo: initialState.errorPlanInfo,
      };
    case act.success(actions.SCHEDULE_PLAN):
      return {
        ...state,
        idPlanScheduled: action.payload.idPlanScheduled,
        savedPlan: action.payload.success,
        isLoading: initialState.isLoading,
        errorPlanMessage: initialState.errorPlanMessage,
        errorPlanCode: initialState.errorPlanCode,
        errorPlanInfo: initialState.errorPlanInfo,
      };
    case act.failure(actions.SCHEDULE_PLAN):
      return {
        ...state,
        errorPlanMessage: action.payload.error,
        errorPlanCode: action.payload.errCode,
        errorPlanInfo: action.payload.errorInfo,
        isLoading: initialState.isLoading,
      };
    case act.request(actions.GET_PLAN_COURSES_HOUR):
      return {
        ...state,
        isLoading: true,
        coursesHours: initialState.coursesHours,
        idCourseSelected: initialState.idCourseSelected,
        courseHourSuccess: false,
      };
    case act.success(actions.GET_PLAN_COURSES_HOUR):
      return {
        ...state,
        coursesHours:
          action.payload.courses.length <= 0 ? initialState.coursesHours : action.payload.courses,
        courseHourSuccess: true,
      };
    case act.failure(actions.GET_PLAN_COURSES_HOUR):
      return {
        ...state,
        isLoading: initialState.isLoading,
      };
    case act.request(actions.ADD_HOUR):
      return {
        ...state,
        isLoading: true,
      };
    case act.success(actions.ADD_HOUR):
      return {
        ...state,
        isLoading: initialState.isLoading,
      };
    case act.failure(actions.ADD_HOUR):
      return {
        ...state,
        isLoading: initialState.isLoading,
      };
    case act.request(actions.DELETE_HOUR):
      return {
        ...state,
        isLoading: true,
      };
    case act.success(actions.DELETE_HOUR):
      return {
        ...state,
        isLoading: initialState.isLoading,
      };
    case act.failure(actions.DELETE_HOUR):
      return {
        ...state,
        isLoading: initialState.isLoading,
      };
    default:
      return state;
  }
};

export default reducer;
