/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable radix */
/* eslint-disable react/jsx-fragments */
import React, { useState, Fragment } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import moment from 'moment';

import messages from '../myClassMessages';
import '../MyClass.scss';

const YearTabs = (props) => {
  const {
    intl,
    onlyYears,
    years,
    currentPage,
    // currentYear,
    onChangePage,
    onChangeYear,
    pages,
    children,
    hideYears,
    removePagination,
  } = props;

  const [currYear, setCurrYear] = useState(onlyYears ? (years || [])[0] : null);
  const [currPage, setCurrPage] = useState(currentPage || 1);
  const [actualYear] = useState(parseInt(moment().format('YYYY')));

  // const prevYear = useRef(years);
  // const prevPage = useRef(pages);

  const checkedYears = years.map((elem, index) => {
    if (!elem) {
      if (index === 0) {
        return parseInt(actualYear) + index;
      }
      return parseInt(actualYear) - index;
    }
    return elem;
  });

  function previousPage() {
    const nextPag = currPage > 1 ? currPage - 1 : currPage;
    if (nextPag !== currPage && currPage > 1) {
      setCurrPage(nextPag);
      onChangePage(nextPag);
      window.scrollTo(0, 0);
    }
  }

  function nextPage() {
    const nextPag = currPage === pages ? currPage : currPage + 1;
    if (nextPag !== currPage && nextPag <= pages) {
      setCurrPage(nextPag);
      onChangePage(nextPag);
      window.scrollTo(0, 0);
    }
  }

  function handleClickTab(year) {
    if (currYear !== year) {
      setCurrYear(year);
      setCurrPage(1);
      onChangeYear(year);
    }
  }

  return (
    <Fragment>
      <div className="year-tabs">
        <ul className="nav nav-tabs">
          {!onlyYears && !hideYears && (
            <li className="nav-item">
              <a
                className={['nav-link', 'year-tabs-link', currYear === null ? 'active' : ''].join(
                  ' ',
                )}
                onClick={() => handleClickTab(null)}
                role="button"
                tabIndex="0"
              >
                {intl.formatMessage(messages.all)}
              </a>
            </li>
          )}
          {checkedYears.map((year) => (
            <li className="nav-item" key={year}>
              <a
                className={['nav-link', 'year-tabs-link', currYear === year ? 'active' : ''].join(
                  ' ',
                )}
                onClick={() => handleClickTab(year)}
                role="button"
                tabIndex="0"
              >
                {year}
              </a>
            </li>
          ))}
        </ul>
        {!removePagination && (
          <div className="pagination navigation-tabs">
            <li className={pages > 1 && currPage > 1 ? 'active' : ''}>
              <a
                className="year-tabs-link"
                title={intl.formatMessage(messages.previous)}
                onClick={previousPage}
                role="button"
                tabIndex="0"
              >
                <i className="nt-icon ti-angle-left" />
              </a>
            </li>
            <div>
              Pag.: {currPage} {intl.formatMessage(messages.of)}
              {pages === 0 ? pages + 1 : pages}
            </div>
            <li className={currPage < pages ? 'active' : ''}>
              <a
                className="year-tabs-link"
                title={intl.formatMessage(messages.next)}
                onClick={nextPage}
                role="button"
                tabIndex="0"
              >
                <i className="nt-icon ti-angle-right" />
              </a>
            </li>
          </div>
        )}
      </div>
      {children({ page: currPage, year: currYear })}
    </Fragment>
  );
};

YearTabs.propTypes = {
  intl: intlShape.isRequired,
  years: PropTypes.arrayOf(PropTypes.number).isRequired,
  children: PropTypes.func.isRequired,
  onlyYears: PropTypes.bool.isRequired,
  removePagination: PropTypes.bool,
  hideYears: PropTypes.bool,
  pages: PropTypes.number.isRequired,
  onChangeYear: PropTypes.func,
  onChangePage: PropTypes.func,
  currentPage: PropTypes.number,
};

YearTabs.defaultProps = {
  currentPage: undefined,
  onChangePage: () => {},
  onChangeYear: () => {},
  hideYears: false,
  removePagination: false,
};

export default compose(injectIntl)(YearTabs);
