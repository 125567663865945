import React, { useState } from 'react';

import Tooltip from '../tooltip/Tooltip';

function Initial({ id, text, name, email, edit, see, onChangeEdit, onChangeSee, color, canChange }) {
  const [tooltipIsOpen, setTooltipState] = useState(false);

  return (
    <span
      className="initials initials-sm inline"
      style={{ backgroundColor: color }}
      onMouseEnter={() => setTooltipState(true)}
      onMouseLeave={() => setTooltipState(false)}
    >
      <Tooltip
        id={id}
        initials={text}
        name={name}
        canChange={canChange}
        email={email}
        edit={edit}
        see={see}
        color={color}
        isOpen={tooltipIsOpen}
        onChangeEdit={onChangeEdit}
        onChangeSee={onChangeSee}
        onMouseLeave={() => setTooltipState(false)}
      />
      {text}
    </span>
  );
}

export default Initial;
