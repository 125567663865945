/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { injectIntl, intlShape } from 'react-intl';

import { Modal } from 'lec-ui';
import SchoolForm from '../configuration/SchoolForm';

import messages from './classManagementMessages';

import './ClassManagement.scss';

const SchoolVacationContainer = (props) => {
  const { intl, showModal, errorMessage, closeSchoolVactionModal, onSubmit, user, idUserSchool } =
    props;

  function handleCloseModal() {
    closeSchoolVactionModal();
  }

  return (
    <Modal
      show={showModal}
      title={errorMessage}
      onClose={() => handleCloseModal(false)}
      buttons={[
        {
          label: intl.formatMessage(messages.cancel),
          onClick: () => handleCloseModal(false),
        },
      ]}
    >
      <SchoolForm modalSchoolForm onSubmit={onSubmit} user={user} idUserSchool={idUserSchool} />
    </Modal>
  );
};

SchoolVacationContainer.propTypes = {
  intl: intlShape,
  closeSchoolVactionModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  idUserSchool: PropTypes.any.isRequired,
  user: PropTypes.any.isRequired,
};

SchoolVacationContainer.defaultProps = {
  intl: [],
};

export default compose(injectIntl)(SchoolVacationContainer);
