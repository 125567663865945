/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { injectIntl, intlShape } from 'react-intl';

import messages from '../myClassMessages';
import '../MyClass.scss';

const Menu = (props) => {
  const { intl, onRemove } = props;

  return (
    <div className="card-menu">
      <i className="fas fa-ellipsis-v" />
      <div className="cm-floating">
        <span>
          <a className="cmf-view" onClick={onRemove}>
            <i className="ti-trash" />
            <div>{intl.formatMessage(messages.delete)}</div>
          </a>
        </span>
      </div>
    </div>
  );
};

Menu.propTypes = {
  intl: intlShape.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default compose(injectIntl)(Menu);
