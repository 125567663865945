import React from 'react';
import { compose, pure } from 'recompose';
import { withRouter, Redirect } from 'react-router-dom';
import queryString from 'query-string';
import { connect } from 'react-redux';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';

const authRoutes = ['/login', '/register', '/auth', '/change-password'];

const AppSecurityRoute = ({ user, location }) => {
  if (user) {
    const queryParams = queryString.parse(location.search);
    if (queryParams.redirect) {
      const redirectParams = omit(queryParams, ['token', 'redirect']);
      return (
        <Redirect
          to={`${queryParams.redirect}${
            Object.keys(redirectParams).length > 0
              ? `?${queryString.stringify(redirectParams)}`
              : ''
          }`}
        />
      );
    }
    if (['/'].concat(authRoutes).includes(location.pathname)) {
      return <Redirect to="/panel" />;
    }
    return null;
  }
  if (!authRoutes.includes(location.pathname)) return <Redirect to="/login" />;
  return null;
};

AppSecurityRoute.propTypes = {
  user: PropTypes.object,
  location: PropTypes.object,
};

const mapStateToProps = ({ app }) => ({ user: app.user });

export default compose(connect(mapStateToProps), withRouter, pure)(AppSecurityRoute);
