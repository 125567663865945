import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './ViewModeSelector.scss';

export const ViewMode = {
  GRID: 1,
  LIST: 2,
};

const ViewModeSelector = props => {
  const [viewMode, setViewMode] = useState(ViewMode.GRID);

  useEffect(() => {
    if (props.onChange) {
      props.onChange(viewMode);
    }
  }, [viewMode]);

  const selectView = viewMode => {
    setViewMode(viewMode);
  };

  const handleChangeViewMode = viewMode => () => setViewMode(viewMode);

  return (
    <div className="view-mode-selector">
      <a
        className={classNames({ active: viewMode === ViewMode.GRID })}
        onClick={handleChangeViewMode(ViewMode.GRID)}
        role="button"
        tabIndex="0"
      >
        <i className="ti-layout-grid2-alt" />
      </a>
      <a
        className={classNames({ active: viewMode === ViewMode.LIST })}
        onClick={handleChangeViewMode(ViewMode.LIST)}
        role="button"
        tabIndex="0"
      >
        <i className="ti-align-justify" />
      </a>
    </div>
  );
};

ViewModeSelector.propTypes = {
  onChange: PropTypes.func,
};

export default ViewModeSelector;
