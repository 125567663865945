import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Loader.scss';

const Loader = ({ show, simple, message, batchMessage, ...props }) => (
  <div>
    <div className="loading-text">
      <h5>{message}</h5>
      <br />
      <h5>{batchMessage}</h5>
    </div>

    <div {...props} className={classNames('loading', { show, simple, message, batchMessage })} />
  </div>
);

Loader.propTypes = {
  show: PropTypes.bool,
  simple: PropTypes.bool,
  message: PropTypes.string,
};

Loader.defaultProps = {
  show: false,
  simple: false,
  message: '',
};

export default Loader;
