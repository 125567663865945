/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-fragments */
import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { injectIntl, intlShape } from 'react-intl';
import { Modal } from 'lec-ui';

import './ClassConfiguration.scss';

import googleMeet from 'app/images/google-meet.svg';
import msTeams from 'app/images/ms-teams.svg';

import messages from './classConfigurationMessages';

const OnlineClassCard = (props) => {
  const { intl, handleSaveOnlineClassLink, content, handleRemoveUploadFileLink } = props;
  const [showOnlineClassModal, setShowOnlineClassModal] = useState(false);
  const [onlineClassInput, setOnlineClassInput] = useState('');
  const [emptyOnlineClassInput, setEmptyOnlineClassInput] = useState(false);
  const [invalidOnlineClassLink, setInvalidOnlineClassLink] = useState(false);
  const [hasOnlineClassLink, setHasOnlineClassLink] = useState(false);
  const [idResource, setIdResource] = useState(null);
  const [action, setAction] = useState('add');

  function validURL(urlString) {
    const urlPattern = new RegExp(
      '^(https?|ftp)://' + // Scheme (http, https, or ftp)
        '(?:(?:[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?\\.)+(?:[A-Z]{2,6}\\.?|[A-Z0-9-]{2,}\\.?))' + // Domain
        '(?::\\d+)?' + // Optional port
        '(?:/?|[/?]\\S+)$',
      'i',
    );
    return !!urlPattern.test(urlString);
  }

  function handleOnlineClassModal() {
    setShowOnlineClassModal(!showOnlineClassModal);
  }

  function handleOnlineClassLink(e) {
    if (!onlineClassInput) {
      setEmptyOnlineClassInput(true);
    } else {
      setEmptyOnlineClassInput(false);
    }
    setOnlineClassInput(e.target.value);
  }

  useEffect(() => {
    if (!onlineClassInput) {
      setEmptyOnlineClassInput(true);
      setInvalidOnlineClassLink(false);
    } else {
      setEmptyOnlineClassInput(false);
    }
  }, [onlineClassInput]);

  function saveOnlineClassModal() {
    const validUrl = validURL(onlineClassInput);

    if (validUrl) {
      try {
        const payload = {
          link: onlineClassInput,
          resourceType: 'online_class',
          files: [],
          action,
          idResource,
        };

        handleSaveOnlineClassLink(payload);
      } finally {
        setShowOnlineClassModal(false);
      }
    } else {
      setInvalidOnlineClassLink(true);
    }
  }

  useEffect(() => {
    if (Object.keys(content).length > 0) {
      setHasOnlineClassLink(true);
      setAction('edit');
      setOnlineClassInput(content.resource);
      setIdResource(content.id);
    } else {
      setHasOnlineClassLink(false);
      setAction('add');
      setOnlineClassInput('');
      setIdResource(null);
    }
  }, [content]);

  function handleClose() {
    setShowOnlineClassModal(false);
    setEmptyOnlineClassInput(false);
  }

  async function removeLink(id) {
    await handleRemoveUploadFileLink(id);
  }

  return (
    <Fragment>
      <button type="button" onClick={() => handleOnlineClassModal()} className="card-resource">
        {hasOnlineClassLink && <div className="onlineClass-badge">1</div>}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="43.575"
          height="43.564"
          viewBox="0 0 43.575 43.564"
        >
          <path
            id="Icon_ionic-ios-globe"
            data-name="Icon ionic-ios-globe"
            d="M25.157,3.375h-.073a21.782,21.782,0,0,0,.01,43.564h.073a21.782,21.782,0,1,0-.01-43.564ZM44.028,23.691H35.9a41.24,41.24,0,0,0-1.026-7.98,31.9,31.9,0,0,0,5.2-2.2A18.762,18.762,0,0,1,44.028,23.691Zm-20.337,0H17.219a37.223,37.223,0,0,1,.922-7.236,31.861,31.861,0,0,0,5.55.744Zm0,2.932v6.482a32,32,0,0,0-5.55.744,37.218,37.218,0,0,1-.922-7.226Zm2.932,0h6.419a37,37,0,0,1-.922,7.215,31.258,31.258,0,0,0-5.5-.733Zm0-2.932V17.2a32,32,0,0,0,5.5-.733,37.114,37.114,0,0,1,.922,7.226ZM38.08,11.344A29.093,29.093,0,0,1,34.1,12.968,21.623,21.623,0,0,0,31.325,7.26,18.95,18.95,0,0,1,38.08,11.344Zm-6.744,2.377a29.472,29.472,0,0,1-4.712.628V6.663C28.4,7.627,30.142,10.213,31.336,13.722Zm-7.645-7.1v7.718a28.964,28.964,0,0,1-4.765-.639C20.141,10.161,21.9,7.574,23.691,6.621Zm-4.775.67a21.793,21.793,0,0,0-2.754,5.665,29.747,29.747,0,0,1-3.927-1.613A18.667,18.667,0,0,1,18.916,7.292Zm-8.681,6.231A31.27,31.27,0,0,0,15.387,15.7a39.578,39.578,0,0,0-1.026,7.98H6.3A18.665,18.665,0,0,1,10.234,13.523Zm-3.948,13.1H14.35a41.134,41.134,0,0,0,1.026,7.98,33.178,33.178,0,0,0-5.152,2.178A18.764,18.764,0,0,1,6.286,26.623ZM12.224,38.97a29.309,29.309,0,0,1,3.938-1.613,21.871,21.871,0,0,0,2.754,5.676A19.131,19.131,0,0,1,12.224,38.97Zm6.7-2.367a29.116,29.116,0,0,1,4.765-.639v7.729C21.89,42.74,20.141,40.143,18.926,36.6Zm7.7,7.048v-7.7a29.473,29.473,0,0,1,4.712.628C30.142,40.1,28.4,42.688,26.623,43.651Zm4.712-.6a21.623,21.623,0,0,0,2.775-5.707,28.614,28.614,0,0,1,3.979,1.634A19.168,19.168,0,0,1,31.336,43.054Zm8.744-6.262a31.9,31.9,0,0,0-5.2-2.2A41.025,41.025,0,0,0,35.9,26.623h8.126A18.68,18.68,0,0,1,40.08,36.792Z"
            transform="translate(-3.375 -3.375)"
            fill="#9a9a9a"
          />
        </svg>
        {intl.formatMessage(messages.onlineClass)}
      </button>

      <Modal
        show={showOnlineClassModal}
        onClose={() => handleClose()}
        title={intl.formatMessage(messages.onlineClass)}
        buttons={[
          {
            label: intl.formatMessage(messages.cancel),
            onClick: () => handleClose(),
          },
          {
            label: intl.formatMessage(messages.save),
            onClick: () => saveOnlineClassModal(),
            primary: true,
            disabled: emptyOnlineClassInput,
          },
        ]}
      >
        <div>
          {invalidOnlineClassLink && (
            <div className="boxMsg alert alert-danger">
              {intl.formatMessage(messages.enterUrlCorrectly)}
            </div>
          )}

          <div className={`linkBlock ${!onlineClassInput && 'inactive'}`}>
            {onlineClassInput ? (
              <div>
                <a href={onlineClassInput} target="_blank" rel="noreferrer">
                  {onlineClassInput}
                </a>
                <i
                  onClick={() => removeLink(idResource)}
                  className="fa fa-trash ml-2"
                  style={{ cursor: 'pointer' }}
                />
              </div>
            ) : (
              intl.formatMessage(messages.noLinkUploadedYet)
            )}
          </div>

          <div className="d-flex onlineClass-icons">
            <a href="https://teams.live.com/" target="_blank" rel="noreferrer">
              <img className="navbar-icon" src={msTeams} alt="MS Teams" />
              MS Teams
            </a>
            <a href="https://meet.google.com/" target="_blank" rel="noreferrer">
              <img className="navbar-icon" src={googleMeet} alt="Google meet" />
              Google Meet
            </a>
          </div>
          <div className="onlineClass-input d-flex">
            {intl.formatMessage(messages.copyMeetLink)}
            <input
              type="text"
              className={invalidOnlineClassLink ? 'danger' : ''}
              value={onlineClassInput}
              onChange={(e) => handleOnlineClassLink(e)}
            />
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

OnlineClassCard.propTypes = {
  intl: intlShape,
  handleSaveOnlineClassLink: PropTypes.func.isRequired,
  content: PropTypes.object,
  handleRemoveUploadFileLink: PropTypes.func.isRequired,
};

OnlineClassCard.defaultProps = {
  intl: [],
  content: {},
};

export default compose(injectIntl)(OnlineClassCard);
