// @flow
import { fork, all, take } from 'redux-saga/effects';
import * as task from 'app/sagas';
import * as a from 'app/api';
import * as actions from './classManagementActions';

export const getCourses = task.apiTask(actions.GET_COURSES, a.api.post, '/teach/getCourses.php');
export const schedulePlan = task.apiTask(
  actions.SCHEDULE_PLAN,
  a.api.post,
  '/teach/schedulePlan.php',
);
// GET HOUR
export const getPlanCoursesHoursTask = task.apiTask(
  actions.GET_PLAN_COURSES_HOUR,
  a.api.post,
  '/teach/getPlanCoursesHours.php',
);
// ADD HOUR
export const addHourTask = task.apiTask(
  actions.ADD_HOUR,
  a.api.post,
  '/teach/addPlanCoursesHours.php',
);
// DELETE HOUR
export const deleteHourTask = task.apiTask(
  actions.DELETE_HOUR,
  a.api.post,
  '/teach/deletePlanCoursesHours.php',
);

export function* watchGetCourses() {
  while (true) {
    const { payload } = yield take(actions.GET_COURSES);
    yield fork(getCourses, payload);
  }
}
export function* watchSchedulePlan() {
  while (true) {
    const { payload } = yield take(actions.SCHEDULE_PLAN);
    yield fork(schedulePlan, payload);
  }
}
export function* watchGetPlanCoursesHours() {
  while (true) {
    const { payload } = yield take(actions.GET_PLAN_COURSES_HOUR);
    yield fork(getPlanCoursesHoursTask, payload);
  }
}
export function* watchAddHour() {
  while (true) {
    const { payload } = yield take(actions.ADD_HOUR);
    yield fork(addHourTask, payload);
  }
}
export function* watchDeleteHour() {
  while (true) {
    const { payload } = yield take(actions.DELETE_HOUR);
    yield fork(deleteHourTask, payload);
  }
}

// Root module saga
export function* sagas() {
  yield all([
    fork(watchGetCourses),
    fork(watchSchedulePlan),
    fork(watchGetPlanCoursesHours),
    fork(watchAddHour),
    fork(watchDeleteHour),
  ]);
}
