import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AsyncPaginate from 'react-select-async-paginate';

import './CreatableSelect.scss';

const PaginatedSelect = ({
  label,
  containerclassname,
  labelclassname,
  valid,
  id,
  hideIndicator,
  helpertext,
  ...props
}) => {
  const handleChange = (value) => {
    if (props.onChange) {
      props.onChange({ target: { value, name: props.name } });
    }
  };
  const handleBlur = () => {
    if (props.onBlur) {
      props.onBlur({ target: { name: props.name } });
    }
  };

  return (
    <div
      className={classNames('form-group form-group-mob', containerclassname, {
        'is-invalid': valid === false,
        'is-valid': valid === true,
        'is-readonly': props.readOnly,
      })}
    >
      {label && (
        <label
          className={classNames('form-control-label', labelclassname, {
            'is-invalid': valid === false,
            'is-valid': valid === true,
          })}
          htmlFor={id}
        >
          {label}
        </label>
      )}
      <AsyncPaginate
        isClearable
        {...props}
        // styles={customStyles}
        className={classNames({
          'hide-indicator': hideIndicator,
          'is-invalid': valid === false,
          'is-valid': valid === true,
        })}
        classNamePrefix="creatable-select"
        onChange={handleChange}
        onBlur={handleBlur}
      />
      {valid === false && (
        <div className="invalid-feedback text-left d-block">{props.invalidfeedback}</div>
      )}
      {valid === true && (
        <div className="valid-feedback text-left d-block">{props.validfeedback}</div>
      )}
      {helpertext && valid === undefined && (
        <small className="form-text text-muted text-left">{helpertext}</small>
      )}
    </div>
  );
};
PaginatedSelect.propTypes = {
  hideIndicator: PropTypes.bool,
};

PaginatedSelect.defaultProps = {
  hideIndicator: false,
};

export default PaginatedSelect;
