import qs from 'query-string';
import { api } from 'app/api';
import { success, failure } from 'app/actions';
import * as loginActions from 'login/loginActions';

const isDev = process.env.NODE_ENV === 'development';

const initialState = {
  role: null,
  user: null,
  token: null,
  error: {},
  queryParams: qs.parse(location.search.replace('?', '')),
  cookies: '',
  userCI: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case success(loginActions.LOGIN):
    case success(loginActions.EXTERNAL_AUTH):
    case success(loginActions.AUTH): {
      const { token, user, cookieToken, userCi } = action.payload;
      api.defaults.headers.common['X-Auth-Token'] = token;
      if (isDev) {
        localStorage.setItem('X-Auth-Token', token);
        localStorage.setItem('X-Token', cookieToken);
      }
      const role = parseInt(user.idType, 0);
      const userCI = !!(!user && userCi !== null);
      return {
        ...state,
        user,
        userCI,
        token,
        cookieToken,
        role,
        authorizing: false,
        error: { ...state.error, login: undefined },
      };
    }
    case failure(loginActions.LOGIN):
      return {
        ...state,
        authorizing: false,
        error: {
          ...state.error,
          login: {
            status: action.status,
            loginErrorAttempts: action.payload.error.loginErrorAttempts,
            message: action.payload.error.message,
            isUserCi: action.payload.error.isUserCi,
            passwordExpired: action.payload.error.passwordExpired,
          },
        },
      };
    case success(loginActions.MANAGE_COOKIES):
      return {
        ...state,
        // isLoading: false,
        cookies: action.payload.success,
      };
    default:
      return state;
  }
};

export default reducer;
