/* eslint-disable prefer-destructuring */
/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-multi-comp */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { injectIntl, intlShape } from 'react-intl';
import { Row, Col, Modal } from 'lec-ui';

import { decode } from 'html-entities';

import Select, { components } from 'react-select';
import Createable from 'react-select/creatable';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

import messages from './createPlanMessages';
import './PlanCards.scss';

const SortableMultiValue = SortableElement((props) => {
  const onMouseDown = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const innerProps = { ...props.innerProps, onMouseDown };
  return <components.MultiValue {...props} innerProps={innerProps} key={props.data.value} />;
});

const SortableMultiValueLabel = SortableHandle((props) => (
  <components.MultiValueLabel {...props} />
));

const SortableSelect = SortableContainer(Createable);

function arrayMove(array, from, to) {
  const slicedArray = array.slice();
  slicedArray.splice(to < 0 ? array.length + to : to, 0, slicedArray.splice(from, 1)[0]);
  return slicedArray;
}

const ModalAddNewClass = (props) => {
  const {
    intl,
    closeModal,
    typeCreateModal,
    period,
    abilitiesArray,
    savePlanClass,
    idLessonSelected,
    periodDateSelected,
    lessonData,
  } = props;
  const [requiredAddClassFields, setRequiredAddClassFields] = useState(false);
  const [objFields, setObjFields] = useState({});
  const [selectAbilities, setSelectAbilities] = useState(null);
  const [selectProcedures, setSelectProcedures] = useState(null);

  const [selectPeriodData, setSelectPeriodData] = useState(null);

  const prevSelectAbilities = useRef();
  const prevSelectProcedures = useRef();

  function handleSaveAddClass() {
    let params = {};
    if (typeCreateModal === 'editing') {
      params = {
        idLesson: idLessonSelected,
        lessonValues: objFields,
      };
    } else {
      params = {
        idLesson: idLessonSelected,
        direction: 'a',
        period,
        lessonValues: objFields,
      };
    }
    savePlanClass(params, typeCreateModal);
    setObjFields({});
  }

  function handleCloseModal() {
    setObjFields({});
    closeModal(false);
  }

  function handleChangeValue(value, field) {
    const newValue = value.target ? value.target.value : value;
    if (field === 'classes' && (isNaN(newValue) || parseInt(newValue, 10) <= 0)) {
      return;
    }

    setObjFields({ ...objFields, [field]: newValue });
  }

  function handleChangeAbilities(value) {
    setSelectAbilities(value);
    if ((value && value.length === 0) || value === null) {
      setObjFields({ ...objFields, abilities: [] });
    } else {
      const arrAbilities = value.map((item) => item.value);
      setObjFields({ ...objFields, abilities: arrAbilities });
    }
  }

  function handleChangeProcedures(value) {
    setSelectProcedures(value);
    if ((value && value.length === 0) || value === null) {
      setObjFields({ ...objFields, procedures: [] });
    } else {
      const arrProcedures = value.map((item) => item.value);
      setObjFields({ ...objFields, procedures: arrProcedures });
    }
  }

  const onSortEndAbilities = ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) return;
    const selectedOptions = arrayMove(selectAbilities, oldIndex, newIndex);
    handleChangeAbilities(selectedOptions);
  };

  const onSortEndProcedures = ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) return;
    const selectedOptions = arrayMove(selectProcedures, oldIndex, newIndex);
    handleChangeProcedures(selectedOptions);
  };

  const styleSelectSortable = {
    multiValue: (base) => ({
      ...base,
      backgroundColor: '#7A2ED3',
      color: '#ffffff',
      borderRadius: '4px',
      maxWidth: '20%',
    }),
    multiValueLabel: (base) => ({
      ...base,
      color: '#ffffff',
    }),
    menuList: (base) => ({
      ...base,
      maxHeight: '200px',
      textAlign: 'left',
    }),
    multiValueRemove: (base) => ({
      ...base,
      cursor: 'pointer',
    }),
  };

  const options = [];
  const optionsBimonthly = [
    {
      value: '1',
      label: `1º ${intl.formatMessage(messages.bimonthly)}`,
    },
    {
      value: '2',
      label: `2º ${intl.formatMessage(messages.bimonthly)}`,
    },
    {
      value: '3',
      label: `3º ${intl.formatMessage(messages.bimonthly)}`,
    },
    {
      value: '4',
      label: `4º ${intl.formatMessage(messages.bimonthly)}`,
    },
  ];
  const optionsQuarterly = [
    {
      value: '1',
      label: `1º ${intl.formatMessage(messages.quarterly)}`,
    },
    {
      value: '2',
      label: `2º ${intl.formatMessage(messages.quarterly)}`,
    },
    {
      value: '3',
      label: `3º ${intl.formatMessage(messages.quarterly)}`,
    },
  ];

  useEffect(() => {
    if (objFields.chapter) {
      setRequiredAddClassFields(true);
    } else {
      setRequiredAddClassFields(false);
    }
  }, [objFields]);

  useEffect(() => {
    if (prevSelectAbilities.current) {
      if (selectAbilities && selectAbilities.length !== prevSelectAbilities.current.length) {
        const arrAbilities = selectAbilities.map((item) => item.value);
        handleChangeValue(arrAbilities, 'abilities');
      }
    } else if (selectAbilities) {
      const arrAbilities = selectAbilities.map((item) => item.value);
      handleChangeValue(arrAbilities, 'abilities');
    }
    if (prevSelectProcedures.current) {
      if (selectProcedures && selectProcedures.length !== prevSelectProcedures.current.length) {
        const arrProcedures = selectProcedures.map((item) => item.value);
        handleChangeValue(arrProcedures, 'procedures');
      }
    } else if (selectProcedures) {
      const arrProcedures = selectProcedures.map((item) => item.value);
      handleChangeValue(arrProcedures, 'procedures');
    }
  }, [selectAbilities, selectProcedures]);

  useEffect(() => {
    if (period === 'bimonthly') {
      setSelectPeriodData(optionsBimonthly[periodDateSelected]);
    } else {
      setSelectPeriodData(optionsQuarterly[periodDateSelected]);
    }
    if (typeCreateModal === 'adding') {
      setObjFields({ ...objFields, classes: '1', period: `${periodDateSelected + 1}` });
    }
  }, []);

  useEffect(() => {
    if (lessonData.length > 0) {
      const obj = {};
      lessonData.forEach((item) => {
        if (item.variable === 'label1') {
          obj.chapter = item.value;
        } else if (item.variable === 'label2') {
          obj.chapterName = item.value;
        } else if (item.variable === 'label3') {
          obj.components = decode(item.value).replace(/(<([^>]+)>)/gi, '');
        } else if (item.variable === 'abilities') {
          obj.abilities = item.value;
          prevSelectAbilities.current = item.value;
          const arrAbilities = item.value.map((el) => {
            const objAbility = abilitiesArray.find((ability) => ability.value === el);
            if (!objAbility) {
              return { value: el, label: el };
            }
            return objAbility;
          });
          handleChangeAbilities(arrAbilities);
        } else if (item.variable === 'procedures') {
          obj.procedures = item.value;
          prevSelectProcedures.current = item.value;
          const arrProcedures = item.value.map((el) => ({ value: el, label: el }));
          handleChangeProcedures(arrProcedures);
        } else {
          obj[item.variable] = item.value;
        }
      });

      setObjFields(obj);
    }
  }, [lessonData]);

  return (
    <Modal
      show
      large
      onClose={handleCloseModal}
      title={
        typeCreateModal === 'adding'
          ? intl.formatMessage(messages.createClassTitle)
          : intl.formatMessage(messages.editClassTitle)
      }
      buttons={[
        {
          label: intl.formatMessage(messages.cancel),
          onClick: handleCloseModal,
        },
        {
          label: intl.formatMessage(messages.save),
          onClick: () => handleSaveAddClass(),
          primary: true,
          disabled: !requiredAddClassFields,
        },
      ]}
    >
      <Row className="mt-5">
        <Col className="add-newClass mb-4" xs={12}>
          <div className="nc-label"> {intl.formatMessage(messages.chapter)}* </div>
          <input
            type="text"
            value={objFields.chapter || ''}
            onChange={(e) => handleChangeValue(e, 'chapter')}
          />
        </Col>
        <Col className="add-newClass mb-4" xs={12}>
          <div className="nc-label">{intl.formatMessage(messages.chapterName)} </div>
          <input
            type="text"
            value={objFields.chapterName || ''}
            onChange={(e) => handleChangeValue(e, 'chapterName')}
          />
        </Col>
        <Col className="add-newClass mb-4" xs={12}>
          <div className="nc-label"> {intl.formatMessage(messages.components)} </div>
          <textarea
            type="textarea"
            value={objFields.components || ''}
            rows="2"
            cols="33"
            onChange={(e) => handleChangeValue(e, 'components')}
          />
        </Col>
        <Col className="add-newClass mb-4" xs={12}>
          <div className="nc-label"> {intl.formatMessage(messages.skillsWorked)} </div>
          <SortableSelect
            helperClass="sortableHelper"
            useDragHandle
            axis="xy"
            onSortEnd={onSortEndAbilities}
            distance={4}
            getHelperDimensions={({ node }) => node.getBoundingClientRect()}
            isMulti
            options={abilitiesArray}
            value={selectAbilities}
            onChange={(e) => handleChangeAbilities(e)}
            placeholder={intl.formatMessage(messages.selectOrCreate)}
            noOptionsMessage={() => intl.formatMessage(messages.noResults)}
            formatCreateLabel={(inputText) => `${intl.formatMessage(messages.create)} ${inputText}`}
            components={{
              MultiValue: SortableMultiValue,
              MultiValueLabel: SortableMultiValueLabel,
            }}
            closeMenuOnSelect={false}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#f2f2f2',
                primary: '#7A2ED3',
                dangerLight: '#ffff',
                danger: '#7A2ED3',
              },
            })}
            styles={styleSelectSortable}
          />
        </Col>
        <Col className="add-newClass mb-4" xs={12}>
          <div className="nc-label"> {intl.formatMessage(messages.procedures)} </div>
          <SortableSelect
            helperClass="sortableHelper"
            useDragHandle
            axis="xy"
            onSortEnd={() => onSortEndProcedures()}
            distance={4}
            getHelperDimensions={({ node }) => node.getBoundingClientRect()}
            isMulti
            options={options}
            value={selectProcedures}
            onChange={(e) => handleChangeProcedures(e)}
            placeholder={intl.formatMessage(messages.create)}
            noOptionsMessage={() => intl.formatMessage(messages.noResults)}
            formatCreateLabel={(inputText) => `${intl.formatMessage(messages.create)} ${inputText}`}
            components={{
              MultiValue: SortableMultiValue,
              MultiValueLabel: SortableMultiValueLabel,
            }}
            closeMenuOnSelect={false}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#f2f2f2',
                primary: '#7A2ED3',
                dangerLight: '#ffff',
                danger: '#7A2ED3',
              },
            })}
            styles={styleSelectSortable}
          />
        </Col>
        <Col className="add-newClass mb-4" xs={12}>
          <div className="nc-label"> {intl.formatMessage(messages.classes)} </div>
          <input
            type="number"
            value={objFields.classes || '1'}
            onChange={(e) => handleChangeValue(e, 'classes')}
          />
        </Col>
        {period === 'bimonthly' && (
          <Col className="add-newClass mb-4" xs={12}>
            <div className="nc-label"> {intl.formatMessage(messages.bimonthly)} </div>
            <Select
              onChange={(e) => handleChangeValue(e, 'period')}
              options={optionsBimonthly}
              value={selectPeriodData || ''}
              placeholder={intl.formatMessage(messages.select)}
              isDisabled={selectPeriodData}
              style={{
                option: (base) => ({
                  ...base,
                  textAlign: 'left',
                }),
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#f2f2f2',
                  primary: '#7A2ED3',
                  dangerLight: '#ffff',
                  danger: '#7A2ED3',
                },
              })}
            />
          </Col>
        )}
        {period === 'quarterly' && (
          <Col className="add-newClass mb-4" xs={12}>
            <div className="nc-label"> {intl.formatMessage(messages.quarterly)} </div>
            <Select
              onChange={(e) => handleChangeValue(e, 'period')}
              options={optionsQuarterly}
              value={selectPeriodData || ''}
              placeholder={intl.formatMessage(messages.select)}
              isDisabled={selectPeriodData}
              style={{
                option: (base) => ({
                  ...base,
                  textAlign: 'left',
                }),
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#f2f2f2',
                  primary: '#7A2ED3',
                  dangerLight: '#ffff',
                  danger: '#7A2ED3',
                },
              })}
            />
          </Col>
        )}
      </Row>
    </Modal>
  );
};

ModalAddNewClass.propTypes = {
  intl: intlShape,
  closeModal: PropTypes.func.isRequired,
  typeCreateModal: PropTypes.string,
  period: PropTypes.string,
  abilitiesArray: PropTypes.array.isRequired,
  savePlanClass: PropTypes.func.isRequired,
};

ModalAddNewClass.defaultProps = {
  intl: [],
  typeCreateModal: 'adding',
  period: 'bimonthly',
};

export default compose(injectIntl)(ModalAddNewClass);
