// @flow
import { fork, all, take } from 'redux-saga/effects';
import * as task from 'app/sagas';
import * as a from 'app/api';
import * as actions from './planLessonsActions';

export const getPlanScheduledData = task.apiTask(
  actions.GET_PLAN_SCHEDULED_DATA,
  a.api.post,
  '/teach/getPlanScheduledData.php',
);

export function* watchGetPlanScheduledData() {
  while (true) {
    const { payload } = yield take(actions.GET_PLAN_SCHEDULED_DATA);
    yield fork(getPlanScheduledData, payload);
  }
}

export const getScheduledPlanCourses = task.apiTask(
  actions.GET_SCHEDULED_PLAN_COURSES,
  a.api.post,
  '/teach/getScheduledPlanCourses.php',
);

export function* watchGetScheduledPlanCourses() {
  while (true) {
    const { payload } = yield take(actions.GET_SCHEDULED_PLAN_COURSES);
    yield fork(getScheduledPlanCourses, payload);
  }
}

export const editScheduledLessonStatus = task.apiTask(
  actions.EDIT_SCHEDULED_LESSON_STATUS,
  a.api.post,
  '/teach/editScheduledLessonStatus.php',
);

export function* watchEditScheduledLessonStatus() {
  while (true) {
    const { payload } = yield take(actions.EDIT_SCHEDULED_LESSON_STATUS);
    yield fork(editScheduledLessonStatus, payload);
  }
}

// Root module saga
export function* sagas() {
  yield all([
    fork(watchGetPlanScheduledData),
    fork(watchGetScheduledPlanCourses),
    fork(watchEditScheduledLessonStatus),
  ]);
}
