/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-sequences */
/* eslint-disable react/jsx-fragments */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState, Fragment, useRef } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';
import { Button, Loader, Modal } from 'lec-ui';
import queryString from 'query-string';

import * as XLSX from 'xlsx';

import { Row, Col } from 'react-bootstrap';

import * as act from 'app/actions';
import PanelFilters from 'app/common/filters/panelFiltersContainer';
import PlanCards from './PlanCards';

import messages from './viewPlanMessages';
import './ViewPlanContainer.scss';

import * as viewPlanActions from './viewPlanActions';
import * as panelActions from '../panel/panelActions';

import placeholderSrc from 'app/images/placeholder-avatar.svg';

import { Avatar } from 'lec-ui';
import { getUserAvatarUrl } from 'app/appHelpers';

const ViewPlanContainer = (props) => {
  const {
    intl,
    location,
    getFilterCatalogings,
    getPlansData,
    getCatalogingAbilities,
    getLessonData,
    filters,
    lessons,
    filterPeriod,
    idPlan,
    updatedDate,
    history,
    isLoading,
    hasPlanCustomChanges,
    lessonData,
    segmentFilterType,
    teacher,
    manageTeacherPlan,
  } = props;

  const params = queryString.parse(location.search.replace('?', ''));
  const [idSegment, setIdSegment] = useState(null);
  const [idComponent, setIdComponent] = useState(null);
  const [idSegmentSelected, setIdSegmentSelected] = useState(null);
  const [idComponentSelected, setIdComponentSelected] = useState(null);

  const [filterSegment, setFilterSegment] = useState([]);
  const [filterComponent, setFilterComponent] = useState([]);
  const [newFilterPeriod, setNewFilterPeriod] = useState([]);
  const [period, setPeriod] = useState('bimonthly');

  const [arrLessonData, setArrLessonData] = useState([]);

  const [lessonsData, setLessonsData] = useState([]);

  const [loading, setLoading] = useState(false);

  const [showManagePlanModal, setShowManagePlanModal] = useState(false);
  const [managePlanType, setManagePlanType] = useState('');

  const [textareaValue, setTextareaValue] = useState('');

  // use ref to store the previous value of the lessons
  const prevLessons = useRef(lessons);

  // useEffect to compare the previous value of lessons with the current value
  useEffect(() => {
    if (prevLessons.current !== lessons) {
      setLessonsData(lessons);
      prevLessons.current = lessons;

      setLoading(false);
    }

    return () => {
      setLessonsData([]);
    };
  }, [lessons]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await getFilterCatalogings();
      await getPlansData({
        idPlan: params.idPlan,
        idComponent: params.component,
        period: params.period || 'bimonthly',
        idPlanSaved: params.planSaved,
      });
      setIdSegmentSelected(params.segment);
      setIdComponentSelected(params.component);
    })();

    // clean up on unmount component
    return () => {
      setFilterSegment([]);
      setFilterComponent([]);
    };
  }, []);

  useEffect(() => {
    if (filterSegment.length <= 0 && segmentFilterType) {
      setFilterSegment(filters);
    }
    if (filterSegment.length > 0 && idSegment && !segmentFilterType) {
      setFilterComponent(filters);
    }
  }, [filters]);

  useEffect(async () => {
    setLoading(true);
    if (idSegment && filterSegment.length > 0) {
      await getFilterCatalogings({ idCataloging: idSegment });
      // setLoading(false);
    }
  }, [idSegment, filterSegment]);

  useEffect(async () => {
    if (period || idComponent) {
      setLoading(true);
      await getPlansData({
        idPlan: params.idPlan,
        idComponent: idComponent || params.component,
        period: period || 'bimonthly',
        idPlanSaved: params.planSaved,
      });

      if (idComponent) {
        await getCatalogingAbilities({ idCataloging: idComponent });
      }
    }
  }, [period, idComponent]);

  useEffect(() => {
    if (filterPeriod.length > 0) {
      const arr = [];
      filterPeriod.map((el) =>
        arr.push({
          value: el,
          label:
            el === 'bimonthly'
              ? intl.formatMessage(messages.bimonthly)
              : intl.formatMessage(messages.quarterly),
        }),
      );

      setNewFilterPeriod(arr);
    }
  }, [filterPeriod]);

  useEffect(() => {
    if (lessonData) {
      setArrLessonData(lessonData);
    }
  }, [lessonData]);

  function handleExportPlan() {
    // Map lessonsData into the desired format
    const columns = [
      'Coleção',
      'Etapa',
      'Ano',
      'Componente Curricular',
      'Bimestre',
      'Trimestre',
      'Unidade/Capítulo',
      'Componentes da unidade',
      'Habilidades trabalhadas',
      'Procedimento de checagem',
      // 'Recursos',
      'Aulas',
    ];

    // Flatten lessonsData
    const rows = [];

    lessonsData.forEach((lessonBlock) => {
      lessonBlock.forEach((lesson) => {
        const row = {};
        lesson.values.forEach((value) => {
          switch (value.label) {
            case 'Coleção':
              row['Coleção'] = value.value;
              break;
            case 'Etapa':
              row['Etapa'] = value.value;
              break;
            case 'Ano':
              row['Ano'] = value.value;
              break;
            case 'Componente curricular':
              row['Componente Curricular'] = value.value;
              break;
            case 'Bimestre':
              row['Bimestre'] = value.value;
              break;
            case 'Trimestre':
              row['Trimestre'] = value.value;
              break;
            case 'Unidade/Capítulo':
              row['Unidade/Capítulo'] = value.value;
              break;
            case 'Componentes da unidade (temática básica trabalhada)':
              row['Componentes da unidade'] = value.value;
              break;
            case 'Habilidades trabalhadas':
              row['Habilidades trabalhadas'] = value.value.join(', ');
              break;
            case 'Procedimentos de checagem':
              row['Procedimento de checagem'] = value.value.join(', ');
              break;
            // case 'Recursos':
            //   row['Recursos'] = value.value.join(', ') || '';
            //   break;
            case 'Aulas':
              row['Aulas'] = value.value;
              break;
            default:
              break;
          }
        });
        rows.push(row);
      });
    });

    // Create a worksheet and workbook
    const worksheet = XLSX.utils.json_to_sheet(rows, { header: columns });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Planilha1');

    const teacherName = teacher.fullname.replace(/ /g, '_');
    let segment = filterSegment.find((el) => el.value === idSegmentSelected)['label'];
    segment = segment.split('-')[1].trim();
    segment = segment.replace(/ /g, '');
    let component = filterComponent.find((el) => el.value === idComponentSelected)['label'];
    component = component.replace(/ /g, '_').toLowerCase();
    const fileName = `Planejamento_${teacherName}_${segment}_${component}.xlsx`;

    // Generate the Excel file
    XLSX.writeFile(workbook, fileName);
  }

  function handleManagePlanModal(type) {
    setManagePlanType(type);
    setShowManagePlanModal(true);
  }

  function handleCloseManagePlanModal() {
    setShowManagePlanModal(false);
    setManagePlanType('');
  }

  async function handleManagePlan() {
    setLoading(true);
    let status = null;

    if (managePlanType === 'approved') {
      status = 1;
    } else if (managePlanType === 'disapproved') {
      status = 2;
    }

    await manageTeacherPlan({
      idPlan: params.idPlan,
      idPlanSaved: params.planSaved,
      comments: textareaValue,
      status: status,
    });

    history.push(
      `/plans-validation?idPlan=${params.idPlan}&planSaved=${params.planSaved}&segment=${
        idSegment || params.segment
      }&component=${idComponent || params.component}&period=${period || 'bimonthly'}`,
    );
    setLoading(false);
  }

  useEffect(() => {
    setTextareaValue(textareaValue);
  }, [textareaValue]);

  return (
    <Fragment>
      <Loader show={isLoading || loading} />
      <Row>
        <Col>
          <PanelFilters
            idSegment={idSegment}
            idComponent={idComponent}
            filterSegment={filterSegment}
            filterComponent={filterComponent}
            filterPeriod={newFilterPeriod}
            setIdSegment={(value) => setIdSegment(value)}
            setIdComponent={(value) => setIdComponent(value)}
            setPeriod={(value) => setPeriod(value)}
            handleShowChangePeriodModal={(value) => handleShowChangePeriodModal(value)}
            idSegmentSelected={idSegmentSelected}
            idComponentSelected={idComponentSelected}
            periodSelected={period}
            hasPlanCustomChanges={hasPlanCustomChanges}
            disabledPeriod
            disabled
          />
          <div className="cp-container viewPlan">
            <div>
              <h3 className="cp-title">{intl.formatMessage(messages.title)}</h3>
              <h5 className="updatedTeacher">
                {intl.formatMessage(messages.updatedBy)}:
                <Avatar
                  src={getUserAvatarUrl(teacher.photo)}
                  placeholderSrc={placeholderSrc}
                  size="28px"
                />
                {teacher.fullname}
              </h5>
              <h5>
                {intl.formatMessage(messages.subtitle)}:{' '}
                <span className="text-purple">
                  {params.planSaved
                    ? updatedDate && `${intl.formatMessage(messages.updatedIn)} ${updatedDate}`
                    : intl.formatMessage(messages.underConstruction)}
                </span>
              </h5>
            </div>

            <div className="btns-container">
              <Button onClick={() => handleExportPlan()} className="btn">
                {intl.formatMessage(messages.exportPlan)}
              </Button>
              <Button onClick={() => handleManagePlanModal('approved')} className="btn">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="122.872"
                  height="102.997"
                  viewBox="0 0 122.872 102.997"
                >
                  <path
                    id="small-check-mark-icon"
                    d="M4.82,69.68c-14.89-16,8-39.87,24.52-24.76,5.83,5.32,12.22,11,18.11,16.27L92.81,5.46c15.79-16.33,40.72,7.65,25.13,24.07l-57,68A17.49,17.49,0,0,1,48.26,103a16.94,16.94,0,0,1-11.58-4.39c-9.74-9.1-21.74-20.32-31.86-28.9Z"
                    transform="translate(-0.004 -0.004)"
                    fill="#10a64a"
                  />
                </svg>
                {intl.formatMessage(messages.approvePlan)}
              </Button>
              <Button onClick={() => handleManagePlanModal('disapproved')} className="btn">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="122.918"
                  height="122.918"
                  viewBox="0 0 122.918 122.918"
                >
                  <path
                    id="cross-icon"
                    d="M6,6H6A20.53,20.53,0,0,1,35,6L61.5,32.49,87.93,6a20.54,20.54,0,0,1,29,0h0a20.53,20.53,0,0,1,0,29L90.41,61.44,116.9,87.93a20.54,20.54,0,0,1,0,29h0a20.54,20.54,0,0,1-29,0L61.44,90.41,35,116.9a20.54,20.54,0,0,1-29,0H6a20.54,20.54,0,0,1,0-29L32.47,61.44,6,34.94A20.53,20.53,0,0,1,6,6Z"
                    transform="translate(-0.008 -0.004)"
                    fill="#ff4141"
                    fill-rule="evenodd"
                  />
                </svg>
                {intl.formatMessage(messages.disapprovePlan)}
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <PlanCards lessons={lessonsData} period={period} />
        </Col>
      </Row>

      <Modal
        show={showManagePlanModal}
        icon={managePlanType === 'approved' ? 'pe-7s-check' : 'pe-7s-close-circle'}
        onClose={() => handleCloseManagePlanModal(false)}
        title={
          managePlanType === 'approved'
            ? intl.formatMessage(messages.approvePlan)
            : intl.formatMessage(messages.disapprovePlan)
        }
        buttons={[
          {
            label: intl.formatMessage(messages.cancel),
            onClick: () => handleCloseManagePlanModal(false),
          },
          {
            label: intl.formatMessage(messages.confirm),
            onClick: () => handleManagePlan(),
            disabled: textareaValue === '',
          },
        ]}
      >
        <textarea
          name="managePlan"
          id=""
          cols="30"
          rows="10"
          placeholder={intl.formatMessage(messages.comments)}
          value={textareaValue}
          onChange={(e) => setTextareaValue(e.target.value)}
        ></textarea>
      </Modal>
    </Fragment>
  );
};

ViewPlanContainer.propTypes = {
  intl: intlShape,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  getFilterCatalogings: PropTypes.func.isRequired,
  getPlansData: PropTypes.func.isRequired,
  filters: PropTypes.arrayOf(PropTypes.any).isRequired,
  filterPeriod: PropTypes.arrayOf(PropTypes.any),
  lessons: PropTypes.arrayOf(PropTypes.any).isRequired,
  idPlan: PropTypes.any,
  updatedDate: PropTypes.string,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  isLoading: PropTypes.bool.isRequired,
  hasPlanCustomChanges: PropTypes.bool,
  catalogingAbilities: PropTypes.arrayOf(PropTypes.any).isRequired,
  getCatalogingAbilities: PropTypes.func.isRequired,
  getLessonData: PropTypes.func.isRequired,
  lessonData: PropTypes.arrayOf(PropTypes.any).isRequired,
  segmentFilterType: PropTypes.bool.isRequired,
  teacher: PropTypes.objectOf(PropTypes.any).isRequired,
  manageTeacherPlan: PropTypes.func.isRequired,
};

ViewPlanContainer.defaultProps = {
  intl: [],
  updatedDate: '',
  filterPeriod: [],
  idPlan: null,
  hasPlanCustomChanges: false,
};

const mapStateToProps = ({ viewPlan, panel }) => ({
  ...viewPlan,
  ...panel,
});

export default compose(
  withRouter,
  injectIntl,
  connect(
    mapStateToProps,
    act.promisify(
      { ...viewPlanActions, ...panelActions },
      {
        include: [
          'getFilterCatalogings',
          'getDashboardTeacher',
          'getCatalogingAbilities',
          'getLessonData',
          'manageTeacherPlan',
        ],
      },
    ),
  ),
)(ViewPlanContainer);
