/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-fragments */
import React, { useState, useEffect, useRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import { injectIntl, intlShape } from 'react-intl';
import { Modal, InputGroup, Row, Col, Alert } from 'lec-ui';

import './ClassConfiguration.scss';
import './AddNewLessonModal.scss';

import messages from './addNewLessonModalMessages';

const shapeAddNewLessonModalSchema = {
  lessonDate: Yup.string().required('errorRequired'),
  lessonPeriods: Yup.array().min(1, 'errorRequired'),
};

const AddNewLessonModalSchema = Yup.object().shape(shapeAddNewLessonModalSchema);

const initialValues = {
  lessonDate: '',
  lessonPeriods: [],
};

const enhanceForm = withFormik({
  mapPropsToValues: ({ data }) => ({ ...data }),
  initialValues,
  validationSchema: AddNewLessonModalSchema,
  handleSubmit: (values, { props }) => {
    const payload = {
      idPlanScheduled: props.idPlanScheduled,
      idLesson: props.idLesson,
      date: values.lessonDate,
      lessonPeriods: values.lessonPeriods,
    };
    props.onSubmit(payload);
  },
  enableReinitialize: true,
});

const AddNewLessonModal = (props) => {
  const {
    intl,
    idPlanScheduled,
    idLesson,
    setFieldValue,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    errors,
    touched,
    showAddExtraLessonModal,
    setShowAddExtraLessonModal,
    errorAddScheduledLesson,
    // successAddScheduledLesson,
    scheduledPlanCoursesHours,
    setTouched,
  } = props;

  const calendarIcon = <i className="ti-calendar" />;

  const [alerts, setAlerts] = useState([]);

  const [lessonDate, setLessonDate] = useState('');
  const [allLessonPeriods, setAllLessonPeriods] = useState(false);

  const [lessonPeriods, setLessonPeriods] = useState([]);
  const [selectedLessonPeriods, setSelectedLessonPeriods] = useState([]);
  const [lessonPeriodsErrors, setLessonPeriodsErrors] = useState(false);

  const prevSelectedLessonPeriods = useRef(selectedLessonPeriods);

  useEffect(() => {
    resetModal();
  }, []);

  // useEffect(() => {
  //   if (successAddScheduledLesson !== '') {
  //     resetModal();
  //   }
  // }, [successAddScheduledLesson]);

  useEffect(() => {
    resetModal();
  }, [showAddExtraLessonModal]);

  useEffect(() => {
    setLessonPeriods(scheduledPlanCoursesHours);
  }, [scheduledPlanCoursesHours]);

  useEffect(() => {
    if (errorAddScheduledLesson !== '') {
      handleAlert(errorAddScheduledLesson, 'danger');
    }
  }, [errorAddScheduledLesson]);

  useEffect(() => {
    const previousArray = prevSelectedLessonPeriods.current;
    const arraysAreEqual = JSON.stringify(selectedLessonPeriods) === JSON.stringify(previousArray);

    if (!arraysAreEqual) {
      setFieldValue('lessonPeriods', selectedLessonPeriods)
      if (selectedLessonPeriods.length <= 0) {
        setLessonPeriodsErrors(true);
      } else {
        setLessonPeriodsErrors(false);
      }
    }

    prevSelectedLessonPeriods.current = selectedLessonPeriods;
  }, [selectedLessonPeriods]);

  function saveAddLessonModal() {
    if (selectedLessonPeriods.length <= 0) {
      setLessonPeriodsErrors(true);
    }

    handleSubmit();
  }

  function getMessageError(field) {
    if (errors && errors[field]) {
      return intl.formatMessage(messages[errors[field]]);
    }
    return undefined;
  }

  function handleAlert(message, type) {
    setAlerts(alerts.concat({ type, message, timeout: 3000 }));
  }

  function removeAlert(alert) {
    setAlerts(alerts.filter((a) => a !== alert));
  }

  function handleIsValid(field) {
    if (errors && errors[field] && touched) {
      return false;
    }
    return undefined;
  }

  function handleChangeAllLessonPeriods() {
    if (allLessonPeriods) {
      setSelectedLessonPeriods([]);
    } else {
      setSelectedLessonPeriods(lessonPeriods.map((item) => item.value))
    }
    setAllLessonPeriods(!allLessonPeriods);
  }

  function handleChangeSelectedLessonPeriods(e) {
    const newValue = e.target.value;

    setSelectedLessonPeriods(
      selectedLessonPeriods.includes(newValue) 
        ? (prevArray) => prevArray.filter(value => value !== newValue)
        : (prevArray) => [...prevArray, newValue]
    );
  }

  function handleCloseModal() {
    resetModal();
    setShowAddExtraLessonModal(false);
  }

  function resetModal() {
    setLessonDate('');
    setFieldValue('lessonDate', '');
    setFieldValue('lessonPeriods', []);
    setSelectedLessonPeriods([]);
    setLessonPeriodsErrors(false);
    prevSelectedLessonPeriods.current = [];
    setAllLessonPeriods(false);
    setTouched({});
  }

  return (
    <Fragment>
      <Modal
        show={showAddExtraLessonModal}
        onClose={() => handleCloseModal()}
        title={intl.formatMessage(messages.addNewLesson)}
        half
        buttons={[
          {
            label: intl.formatMessage(messages.cancel),
            onClick: () => handleCloseModal(),
          },
          {
            label: intl.formatMessage(messages.save),
            onClick: () => saveAddLessonModal(),
            primary: true,
            disabled: !isValid
          },
        ]}
      >
        <div>
          <Row>
            <Col sm={12}>
              {alerts.map((alert) => (
                <Alert
                  type={alert.type}
                  onClose={() => removeAlert(alert)}
                  onExpire={() => removeAlert(alert)}
                  timeout={alert.timeout}
                >
                  {alert.message === 'notAllowedInSameDate' || true ? intl.formatMessage(messages.notAllowedInSameDate) : ''}
                </Alert>
              ))}
              <div className="add-extra-lesson-form">
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col>
                      <InputGroup
                        icon={calendarIcon}
                        label={intl.formatMessage(messages.date)}
                        name="lessonDate"
                        valid={touched && touched.lessonDate && handleIsValid('lessonDate')}
                        invalidfeedback={touched && touched.lessonDate && getMessageError('lessonDate')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="date"
                        value={lessonDate || ''}
                        readOnly={false}
                        disabled={false}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="fullTimePeriodToggle">
                      <div>{intl.formatMessage(messages.fullTimePeriod)} </div>
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={allLessonPeriods}
                          onChange={handleChangeAllLessonPeriods}
                        />
                        <span className="slider" />
                        <i className="fa-light fa-book-open-reader" />
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className={lessonPeriodsErrors ? "is-invalid" : undefined}>
                        <div className="form-group checkbox-list">
                          <label>{intl.formatMessage(messages.whichClassesWillBeOccupied)}</label>
                          <ul>
                            {lessonPeriods.length > 0 && lessonPeriods.map((item) => (
                              <li key={item.value}>
                                <div className="text-left">
                                  <input
                                    className="custom"
                                    id={item.value}
                                    name="lessonPeriods"
                                    type="checkbox"
                                    checked={selectedLessonPeriods.includes(item.value)}
                                    value={item.value}
                                    onChange={(e) => handleChangeSelectedLessonPeriods(e)}
                                  />
                                  <label htmlFor={item.value}>
                                    {item.label}
                                  </label>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                        <div className="invalid-feedback" style={{'display': lessonPeriodsErrors && 'block'}}>
                          {intl.formatMessage(messages.errorRequired)}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </form>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    </Fragment>
  );
};

AddNewLessonModal.propTypes = {
  intl: intlShape,
  isLoading: PropTypes.bool,
  content: PropTypes.arrayOf(PropTypes.any),
  showAddExtraLessonModal: PropTypes.bool.isRequired,
  setShowAddExtraLessonModal: PropTypes.func.isRequired,
  errors: PropTypes.shape([]).isRequired,
  scheduledPlanCoursesHours: PropTypes.shape([]).isRequired,
};

AddNewLessonModal.defaultProps = {
  intl: [],
  isLoading: false,
  content: [],
  scheduledPlanCoursesHours: [],
};

export default compose(injectIntl, enhanceForm)(AddNewLessonModal);
