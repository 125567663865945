import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Avatar.scss';

const Avatar = ({ className, size, fullSize, src, placeholderSrc }) => (
  <div
    className={classNames('avatar-sm', className)}
    style={{
      width: fullSize ? '100%' : size,
      height: fullSize ? '100%' : size,
      background: `url(${src}) ${
        placeholderSrc ? `, url(${placeholderSrc})` : ''
      } no-repeat center center`,
    }}
  />
);

Avatar.propTypes = {
  src: PropTypes.string.isRequired,
  placeholderSrc: PropTypes.string,
  size: PropTypes.number,
  className: PropTypes.string,
  fullSize: PropTypes.bool,
};

Avatar.defaultProps = {
  placeholderSrc: undefined,
  className: undefined,
  fullSize: false,
  size: 70,
};

export default Avatar;
