/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

const View = ({ className, ...props }) => <div className={[className].join(' ')} {...props} />;

View.propTypes = {
  className: PropTypes.string,
};

View.defaultProps = {
  className: '',
};

export default View;
