/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-fragments */
/* eslint-disable react/no-array-index-key */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { injectIntl, intlShape } from 'react-intl';

import messages from '../myClassMessages';
import '../MyClass.scss';

const Schedule = (props) => {
  const { intl, onClick } = props;

  return (
    <Fragment>
      <a className="card-more" onClick={onClick}>
        {intl.formatMessage(messages.loadMore)}
      </a>
    </Fragment>
  );
};

Schedule.propTypes = {
  intl: intlShape.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default compose(injectIntl)(Schedule);
