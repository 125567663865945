import * as act from 'app/actions';
import * as actions from './studentLessonActions';

const initialState = {
  isLoading: false,
  studentLessonData: {},
  canSendMessages: false,
  conversationsList: [],
  messagesList: [],
  mediaFile: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case act.request(actions.GET_STUDENT_LESSON):
      return {
        ...state,
        isLoading: true,
        studentLessonData: initialState.studentLessonData,
      };
    case act.success(actions.GET_STUDENT_LESSON):
      return {
        ...state,
        studentLessonData: action.payload.content,
        isLoading: initialState.isLoading,
      };
    case act.failure(actions.GET_STUDENT_LESSON):
      return {
        ...state,
        isLoading: initialState.isLoading,
      };
    case act.request(actions.GET_SCHEDULED_LESSON_CHAT):
      return {
        ...state,
        isLoading: true,
        canSendMessages: initialState.canSendMessages,
        conversationsList: initialState.conversationsList,
        messagesList: initialState.messagesList,
      };
    case act.success(actions.GET_SCHEDULED_LESSON_CHAT):
      return {
        ...state,
        isLoading: initialState.isLoading,
        canSendMessages: action.payload.canSendMessages,
        conversationsList: action.payload.conversationsList,
        messagesList: action.payload.messagesList,
      };
    case act.failure(actions.GET_SCHEDULED_LESSON_CHAT):
      return {
        ...state,
        isLoading: initialState.isLoading,
        canSendMessages: initialState.canSendMessages,
        conversationsList: initialState.conversationsList,
        messagesList: initialState.messagesList,
      };
    case act.request(actions.ADD_SCHEDULED_LESSON_CHAT_MESSAGE):
      return {
        ...state,
        isLoading: true,
      };
    case act.success(actions.ADD_SCHEDULED_LESSON_CHAT_MESSAGE):
      return {
        ...state,
        isLoading: initialState.isLoading,
      };
    case act.failure(actions.ADD_SCHEDULED_LESSON_CHAT_MESSAGE):
      return {
        ...state,
        isLoading: initialState.isLoading,
      };
    case act.request(actions.DOWNLOAD_LESSON_RESOURCE_FILE):
      return {
        ...state,
        isLoading: true,
      };
    case act.success(actions.DOWNLOAD_LESSON_RESOURCE_FILE):
      return {
        ...state,
        isLoading: initialState.isLoading,
      };
    case act.failure(actions.DOWNLOAD_LESSON_RESOURCE_FILE):
      return {
        ...state,
        isLoading: initialState.isLoading,
      };
    case act.request(actions.DELETE_SCHEDULED_LESSON_RESOURCES):
      return {
        ...state,
        isLoading: true,
      };
    case act.success(actions.DELETE_SCHEDULED_LESSON_RESOURCES):
      return {
        ...state,
        isLoading: initialState.isLoading,
      };
    case act.failure(actions.DELETE_SCHEDULED_LESSON_RESOURCES):
      return {
        ...state,
        isLoading: initialState.isLoading,
      };
    case act.request(actions.VIEW_LESSON_RESOURCE_FILE):
      return {
        ...state,
        isLoading: true,
        mediaFile: initialState.mediaFile,
      };
    case act.success(actions.VIEW_LESSON_RESOURCE_FILE):
      return {
        ...state,
        isLoading: initialState.isLoading,
        mediaFile: action.payload,
      };
    case act.failure(actions.VIEW_LESSON_RESOURCE_FILE):
      return {
        ...state,
        isLoading: initialState.isLoading,
        mediaFile: initialState.mediaFile,
      };
    default:
      return state;
  }
};

export default reducer;
