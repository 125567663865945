/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';

import messages from './configurationMessages';
import messages2 from './schoolFormMessages';
import ConfigurationForm from './ConfigurationForm';
import * as actions from './configurationActions';

import SchoolForm from './SchoolForm';

const ConfigurationContainer = (props) => {
  const {
    intl,
    save,
    upload,
    removePhoto,
    isLoading,
    userInfo,
    user,
    userInfoStatus,
    getSchools,
    getSchoolDetails,
    saveSchoolDetails,
    schoolDetails,
    role,
    status,
    idSchool,
    idUserSchool,
    schools,
    schoolFormStatus,
  } = props;

  const [stateIdUserSchool, setStateIdUserSchool] = useState(null);
  const [activeTabUser, setActiveTabUser] = useState(true);
  const [activeTabSchool, setActiveTabSchool] = useState(false);

  useEffect(() => {
    getSchools();
  }, []);

  useEffect(() => {
    if (schools.length > 0) {
      const school = schools[0];
      setStateIdUserSchool(school.idUserSchool);
      getSchoolDetails(school.idUserSchool);
    }
  }, [schools]);

  function handleChangeTab(tab) {
    if (tab === 'user') {
      setActiveTabUser(true);
      setActiveTabSchool(false);
    } else if (tab === 'school') {
      setActiveTabUser(false);
      setActiveTabSchool(true);
    }
  }

  async function handleUploadPhoto(photo) {
    await upload(photo);
    setTimeout(() => {
      window.location.reload(false);
    }, 1000);
  }

  async function handleRemovePhoto() {
    await removePhoto();
    setTimeout(() => {
      window.location.reload(false);
    }, 1000);
  }

  return (
    <div>
      <div className="d-flex">
        <div className="col-sm-3">
          <h1 className="page-title mb-5 mr-5">{intl.formatMessage(messages.title)}</h1>
        </div>

        <div className="col-sm-9">
          <button
            className={`buttonTab ${activeTabUser && 'active'}`}
            type="button"
            onClick={() => handleChangeTab('user')}
          >
            <h2 className="section-title">
              <i className="pe-7s-tools" />
              {intl.formatMessage(messages.myData)}
            </h2>
          </button>
          {schoolDetails && user.idType < 4 && (
            <button
              className={`buttonTab ${activeTabSchool && 'active'}`}
              type="button"
              onClick={() => handleChangeTab('school')}
            >
              <h2 className="section-title">
                <i className="pe-7s-tools" />
                {intl.formatMessage(messages2.schoolData)}
              </h2>
            </button>
          )}
        </div>
      </div>

      {user && activeTabUser && (
        <ConfigurationForm
          onSubmit={save}
          user={user}
          onUpload={(e) => handleUploadPhoto(e)}
          onRemove={() => handleRemovePhoto()}
          isLoading={isLoading}
          userInfo={userInfo}
          userInfoStatus={userInfoStatus}
          intl={intl}
        />
      )}

      {schoolDetails && activeTabSchool && (
        <SchoolForm
          data={schoolDetails}
          onSubmit={saveSchoolDetails}
          schoolFormStatus={schoolFormStatus}
          role={role}
          statusEdition={status}
          idSchool={idSchool}
          schools={schools}
          idUserSchool={idUserSchool || stateIdUserSchool}
          user={user}
        />
      )}
    </div>
  );
};

ConfigurationContainer.propTypes = {
  intl: intlShape.isRequired,
  upload: PropTypes.func.isRequired,
  removePhoto: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  userInfo: PropTypes.string.isRequired,
  user: PropTypes.shape({
    idType: PropTypes.number,
  }).isRequired,
  userInfoStatus: PropTypes.string,
  getSchoolDetails: PropTypes.func.isRequired,
  role: PropTypes.number.isRequired,
  match: PropTypes.shape({}).isRequired,
  saveSchoolDetails: PropTypes.func.isRequired,
  schoolDetails: PropTypes.shape({}),
  getSchools: PropTypes.func.isRequired,
  status: PropTypes.number.isRequired,
  idSchool: PropTypes.number,
  idUserSchool: PropTypes.number,
  schools: PropTypes.array.isRequired,
  save: PropTypes.func.isRequired,
  schoolFormStatus: PropTypes.number.isRequired,
};

ConfigurationContainer.defaultProps = {
  userInfoStatus: '',
  schoolDetails: {},
  idSchool: null,
  idUserSchool: null,
};

const mapStateToProps = ({ app, configuration }) => ({
  user: app.user,
  userInfo: configuration.userInfo,
  userInfoStatus: configuration.userInfoStatus,
  isLoading: configuration.isLoading,
  role: app.role,
  status: configuration.schoolFormStatus,
  schools: configuration.schools,
  schoolDetails: configuration.schoolDetails,
  schoolFormStatus: configuration.schoolFormStatus,
  ...app,
});

export default compose(connect(mapStateToProps, actions), injectIntl)(ConfigurationContainer);
