// @flow
import { fork, all, take } from 'redux-saga/effects';
import * as task from 'app/sagas';
import * as a from 'app/api';
import * as actions from './myPlansActions';

export const getMyPlans = task.apiTask(actions.GET_MY_PLANS, a.api.post, '/teach/getMyPlans.php');
export const deletePlan = task.apiTask(actions.DELETE_PLAN, a.api.post, '/teach/deletePlan.php');
export const deleteScheduledPlan = task.apiTask(
  actions.DELETE_SCHEDULED_PLAN,
  a.api.post,
  '/teach/deleteScheduledPlan.php',
);

export function* watchGetMyPlans() {
  while (true) {
    const { payload } = yield take(actions.GET_MY_PLANS);
    yield fork(getMyPlans, payload);
  }
}

export function* watchDeletePlan() {
  while (true) {
    const { payload } = yield take(actions.DELETE_PLAN);
    yield fork(deletePlan, payload);
  }
}

export function* watchDeleteScheduledPlan() {
  while (true) {
    const { payload } = yield take(actions.DELETE_SCHEDULED_PLAN);
    yield fork(deleteScheduledPlan, payload);
  }
}

// Root module saga
export function* sagas() {
  yield all([fork(watchGetMyPlans), fork(watchDeletePlan), fork(watchDeleteScheduledPlan)]);
}
