import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Section = props => (
    <div>
        <div>
        <h1 className={classNames("page-title mb-5", props.className)}>{props.title}</h1>
        </div>
        <div>
            {props.children}
        </div>
    </div>
)

Section.propTypes = {
    title: PropTypes.node,
    children: PropTypes.node,
};

export default Section;