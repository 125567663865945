import * as act from 'app/actions';

export const REGISTER = 'register/REGISTER';
export const GET_STATES = 'register/GET_STATES';
export const GET_CITIES = 'register/GET_CITIES';
export const SEARCH_SCHOOLS = 'register/SEARCH_SCHOOLS';

export const register = (payload) => act.action(REGISTER, { payload });
export const getStates = (payload) => act.action(GET_STATES, { payload });
export const getCities = (payload) => act.action(GET_CITIES, { payload });
export const searchSchools = (payload) => act.action(SEARCH_SCHOOLS, { payload });
