/* eslint-disable react/jsx-fragments */
/* eslint-disable react/jsx-no-bind */
import React, { Fragment } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import { Alert } from 'react-bootstrap';
import { Modal } from 'lec-ui';
import Select from 'react-select';

import messages from '../myClassMessages';

const AddGroupModal = (props) => {
  const {
    intl,
    isOpen,
    hasError,
    schools,
    selectedSchool,
    groupName,
    voucher,
    handleClose,
    handleConfirm,
    handleChangeInput,
    handleChangeSelect,
    handleTimeout,
    isStudent,
    errCode,
  } = props;

  return (
    <Modal
      show={isOpen}
      onClose={handleClose}
      title={
        isStudent
          ? intl.formatMessage(messages.buttonLabelStudent)
          : intl.formatMessage(messages.buttonLabel)
      }
      icon="pe-7s-drawer"
      buttons={[
        {
          label: intl.formatMessage(messages.cancel),
          onClick: handleClose,
        },
        {
          label: intl.formatMessage(messages.confirm),
          onClick: () => handleConfirm(),
          primary: true,
          disabled: isStudent ? voucher === '' : groupName === '',
        },
      ]}
    >
      {hasError && (
        <div style={{ maxWidth: '50%', margin: '10px auto' }}>
          <Alert timeout={3000} onTimeout={handleTimeout}>
            {isStudent ? (
              <Fragment>
                {errCode === 11
                  ? intl.formatMessage(messages.modalError11Message)
                  : intl.formatMessage(messages.modalError15Message)}
              </Fragment>
            ) : (
              intl.formatMessage(messages.modalErrorMessage)
            )}
          </Alert>
        </div>
      )}
      <div className="my-class-modal-content">
        <div className="form-group">
          <label htmlFor="form-name">
            {isStudent
              ? intl.formatMessage(messages.modalFieldVoucher)
              : intl.formatMessage(messages.modalFieldGroupName)}
          </label>
          <input
            className="form-control"
            type="text"
            value={isStudent ? voucher : groupName}
            onChange={handleChangeInput}
          />
        </div>
        {!isStudent && schools.length > 0 && (
          <div className="form-group">
            <label htmlFor="form-name">{intl.formatMessage(messages.modalFieldSchool)}</label>
            <Select
              defaultValue={selectedSchool && selectedSchool}
              options={schools}
              placeholder={intl.formatMessage(messages.selectSchoolPlaceholder)}
              onChange={handleChangeSelect}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#f2f2f2',
                  primary: '#7A2ED3',
                  dangerLight: '#ffff',
                  danger: '#7A2ED3',
                },
              })}
              styles={{
                menu: (base) => ({ ...base, zIndex: 99999 }),
              }}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

AddGroupModal.propTypes = {
  intl: intlShape.isRequired,
  isOpen: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
  schools: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedSchool: PropTypes.objectOf(PropTypes.any),
  groupName: PropTypes.string.isRequired,
  voucher: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  handleChangeInput: PropTypes.func.isRequired,
  handleChangeSelect: PropTypes.func.isRequired,
  handleTimeout: PropTypes.func.isRequired,
  isStudent: PropTypes.bool.isRequired,
  errCode: PropTypes.string.isRequired,
};

AddGroupModal.defaultProps = {
  selectedSchool: null,
};

export default compose(injectIntl)(AddGroupModal);
